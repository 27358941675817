/***
 *
 * Controller class for user.
 * @file VenueBasicDetails.js
 * @description VenueBasicDetails component
 * @author Rajinder Singh
 * @since 22 Jul 2024
 *
 */

import React, { useEffect, useRef, useState } from "react";
import "./VenueBasicDetails.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode"
import { Col, Input, Progress, Row } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap/lib";
import { useSelector } from "react-redux";
import SwitchSVG from "../../pages/Infrastructure/InfraList/SwitchSVG";
import APSVG from "../../pages/Infrastructure/InfraList/APSVG";
import { ReactComponent as Design } from "../../assets/images/icons/BusinessView.svg";
import { ReactComponent as Live } from "../../assets/images/icons/NetworkView.svg";
import { ReactComponent as Deployed } from "../../assets/images/icons/ProjectView.svg";
import { ReactComponent as MapViewIcon } from "../../assets/images/icons/map_view/map_view_icon.svg";
import { ReactComponent as StreetViewIcon } from "../../assets/images/icons/map_view/street_view_icon.svg";
import { ALERT_HIG, ALERT_LOW, ALERT_MED, DEPLOYMENT_VIEW, VENUE_STATE } from "../../utility/constants";
import { statusIcons, VenueIcon } from "../../pages/Venue/VenueTree";
import { ReactComponent as WarningIcon } from '../../assets/images/icons/OrangeWarning.svg'
import { ReactComponent as CriticalIcon } from '../../assets/images/icons/emergency-siren.svg'
import { ReactComponent as MinorIcon } from '../../assets/images/icons/WarningWhiteExclamation.svg'
import { ReactComponent as GreenTick } from '../../assets/images/icons/Success.svg'
import { ReactComponent as InstallIcon } from '../../assets/images/icons/install_cloud_color.svg'
import { ReactComponent as PhotoIcon } from '../../assets/images/icons/camera-icon.svg'
import { ReactComponent as PlacementIcon } from '../../assets/images/icons/placement-icon.svg'
import { ReactComponent as ValidateIcon } from '../../assets/images/icons/validate-icon.svg'
import { ReactComponent as TicketIcon } from '../../assets/images/icons/ticket-icon-col.svg'
import { ReactComponent as UpdateIcon } from "../../assets/images/icons/UpdateIcon.svg";
import { ReactComponent as APColored } from "../../assets/images/icons/AP.svg";
import { ReactComponent as SwitchColored } from "../../assets/images/icons/SwitchColored.svg";


import { dateLeftFinder } from "../../utility/Utils";
import LightBadge from "../LightBadge";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP } from "./helper";
import { getVenueStreetView } from "../MapModal";
import { dateTimeFormatter } from "../../utility/Localization";
import VenueStateGroup from "../VenueStateGroup";
import FirmwareReschedule from "../FirmwareRescheduleVenue";
import LinkWrapper from "../LinkWrapper";
import { zoneMap } from "../MapWidget";
import { UPTIME } from "../../pages/Infrastructure/Details";

export const venueOnlineInfraLink = (onlineCount, orgId, venueId) => {
  return onlineCount > 0 ?
    `/organization/${orgId}/venues/${venueId}/infrastructure/?filter=${JSON.stringify({
      status: ['ONLINE']
    })}`
    : null
}


const VenueBasicDetails = (props) => {
  const { editable, setFieldValue, setFormikAddress } = props
  const [pos, setPos] = useState(null)
  const [map, setMap] = useState(null)
  const [venues, setVenues] = useState([])
  const view = useSelector(store => store.identity.meta.view);
  const activeVenue = useSelector(store => store.activeVenue.data);
  const permissions = useSelector(store => store.rbac?.permissions);
  const rbac = useSelector(store => store.rbac?.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [selectedVenueState, setSelectedVenueState] = useState(activeVenue.state);
  const [streetview, setStreetview] = useState(null)
  const [venueInfoCount, setVenueInfoCount] = useState({
    tickets: 0,
    alarms: 0,
    lastStatusAt: 0
  })

  const [venueTasks, setVenueTasks] = useState({
    pendingTasks: 0,
    totalTasks: 0,
    notInstalled: 0,
    notPlaced: 0,
    notPhotos: 0,
    notValidated: 0
  })
  const [address, setAddress] = useState({ venue: activeVenue?.venueAddress?.addressLine });
  const [venueAddressAlert, setVenueAddressAlert] = useState(false);
  const [basicVenueDetails, setBasicVenueDetails] = useState({})

  const venueAdd = useRef();
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [googleMapView, setGoogleMapView] = useState(GOOGLE_MAP.MAP_VIEW)
  const [options, setOptions] = useState(null)




  Geocode.setApiKey(process.env.REACT_APP_MAP)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: 0, lng: 0 });
    map.fitBounds(bounds);
    setMap(map);
  }, [map])

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, [])

  useEffect(() => {
    if (activeVenue.venueId != null) {
      getVenueBasicDetails()
      getVenueTasks()
    }
  }, [])

  useEffect(() => {
    if (activeVenue.venueId != null) {
      getVenueStreetView(activeVenue.orgId, activeVenue.venueId, (res) => setStreetview(res));
      setTimeout(() =>
        setVenues([
          { lat: activeVenue?.venueAddress.latitude, lng: activeVenue?.venueAddress.longitude}
        ]), 500);
    }
  }, []);

  const getVenueTasks = () => {
    const { run, controller } = createRequest(services.telemetry.GET_VENUE_TASKS, [activeVenue.venueId, "AP"])
    run()
      .then(res => {
        setVenueTasks(res.data)
      })
      .catch(err => {
        make_custom_toast('error', 'Venue', (new CatchedWebError(err)).message)
      })
  }

  const getVenueBasicDetails = () => {
    const { run } = createRequest(services.telemetry.GET_VENUE_DETAILS, [activeVenue?.venueId])
    run()
      .then(res => {
        setBasicVenueDetails(res.data)
      })
  }


  const DeploymentVenueData = () => {
    return (
      <div className="bg-white rounded " style={{ height: '8.4em' }}>
        <div className="fw-600 mb-1">
          Pending Tasks {`(${basicVenueDetails?.pendingTasks}/${basicVenueDetails?.totalTasks})`}
        </div>

        <div >
          <Row >
            <Col >
              <div className="pending-task-row d-flex align-items-center mb-50"><InstallIcon width={22} height={22} /> Initialize ({basicVenueDetails?.initializePending})</div>
            </Col>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><PhotoIcon width={22} height={22} /> Photo ({basicVenueDetails?.photosPending})</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><PlacementIcon width={22} height={22} /> Physical Install ({basicVenueDetails?.installPending})</div>
            </Col>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><ValidateIcon width={22} height={22} /> Validate ({basicVenueDetails?.validatePending})</div>
            </Col>
          </Row>
        </div>
        <div className="mt-50">
          <Progress value={(1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) * 100} color="success" style={{ fontSize: "0.75rem" }}>
            {/* {((1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) > 0.2) ? `${Math.floor((1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) * 100)}%` : ""} */}
          </Progress>
        </div>

      </div>
    )
  }

  const criticalAlarmLink = (alarmImpact) => {
    return `/organization/${activeOrgId}/notification?filter=${JSON.stringify({
      venues: [{ venueId: activeVenue.venueId }],
      // orgs: orgs.map(it => {return{orgId:it}}),
      // venueChildren: false, 
      alertImpact: [alarmImpact],
      alertType: ['alarm']
    })}`
  }

  const BasicVenueDetails = () => {
    return (
      <div style={{ height: '8.42em' }} className="d-flex flex-column justify-content-between">
        <div className="mb-50 row--equal-fit">
          <div className="row--equal-fit">
            <div>
              Alarms
            </div>
            <div className="d-flex align-items-center">
            {
              (basicVenueDetails?.criticalAlarm > 0) &&
              <span className="d-flex mr-1">
                <CriticalIcon width="18px" className="mr-50 " />
                <LinkWrapper
                  to={criticalAlarmLink(ALERT_HIG)}
                >
                  {basicVenueDetails?.criticalAlarm}
                </LinkWrapper>
              </span>
            }

            {
              (basicVenueDetails?.majorAlarm > 0) &&
              <span className="d-flex mr-1"> <WarningIcon width="18px" className="mr-50" />
                <LinkWrapper
                  to={criticalAlarmLink(ALERT_MED)}
                >
                  {basicVenueDetails?.majorAlarm}
                </LinkWrapper>
              </span>

            }
            {
              (basicVenueDetails?.minorAlarm > 0) &&
              <span className="d-flex mr-1"> <MinorIcon width="18px" className="mr-50" />
                <LinkWrapper
                  to={criticalAlarmLink(ALERT_LOW)}
                >
                  {basicVenueDetails?.minorAlarm}
                </LinkWrapper>
              </span>
            }
            {
              (basicVenueDetails?.criticalAlarm < 1 && basicVenueDetails?.majorAlarm < 1 && basicVenueDetails?.minorAlarm < 1) &&
              <span></span>
            }
            </div>
          </div>
          <div className="d-flex flex-wrap gap--half-rem">
            <div>
              Avg Uptime:
            </div>
            <div id='uptime'>
            {basicVenueDetails?.uptimeReported != null && basicVenueDetails?.realInfras ? UPTIME(basicVenueDetails?.uptimeReported, true) : ''}
            </div>
            {basicVenueDetails?.uptimeReported != null && basicVenueDetails?.realInfras ?
            <UncontrolledTooltip
              target='#uptime'
            >
              {UPTIME(basicVenueDetails?.uptimeReported)}
            </UncontrolledTooltip> : null}
          </div>
        </div>

        <div className="row--equal-fit w-50 mb-1  align-items-end">
          <div>
            Infrastructure
          </div>
          <div>
            <LinkWrapper
              to={venueOnlineInfraLink(
                basicVenueDetails?.onlineInfras,
                activeOrgId,
                activeVenue.venueId
              )}
            >
              <span style={{ fontSize: '20px' }} className={basicVenueDetails?.onlineInfras > 0 ? `text-success` : ''}
              >
                {basicVenueDetails?.onlineInfras ?? 0}
              </span>
            </LinkWrapper> / &nbsp;
            <LinkWrapper
              to={basicVenueDetails?.totalInfras > 0 ? `/organization/${activeOrgId}/venues/${activeVenue.venueId}/infrastructure/` : null}
            >
              {basicVenueDetails?.totalInfras ?? 0}
            </LinkWrapper>

          </div>
        </div>

        <div className="d-flex ">
          <span className="w-25">
            Release
          </span>
          <LightBadge className="m-0" color="success">{basicVenueDetails?.firmwareVersion}</LightBadge>
        </div>
      </div >)
  }

  const StreetView = (props) => {

    return (<div className="text-center" style={{ height: "7.45em" }}>
      {props.streetview != null ?
        <img className="ap-img" alt={"No streetview"} src={props.streetview} onError={() => props.setStreetview(null)} />
        : <div className="pt-2">
          No Streetview found
        </div>}
    </div>)
  }

  return (
    <div className="VenueBasicDetails" >

      {
        options != null &&
        <FirmwareReschedule
          options={options}
          setModal={setOptions}
          updateSnoozeDate={(snzDate, itemId, rescheduleInfra) => {
            // REDUX_WORKER.getInfra(infraData?.infraItemId, false)
            getVenueBasicDetails()
          }}
        />
      }

      {
        !editable &&
        <span className="d-flex">
          {
            basicVenueDetails?.scheduleSnoozed &&
            <span className="px-1 py-50 yellow-info-badge shadow-sm rounded mr-1 mb-1">
              <UpdateIcon width={24} height={24} className="mr-50" />
              Venue firmware upgrade in <span id="schedule-tag-info" className="text-primary cursor-pointer"
                onClick={() =>
                  setOptions({
                    scheduleId: basicVenueDetails?.scheduleId,
                    venueId: activeVenue.venueId,
                    scheduleDate: basicVenueDetails?.scheduleDate,
                    scheduleSnoozed: basicVenueDetails?.scheduleSnoozed,
                    timezone: basicVenueDetails?.venueTimezone,
                  })
                }>{dateLeftFinder(basicVenueDetails?.scheduleSnoozed)}</span>
              <UncontrolledTooltip target="schedule-tag-info">
                {dateTimeFormatter(basicVenueDetails?.scheduleSnoozed, 'long', 'short')}
              </UncontrolledTooltip>
            </span>
          }
          {
            (rbac?.level<3 && permissions?.manageTicket?.view && basicVenueDetails?.awaitingTickets > 0) &&
            <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded mb-1">
              <TicketIcon width={22} height={24} />
              <span className="ml-50"
              >
                <LinkWrapper
                  to={`/organization/${activeOrgId}/venues/${activeVenue.venueId}/admin?filter=${JSON.stringify({ "ticketFilter": "Awaiting feedback", "statuses": [7] })}`}
                >
                  {basicVenueDetails?.awaitingTickets} Ticket
                </LinkWrapper>
              </span> &nbsp; awaiting customer feedback
            </span>
          }
        </span>
      }

      <Row className="p-0 m-0">
        <Col xs="5" className="p-0 ">
          <div className="bg-white rounded p-2 d-flex flex-column justify-content-between" style={{ height: '160px' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center w-75">
                {statusIcons(basicVenueDetails?.zone)}
                {VenueIcon[activeVenue.venueType]}
                <strong className="venue-name">
                  {props.linkName
                    ? <LinkWrapper to={`/organization/${activeOrgId}/venues/${activeVenue.venueId}`}>
                      {activeVenue.venueName}
                    </LinkWrapper>
                    : <>{activeVenue.venueName}</>
                  }
                </strong>
              </div>

              <div className="d-flex align-items-center ">
                <div className="mr-1" >
                  State
                </div>
                {selectedVenueState != VENUE_STATE.DISABLED ? <Col className="pl-0 pt-50">
                  <div style={{ paddingBottom: '8px' }}>
                    <VenueStateGroup selectedSize={28} size={20} active={selectedVenueState} showDisableBadge={true} />
                  </div>
                </Col> : <LightBadge color="secondary">Disabled</LightBadge>}

              </div>
            </div>
            <div className="d-flex mt-1">
              {
                (basicVenueDetails?.infraTypeCount?.Outdoor > 0 || basicVenueDetails?.infraTypeCount?.Ceiling > 0 || basicVenueDetails?.infraTypeCount?.["Wall Plate"] > 0) &&
                <APColored width="22px" className="mr-1" />
              }
              {
                basicVenueDetails?.infraTypeCount?.Ceiling > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.Ceiling ?? 0} x Ceiling
                </span>
              }

              {
                basicVenueDetails?.infraTypeCount?.["Wall Plate"] > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["Wall Plate"] ?? 0}  x Wall Plate
                </span>
              }

              {
                basicVenueDetails?.infraTypeCount?.Outdoor > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.Outdoor ?? 0}  x Outdoor
                </span>
              }
            </div>

            <div className="d-flex mt-1">
              {
                (basicVenueDetails?.infraTypeCount?.["48 Port Switch"] > 0 || basicVenueDetails?.infraTypeCount?.["24 Port Switch"] > 0 || basicVenueDetails?.infraTypeCount?.["8 Port Switch"] > 0) &&
                < SwitchColored className="mr-1" />
              }
              {
                basicVenueDetails?.infraTypeCount?.["48 Port Switch"] > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["48 Port Switch"] ?? 0}  x 48 Ports
                </span>
              }
              {
                basicVenueDetails?.infraTypeCount?.["24 Port Switch"] > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["24 Port Switch"] ?? 0}  x 24 Ports
                </span>
              }
              {
                basicVenueDetails?.infraTypeCount?.["8 Port Switch"] > 0 &&
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["8 Port Switch"] ?? 0}  x 8 Ports
                </span>
              }
            </div>
          </div>
        </Col>
        <Col xs="4" className="p-0 pl-1">
          <div className="bg-white rounded p-2" >
            {
              view != DEPLOYMENT_VIEW ?
                <BasicVenueDetails />
                : <DeploymentVenueData />
            }
          </div>
        </Col>
        <Col xs="3" className="pr-0">
          <div className="bg-white rounded p-1 pt-0">
            {
              editable ?
                <Autocomplete className={"form-control autocomplete-input " + (venueAddressAlert && "invalid")}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  disabled={disableUpdate}
                  onChange={(e) => {
                    setFieldValue('venueAddressLat', null);
                    setFieldValue('venueAddressLng', null);
                    setVenueAddressAlert(null);
                    setAddress(currState => { return { ...currState, venue: e.target.value } });
                    setFormikAddress(currState => { return { ...currState, venue: e.target.value } });

                    // setAddress(currState => { return { ...currState, venue: place.formatted_address ?? place.name } });
                    // shippingHandle();
                  }}
                  options={{
                    types: [],
                  }}
                  placeholder="Venue Address"
                  ref={venueAdd}
                  defaultValue={activeVenue?.venueAddress?.addressLine}
                  onPlaceSelected={
                    (place) => {
                      const selectLat = place?.geometry?.location?.lat() ?? 0;
                      const selectLng = place?.geometry?.location?.lng() ?? 0;
                      setAddress(currState => { return { ...currState, venue: place.formatted_address ?? place.name } });
                      setFormikAddress(currState => { return { ...currState, venue: place.formatted_address ?? place.name } });
                      setFieldValue('venueAddressLat', selectLat);
                      setFieldValue('venueAddressLng', selectLng);
                      setPos({ lat: selectLat, lng: selectLng });
                    }
                  }
                />
                : <div title={activeVenue?.venueAddress?.addressLine} className="address-title mb-50">{activeVenue?.venueAddress?.addressLine}</div>
            }
            <div className="google-map-container">
              {googleMapView == GOOGLE_MAP.MAP_VIEW ? <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '7.45em'
                }}
                zoom={12}
                onLoad={onLoad}
                options={{
                  disableDefaultUI: true,
                  keyboardShortcuts: false,
                  minZoom: 5
                }}
                onUnmount={onUnmount}
              >
                {
                  <div>
                    {venues && venues.map((item, index) => {
                      if (item.lat === undefined)
                        return <React.Fragment key={index}></React.Fragment>
                      map?.panTo({ lat: item.lat, lng: item.lng })
                      // map?.setZoom(12);
                      setTimeout(() => map?.setZoom(12), 10)
                      return <Marker key={index} position={{ lat: item.lat, lng: item.lng }}
                        icon={{url: zoneMap[basicVenueDetails?.zone]}} />
                    }
                    )}
                    {(!!pos) && <Marker position={pos} />}{!!(pos) && map?.panTo(pos)}
                  </div>
                }
              </GoogleMap> :
                <StreetView orgId={activeVenue.orgId} venueId={activeVenue.venueId} streetview={streetview} setStreetview={setStreetview} />}
              <div className="map-switch-container d-flex ">
                <div className={`cursor-pointer map-switch-button d-flex leftsideborder ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.STREET_VIEW)}>
                  <StreetViewIcon
                    className={`street-icon ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`}
                    width={12}
                    height={12} />
                </div>
                <div className={`cursor-pointer map-switch-button d-flex rightsideborder ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`} onClick={() => { setGoogleMapView(GOOGLE_MAP.MAP_VIEW) }}>
                  <MapViewIcon
                    className={`map-icon ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`}
                    width={12}
                    height={12} />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

VenueBasicDetails.propTypes = {};

VenueBasicDetails.defaultProps = {};

export default VenueBasicDetails;
