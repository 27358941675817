/***
 *
 * Controller class for user.
 * @file ApOverview.js
 * @description ApOverview component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router";
import { Alert, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Spinner, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { Actions, APMockUp, SupportModal, VeryFancyLoader } from "../../../components";
import { CatchedWebError } from "../../../configs";
import { ReactComponent as Ticket } from "../../../assets/images/icons/Ticket.svg";
import { ReactComponent as Reboot } from "../../../assets/images/icons/Reboot.svg";
import { ReactComponent as Blink } from "../../../assets/images/icons/Blink.svg";
import { ReactComponent as Reset } from "../../../assets/images/icons/FactoryReset.svg";
import { ReactComponent as Scan } from "../../../assets/images/icons/WifiScan.svg";
import { ReactComponent as Connect } from "../../../assets/images/icons/Connect.svg";
import { ReactComponent as InfraNotesIcon } from "../../../assets/images/icons/Infra_notes_icon.svg";
import { ReactComponent as Install } from "../../../assets/images/icons/install_cloud_color.svg"
import { ReactComponent as InstallIconBlack } from "../../../assets/images/icons/install_cloud.svg"
import { ReactComponent as LayoutIcon } from "../../../assets/images/icons/placement-icon.svg"
import { ReactComponent as LayoutIconBlack } from "../../../assets/images/icons/placement-icon-black.svg"
import { ReactComponent as ValidateIcon } from '../../../assets/images/icons/validate-icon.svg'
import { ReactComponent as ValidateIconBlack } from '../../../assets/images/icons/validate-icon-black.svg'
import { ReactComponent as CameraImageBlack } from "../../../assets/images/icons/camera-icon-black.svg";
import { ReactComponent as CameraImage } from "../../../assets/images/icons/camera-icon.svg";
import { ReactComponent as Success } from "../../../assets/images/icons/Success.svg"

import { ReactComponent as TicketIcon } from '../../../assets/images/icons/ticket-icon-col.svg'
import { ReactComponent as UpdateIcon } from "../../../assets/images/icons/UpdateIcon.svg";


import Expand from '../../../assets/images/icons/ExpandIcon.svg';
import { activeVenueActions, breadcrumbActions, infraListActions, oneInfraActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import Header from "../_builder/Header";
import "./APOverview.scss";
import { make_toast } from "../../../helpers";
import blazer from "../../../services/blazer.service";
import axios from "axios";
import { dateTimeFormatter } from "../../../utility/Localization";
import useAutoclear from "../../../hooks/useAutoclear";
import TimeProgress from "../../../components/TimeProgress";
import { timeDiff } from "../SwitchOverview";
import moment from 'moment-timezone';
import { PRIVATE_IP, PUBLIC_IP } from "../../../utility/tooltip-config-global";
import PencilIcon from '../../../assets/images/icons/Pencil.svg'
import REDUX_WORKER from "../../../redux/workers";
import { AddIcon } from "../../../assets/images/icons/Icons";
import { make_custom_toast } from "../../../helpers/toasts";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { AP_REBOOT, AP_RESET, BLINK, CONNECT_RTTY, SCAN } from "../../../utility/tooltip-config-global";
import { DEPLOYMENT_VIEW, INFRA_DELETE_OPTIONS, THIRD_PARTY_TITLE } from "../../../utility/constants";
import { ipFormater } from "../InfraList";
import AlertInfraTable from "../../../components/AlertInfraTable";
import { ReactComponent as Floor } from "../../../assets/images/icons/Floor.svg";
import { ReactComponent as OrgGlobe } from "../../../assets/images/icons/OrgGlobe.svg";
import { ReactComponent as Validate } from "../../../assets/images/icons/validate-icon-black.svg";

import APSVG from "../InfraList/APSVG";
import LightBadge from "../../../components/LightBadge";
import InfraInsightCharts from "../../../components/InfraInsightCharts";
import { UPTIME } from "../Details";
import { statusIcons, versionColors } from "../../Venue/VenueTree";
import RoomLocationModal from "../Components/RoomLocationModal";
import InfraImageModal from "../Components/InfraImageModal";
import InstalledImageModal from "../Components/InstalledImageModal";
import { FAKE_WALL_DATA, LAYOUT_TYPES } from "../../Venue/FloorPlan2/constants";
import FloorplanSlideView from "../../Venue/FloorPlan2/components/FloorplanSlideView";
import { dateLeftFinder } from "../../../utility/Utils";
import FirmwareReschedule from "../../../components/FirmwareRescheduleVenue";
import LinkWrapper from "../../../components/LinkWrapper";
import InfraDisabledCard from "../../../components/InfraDisabledCard";
import { useSearchParams } from "react-router-dom";
const DeleteInfraModal = lazy(() => import("../../../components/DeleteInfraModal"));
const Empty = lazy(() => import("./Empty"));
const Device = lazy(() => import("./Device"));

const COPYABLE_ID = {
  PUBLIC_IP: 'ap-public-ip-copy-icon',
  PRIVATE_IP: 'ap-private-ip-copy-icon',
  MAC_ADDRESS: 'ap-mac-add-copy-icon',

}

export const ImagePage = ({ imgs }) => {
  const [active, setActive] = useState(0);
  const [keys, setKeys] = useState(Object.keys(imgs ?? {}));
  const [expandActive, setExpandActive] = useState(0);
  const [selImg, setSelImg] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className="bg-white border rounded p-2 mt-2 mb-1" style={{ position: "relative", height: "300px", overflow: "hidden" }}>
        <div className="d-flex justify-content-center align-items-center" style={{ height: "250px", width: "100%" }}>
          {(selImg == null && loading) ?
            <div className="text-center">
              <Spinner color="primary" />
            </div> : <></>}
          {(imgs) ?
            <img src={imgs[keys[active]]} onLoad={() => setLoading(false)} className={"ap-img " + (loading ? "d-none" : "")} />
            : "No Images Available"}
        </div>
        <div className="cursor-pointer" style={{ position: "absolute", right: 10, bottom: 10 }}>

          {/* <span className="material-symbols-outlined" onClick={() => setSelImg(imgs[active])}>open_in_full</span> */}
          <img src={Expand} onClick={() => { setExpandActive(active); setSelImg(imgs[keys[active]]) }} />
        </div>
      </div>
      <Modal className="modal-lg" centered isOpen={!!selImg} toggle={() => setSelImg(null)}>
        <ModalHeader className="bg-white" toggle={() => setSelImg(null)}></ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center p-4" style={{ height: "600px" }}>
            {loading ?
              <div className="text-center" style={{ width: "100%" }}>
                <Spinner color="primary" />
              </div> : <></>}
            <img src={selImg} onLoad={() => setLoading(false)} className={"ap-img-lg " + (loading ? "d-none" : "")} />
          </div>
          <div className="text-center">
            <Pagination size="sm" className="d-flex justify-content-center">
              <PaginationItem key={"prev"}>
                <PaginationLink onClick={() => {
                  if (expandActive > 0) {
                    setLoading(true);
                    setSelImg(imgs[keys[expandActive - 1]]);
                    setExpandActive(prevState => prevState - 1)
                  }
                }}>
                  <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_left</span>
                </PaginationLink>
              </PaginationItem>
              {keys?.map((img, index) => {
                return <PaginationItem active={expandActive == index} key={index}>
                  <PaginationLink onClick={() => { if (expandActive != index) setLoading(true); setExpandActive(index); setSelImg(imgs[keys[index]]) }}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              })}
              <PaginationItem key={"next"}>
                <PaginationLink onClick={() => {
                  if (expandActive < (keys?.length - 1)) {
                    setLoading(true);
                    setSelImg(imgs[keys[expandActive + 1]]);
                    setExpandActive(prevState => prevState + 1);
                  }
                }}>
                  <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_right</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        </ModalBody>
      </Modal>
      <Pagination size="sm">
        <PaginationItem key={"prev"}>
          <PaginationLink onClick={() => { if (active > 0) { setLoading(true); setActive(prevState => prevState - 1); } }}>
            <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_left</span>
          </PaginationLink>
        </PaginationItem>
        {keys?.map((img, index) => {
          return <PaginationItem active={active == index} key={index}>
            <PaginationLink onClick={() => { if (active != index) setLoading(true); setActive(index); }}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        })}
        <PaginationItem key={"next"}>
          <PaginationLink onClick={() => { if (active < (keys?.length - 1)) { setLoading(true); setActive(prevState => prevState + 1); } }}>
            <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_right</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>)
}

export const handleCopy = (content = "") => {
  navigator.clipboard.writeText(content);
  // make_toast("info", "Copied to clipboard.", true);
}

export const showCopyIcon = (id) => {
  const element = document.getElementById(id)
  element.classList.remove('copy-icon')
  element.classList.add('copy-icon-active')

}

export const hideCopyIcon = (id) => {
  const element = document.getElementById(id)
  element.classList.remove('copy-icon-active')
  element.classList.add('copy-icon')

}

export const NotesCard = ({ onPencilClick }) => {
  const infraData = useSelector(store => store.oneInfra.data);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const venueId = useSelector(store => store.oneInfra.data.venueId)
  return (
    <>
      <span className="d-flex justify-content-between mb-1">
        <span>
          <InfraNotesIcon className="mr-1" />
          <span>
            Notes
          </span>
        </span>
        {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
          <img className="cursor-pointer" src={PencilIcon} onClick={onPencilClick} /> : <span />}
      </span>
      <div style={{ whiteSpace: "pre-line", height: "180px", overflow: "auto" }}>
        {infraData?.description || ((venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ? <div className="center-div" style={{ minHeight: '100px' }}><em className="text-secondary ">No Notes</em></div> : null)}
      </div>
    </>
  )
}

export const DisabledInfraBadge = () => {
  return (
    <LightBadge
      color='#F6F6F6'
      notLighter
      borderColor='#D4D4D4'
    >
      <div className="text-dark">Disabled</div>
    </LightBadge>
  )
}

const ApOverview = () => {
  const [params] = useSearchParams();
  const infraData = useSelector(store => store.oneInfra.data);
  const infraItemId = useSelector(store => store.oneInfra.data.infraItemId);
  const issues = useSelector(store => store.oneInfra.issues);
  const alarms = useSelector(store => store.oneInfra.alarms);
  const rebooting = useSelector(store => store.rebooting.data[infraData.infraItemId]);
  // const infraDetails = useSelector(store => store.oneInfra.details.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const infraTypes = useSelector(store => store.infraTypes.data);
  const view = useSelector(store => store.identity.meta.view);
  const infraitemLoading = useSelector(store => store.oneInfra.status.loading);
  const hasWarehouse = useSelector(store => store.activeOrg.data.capabilities?.vwh?.ok);
  
  const [isDeleteInfraOpen, setIsDeleteInfraOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState(INFRA_DELETE_OPTIONS[0].value)
  const [profileData, setProfileData] = useState(null);
  const [connectionData, setConnectionData] = useState(null);
  const [error, setError] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [runCommand, setRunCommand] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [roomLocationModal, setRoomLocationModal] = useState(false);
  const [infraImageModal, setInfraImageModal] = useState(false);
  const [installedImageModal, setInstalledImageModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [downgrading, setDowngrading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [support, setSupport] = useState(false);
  const [deviceCount, setDeviceCount] = useState(0);
  const [connectURL, setConnectURL] = useState(null);
  const [password, setPassword] = useState(null);
  const [consoleLoading, setConsoleLoading] = useState(true);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [misMatch, setMismatch] = useState(null);
  const [misMatchModal, setMismatchModal] = useState(false);
  const [updatingInfra, setUpdatingInfra] = useState(false);
  const [enableToggle, setEnableToggle] = useState(false);

  const permissions = useSelector(store => store?.rbac?.permissions);
  const rbac = useSelector(store => store?.rbac?.data);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const venueId = useSelector(store => store.oneInfra.data.venueId)

  const [imgLoading, setImgLoading] = useState(true);
  const [instImg, setInstImg] = useState({});
  const [instCount, setInstCount] = useState([]);
  const [instActive, setInstActive] = useState(0);
  const [bannedRadio, setBannedRadio] = useState({})
  const [radioDetails, setRadioDetails] = useState({})
  const [floorplanList, setFloorplanList] = useState([])
  const [floorplanLoading, setFloorplanLoading] = useState(false)

  const hiddenFileInput = useRef(null);
  const noteRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { infraId } = useParams();
  let interval;
  const [options, setOptions] = useState(null)

  const InstallationImages = () => {
    const [deleting, setDeleting] = useState(false);
    const [first, setFirst] = useState(true);

    const removeImg = () => {
      const { run } = createRequest(services.infra.INSTALL_IMG_DELETE, [infraData?.infraItemId, instCount[instActive]])

      if (!deleting && instCount[instActive]) {
        setDeleting(true)
        run()
          .then(() => {
            setInstImg(prevState => {
              let newObj = { ...prevState }
              delete newObj[instCount[instActive]];
              return { ...newObj }
            });
            setInstCount(prevState => {
              let newArray = [...prevState];
              newArray.splice(instActive, 1);
              return [...newArray];
            })
            setInstActive(0);
          })
          .catch((err) => {
            setError((new CatchedWebError(err)).message)
          })
          .finally(() => setDeleting(false))
      }
    }

    async function uploadImageToCloud(img) {
      if (!!img) {
        const options = { maxSizeMB: 5, maxWidthOrHeight: 250, useWebWorker: true }
        const selectedImageCom = await imageCompression(img, options);
        const { run } = createRequest(services.infra.INSTALL_IMG, [infraData?.infraItemId, 'put', Number(instCount.slice(-1)) + 1]);
        try {
          let response = await run();
          await axios.put(response.data, selectedImageCom)
            .then(() => {
              const { run } = createRequest(services.telemetry.UPDATE_TASK, [infraData.macAddress], { photos: true })
              if (first) {
                run()
                  .then(() => {
                    REDUX_WORKER.getInfra(infraData?.infraItemId, false) //this will make photos boolean true in infra details response
                  })
                setFirst(false);
              }
            });
        } catch (err) {
          make_custom_toast("error", "Infrastructure", "Failed to upload Installation Image. Please try again later")
        }
      }
    }

    const uploadImage = (event) => {
      if (["image/png", "image/jpg", "image/jpeg"].includes(event.target.files[0]?.type)) {
        const ImgURL = URL.createObjectURL(event.target.files[0]);
        setInstImg(prevState => { return { ...prevState, [Number(instCount.slice(-1)) + 1]: ImgURL } })
        setInstCount(prevState => [...prevState, Number(instCount.slice(-1)) + 1])
        setInstActive(instCount.length);
        uploadImageToCloud(event.target.files[0]);
        hiddenFileInput.current.value = null;
      }
      else
        make_toast("error", "Invalid Format, png/jpg Accepted Only", true);
    }



    return (
      <div className="">
        <div className="d-flex align-items-center justify-content-between mb-50">
          <div className="d-flex align-items-center">
            <div style={{ position: 'relative' }}>

              {
                infraData?.photos ?
                  <CameraImage className="mr-50" width={22} height={22} /> :
                  <CameraImageBlack className="mr-50" width={22} height={22} />
              }
              {infraData?.photos
                ? <div className="success-top-icon-small">
                  <Success width={14} height={14} />
                </div>
                : <></>
              }
            </div>
            <h6 className="font-weight-500 p-0 m-0">Installation Images</h6>
          </div>
          {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
            <div className="d-flex">
              <div className="cursor-pointer d-flex align-items-center" onClick={() => hiddenFileInput.current.click()}><AddIcon width={22} height={22} /></div>
              {/* <div className="cursor-pointer d-flex align-items-center ml-1" onClick={removeImg}><img src={DeleteIcon} /></div> */}
            </div> : null}
        </div>
        <div className="">

          <div
            className="d-flex align-items-center justify-content-center"
          // style={{ minHeight: "299px", maxHeight: "300px" }}
          >
            <input label="Logo" type="file" name="file" id="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={uploadImage} />
            {
              imgLoading ?
                <div className="text-center"><Spinner color="primary" /></div> :
                instCount?.length > 0 ?
                  <div className="d-flex w-100 flex-column align-items-center justify-content-center ">
                    <img
                      className="inst-img w-100 cursor-pointer"
                      src={instImg[instCount[instActive]]}
                      onClick={() => setInstalledImageModal(true)}
                      onError={() => {
                        setInstImg({})
                      }} />
                    <div className="d-flex align-items-center justify-content-between mt-50 w-100 ">
                      <span
                        className="material-symbols-outlined text-primary cursor-pointer"
                        style={{ fontSize: "1.8rem" }}
                        onClick={() => { if (instActive > 0) { setInstActive(prevState => prevState - 1); } }}
                      >chevron_left</span>

                      <div className="d-flex align-items-center">
                        {instCount?.length > 0 && instCount.map((img, index) => {
                          return (
                            <div className={`${index == instActive ? 'selected-img-dot' : 'grey-img-dot'}`} />
                          )
                        })}
                      </div>

                      <span
                        className="material-symbols-outlined text-primary cursor-pointer"
                        style={{ fontSize: "1.8rem" }}
                        onClick={() => { if (instActive < (instCount?.length - 1)) { setInstActive(prevState => prevState + 1); } }}
                      >chevron_right</span>
                    </div>
                  </div> :
                  <div className="d-flex flex-column align-items-center justify-content-center my-1">
                    <Suspense fallback={<></>}><Empty height={'70px'} /></Suspense>
                    <small>No Image Uploaded</small>


                  </div>
            }
          </div>

        </div>




        <div className=" d-flex justify-content-between pt-50">
          <div className="d-flex w-100" style={{ overflow: 'hidden' }}>
            <div className="d-flex w-50 mr-50">
              <div className="bold-text">Room:&nbsp;</div>
              <div className="ellipse-text-box text-nowrap" title={infraData?.room ?? ''}>{infraData?.room ?? ''}</div>
            </div>
            <div className="d-flex w-50">
              <div className="bold-text">Location:&nbsp;</div>
              <div className="ellipse-text-box text-nowrap" title={infraData?.location ?? ''}>{infraData?.location ?? ''}</div>
            </div>
          </div>
          <img className="cursor-pointer" src={PencilIcon} onClick={() => setRoomLocationModal(true)} />

        </div>
      </div>
    )
  }

  const getDeviceCount = () => {
    const { run } = createRequest(blazer.INFRA_DEVICES_COUNT, [infraData.macAddress, false]);
    run().then((response) => {
      setDeviceCount(response.data.connected.all);
    })
  }

  const getPortStatus = () => {
    const { run: getConnect } = createRequest(services.networks.GET_PORT_INFRA, [infraData?.infraItemId]);

    getConnect().then((response) => {
      setConnectionData(response.data)
    })
  }

  const getRadioInfo = () => {
    const { run } = createRequest(services.networks.BANNED_RADIO, [infraData?.infraItemId]);

    run().then((response) => {
      setBannedRadio(response.data);
    })
  }

  const getRadioDetails = (radioList) => {
    let tempRadioDetails = {
      "2G": null,
      "5G": null,
      "6G": null
    }

    radioList?.map((radio) => {
      console.log('radio:', radio)
      if (radio.band[0] == '2G') {
        tempRadioDetails['2G'] = {
          channel: radio?.channel,
          channelWidth: radio?.channelWidth,
          txPower: radio?.txPower
        }
      }
      if (radio.band[0] == '5G') {
        tempRadioDetails['5G'] = {
          channel: radio?.channel,
          channelWidth: radio?.channelWidth,
          txPower: radio?.txPower
        }
      }
      if (radio.band[0] == '6G') {
        tempRadioDetails['6G'] = {
          channel: radio?.channel,
          channelWidth: radio?.channelWidth,
          txPower: radio?.txPower
        }
      }
    })

    setRadioDetails(tempRadioDetails)
  }

  // const getInfraDeploymentInfo = () => {
  //   const { run } = createRequest(services.telemetry.GET_INFRA_DEPLOYMENT_INFO, [infraData?.macAddress])
  //   run()
  //     .then(res => {

  //     })
  //     .catch(err => {

  //     })
  // }

  const getFloorPlanList = () => {
    setFloorplanLoading(true)


    let apiCaller
    if (infraData?.installed) {
      apiCaller = createRequest(services.wre.GET_PLACED_FLOORPLAN, [infraData?.infraItemId, infraData?.venueId])
    }
    else {
      apiCaller = createRequest(services.wre.GET_FLOORPLAN_LIST, [infraData?.venueId])
    }

    apiCaller.run()
      .then(res => {
        let tempData = []
        if (res.data.length == undefined) {
          tempData = [res.data]
        }
        else {

          tempData = res.data.sort((a, b) => {
            if (a.layoutJson)
              return -1
            else
              return 1
            return 0
          }

          )
        }
        for (let i in tempData) {
          if (tempData[i].layoutJson == null) {
            tempData[i].layoutJson = FAKE_WALL_DATA
            tempData[i].layoutType = LAYOUT_TYPES.IMAGE
          }
        }
        // setFloorplanList(tempData.filter((floorplan) => floorplan.layoutJson != null))

        setFloorplanList(tempData)
        setFloorplanLoading(false)
      })
      .catch(err => {
        setFloorplanLoading(false)
        // make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
      })
    return apiCaller.controller.abort()
  }



  useEffect(() => {

    getRadioInfo()
    getRadioDetails(infraData?.radios)
    // getInfraDeploymentInfo() called this for info like: room, location, speedtest, photo, validate.
    getFloorPlanList()

    if (infraData && infraData.infraItemId == infraId && (permissions?.manageInfrastats?.view || venuePermissions[venueId]?.infra?.view)) {
      // getDeviceCount();
      getPortStatus();
      interval = setInterval(() => {
        // getDeviceCount();
        getPortStatus();
      }, 60000);
    }

    return (() => {
      clearInterval(interval);
    })
  }, [])



  useEffect(() => {
    if (Number(infraId) === infraData?.infraItemId)
      dispatch(breadcrumbActions.setData([
        {
          path: (params.has("backTo")? `/organization/${activeOrgId}/venues/${params.get("backTo")}/infrastructure/` : `/organization/${activeOrgId}/infra`),
          text: "Infrastructure",
          active: false
        },
        {
          text: infraData?.infraDisplayName,
          active: false
        },
        {
          path: `/organization/${activeOrgId}/infra/${infraData?.infraItemId}`,
          text: "Overview",
          active: true
        },
      ]))
  }, [params])

  useEffect(() => {
    if (infraData && infraData.infraItemId == infraId) {
      const { run } = createRequest(services.networks.GET_PROFILE_INFRA, [infraData?.infraItemId, activeOrgId])

      run()
        .then((response) => {
          setProfileData(response.data);
        })

      const { run: getImg } = createRequest(services.infra.INSTALL_IMG, [infraData?.infraItemId, "get"])

      getImg().then((response) => {
        setInstImg(response.data);
        setInstCount(Object.keys(response.data));
      })
        .finally(() => setImgLoading(false));

      const { run: getSchedule } = createRequest(services.fwupgrade.GET_INFRA_SCHEDULE, [infraId]);
      getSchedule()
        .then(response => {
          if (response.data?.status === 'Scheduled' && !(response.data?.completedDate)) {
            // setScheduleDate(response.data?.scheduleDate);
            if (response.data?.scheduleDate && !!moment.tz.zone(response.data?.timezone)) {
              const scheduleDateObj = (moment.utc(response?.data?.scheduleDate));
              setScheduleDate(((scheduleDateObj.tz(response?.data?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000')));
              setTimezone(response.data?.timezone);
            }
          }
        })
        .catch(err => {
          //not showing this error on UI as it is not a major one
          // setError((new CatchedWebError(err)).message);
        })

      const { run: runMismatch } = createRequest(services.networks.GET_CONFIG, [infraId]);

      runMismatch().then((response) => {
        setMismatch(response.data);
      })
        .catch(err => { })

    }

  }, []);

  // const getStatus = () => {
  //   // setLoading(true);
  //   const { run } = createRequest(services.infra.STATUS, [infraId]);

  //   run().then((response) => {
  //     dispatch(oneInfraActions.setStatus(response.data));
  //     dispatch(infraListActions.setStatus({ infraItemId: infraId, status: response.data }));
  //   })
  //     .catch((err) => {
  //       // make_toast('error', (new CatchedWebError(err)).message);
  //     })
  //     .finally(() => {
  //       // setLoading(false);
  //     })
  // }


  const updateAP = (description = infraData?.description) => {
    const submitData = {
      infraTypeId: infraData?.infraTypeId,
      macAddress: infraData?.macAddress,
      infraDisplayName: infraData?.infraDisplayName,
      venueId: infraData?.venueId,
      description: description,
      orgId: infraData?.orgId
    };

    const { run } = createRequest(services.infra.MODIFY, [infraData?.infraItemId], submitData);
    setModalLoading(true);
    run()
      .then(response => {
        dispatch(oneInfraActions.setInfra({ ...infraData, description: response?.data?.description, orgId: activeOrgId, connected: infraData?.connected }))
      })
      .catch(err => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setModalLoading(false);
        setNoteModal(false);
      })

  }

  const deleteInfra = () => {
    setDeleting(true);
    const { context, run } = createRequest(services.infra.DELETE, [infraData?.infraItemId, deleteOption == 2]);
    
    const hasOption = hasWarehouse && permissions?.manageIntegration?.create

    run()
      .then(() => {
        setDeleteError(null);
        dispatch(infraListActions.reset());
        dispatch(oneInfraActions.resetInfra());
        dispatch(activeVenueActions.resetInfra());
        navigate(`/organization/${activeOrgId}/infra?deleted=true`);
      })
      .catch(err => {
        // setDeleteError((new CatchedWebError(err)).message);
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(
          context,
          `${(hasOption && deleteOption == 1) ? 'Moving' : 'Deleting'} Infrastructure`,
          TICKET_CATEGORY.NETWORK,
          err
        );
        make_custom_toast(
          "error",
          `${(hasOption && deleteOption == 1) ? 'Moving' : 'Deleting'} Infrastructure`,
          x.message,
          true,
          "Create Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        );
      })
      .finally(() => {
        setDeleting(false);
      });
  };


  const fetchURL = () => {
    if (!connectURL && infraId) {
      const { run } = createRequest(services.infra.CONNECT, [infraId]);
      setConsoleLoading(true);
      run()
        .then((response) => {
          const url = new URL(`https://${response?.data?.server}:${response?.data?.viewport}/rtty/${response?.data?.connectionId}`);
          setConnectURL(url);
          setPassword(response?.data?.devicePassword);
        })
        .catch((err) => {
          setError((new CatchedWebError(err)).message);
          setRunCommand(null);
        })
        .finally(() => {
          setConsoleLoading(false);
        })
    }
  }

  useEffect(() => {
    if (runCommand === 'connect') {
      fetchURL();
    }
  }, [runCommand])



  const downgrade = () => {
    if (infraData?.macAddress && infraData?.orgId && infraData?.venueId && infraData?.infraItemId && infraTypes) {
      setDowngrading(true);
      const { run } = createRequest(services.fwupgrade.GET_VERSION, [infraData?.macAddress]);
      run()
        .then((response) => {
          if (response?.data?.prevVersion) {
            const postData = {
              "orgs": [
                {
                  "orgId": infraData?.orgId,
                  "venues": [
                    {
                      "venueId": infraData?.venueId,
                      "scheduleDate": new Date().toISOString(),
                      "infras": [infraData?.infraItemId]
                    }
                  ]
                }
              ],
              "firmwareVersion": response?.data?.prevVersion,
              "scheduleName": "Downgrade",
              "description": "Downgraded from Infra Overview Page",
              "type": "GA release",
              "upgradeType": infraTypes.find(itype => itype.infraTypeId === infraData?.infraTypeId)?.infraCategory === 1 ? "AP" : "Switch",
              "force": true
            }
            const { run } = createRequest(services.fwupgrade.NEW_FWUPGRADE, [], postData);
            run()
              .then((response) => {
                setSuccess("Downgrading");
              })
              .catch(err => {
                setError((new CatchedWebError(err)).message);
              })
              .finally(() => {
                setDowngrading(false);
                setWarningModal(false);
              })
          }
          else {
            setError("Downgrade unavailable");
            setDowngrading(false);
            setWarningModal(false);
          }
        })
    }
    else {
      setError("Downgrade unavailable");
    }
  }

  const runBlinkCommand = () => {
    let requestbody = { cmd: "blink" };

    make_custom_toast('info', 'Infrastructure', 'Command Execution In progress...');
    const { context, run } = createRequest(services.infra.RUN, [infraItemId], requestbody);
    run().then(
      (res) => {
        make_custom_toast('success', 'Infrastructure', res.data.message || 'Command Executed');
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(context, 'Running Blink Command', TICKET_CATEGORY.NETWORKs, err);
        make_custom_toast('error', 'Infrastructure', x.message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/createticket`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        });
      })
  }

  useAutoclear(success, () => setSuccess(null));



  const ApStatInfo = () => {
    return (
      <div className="bg-white rounded p-1" style={{ width: '39%', position: 'relative' }}>

        <div className="d-flex justify-content-between align-items-center mb-1">
          <span className="w-50">
            <Device height={20} width={20} className="fill-client-svg mr-1" />
            <LinkWrapper
              to={
                (
                  (venuePermissions[venueId]?.device?.view == null
                    ? permissions?.manageDevice?.view
                    : venuePermissions[venueId]?.device?.view) &&
                  infraData?.connectedClients > 0
                )
                  ? `/organization/${activeOrgId}/infra/${infraItemId}/clients/`
                  : null
              }
            >
              {infraData?.connectedClients} Connected Clients
            </LinkWrapper>
          </span>

          <span className="w-50">
            <span className="bold-text">
              Uptime:&nbsp;
            </span>
            <span id="uptime-time-id">
              {infraData?.uptimeReported > 0 ? UPTIME(infraData?.uptimeReported, true) : infraData?.uptimeReported}
            </span>
            {
              infraData?.uptimeReported > 0 &&
              <UncontrolledTooltip target={"uptime-time-id"}>
                {UPTIME(infraData?.uptimeReported)}
              </UncontrolledTooltip>
            }
          </span>
        </div>

        <div className="d-flex justify-content-between align -items-center mb-1" >


          <div className="w-50 d-flex">
            <span className="bold-text ">
              Release&nbsp;
            </span>
            {
              infraData?.firmwareVersion != "Unknown" ?
                <LightBadge className=" badge-custom" color={versionColors[infraData?.firmwareStatus] ?? "secondary"}>
                  {infraData?.firmwareVersion?.replace("Unknown", ``) ?? "-"}
                </LightBadge> : "Unknown"
            }
          </div>

          <span className="w-50" >
            {
              infraData?.radio2G.supported == false ? <span></span>
                :
                <span className="d-flex align -items-center">
                  <span className="mr-50 rounded box mode2g">
                    2G
                  </span>
                  <span>
                    {
                      infraData?.radio2G?.active == false ? 'Disabled' :
                        (!infraData?.radio2G?.channel && !infraData?.radio2G?.channel_width && !infraData?.radio2G?.tx_power) ? <span>No data available</span> :
                          <span>
                            {infraData?.radio2G?.channel ? `Ch ${infraData?.radio2G?.channel},` : ``}&nbsp;
                            {infraData?.radio2G?.channel_width ? `${infraData?.radio2G?.channel_width} Mhz,` : ''}&nbsp;
                            {infraData?.radio2G?.tx_power ? `${infraData?.radio2G?.tx_power} dBm TX` : ''}

                          </span>
                    }
                  </span>
                </span>
            }
          </span>

        </div>

        <div className="d-flex justify-content-between align-items-center mb-1">

          <span className="w-50 d-flex align-items-center">
            <span className="bold-text">
              Public IP:&nbsp;
            </span>
            <span
              className="cursor-pointer d-flex align-items-center"
              onClick={() => handleCopy(infraData?.publicIP ?? "Unavailable")}
              onMouseEnter={() => {
                showCopyIcon(COPYABLE_ID.PUBLIC_IP)
              }}
              onMouseLeave={() => {
                hideCopyIcon(COPYABLE_ID.PUBLIC_IP)
              }}
            >
              {infraData?.publicIP ?? "Unavailable"}

              <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.PUBLIC_IP} style={{ fontSize: '1em' }}>
                content_copy
              </span>
            </span>
          </span>
          <span className="d-flex align -items-center w-50">
            {
              infraData?.radio5G.supported == false ? <span></span>
                :
                <span className="d-flex align -items-center">

                  <span className="mr-50 rounded box mode5g">
                    5G
                  </span>
                  <span>
                    {
                      infraData?.radio5G.active == false ? 'Disabled' :
                        (!infraData?.radio5G?.channel && !infraData?.radio5G?.channel_width && !infraData?.radio5G?.tx_power) ? <span>No data available</span> :
                          <span>
                            {infraData?.radio5G?.channel ? `Ch ${infraData?.radio5G?.channel},` : ``}&nbsp;
                            {infraData?.radio5G?.channel_width ? `${infraData?.radio5G?.channel_width} Mhz,` : ''}&nbsp;
                            {infraData?.radio5G?.tx_power ? `${infraData?.radio5G?.tx_power} dBm TX` : ''}
                          </span>
                    }
                  </span>
                </span>
            }
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center  mb-1">

          <span className="w-50 d-flex align-items-center">
            <span className="bold-text">
              Private IP:&nbsp;
            </span>
            <span
              className="cursor-pointer"
              onClick={() => handleCopy(infraData?.privateIP)}
              onMouseEnter={() => {
                showCopyIcon(COPYABLE_ID.PRIVATE_IP)
              }}
              onMouseLeave={() => {
                hideCopyIcon(COPYABLE_ID.PRIVATE_IP)
              }}
            >
              {infraData?.privateIP ?? "Unavailable"}
              <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.PRIVATE_IP} style={{ fontSize: '1em' }}>
                content_copy
              </span>
            </span>
          </span>
          <span className="d-flex align -items-center w-50">
            {
              infraData?.radio6G.supported == false ? <span></span>
                :
                <span className="d-flex align -items-center">
                  <span className="mr-50 rounded box mode6g">
                    6G
                  </span>
                  <span>
                    {
                      infraData?.radio6G.active == false ? 'Disabled' :
                        (!infraData?.radio6G?.channel && !infraData?.radio6G?.channel_width && !infraData?.radio6G?.tx_power) ? <span>No data available</span> :
                          <span>
                            {infraData?.radio6G?.channel ? `Ch ${infraData?.radio6G?.channel},` : ``}&nbsp;
                            {infraData?.radio6G?.channel_width ? `${infraData?.radio6G?.channel_width} Mhz,` : ''}&nbsp;
                            {infraData?.radio6G?.tx_power ? `${infraData?.radio6G?.tx_power} dBm TX` : ''}
                          </span>
                    }
                  </span>
                </span>
            }
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center  mb-1">
          <div className="d-flex w-50">
            <div className="bold-text text-nowrap">
              AP Profile:&nbsp;
            </div>
            <div className={`ellipse-text-box text-nowrap`}
            >
              <LinkWrapper
                to={infraData?.apProfileId == null
                  ? null
                  : `/organization/${infraData?.orgId}/profiles/${infraData?.apProfileId}?infraType=${infraData?.infraTypeId}`
                }
              >
                {infraData?.apProfile ?? "Unavailable"}
              </LinkWrapper>
            </div>
          </div>
          <div className="d-flex w-50">
            <div className="bold-text text-nowrap">
              RF Profile:&nbsp;
            </div>
            <div className={`ellipse-text-box text-nowrap`}>
              <LinkWrapper
                to={infraData?.rfProfileId == null
                  ? null
                  : `/organization/${infraData?.orgId}/profiles/${infraData?.rfProfileId}?infraType=${infraData?.infraTypeId}`
                }
              >
                {infraData?.rfProfile ?? "Unavailable"}
              </LinkWrapper>
            </div>
          </div>
        </div>

      </div>)
  }
  const ApDeploymentInfo = () => {
    return (<div className="bg-white rounded p-1" style={{ width: '39%' }}>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <span className="w-50">
          <span style={{ position: 'relative' }}>
            {
              infraData?.validated ?
                <ValidateIcon width={24} height={24} /> :
                <ValidateIconBlack width={24} height={24} />
            }
            {infraData?.validated
              ? <div className="success-top-icon">
                <Success width={16} height={16} />
              </div>
              : <></>
            }
          </span>
          <span className="ml-1">
            Network Validation
          </span>
        </span>
      </div>

      <div className="d-flex justify-content-between align -items-center mb-1" >
        <span className="d-flex align-items-center w-50" >
          <span class="material-symbols-outlined mr-1">
            compare_arrows
          </span>
          <span className="bold-text">
            Ping :&nbsp;
          </span>
          <span>
            {infraData?.speedTestResult?.ping | ''} ms
          </span>
        </span>


        <span className="w-50">
          <span className="bold-text ">
            Captured : &nbsp;
          </span>
          <span id={'execution-time-id'}>
            {infraData?.speedTestResult?.executionTime ? timeDiff(infraData?.speedTestResult?.executionTime) + 'ago' : ''}
          </span>
          {
            infraData?.speedTestResult?.executionTime &&
            <UncontrolledTooltip target={"execution-time-id"}>
              {dateTimeFormatter(infraData?.speedTestResult?.executionTime, 'long', 'medium')}
            </UncontrolledTooltip>
          }
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-1">
        <span className="d-flex align -items-center w-50">
          <span class="material-symbols-outlined mr-1">
            download
          </span>
          <span className="bold-text">
            Download:&nbsp;
          </span>
          <span>{infraData?.speedTestResult?.downlink ? infraData?.speedTestResult?.downlink.toFixed(2) + 'Mb/s' : ''}</span>
        </span>
        <span className="w-50">
          <span className="">
            <span className="bold-text">
              Captured by : &nbsp;
            </span>
            {infraData?.speedTestResult?.email ? infraData?.speedTestResult?.email :
              infraData?.speedTestResult?.userName ? infraData?.speedTestResult?.userName :
                ''}
          </span>
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center  mb-1">
        <span className="d-flex align -items-center w-50">
          <span class="material-symbols-outlined mr-1">
            publish
          </span>
          <span className="bold-text">
            Upload : &nbsp;
          </span>
          <span>{infraData?.speedTestResult?.uplink ? infraData?.speedTestResult?.uplink.toFixed(2) + 'Mb/s' : ''}</span>
        </span>
        <span className="w-50">
          <span className="">
            <span className="bold-text">
              Signal : &nbsp;
            </span>
            {
              infraData?.speedTestResult?.band && <span>{infraData?.speedTestResult?.band}, &nbsp; </span>
            }
            {
              infraData?.speedTestResult?.channel && <span>Ch {infraData?.speedTestResult?.channel}, </span>
            }
            {
              infraData?.speedTestResult?.rssi && <span>{infraData?.speedTestResult?.rssi} dBm</span>
            }
            <span>

            </span>
          </span>
        </span>
      </div>



    </div>)
  }

  const updateInfra = (newData) => {
    const { run, context } = createRequest(services.infra.MODIFY, [infraData.infraItemId], {
      enabled: infraData.enabled,
      // macAddress: infraData.macAddress.replace(/[\s\.:\-]/g, ""),
      macAddress: infraData.macAddress,
      infraTypeId: Number(
        infraTypes.find(
          type => type.infraTypeId == infraData.infraTypeId
        )?.infraTypeId?.toString() ||
        infraTypes[0].infraTypeId?.toString()
      ),
      infraDisplayName: infraData.infraDisplayName,
      description: infraData.description,
      orgId: activeOrgId,
      serialNumber: infraData.serialNumber ?? '',
      assetTag: infraData.assetTag ?? '',
      venueId: infraData.venueId,
      ...newData
    })
    setEnableToggle(newData?.enabled)
    setUpdatingInfra(true)
    run()
      .then(response => {
        make_custom_toast('success', 'Infrastructure', 'Infrastructure updated successfully')
        REDUX_WORKER.getInfra(infraData?.infraItemId, true)
      })
      .catch(err => {
        setEnableToggle(infraData?.enabled)
        //console.log(err)
        const apiContext = createErrorContext(context, 'UPDATE INFRASTRUCTURE', TICKET_CATEGORY.NETWORK, err)
        make_custom_toast('error', 'Infrastructure', (new CatchedWebError(err)).message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        })
      })
  }

  const handleDisableClick = () => {
    updateInfra({ enabled: false })
  }
  const handleEnableClick = () => {
    updateInfra({ enabled: true })
  }

  const ApOptionDropdown = () => {
    return (<UncontrolledDropdown className="ml-1 ">
      <DropdownToggle tag="span" className="material-symbols-outlined dropdownToggle text-primary cursor-pointer">
        more_vert
      </DropdownToggle>
      <DropdownMenu right>
        {(venuePermissions[venueId]?.infra?.delete == null ? permissions?.manageInfra?.delete : venuePermissions[venueId]?.infra?.delete)
          && <DropdownItem className="w-100" onClick={() => { setIsDeleteInfraOpen(true) }}>Delete</DropdownItem>}
        {(rbac?.level<3 && permissions?.manageTicket?.create) && <DropdownItem className="w-100" onClick={() => { setSupport(true) }}>Support</DropdownItem>}
        {infraData?.enabled && (venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update)
          && <DropdownItem className="w-100" disabled={updatingInfra} onClick={handleDisableClick}>Disable</DropdownItem>}
        {/* {infra.venueId !== 0 && <DropdownItem className="w-100" onClick={() => { }}>Unassign</DropdownItem>} */}
        {infraData?.enabled &&
          (venuePermissions[venueId]?.infra?.create == null ? permissions?.manageInfra?.create : venuePermissions[venueId]?.infra?.create)
          &&
          <>
            <DropdownItem divider />
            <DropdownItem className="w-100" id="reboot" disabled={!infraData?.connected} onClick={() => { setRunCommand("reboot"); }}>
              <div className='d-flex justify-content-between align-items-center'>
                Reboot
                <div className="cursor-pointer" id="AP_REBOOT"><Reboot width={20} height={20} /></div>
                <UncontrolledTooltip target="AP_REBOOT">
                  {AP_REBOOT}
                </UncontrolledTooltip>
              </div>
            </DropdownItem>
            <DropdownItem id="blink" className="w-100" disabled={!infraData?.connected} onClick={() => { runBlinkCommand() }}>
              <div className='d-flex justify-content-between align-items-center'>
                Blink
                <div className="cursor-pointer" id="BLINK">
                  <Blink width={20} height={20} />
                </div>
                <UncontrolledTooltip target="BLINK">
                  {BLINK}
                </UncontrolledTooltip>
              </div>
            </DropdownItem>
            <DropdownItem id="reset" className="w-100" disabled={!infraData?.connected} onClick={() => { setRunCommand("reset"); }}>
              <div className='d-flex justify-content-between align-items-center'>
                Factory Reset
                <div className="cursor-pointer" id="AP_RESET"><Reset width={20} height={20} /></div>
                <UncontrolledTooltip target="AP_RESET">
                  {AP_RESET}
                </UncontrolledTooltip>
              </div>
            </DropdownItem>
            <DropdownItem id="scan" className="w-100" disabled={!infraData?.connected} onClick={() => { setRunCommand("wifi-scan"); }}>
              <div className='d-flex justify-content-between align-items-center'>
                Wi-Fi Scan
                <div className="cursor-pointer" style={{ fontWeight: "500" }} id="SCAN"><Scan width={20} height={20} /></div>
                <UncontrolledTooltip target="SCAN">
                  {SCAN}
                </UncontrolledTooltip>
              </div>
            </DropdownItem>
            {/* only PA can connect */}
            {permissions?.manageFirmware?.create &&
              <DropdownItem className="w-100" id="connect" disabled={!infraData?.connected} onClick={() => { setRunCommand("connect"); }}>
                <div className='d-flex justify-content-between align-items-center'>
                  Connect
                  <div className="cursor-pointer" style={{ fontWeight: "500" }} id="CONNECT_RTTY"><Connect width={20} height={20} /></div>
                  <UncontrolledTooltip target="CONNECT_RTTY">
                    {CONNECT_RTTY}
                  </UncontrolledTooltip>
                </div>
              </DropdownItem>}
          </>}
      </DropdownMenu>
    </UncontrolledDropdown>)
  }

  return (
    <div className="ApOverview" data-testid="ApOverview">
      <div className="d-flex align-items-center justify-content-between w-100" >
        <Header heading={infraData?.infraDisplayName} showStatus />
        {
          (venuePermissions[venueId]?.infra == null ?
            (permissions?.manageInfra?.delete || permissions?.manageInfra?.create)
            : (venuePermissions[venueId]?.infra?.delete || venuePermissions[venueId]?.infra?.create)
            || permissions?.manageTicket?.create) &&
          <ApOptionDropdown />
        }
      </div>
      <Alert color="danger" isOpen={!!error} toggle={() => setError(null)}><div className="alert-body">{error}</div></Alert>
      <Alert color={success === 'Downgrading' ? "warning" : "success"} isOpen={!!success} toggle={() => setSuccess(null)}><div className="alert-body">{success}</div></Alert>

      {
        options != null &&

        <FirmwareReschedule
          options={options}
          setModal={setOptions}
          updateSnoozeDate={(snzDate, itemId, rescheduleInfra) => {
            REDUX_WORKER.getInfra(infraData?.infraItemId, false)
            // if (rescheduleInfra) {
            //   if (options.setSubList) {
            //     options.setSubList((prevState) => {
            //       let index = prevState.findIndex((it) => it.infraItemId == itemId);
            //       let newList = [...prevState];
            //       if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //       return [...newList];
            //     });
            //   }
            //   else {
            //     setList((prevState) => {
            //       let index = prevState.findIndex((it) => it.infraItemId == itemId);
            //       let newList = [...prevState];
            //       if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //       return [...newList];
            //     });
            //   }
            //   return
            // }
            // if (options.setSubList) {
            //   options.setSubList((prevState) => {
            //     let index = prevState.findIndex((it) => it.venueId == itemId);
            //     let newList = [...prevState];
            //     if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //     return [...newList];
            //   });
            // } else {
            //   setList((prevState) => {
            //     let index = prevState.findIndex((it) => it.venueId == itemId);
            //     let newList = [...prevState];
            //     if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //     return [...newList];
            //   });
            // }
          }}
        />
      }


      {/* ----------- NEW VIEW -------------- */}
      <span className="d-flex">
        {
          infraData?.alarmCount > 0 &&
          <span className="px-1 py-50 yellow-info-badge shadow-sm rounded mr-1 mb-1">
            {statusIcons(infraData?.zone)}

            <span className="mr-50"
            >
              <LinkWrapper
                to={`/organization/${activeOrgId}/notification?filter=${JSON.stringify({
                  infras: [{ infraItemId: infraData?.infraItemId, infraName: infraData?.infraDisplayName }],
                  alertType: ['alarm']
                })}`}
              >
                {infraData?.alarmCount}
              </LinkWrapper>
            </span>

            Active Alarms
          </span>
        }
        {
          infraData?.scheduleSnoozed &&
          <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded mr-1 mb-1">
            <UpdateIcon width={24} height={24} className="mr-50" />
            Firmware upgrade schedule in &nbsp;
            <span id="schedule-tag-info" className="text-primary cursor-pointer"
              onClick={() =>
                setOptions({
                  scheduleId: infraData?.scheduleId,
                  venueId: infraData?.venueId,
                  scheduleDate: infraData?.scheduleDate,
                  scheduleSnoozed: infraData?.scheduleSnoozed,
                  timezone: infraData?.venueTimezone,
                  infraItemId: infraData?.infraItemId,
                  rescheduleInfra: true

                })

              }>{dateLeftFinder(infraData?.scheduleSnoozed)}</span>
            <UncontrolledTooltip target="schedule-tag-info">
              {dateTimeFormatter(infraData?.scheduleSnoozed, 'long', 'short')}
            </UncontrolledTooltip>
          </span>
        }
        {
          (rbac?.level<3 && permissions?.manageTicket?.view && infraData?.awaitingTickets > 0) &&
          <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded mb-1">
            <TicketIcon width={22} height={24} />
            <span className="ml-50"
            >
              <LinkWrapper
                to={
                  `/organization/${activeOrgId}/support/tickets/?filter=${JSON.stringify({
                    "infraId": infraData?.infraItemId, "statuses": [7], "mainFilter": "Awaiting feedback"
                  })
                  }`}
              >
                {infraData?.awaitingTickets} Ticket(s)
              </LinkWrapper>
            </span> &nbsp; awaiting customer feedback
          </span>
        }
      </span>

      <div className="d-flex justify-content-between mb-1">

        {/* --- Left card --- */}
        <div className="bg-white rounded p-1" style={{ width: '34%' }}>


          <div className="d-flex justify-content-between mb-50">
            <span>
              {
                infraData?.status != "demo" &&
                statusIcons(infraData?.zone)
              }
              <APSVG className="mr-50" />
              <strong>{infraData?.infraDisplayName || '-'}</strong>
            </span>
            <div >
              {
                !infraData?.enabled ?
                  <DisabledInfraBadge /> :
                  !!rebooting ?
                    <TimeProgress start={rebooting?.start} end={rebooting?.end} infraId={infraId} />
                    : infraData?.status === "demo" ?
                      <>
                        <LightBadge color="secondary">
                          <span className="text-dark">

                            {THIRD_PARTY_TITLE}
                          </span>
                        </LightBadge>
                      </> :
                      infraData?.status === "pending" ?
                        <>
                          <LightBadge color="warning">
                            <span className="text-dark">


                              Pending
                            </span>
                          </LightBadge>
                        </> :
                        infraData?.connected ?
                          <>
                            <LightBadge
                              color="success"
                              size="sm"
                              id='infra-connection-status-success-badge'>
                              <span className="text-dark">
                                Online
                              </span>
                            </LightBadge>
                            {infraData?.lastStatusAt != null ?
                              <UncontrolledTooltip target='#infra-connection-status-success-badge' placement="bottom">
                                Connected&nbsp;{timeDiff(infraData?.lastStatusAt)}&nbsp;ago
                              </UncontrolledTooltip> : null}
                          </>
                          :
                          <>
                            <LightBadge color="danger">
                              <span className="text-dark">

                                Offline
                              </span>
                            </LightBadge>
                          </>
              }
            </div>
          </div>

          <div className="d-flex justify-content-between w-100 mb-1" >
            <div className="d-flex flex-column justify-content-between" style={{ width: '70%' }}>
              <div className="d-flex align-items-center w-100">
                <span className="d-flex align-items-center mr-1" style={{ fontSize: '12px', maxWidth: "50%" }}>
                  <OrgGlobe width={'18px'} height={'18px'} className="mr-50" />
                  <span className="ellipse-text-box text-nowrap"
                  >
                    <LinkWrapper
                      to={`/organization/${infraData?.orgId}`}
                    >
                      {infraData?.orgName}
                    </LinkWrapper>
                  </span>
                </span>
                <span className="d-flex align-items-center" style={{ fontSize: '12px', width: '50%' }}>
                  <Floor width={'18px'} height={'18px'} className="mr-50" />
                  <span className="ellipse-text-box text-nowrap">
                    <LinkWrapper
                      to={`/organization/${infraData?.orgId}/venues/${infraData?.venueId}`}
                    >
                      {infraData?.venueName}
                    </LinkWrapper>
                  </span>
                </span>
              </div>
              <div>
                <span className="bold-text" >MAC:&nbsp;</span>
                <span
                  className="cursor-pointer"
                  onClick={() => handleCopy(infraData?.macAddress)}
                  onMouseEnter={() => {
                    showCopyIcon(COPYABLE_ID.MAC_ADDRESS)
                  }}
                  onMouseLeave={() => {
                    hideCopyIcon(COPYABLE_ID.MAC_ADDRESS)
                  }}
                >
                  <span>{infraData?.macAddress?.substring(0, 8)}</span>
                  <span className="font-weight-bolder">{infraData?.macAddress?.substring(8, 12)}</span>
                  <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.MAC_ADDRESS} style={{ fontSize: '1em' }}>
                    content_copy
                  </span>
                </span>

              </div>

            </div>

            <div className="center-div" style={{ height: '70px', width: '90px' }}>
              <img className="ap-img cursor-pointer" src={infraTypes.find(it => it.infraTypeId == infraData?.infraTypeId)?.images['1']} onClick={() => setInfraImageModal(true)} />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-1" >
            <div className="d-flex  mr-50 " style={{ width: '60%' }}>
              <div className="bold-text text-nowrap" >Serial number:&nbsp;</div>
              <div title={infraData?.serialNumber ? infraData?.serialNumber : ''}
                className="ellipse-text-box text-nowrap">{infraData?.serialNumber ?? ''}</div>
            </div>
            <div className="d-flex justify-content-end  w-50" >
              <div
                title={infraTypes?.find(it => it.infraTypeId == infraData?.infraTypeId)?.infraType?.replace(/_/g, " ")}
                className="ellipse-text-box text-nowrap text-right"
              >
                <span className="bold-text text-right" >Model:&nbsp;</span>
                {infraTypes?.find(it => it.infraTypeId == infraData?.infraTypeId)?.infraType?.replace(/_/g, " ")}</div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-1" >
            <div className="ellipse-text-box text-nowrap">
              <span className="bold-text" >Asset ID: </span>
              <span >{infraData?.assetTag || ''}</span>
            </div>

            <span className="">
              {
                view == DEPLOYMENT_VIEW &&
                <div className="d-flex" style={{ position: 'relative' }}>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infraData?.initialized ?
                        <Install width={24} height={24} /> :
                        <InstallIconBlack width={24} height={24} />
                    }
                    {infraData?.initialized
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infraData?.photos ?
                        <CameraImage width={24} height={24} /> :
                        <CameraImageBlack width={24} height={24} />
                    }
                    {infraData?.photos
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infraData?.installed ?
                        <LayoutIcon width={24} height={24} /> :
                        <LayoutIconBlack width={24} height={24} />
                    }
                    {infraData?.installed
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div style={{ position: 'relative' }}>
                    {
                      infraData?.validated ?
                        <ValidateIcon width={24} height={24} /> :
                        <ValidateIconBlack width={24} height={24} />
                    }
                    {infraData?.validated
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                </div>
              }
            </span>
          </div>

        </div>

        {/*--- Middle Card --- */}
        {
          !infraData.enabled ?
            <InfraDisabledCard
              onEnableClick={handleEnableClick}
              disabled={updatingInfra || infraitemLoading}
              loading={updatingInfra}
              enableToggle={enableToggle}
            /> :
            view != DEPLOYMENT_VIEW ?
              <ApStatInfo /> :
              <ApDeploymentInfo />
        }


        {/* --- Right Card --- */}
        <div className="bg-white rounded p-1" style={{ width: '25%' }}>
          {!infraData.enabled ?
            <NotesCard onPencilClick={() => setNoteModal(true)} />
            : <InstallationImages />
          }
        </div>

      </div>

      {/* --------------------------------------------------------------------- */}
      {!infraData.enabled ? null :
        <>
          {view != DEPLOYMENT_VIEW &&
            <div className="d-flex justify-content-between">
              <div className="bg-white p-1 rounded   pb-2 " style={{ width: '74%' }}>
                <div className='d-flex justify-content-between'>
                  <div className="d-flex">
                    {/* removed as per figma */}
                    {/* <div>
              {
                !!rebooting ?
                  <TimeProgress start={rebooting?.start} end={rebooting?.end} infraId={infraId} />
                  : infraData?.status === "DEMO" ?
                    <>
                      <div className="dark-badge text-center">
                        <span className="status-text">{THIRD_PARTY_TITLE}</span></div>
                    </> :
                    infraData?.status === "PENDING" ?
                      <>
                        <div className="warning-badge text-center">
                          <span className="status-text">Pending</span></div>
                      </> :
                      infraData?.connected ?
                        <>
                          <div className="success-badge text-center cursor-pointer"
                            id='infra-connection-status-success-badge'
                          >
                            <span className="status-text">Online</span></div>
                          {infraDetails?.lastStatusAt != null ?
                            <UncontrolledTooltip target='#infra-connection-status-success-badge' placement="bottom">
                              Connected&nbsp;{timeDiff(infraDetails?.lastStatusAt)}&nbsp;ago
                            </UncontrolledTooltip> : null}
                        </>
                        :
                        <>
                          <div className="fail-badge text-center">
                            <span className="status-text">Offline</span></div>
                        </>
              }
            </div> */}
                    {/* --- removed as per figma --- */}
                    {/* <div>
              {permissions?.manageInfrastats?.view && infraData?.connected && !rebooting &&
                <div className={"connect-text client-badge " + ((venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) && deviceCount > 0 ? "cursor-pointer" : "")}
                  onClick={() => {
                    if ((venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) && deviceCount > 0)
                      navigate(`/organization/${activeOrgId}/infra/${infraItemId}/clients/`)
                  }}
                >
                  <Suspense fallback={<></>}><Device height={24} width={24} className="fill-svg" />&nbsp;&nbsp;</Suspense>{deviceCount} Connected Clients</div>
              }
            </div> */}
                    {/* {(!infraData.connected && infraDetails?.lastStatusAt) &&
              <div className="ml-50 pt-50">
                Last Connected {timeDiff(infraDetails?.lastStatusAt)} ago
              </div>} */}
                  </div>
                  {/* 
              <div className="d-flex align-items-center">
                {permissions?.manageTicket?.view && issues?.length > 0 && <div className="rounded quicklink-button-ticket cursor-pointer" onClick={() => {
                  navigate(`/organization/${activeOrgId}/support/ticket/`, {
                    state: {
                      filter: { "infraId": infraId }
                    }
                  })
                }}>
                  <div id="ticket-tag" className="d-flex align-items-center support-button">
                    <div className="main-text d-flex align-items-center">
                      <Ticket width={28} />
                      &nbsp;{issues?.length > 5 ? ("5+") : issues?.length} Open Issue(s)
                    </div>
                    <UncontrolledTooltip target="ticket-tag">
                      Last updated {timeDiff(issues[0].updatedAt)} ago
                    </UncontrolledTooltip>
                  </div >
                </div >}

                {permissions?.manageNotificationstats?.view && alarms?.count > 0 && <div className="rounded quicklink-button-ticket cursor-pointer" onClick={() => {
                  navigate(`/organization/${activeOrgId}/notification/`, {
                    state: {
                      filter: { infras: [{ infraItemId: infraId, infraName: infraData?.infraDisplayName }], alertType: ['alarm'] }
                    }
                  })
                }}>
                  <div className="d-flex align-items-center support-button">
                    <div className="main-text d-flex align-items-center" id="alert-tag">
                      <div className="material-symbols-outlined text-danger">warning</div>
                      {/* correcting to active alarm/ active alarms instead of the current 'Alarms(s)' text as part of fixing TASK-3822 
                      &nbsp;{`${alarms?.count} Active ${alarms?.count == 1 ? 'Alarm' : 'Alarms'}`}
                    </div>
                    <UncontrolledTooltip target="alert-tag">
                      Last updated {timeDiff(alarms?.updatedAt)} ago
                    </UncontrolledTooltip>
                  </div>
                </div>}
                {
                  !!misMatch &&
                  <div className="cursor-pointer ml-1" onClick={() => setMismatchModal(true)}><span className="material-symbols-outlined text-warning">warning</span></div>
                }

              </div > */}
                </div >

                {runCommand === 'connect' ?
                  <div>
                    {consoleLoading ?
                      <div className="d-flex justify-content-center align-items-center mb-1 console-frame">
                        <VeryFancyLoader />
                      </div>
                      :
                      <>
                        <p>username: <i><b>root</b></i>, password: <i><b>{password ?? '-'}</b></i></p>
                        <div className='relative'>
                          <iframe sandbox className='w-100 rounded console-frame' src={connectURL} title="Console" allow="clipboard-read; clipboard-write"></iframe>
                          {/* <div className='p-1 d-flex justify-content-center align-items-center fullscreen cursor-pointer'
                  onClick={()=> {

                  }}>
                    <span class="material-symbols-outlined">
                      fullscreen
                    </span>
                  </div> */}
                        </div>
                      </>}
                    <div className='d-flex justify-content-center'><Button.Ripple color='outline-primary' onClick={() => { setRunCommand(null); setConnectURL(null); }}>Go Back</Button.Ripple></div>
                  </div>
                  : <>
                    <div className=" pt-2 mt-1 " style={{ minHeight: "130px" }}>
                      {profileData && <APMockUp isMock={infraData?.status === "DEMO"} infraData={infraData} profileData={profileData} setProfileData={setProfileData} connectionData={connectionData} />}
                    </div>


                    {/* --- removed as per figma --- */}
                    {/* <Row className="mt-2 px-50">
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="mb-lg-0 mb-1 border rounded bg-grey p-50">
                  <div className="info-heading">AP Model <Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} onClick={() => handleCopy(infraTypes?.find(it => it.infraTypeId == infraData?.infraTypeId)?.infraType?.replace(/_/g, " "))} /></Suspense></div>
                  <div className="info mt-50">{infraTypes?.find(it => it.infraTypeId == infraData?.infraTypeId)?.infraType?.replace(/_/g, " ")}</div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="border rounded bg-grey p-50">
                  <div className="info-heading">MAC <Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} onClick={() => handleCopy(infraData?.macAddress)} /></Suspense></div>

                  <div className="mt-50">
                    <span>{infraData?.macAddress?.substring(0, 8)}</span>
                    <span className="font-weight-bolder">{infraData?.macAddress?.substring(8, 12)}</span>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="border rounded bg-grey p-50">
                  <div className="info-heading">
                    <div className="d-flex align-items-center">

                      <span style={{ marginRight: 4 }}>
                        Public IP
                      </span>

                      <span id="target-public-ip" class="material-symbols-outlined cursor-pointer" style={{
                        fontSize: 16
                      }}>
                        info
                      </span>
                      <UncontrolledTooltip target="target-public-ip">
                        {PUBLIC_IP}
                      </UncontrolledTooltip>

                    </div>
                    <Suspense fallback={<></>}>
                      <Copy className="cursor-pointer" height={18} onClick={() => handleCopy((infraData?.connected && !!infraDetails?.publicIPAddress) ? infraDetails?.publicIPAddress : "Not Available")} />
                    </Suspense>
                  </div>
                  <div className="info mt-50">{(infraData?.connected && !!infraDetails?.publicIPAddress) ? infraDetails?.publicIPAddress : "Unavailable"}</div>
                </div >
              </Col >
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="mb-lg-0 mb-1 border rounded bg-grey p-50">
                  <div className="info-heading">
                    <div className="d-flex align-items-center">
                      <span style={{ marginRight: 4 }}>
                        Private IP
                      </span>

                      <span id="target-private-ip" class="material-symbols-outlined cursor-pointer " style={{
                        fontSize: 16
                      }}>
                        info
                      </span>
                      <UncontrolledTooltip target="target-private-ip">
                        {PRIVATE_IP}
                      </UncontrolledTooltip>
                    </div>
                    <Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} onClick={() => handleCopy(infraDetails?.ipv4?.split("/")[0])} /></Suspense></div>
                  <div className={"mt-50 " + ((infraData?.connected && infraDetails?.ipv4?.length > 0) ? "" : "info")}>{((infraData?.connected && infraDetails?.ipv4?.length > 0) && ipFormater(infraDetails?.ipv4?.split("/")[0])) || "Unavailable"}</div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="border rounded bg-grey p-50">
                  <div className="info-heading">RF Profile <Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} onClick={() => handleCopy(profileData?.rfProfileName)} /></Suspense></div>
                  <div className="d-flex justify-content-between mt-50">
                    <div className="info">
                      {profileData?.rfProfileName ?? "Unavailable"}
                    </div>
                    {profileData?.rfProfileId &&

                      <img className="cursor-pointer" src={PencilIcon} style={{
                        width: '1.4rem',
                        paddingRight: '0.3rem'
                      }} onClick={() => {
                        navigate(`/organization/${activeOrgId}/profiles/${profileData?.rfProfileId}`)
                      }} />
                    }
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className="px-50">
                <div className="border rounded bg-grey p-50">
                  <div className="info-heading">AP Profile <Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} onClick={() => handleCopy(profileData?.apProfileName)} /></Suspense></div>
                  <div className="d-flex justify-content-between mt-50">
                    <div className="info">
                      {profileData?.apProfileName ?? "Unavailable"}
                    </div>
                    {profileData?.apProfileId &&

                      <img className="cursor-pointer" src={PencilIcon} style={{
                        width: '1.4rem',
                        paddingRight: '0.3rem'
                      }} onClick={() => {
                        navigate(`/organization/${activeOrgId}/profiles/${profileData?.apProfileId}`)
                      }} />
                    }
                  </div>
                </div>
              </Col>
            </Row > */}

                    {/* <Row className="mt-2">
              <Col md={12} lg={5}>
                <div className="border rounded bg-grey p-1">
                  <h6 className="font-weight-bolder">Device Images</h6>
                  <div>
                    <ImagePage imgs={infraTypes.find(it => it.infraTypeId == infraData?.infraTypeId)?.images} />
                  </div>
                </div>
              </Col>
              <Col md={12} lg={7}>

              </Col>
            </Row> */}
                  </>}
              </div >

              <div className="rounded bg-white p-1" style={{ width: '25%' }}>
                <NotesCard
                  onPencilClick={() => setNoteModal(true)}
                />
              </div>
            </div>
          }
          {
            view != DEPLOYMENT_VIEW &&
            <InfraInsightCharts />
          }

          {
            view != DEPLOYMENT_VIEW &&
            <AlertInfraTable infraItemId={infraData.infraItemId} />
          }

          {
            view == DEPLOYMENT_VIEW &&
            <div>
              {/* --- deployment view --- */}
              {
                floorplanLoading ?
                  <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '220px' }}>
                    <Spinner color="primary" />
                  </div>
                  : floorplanList.length > 0 ?
                    <FloorplanSlide floorplanList={floorplanList} venueId={infraData?.venueId} infraData={infraData} />
                    :
                    <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '220px' }}>
                      No Floorplan Available
                    </div>
              }
            </div>
          }
        </>}

      <Modal centered isOpen={!!runCommand && runCommand !== 'connect' && runCommand !== 'downgrade'} toggle={() => !modalLoading && setRunCommand(null)}>
        <ModalHeader toggle={() => !modalLoading && setRunCommand(null)}>
        </ModalHeader>
        <ModalBody>
          <Actions runCommand={runCommand} setRunCommand={setRunCommand} modalLoading={setModalLoading} hideModal={() => setRunCommand(null)} />
        </ModalBody>
      </Modal>

      <Modal centered isOpen={noteModal} toggle={() => { setNoteModal(false); setNotesError(null) }}>
        <ModalHeader className="bg-white" toggle={() => { setNoteModal(false); setNotesError(null) }} />
        <ModalBody>
          <h3>Add Notes to Access Point</h3>
          <Alert color="danger" isOpen={!!notesError} toggle={() => setNotesError(null)}><div className="alert-body">{notesError}</div></Alert>
          <div>
            <small>Notes</small>
            <Input type="textarea" className="w-100" defaultValue={infraData?.description} rows={7} innerRef={noteRef} onChange={(e) => {
              if (e.target?.value?.length > 512)
                setNotesError("Notes can be atmost 512 characters long.");
              else
                setNotesError(null);
            }} />
          </div>
          <div className="my-2 text-right">
            <Button.Ripple className="mr-1" disabled={modalLoading} color="primary" outline onClick={() => { setNoteModal(false); setNotesError(null) }}>Discard</Button.Ripple>
            <Button.Ripple color="primary" disabled={modalLoading || !!notesError} onClick={() => updateAP(noteRef?.current?.value)}>{modalLoading ? <Spinner size="sm" /> : "Submit"}</Button.Ripple>
          </div>
        </ModalBody>
      </Modal>

      <Modal centered isOpen={noteModal} toggle={() => { setNoteModal(false); setNotesError(null) }}>
        <ModalHeader className="bg-white" toggle={() => { setNoteModal(false); setNotesError(null) }} />
        <ModalBody>
          <h3>Add Notes to Access Point</h3>
          <Alert color="danger" isOpen={!!notesError} toggle={() => setNotesError(null)}><div className="alert-body">{notesError}</div></Alert>
          <div>
            <small>Notes</small>
            <Input type="textarea" className="w-100" defaultValue={infraData?.description} rows={7} innerRef={noteRef} onChange={(e) => {
              if (e.target?.value?.length > 512)
                setNotesError("Notes can be atmost 512 characters long.");
              else
                setNotesError(null);
            }} />
          </div>
          <div className="my-2 text-right">
            <Button.Ripple className="mr-1" disabled={modalLoading} color="primary" outline onClick={() => { setNoteModal(false); setNotesError(null) }}>Discard</Button.Ripple>
            <Button.Ripple color="primary" disabled={modalLoading || !!notesError} onClick={() => updateAP(noteRef.current.value)}>{modalLoading ? <Spinner size="sm" /> : "Submit"}</Button.Ripple>
          </div>
        </ModalBody>
      </Modal>
      {!infraData?.enabled ? null :
        <>
          {
            !!roomLocationModal &&
            <RoomLocationModal
              visible={roomLocationModal}
              setVisible={setRoomLocationModal}
              infraData={infraData}

            />
          }

          {
            !!infraImageModal &&
            <InfraImageModal
              visible={infraImageModal}
              setVisible={setInfraImageModal}
              imgs={infraTypes.find(it => it.infraTypeId == infraData?.infraTypeId)?.images}
            />
          }
          {
            !!installedImageModal &&
            <InstalledImageModal
              visible={installedImageModal}
              setVisible={setInstalledImageModal}
              instImg={instImg}
              setInstImg={setInstImg}
              instCount={instCount}
              setInstCount={setInstCount}
              infraData={infraData}
              instActive={instActive}
              setInstActive={setInstActive}
            />

          }
        </>}
      <Modal centered isOpen={misMatchModal} toggle={() => setMismatchModal(false)}>
        <ModalBody>
          <div>
            <div className="text-center">
              <span className="material-symbols-outlined text-warning display-2">warning</span>
            </div>

            <div className="p-2 font-weight-bolder text-center">
              We faced some issues while attempting to configure your Access Point.<br /><br />
              Don't worry. We will try again at
              &nbsp;{dateTimeFormatter(misMatch?.nextRetryTime, "medium", "short")}
            </div>

            <div className="text-center">
              <Button className="ml-1" color="primary" onClick={() => setMismatchModal(false)}>OK</Button>
              <Button className="ml-1" color="primary" outline onClick={() => navigate(`/organization/${activeOrgId}/sysadmin/`)}>View All</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <SupportModal isOpen={support} setIsOpen={setSupport}
        onSuccess={() => REDUX_WORKER.getIssues(infraData?.infraItemId, infraData?.orgId)}
        ticketDetails={
          {
            category: "Network",
            subCategory: "Infrastructure",
            infraName: infraData?.infraDisplayName,
            venueName: infraData?.venueName,
            venueId: infraData?.venueId,
            infraId: infraData?.infraItemId,
            macAddress: infraData?.macAddress
          }
        } />
      <Suspense fallback={<></>}>
        <DeleteInfraModal
          option={deleteOption}
          setOption={setDeleteOption}
          isOpen={isDeleteInfraOpen}
          setIsOpen={setIsDeleteInfraOpen}
          onDelete={deleteInfra}
          infraIds={new Set([infraData?.infraItemId])}
          disabled={deleting}
          orgId={infraData?.orgId}
        />
      </Suspense>
      <Modal centered isOpen={warningModal} toggle={() => { !deleting && setWarningModal(false); }}>
        <ModalHeader toggle={() => { !deleting && setWarningModal(false); }}></ModalHeader>
        <ModalBody className="p-3">
          <div className="d-flex flex-column align-items-center">
            {/* Autoclear */}
            <Alert color="danger" className="w-100" isOpen={deleteError !== null} toggle={() => { setDeleteError(null); }}>
              <div className="alert-body">
                {deleteError}
              </div>
            </Alert>
            <span className="material-symbols-outlined display-1 text-danger">warning</span>
            {runCommand === 'downgrade' ?
              <strong className="py-1">Are you sure you want to downgrade this infrastructure?</strong>
              : <strong className="py-1">Are you sure you want to delete this infrastructure?</strong>}
            <span>This process cannot be reverted.</span>
            {(deleting || downgrading) && <strong className="text-danger"><Spinner /></strong>}
            {runCommand === 'downgrade' ?
              <div className="d-flex justify-content-center pt-2">
                <Button color="primary" disabled={downgrading} onClick={() => { setWarningModal(false); setRunCommand(null); }}>Cancel</Button>
                <Button className="ml-2" disabled={downgrading} color="outline-danger" onClick={downgrade}>Downgrade</Button>
              </div>
              : <div className="d-flex justify-content-center pt-2">
                <Button color="primary" disabled={deleting} onClick={() => { setWarningModal(false); }}>Cancel</Button>
                <Button className="ml-2" disabled={deleting} color="outline-danger" onClick={deleteInfra}>Delete</Button>
              </div>}
          </div>
        </ModalBody>
      </Modal>
    </div >
  );


};

export const FloorplanSlide = ({ floorplanList, venueId, infraData }) => {
  const [floorplanIndex, setFloorplanIndex] = useState(0)
  return (

    <div className=" my-1 p-1 bg-white rounded InfraFloorplanContainer ">
      <div className="d-flex">
        <div className="mr-1" style={{ position: 'relative' }}>
          {
            infraData?.installed ?
              <LayoutIcon width={24} height={24} /> :
              <LayoutIconBlack width={24} height={24} />
          }
          {infraData?.installed
            ? <div className="success-top-icon">
              <Success width={16} height={16} />
            </div>
            : <></>
          }
        </div>
        <span className="bold-text">Installation Location</span>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="w-100 rounded mx-50">
          <div className="slide-container ">
            <FloorplanSlideView
              layout={floorplanList[floorplanIndex]}
              hideDropdown={true}
              onDelete={() => { }}
              isDesignLayout={true}
              hideBackGrid={true}
              onHeaderClick={() => { }}
              showAP={true}
              showRealAp={true}
              showCableDrop={true}
              venueId={venueId}
            />

          </div>
          <div className="d-flex center-div mb-50">
            {floorplanList.length > 1 &&
              <div
                className={" center-div  " + (floorplanList.length > 1 ? '' : ' ') + (floorplanIndex > 0 ? 'cursor-pointer' : 'cursor-not-allowed')}
                onClick={() => {
                  if (floorplanIndex > 0)
                    setFloorplanIndex(floorplan => floorplan - 1)
                }}
              >
                <span class="material-symbols-outlined text-primary mr-2" style={{ fontSize: '2.8em' }}>
                  chevron_left
                </span>
              </div>
            }
            {
              floorplanList.length > 1 && floorplanList.map((plan, index) => {
                return <div className={index == floorplanIndex ? "slide-grey-dot-selected cursor-pointer" : "slide-grey-dot cursor-pointer"}
                  onClick={() => setFloorplanIndex(index)} />
              })
            }
            {
              floorplanList.length > 1 &&
              <div
                className={"center-div  " + (floorplanList.length > 1 ? ' ' : '') + (floorplanIndex < floorplanList.length - 1 ? 'cursor-pointer' : 'cursor-not-allowed')}
                onClick={() => {
                  if (floorplanIndex < floorplanList.length - 1)
                    setFloorplanIndex(floorplan => floorplan + 1)
                }}>
                <span class="material-symbols-outlined text-primary ml-2" style={{ fontSize: '2.8em' }}>
                  chevron_right
                </span>
              </div>
            }
          </div>
        </div>


      </div>

      {/* --- bottom indicator --- */}


    </div >

  )
}
ApOverview.propTypes = {};

ApOverview.defaultProps = {};

export default ApOverview;
