/***
 *
 * Controller class for user.
 * @file Overview.js
 * @description Overview component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Button,
  InputGroup,
  Input,
  Spinner,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../_builder/Header";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from 'prop-types';
import "./Overview.scss";
import createRequest, { services } from "../../../services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CatchedWebError } from "../../../configs";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import blazer from "../../../services/blazer.service";
import Blazer from "../../../redux/slices/blazer.slice";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import {
  formatNetworkCardData,
  formatNetworkThreshholdCardData,
  formatPhyRateCardData,
  formatRssiCardData,
} from "../../Dashboard/NewDashboard/BlazerUtil";
import { breadcrumbActions } from "../../../redux/slices";

import ChartError from "../../Dashboard/Graphs/ChartError";
import DateRangeSelector from "../../Dashboard/Graphs/DateRangeSelector";
import NetworkChart from "../../Dashboard/Graphs/NetworkChart";
import { colon_mac } from "../../../helpers/macHelper";
import { make_custom_toast } from "../../../helpers/toasts";
import {
  BanClient,
  SimpleTroubleshootModal,
  displayPort,
} from "../DeviceList";
import {
  ColumnHeader,
  FilterSetter,
  SearchIconAddon,
  SupportModal,
} from "../../../components";
import GroupButton from "../../../components/GroupButton";
import FilterButton from "../../../components/FilterButton";
import { ReactComponent as ClientIcon } from "../../../assets/images/icons/Devices-SM.svg";
import { ReactComponent as PortIcon } from "../../../assets/images/icons/Switch Icon.svg";
import { ReactComponent as APColored } from "../../../assets/images/icons/APColored.svg";
import { ReactComponent as SwitchColored } from "../../../assets/images/icons/SwitchColored.svg";
import { ReactComponent as ClientsIconGrey } from "../../../assets/images/icons/ClientsIconGrey.svg";
import { ReactComponent as WifiIconColored } from "../../../assets/images/icons/WifiIconColored.svg";
import { ReactComponent as WifiIconGrey } from "../../../assets/images/icons/WifiIconGrey.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/images/icons/Refresh.svg";
import { ReactComponent as WarningIcon } from '../../../assets/images/icons/WarningWhiteExclamation.svg'
// import APSVG from "../../Infrastructure/InfraList/APSVG";
// import SwitchSVG from "../../Infrastructure/InfraList/SwitchSVG";
import LightBadge from "../../../components/LightBadge";
import Skeleton from "../../Dashboard/Graphs/Skeleton";
// import Skeleton from "react-loading-skeleton";
import PhyRateChart from "../../Dashboard/Graphs/PhyRateChart";
import RssiChart from "../../Dashboard/Graphs/RssiChart";
import lodash from "lodash-es";
import { EventFilter, PriorityFilter } from "../../Filters/filters";
import { timeDiff } from "../../Infrastructure/SwitchOverview";
import InfiniteScroll from "react-infinite-scroll-component";
import { AP, CLIENT_EVENTS, SCROLL_MORE_TEXT, SWITCH } from "../../../utility/constants";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { dateTimeFormatter, getTimeZone } from "../../../utility/Localization";
import ToolTip from "react-portal-tooltip";
import { ReactComponent as CsvIcon } from '../../../assets/images/icons/xls_icon.svg';
import axios from "axios";
import { D } from "../../../helpers";
import { LowerCase, capitalizeWord, downloadSpreadsheet, scrollToTop } from "../../../utility/Utils";
import { ipFormater } from "../../Infrastructure/InfraList";
import LinkWrapper from "../../../components/LinkWrapper";
import NetworkThresholdChart from "../../Dashboard/Graphs/NetworkThresholdChart";
import RadiusDetails, { AttributeFormatter } from "../../../components/RadiusDetails";

let timer;

const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
};

const filterInitial = {};

const Cap = (text) => {
  return !!text ? text[0].toUpperCase() + text.slice(1) : text;
};

const DefaultDetails = () => {
  return <span>-</span>;
};

const ClickableInfra = (props) => {
  const { event } = props;
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  return (
    <LinkWrapper
      to={event.infraId != null
        ? `/organization/${activeOrgId}/infra/${event.infraId}/`
        : null
      }
    >
      {event.infraName}
    </LinkWrapper>
  );
};

const CommaSeparatedInfo = ({ children, notEnd }) => {
  children = children.filter(child => !!child)
  if (children.length === 0)
    return null
  else if (children.length === 1)
    return <>{children}{notEnd ? '' : '.'}</>
  else
    return <>
      {children.map((child, key) => {
        if (key === children.length - 1)
          return child
        return <>{child}, </>
      })}
      <span>{notEnd ? '' : '.'}</span>
    </>
}

export const EventDetails = (props) => {
  const { event } = props;
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);

  return LowerCase(event.event) === LowerCase(CLIENT_EVENTS.CONNECT) || LowerCase(event.event) === LowerCase(CLIENT_EVENTS.RECONNECT) ? (
    <span>
      Client connected
      {!!event.infraName ? (
        <>
          {` to `}
          <ClickableInfra event={event} />
        </>
      ) : null}
      {!!event.ssid ? (
        <>
          {` on network `}
          <LinkWrapper
            className={event.networkId != null ? "table-link" : ""}
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
        </>
      ) : null}
      .&nbsp;
      <CommaSeparatedInfo>
        {!!event.port ?
          <span>Port: {displayPort(event.port) ?? "-"}</span> : null}
        {!!event.band ?
          <span>Band: {event.band}</span> : null}
        {event.rssi != null ?
          <span>RSSI: {event.rssi}{` dBm`}</span> : null}
        {event.channel != null ?
          <span>Channel: {event.channel}</span> : null}
        {event.aaaServer != null && event.aaaPort != null ?
          <span>AAA Server: {`${event.aaaServer}:${event.aaaPort}`}</span> : null}
        {event.aaaRTT != null ?
          <span>AAA RTT:{
            (Number(event.aaaRTT) > 100 ?
              <>&nbsp;<WarningIcon height={18} width={18} className="align-text-top" /></> : '')}
            &nbsp;
            {`${event.aaaRTT} ms`}</span> : null}
        {event.aaaUsername != null ?
          <span>AAA Username:{` ${event.aaaUsername}`}</span> : null}
        {event.aaaReplyMessage != null ?
          <span>AAA Reply:{` ${event.aaaReplyMessage}`}</span> : null}
        {event.vlan != null ?
          <span>VLAN: <span>{Array.isArray(event.vlan) ? event.vlan.join(', ') : event.vlan}</span></span> : null}
      </CommaSeparatedInfo>
    </span>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.DISCONNECT) ? (
    <>
      Client disconnected
      {!!event.infraName ? (
        <span>
          {` from `}
          <ClickableInfra event={event} />
        </span>
      ) : null}
      {!!event.ssid ? (
        <>
          {` on network `}
          <LinkWrapper
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
        </>
      ) : null}
      .&nbsp;
      <CommaSeparatedInfo>
        {!!event.port ?
          <span>Port: {displayPort(event.port) ?? "-"}</span> : null}
        {!!event.band ?
          <span>Band: {event.band}</span> : null}
        {event.rssi != null ?
          <span>RSSI: {event.rssi}{` dBm`}</span> : null}
        {event.channel != null ?
          <span>Channel: {event.channel}</span> : null}
        {event.vlan != null ?
          <span>VLAN: <span>{Array.isArray(event.vlan) ? event.vlan.join(', ') : event.vlan}</span></span> : null}
      </CommaSeparatedInfo>
    </>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.ROAMING) ? (
    <>
      Client moved
      {!!event.oldInfraId ? (
        <span>
          {!!event.oldInfraName ? (
            <>
              {` from `}
              <LinkWrapper
                to={event.oldInfraId != null
                  ? `/organization/${activeOrgId}/infra/${event.oldInfraId}/`
                  : null
                }
              >
                {event.oldInfraName}
              </LinkWrapper>
              {!!event.oldSsid ? (
                <>
                  {` on network `}
                  <LinkWrapper
                    to={event.oldNetworkId != null
                      ? `/organization/${activeOrgId}/networks/${event.oldNetworkId}`
                      : null
                    }
                  >
                    {event.oldSsid}
                  </LinkWrapper>
                </>
              ) : null}
              {(!!event.oldBand || event.oldChannel != null || event.oldSignal != null || event.oldVlanId != null) ?
                <>
                  {` (`}
                  <CommaSeparatedInfo notEnd={true}>
                    {!!event.oldBand ?
                      <span>Band: {event.oldBand}</span> : null}
                    {event.oldChannel != null ?
                      <span>Channel: {event.oldChannel}</span> : null}
                    {event.oldSignal != null ?
                      <span>RSSI: {event.oldSignal}{` dBm`}</span> : null}
                    {/* {event.oldAaaServer != null && event.oldAaaPort != null?
                  <span>AAA Server: {`${event.oldAaaServer}:${event.oldAaaPort}`}</span>:null} */}
                    {/* {event.oldAaaRtt != null?
                  <span>AAA RTT:{
                    (Number(event.oldAaaRtt) > 100? 
                      <>&nbsp;<WarningIcon height={18} width={18} className="align-text-top"/></>:'')}
                    &nbsp;
                    {`${event.oldAaaRtt} ms`}</span>:null} */}
                    {event.oldVlanId != null ?
                      <span>VLAN: <span>{Array.isArray(event.oldVlanId) ? event.oldVlanId.join(', ') : event.oldVlanId}</span></span> : null}
                  </CommaSeparatedInfo>
                  {`)`}
                </>
                : null}
            </>
          ) : null}
        </span>
      ) : null}
      {!!event.infraId ? (
        <span>
          {!!event.infraName ? (
            <>
              {` to `} <ClickableInfra event={event} />
              {!!event.newSsid ? (
                <>
                  {` on network `}
                  <LinkWrapper
                    to={event.newNetworkId != null
                      ? `/organization/${activeOrgId}/networks/${event.newNetworkId}`
                      : null
                    }
                  >
                    {event.newSsid}
                  </LinkWrapper>
                </>
              ) : null}
              {(!!event.newBand || event.newChannel != null || event.newSignal != null ||
                (event.newAaaServer != null && event.newAaaPort != null) ||
                event.newAaaRtt != null ||
                event.newAaaUsername != null ||
                event.newAaaReplyMessage != null ||
                event.newVlanId != null
              ) ?
                <>
                  {` (`}
                  <CommaSeparatedInfo notEnd={true}>
                    {!!event.newBand ?
                      <span>Band: {event.newBand}</span> : null}
                    {event.newChannel != null ?
                      <span>Channel: {event.newChannel}</span> : null}
                    {event.newSignal != null ?
                      <span>RSSI: {event.newSignal}{` dBm`}</span> : null}
                    {event.newAaaServer != null && event.newAaaPort != null ?
                      <span>AAA Server: {`${event.newAaaServer}:${event.newAaaPort}`}</span> : null}
                    {event.newAaaRtt != null ?
                      <span>AAA RTT:{
                        (Number(event.newAaaRtt) > 100 ?
                          <>&nbsp;<WarningIcon height={18} width={18} className="align-text-top" /></> : '')}
                        &nbsp;
                        {`${event.newAaaRtt} ms`}</span> : null}
                    {event.newAaaUsername != null ?
                      <span>AAA Username:{` ${event.newAaaUsername}`}</span> : null}
                    {event.newAaaReplyMessage != null ?
                      <span>AAA Reply:{` ${event.newAaaReplyMessage}`}</span> : null}
                    {event.newVlanId != null ?
                      <span>VLAN: <span>{Array.isArray(event.newVlanId) ? event.newVlanId.join(', ') : event.newVlanId}</span></span> : null}
                  </CommaSeparatedInfo>
                  {`)`}
                </>
                : null}
            </>
          ) : null}
        </span>
      ) : null}
      .
    </>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.KEY_MISMATCH) ? (
    <>
      Authentication failed due to{` ${CLIENT_EVENTS.KEY_MISMATCH}`}
      {!!event.ssid ? (
        <>
          {` on network `}
          <LinkWrapper
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
        </>
      ) : null}
      {!!event.infraName ? (
        <span>
          {` with `}
          <ClickableInfra event={event} />
        </span>
      ) : null}
      .
    </>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.NO_RADIUS_RESPONSE) ? (
    <span>
      AAA server
      {(!!event.aaaServer && !!event.aaaPort) ? (
        <>
          {` ${event.aaaServer}:${event.aaaPort}`}
        </>
      ) : null}
      {event.aaaRTT != null ||
        event.aaaUsername != null ||
        event.aaaReplyMessage != null ?
        <>
          {` (`}
          <CommaSeparatedInfo notEnd={true}>
            {event.aaaRTT != null ?
              <span>AAA RTT:{
                (Number(event.aaaRTT) > 100 ?
                  <>&nbsp;<WarningIcon height={18} width={18} className="align-text-top" /></> : '')}
                &nbsp;
                {`${event.aaaRTT} ms`}</span> : null}
            {event.aaaUsername != null ?
              <span>AAA Username:{` ${event.aaaUsername}`}</span> : null}
            {event.aaaReplyMessage != null ?
              <span>AAA Reply:{` ${event.aaaReplyMessage}`}</span> : null}
          </CommaSeparatedInfo>
          {`)`}
        </> : null}
      {` is not responding`}
      {!!event.infraName ? (
        <>
          {` to `}
          <ClickableInfra event={event} />
          {!!event.ssid ? (
            <>
              {` for network `}
              <LinkWrapper
                to={event.networkId != null
                  ? `/organization/${activeOrgId}/networks/${event.networkId}`
                  : null
                }
              >
                {event.ssid}
              </LinkWrapper>
            </>
          ) : null}
          .&nbsp;
          {(!!event.band || event.channel != null) ?
            <CommaSeparatedInfo>
              {!!event.band ?
                <span>Band: {event.band}</span> : null}
              {event.channel != null ?
                <span>Channel: {event.channel}</span> : null}
            </CommaSeparatedInfo>
            : null}
        </>
      ) : null}
    </span>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.AAA_REJECTED_AUTH) ? (
    <span>
      AAA server
      {(!!event.aaaServer && !!event.aaaPort) ? (
        <>
          {` ${event.aaaServer}:${event.aaaPort}`}
          {/* {` (`}
          <CommaSeparatedInfo notEnd={true}>
            {!!event.aaaServer?
            <span>IP Address: {event.aaaServer}</span>:null}
            {!!event.aaaPort?
            <span>Port: {event.aaaPort}</span>:null}
          </CommaSeparatedInfo>
          {`)`} */}
        </>
      ) : null}
      {` rejected client authentication`}
      {!!event.infraName ? (
        <>
          {` through `}
          <ClickableInfra event={event} />
        </>
      ) : null}
      {!!event.ssid ? (
        <>
          {`, network `}
          <LinkWrapper
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
        </>
      ) : null}
      .&nbsp;
      <CommaSeparatedInfo>
        {!!event.band ?
          <span>Band: {event.band}</span> : null}
        {event.channel != null ?
          <span>Channel: {event.channel}</span> : null}
        {event.aaaRTT != null ?
          <span>AAA RTT:{
            (Number(event.aaaRTT) > 100 ?
              <>&nbsp;<WarningIcon height={18} width={18} className="align-text-top" /></> : '')}
            &nbsp;
            {`${event.aaaRTT} ms`}</span> : null}
        {event.aaaUsername != null ?
          <span>AAA Username:{` ${event.aaaUsername}`}</span> : null}
        {event.aaaReplyMessage != null ?
          <span>AAA Reply:{` ${event.aaaReplyMessage}`}</span> : null}
      </CommaSeparatedInfo>
    </span>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.BANNED) ? (
    <span>
      Client was banned
      {event.ssid != null ? (
        <>
          {` on `}
          <LinkWrapper
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
          .
        </>
      ) : (
        `.`
      )}
    </span>
  ) : LowerCase(event.event) === LowerCase(CLIENT_EVENTS.UNBANNED) ? (
    <span>
      Client was unbanned
      {event.ssid != null ? (
        <>
          {` on `}
          <LinkWrapper
            to={event.networkId != null
              ? `/organization/${activeOrgId}/networks/${event.networkId}`
              : null
            }
          >
            {event.ssid}
          </LinkWrapper>
          .
        </>
      ) : (
        `.`
      )}
    </span>
  ) : (
    <DefaultDetails />
  );
};

const unitConverter = (value) => {
  if (!value || isNaN(value) || Math.sign(value) === -1) return null;
  const size = 1000;
  const types = ['Kbps', 'Mbps', 'Gbps', 'Tbps'];
  let index = value < 1 ? 0 : Math.floor(Math.log(value) / Math.log(size));
  let newValue = value / Math.pow(size, index);
  newValue = Math.round(newValue * 10) / 10;
  return newValue + " " + types[index];
};

const allowedQuickFilters = ['all', 'failures', 'roaming']
const allowedEvents = Object.keys(CLIENT_EVENTS).map(item => CLIENT_EVENTS[item])
const Overview = (props) => {
  const { clientMac } = useParams();
  const [searchParams, setSearchParams]= useSearchParams();
  const [receivedState, setReceivedState] = useState(() => {
    const searchParamClientData = searchParams.get('clientInfo')
    return searchParamClientData != null ? JSON.parse(searchParamClientData) : null
  });
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  const networkRef = useSelector((state) => state.device_network);
  const phyRateRef = useSelector((state) => state.device_phyRate);
  const rssiRef = useSelector((state) => state.device_rssi);
  const range = useSelector((store) => store.activeOrg.meta.dateRange);
  const identity = useSelector(store => store.identity)

  const [colonMac, setColonMac] = useState(colon_mac(clientMac));
  const [device, setDevice] = useState(null);
  const [deviceLoading, setDeviceLoading] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [eventDataLoading, setEventDataLoading] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [banClient, setBanClient] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [mainEventCategory, setMainEventCategory] = useState(allowedQuickFilters.includes(searchParams.get("events")) ? searchParams.get("events") : "all")
  const [filterCount, setFilterCount] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [phyChartData, setPhyChartData] = useState(
    formatPhyRateCardData(JSON.parse(phyRateRef?.data ?? "{}"))
  );
  const [rssiChartData, setRssiChartData] = useState(
    formatRssiCardData(JSON.parse(rssiRef?.data ?? "{}"))
  );
  const paramFilter = () => {
    let event = searchParams.get("event");
    return (searchParams.get("events") === 'all' && allowedEvents.includes(event)) ?
      {
        event: [event]
      } : undefined
  }
  const filterValue = paramFilter();
  const [filterSelection, setFilterSelection] = useState(filterValue);
  const [filterData, setFilterData] = useState(filterValue);
  const [filterActive, setFilterActive] = useState(!!filterValue);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [supportModalData, setSupportModalData] = useState(null);
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);

  const [isSimpleOpen, setIsSimpleOpen] = useState(false);
  const [simpleTroubleshootData, setSimpleTroubleshootData] = useState(null);
  const [submittingSimpleModal, setSubmittingSimpleModal] = useState(false);
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  // const [validationError, setValidationError] = useState("nickname can only be alphanumeric.");
  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: "timestamp"
  });
  const [disableAsbCapture, setDisableAsbCapture] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const eventListRef = useRef(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!mainEventCategory) {
      let updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set("events", mainEventCategory);
      setSearchParams(updatedSearchParams.toString(), { replace: true });
    }
  }, [mainEventCategory])

  useEffect(() => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (mainEventCategory === 'all' && filterData?.event?.length > 0) {
      updatedSearchParams.set("event", filterData?.event);
    }
    else {
      updatedSearchParams.delete("event", filterData?.event);
    }
    setSearchParams(updatedSearchParams.toString(), { replace: true });
  }, [filterData, mainEventCategory])

  const handleMainEventCategoryClick = (category) => {
    setMainEventCategory(category);
    setFilterData(filterInitial);
    setFilterSelection(filterInitial);
    fetchEvents(filterInitial, category);
    getCount(filterInitial, category);
    setFilterActive(false);
  };

  useEffect(() => {
    setPhyChartData(formatPhyRateCardData(JSON.parse(phyRateRef?.data)));
  }, [phyRateRef?.data]);

  useEffect(() => {
    setRssiChartData(formatRssiCardData(JSON.parse(rssiRef?.data)));
  }, [rssiRef?.data]);

  useEffect(() => {
    setColonMac(colon_mac(clientMac));
  }, [clientMac]);

  useTimedCaller(
    {
      service: blazer.DEVICE_NETWORK,
      params: [
        colonMac,
        isoDate(range),
        isoDate(),
        new Date().getTimezoneOffset(),
      ],
      data: {},
    },
    range,
    colonMac,
    (store) => store.device_network,
    Blazer.device_network.actions,
    forceRefresh,
    setForceRefresh
  );
  useTimedCaller(
    {
      service: blazer.DEVICE_PHYRATE,
      params: [
        colonMac,
        isoDate(range),
        isoDate(),
        new Date().getTimezoneOffset(),
      ],
      data: {},
    },
    range,
    colonMac,
    (store) => store.device_phyRate,
    Blazer.device_phyRate.actions,
    forceRefresh,
    setForceRefresh
  );
  useTimedCaller(
    {
      service: blazer.DEVICE_RSSI,
      params: [
        colonMac,
        isoDate(range),
        isoDate(),
        new Date().getTimezoneOffset(),
      ],
      data: {},
    },
    range,
    colonMac,
    (store) => store.device_rssi,
    Blazer.device_rssi.actions,
    forceRefresh,
    setForceRefresh
  );

  const getBannedClient = (blockClientId = null) => {
    let blockId = searchParams?.get("blockClientId") ?? blockClientId;
    if (!!blockId) {
      const { run } = createRequest(services.networks.GET_CLIENT, [
        blockId,
        activeOrgId,
      ]);

      run()
        .then((response) => {
          setDevice(response.data);
        })
        .finally(() => setDeviceLoading(false));
    }
  };

  useEffect(() => {
    if (!!activeOrgId && !!searchParams?.get("blockClientId")) {
      getBannedClient();
    }
  }, [activeOrgId]);

  const getDeviceDetails = () => {
    const { run, controller } = createRequest(services.devices.GET_DEVICE, [
      colonMac,
      activeOrgId,
    ]);
    if (!searchParams?.get("blockClientId")) {
      run()
        .then((response) => {
          try {
            let responseData = response.data;
            // if (responseData.infraCategory == SWITCH) {
            //   let foundSwitch;
            //   if (responseData.infraId != -1) {
            //     foundSwitch = responseData?.switches?.find(
            //       (item) => item.infra_id == responseData.infraId
            //     );
            //   } else {
            //     foundSwitch = responseData?.switches?.find(
            //       (item) => item.mac_address == responseData.macAddress
            //     );
            //   }
            //   responseData.port = foundSwitch?.port;
            //   responseData.vlan = foundSwitch?.vlan?.join(",");
            // }
            setDevice(responseData);
            const { run } = createRequest(services.networks.VERIFY_BANNED, [], {
              orgId: activeOrgId,
              clients: [{
                "mac": responseData.clientMac?.replaceAll(":", ""),
                "networkId": responseData.networkId
              }]
            })

            run().then(response => {
              if (response.data[0].banned) {
                let updatedSearchParams = new URLSearchParams(searchParams.toString());
                updatedSearchParams.set("blockClientId", response.data[0].blockClientId);
                setSearchParams(updatedSearchParams.toString(), { replace: true });
                getBannedClient(response.data[0].blockClientId)
              }
            });
          } catch (error) { }
        })
        .catch((error) => {

          //disabled due to failed clients details not reveived sometimes issue (as discussed with team)
          // make_custom_toast(
          //   "error",
          //   "Client Details",
          //   new CatchedWebError(error).message
          // );
          if (!receivedState?.macAddress)
            setDisableAsbCapture(true)
          setDevice(receivedState)
        })
        .finally(() => setDeviceLoading(false));
    }
    else {
      setDeviceLoading(false);
    }

    return controller;
  }

  useEffect(() => {
    let controller = getDeviceDetails();

    return () => {
      controller.abort();
    };
  }, [colonMac, activeOrgId, searchParams.get("blockClientId")]);

  const getCount = (filter = filterData, quickFilter = mainEventCategory) => {
    const { run: eventsFetcher } = createRequest(
      services.devices.GET_DEVICE_EVENTS_COUNT,
      [
        colonMac,
        activeOrgId,
        search,
        //start date,
        isoDate(range),
        //end date
        isoDate(),
        // timezone
        new Date().getTimezoneOffset(),
        // eventType
        quickFilter,
        filter,
        sort
      ]
    );
    eventsFetcher()
      .then((response) => {
        setFilterCount(response.data);
      })
      .catch((error) => {
        make_custom_toast(
          "error",
          "Client Events Count",
          new CatchedWebError(error).message
        );
      })
  };

  const fetchEvents = (
    filter = filterData,
    quickFilter = mainEventCategory
  ) => {
    const limit = 20;
    const { run: eventsFetcher } = createRequest(
      services.devices.GET_DEVICE_EVENTS,
      [
        colonMac,
        activeOrgId,
        limit,
        0,
        search,
        //start date,
        isoDate(range),
        //end date
        isoDate(),
        // timezone
        new Date().getTimezoneOffset(),
        // eventType
        quickFilter,
        filter,
        sort
      ]
    );
    setEventDataLoading(true)
    eventsFetcher()
      .then((response) => {
        scrollToTop(eventListRef)
        setEventData(response.data);
        if (response.data.length < limit) setHasMore(false);
        else setHasMore(true);
      })
      .catch((error) => {
        setHasMore(false);
        make_custom_toast(
          "error",
          "Client Events",
          new CatchedWebError(error).message
        );
      })
      .finally(() => setEventDataLoading(false));
  };

  useEffect(() => {
    getCount();
    fetchEvents();
  }, [activeOrgId, colonMac, search, range, sort]);

  useEffect(() => {
    dispatch(
      breadcrumbActions.setData([
        {
          text: "Clients",
          path: (searchParams.has("backTo") ? `/organization/${activeOrgId}/venues/${searchParams.get("backTo")}/clients/` : `/organization/${activeOrgId}/clients`),
          active: false,
        },
        !!clientMac
          ? {
            text: clientMac,
            active: true,
          }
          : {},
      ])
    );
  }, [clientMac, dispatch, activeOrgId]);

  useEffect(() => {
    setEditValue(device?.vendor);
  }, [device]);

  const handleDownload = () => {
    const apiURL = D(`/userdevice/${colonMac}/events/export?orgId=${activeOrgId}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${isoDate(range)}&endDate=${isoDate()}&timezoneOffset=${new Date().getTimezoneOffset()}&eventType=${mainEventCategory}&search=${search}`
      + (filterData?.priorities?.length ? `&severity=${filterData.priorities}` : ``)
      + (filterData?.event?.length ? `&event=${filterData.event}` : ``)
    )
    const fileName = `Events List${!!mainEventCategory && !filterActive ? ('-(' + capitalizeWord(mainEventCategory) + ')') : ''}${!!clientMac ? ('-(' + clientMac + ')') : ''}.xlsx`;
    setDownloading(true)
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
      .catch(err => {
        make_custom_toast('error', 'Export Event List', (new CatchedWebError(err)).message)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  return (
    <div
      className="Overview mt-1"
      data-testid="Overview"
      onClickCapture={(e) => {
        if (
          !e.target.matches("#nickname-input") &&
          !e.target.matches("#nickname-input-tick")
        )
          setEditing(false);
      }}
    >
      <SupportModal
        isOpen={supportModalIsOpen && !!supportModalData}
        setIsOpen={setSupportModalIsOpen}
        // onSuccess={() => {}}
        ticketDetails={{
          ...supportModalData?.ticketDetails,
          category: TICKET_CATEGORY.NETWORK,
          subCategory: "Clients"
        }}
        context={supportModalData?.context}
      />
      <SimpleTroubleshootModal
        isOpen={isSimpleOpen}
        setIsOpen={setIsSimpleOpen}
        modalData={simpleTroubleshootData}
        setModalData={setSimpleTroubleshootData}
        submitting={submittingSimpleModal}
        setSubmitting={setSubmittingSimpleModal}
        flag={true}
      />
      <Modal centered isOpen={banClient} toggle={() => setBanClient(false)}>
        <ModalHeader
          className="bg-white p-0"
          toggle={() => setBanClient(false)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center px-3">
            <div className="material-symbols-outlined display-2 text-warning">
              error
            </div>
            <div>
              AP running firmware prior to Release 3.1 - banning this client results in a short disconnection of all clients on the network
            </div>
            <div className="mt-1">
              <h5>
                Are you sure you want to ban <br />"
                <span className="font-weight-bolder">{colonMac}</span>"?
              </h5>
            </div>
            <div className="mt-2 mb-1">
              <Button.Ripple
                className="mr-1"
                color="primary"
                onClick={() => setBanClient(false)}
              >
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="danger"
                outline
                onClick={() =>
                  BanClient(
                    {
                      ...device,
                      clientType: device.clientType,
                      ssid: device.ssid,
                      venue_id: device.venueId,
                      clientName: device.name,
                      vendor: device.vendor,
                      mac: device.clientMac,
                      last_reported_time: device.clientStatus === "disconnected" || (device.clientStatus === 'rejected' && device.lastStatusAt != null) ?
                        device.lastStatusAt
                        : new Date(),
                      networkId: device.networkId,
                    },
                    () => {
                      setBanClient(false);
                      navigate(-1);
                    },
                    device?.clientStatus === "connected"
                  )
                }
              >
                Yes
              </Button.Ripple>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className="d-flex justify-content-end mb-50 mr-50">
        <DateRangeSelector />
        <UncontrolledDropdown
          disabled={deviceLoading}
        >
          <DropdownToggle color="white" className="p-0 dropdown-toggle">
            <span
              tag="span"
              className={`material-symbols-outlined client-overview-dropdown dropdownToggle ${deviceLoading ? "text-secondary cursor-not-allowed" : "text-primary cursor-pointer"
                } p-0`}
            >
              more_vert
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            {device?.clientType === "wireless" &&
              !!device.ssid &&
              !!device.networkId &&
              !searchParams.get("blockClientId") ? (
              <DropdownItem
                className="w-100"
                onClick={() => setBanClient(true)}
              >
                Ban Client
              </DropdownItem>
            ) : null}
            <DropdownItem
              className="w-100"
              onClick={() => {
                setSupportModalIsOpen(true);
                setSupportModalData({
                  ticketDetails: {
                    category: 3,
                    infraName: device?.infraName,
                    venueName: device?.venueName,
                    venueId: !device?.venueId || isNaN(Number(device?.venueId)) ? null : Number(device?.venueId),
                    infraId: device?.infraId,
                    macAddress: device?.macAddress,
                    description: !!colonMac
                      ? `Client Mac Address: ${colonMac}`
                      : "",
                  },
                  context: {
                    ...(!!device?.vendor ? { vendor: device?.vendor } : {}),
                    ...(!!device?.clientName ? { clientName: device?.name } : {}),
                  },
                });
              }}
            >
              Support Case
            </DropdownItem>
            {!!device?.macAddress && !disableAsbCapture && device?.infraCategory != SWITCH ? (
              <DropdownItem
                className="w-100"
                onClick={() => {
                  setSimpleTroubleshootData({
                    deviceMAC: colonMac,
                    duration: "",
                    keyword: "",
                    macAddress: device?.macAddress,
                  });
                  setIsSimpleOpen(true);
                }}
              >
                Collect ASB
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div
        className="d-flex cards-row"
        style={{ gap: "0.65%", flexWrap: "wrap" }}
      >
        {deviceLoading || !!device ? (
          <div
            id="client-info-card"
            className="d-flex justify-content-between bg-white rounded"
            style={{ width: device?.clientType === "wired" ? "55%" : "38%", position: "relative" }}
          >
            {deviceLoading ? (
              <div className="w-100 d-flex justify-content-center align-items-center text-primary">
                <Spinner />
              </div>
            ) : (
              <>
                {!searchParams?.get("blockClientId") ? <div className="border rounded cursor-pointer refresh-icon" onClick={() => {
                  setDeviceLoading(true);
                  getDeviceDetails();
                }}>
                  <RefreshIcon width={18} height={18} />
                </div> : null}
                <div>
                  <div className="d-flex justify-content-center align-items-center position-relative">
                    {device?.clientStatus === "connected" ? (
                      <ClientIcon className="icon" />
                    ) : (
                      <ClientsIconGrey className="icon" />
                    )}
                    {device?.clientType === "wired" ? (
                      <hr className="icon-line position-absolute" />
                    ) : null}
                  </div>
                  <LightBadge
                    className="d-flex justify-content-center my-50"
                    color={
                      device?.clientStatus === "connected"
                        ? "success"
                        : "secondary"
                    }
                  >
                    {device?.clientStatus === "connected"
                      ? "Connected"
                      : device?.clientStatus === "disconnected"
                        ? "Disconnected"
                        : device?.clientStatus === "rejected"
                          ? "Failed to connect"
                          : "-"
                    }
                  </LightBadge>
                  <div
                    className="client-details text-font-small-size"
                    data-wired={device?.clientType === "wired" ? true : false}
                  >
                    <div title={device?.clientName ?? device.vendor ?? "-"}>
                      <b>Name:&nbsp;</b>{" "}
                      {device.clientName ?? device.vendor ?? "-"}
                    </div>
                    {device.clientStatus === "disconnected" || device.clientStatus === "rejected" ? (
                      <div
                        title={
                          !!device.lastStatusAt ?
                            (dateTimeFormatter(
                              device.lastStatusAt,
                              "long",
                              "medium"
                            ) +
                              " (" +
                              getTimeZone(false) +
                              ") ")
                            : '-'
                        }
                      >
                        <b>Last Seen:&nbsp;</b>{" "}
                        {!!device.lastStatusAt ?
                          (timeDiff(device.lastStatusAt) + " ago")
                          : "-"}
                      </div>
                    ) : null}
                    {device.clientStatus === "connected" &&
                      device?.clientType === "wireless" ? (
                      <>
                        {(
                          (!!device?.ipv4Address && device?.ipv4Address.length === 1) ||
                          (!!device?.ipv6Address && device?.ipv6Address.length === 1)
                        ) &&
                          device?.ipAddressAt ?
                          <UncontrolledTooltip
                            target="client-ipaddressat"
                          >
                            <div>
                              Private IP: {
                                !!device?.ipv4Address &&
                                  device?.ipv4Address.length === 1
                                  ? device.ipv4Address[0]
                                  : !!device?.ipv6Address &&
                                    device?.ipv6Address.length === 1
                                    ? device.ipv6Address[0]
                                    : "-"
                              }
                            </div>
                            <div>
                              Last Updated: {dateTimeFormatter(
                                device?.ipAddressAt,
                                "long",
                                "medium"
                              ) +
                                " (" +
                                getTimeZone(false) +
                                ") "}
                            </div>
                          </UncontrolledTooltip> : null}
                        <div
                          title={
                            !!device?.ipAddressAt ?
                              null
                              : !!device?.ipv4Address &&
                                device?.ipv4Address.length === 1
                                ? device.ipv4Address[0]
                                : !!device?.ipv6Address &&
                                  device?.ipv6Address.length === 1
                                  ? device.ipv6Address[0]
                                  : "-"
                          }
                          id="client-ipaddressat"
                        >
                          <b>Private IP:&nbsp;</b>
                          {!!device?.ipv4Address &&
                            device?.ipv4Address.length === 1
                            ? ipFormater(device.ipv4Address[0])
                            : !!device?.ipv6Address &&
                              device?.ipv6Address.length === 1
                              ? device.ipv6Address[0]
                              : "-"}
                        </div>
                        {/* <div title={device?.publicIP}>
                          <b>Public IP:&nbsp;</b>
                          {device?.publicIP ?? "-"}
                        </div> */}
                      </>
                    ) : null}
                    {/* connectedTime */}
                    {device.clientStatus === "connected" ? (
                      <div id={`client-details-connected`}>
                        <div
                        // title={UPTIME(
                        //   device.lastStatusAt ?? 0,
                        //   true,
                        //   false,
                        //   true
                        // )}
                        >
                          <b>Connected:&nbsp;</b>
                          {timeDiff(device.lastStatusAt)
                            // + (device.clientStatus === 'disconnected'?' ago':'')
                          }
                        </div>
                        {!!device.lastStatusAt ?
                          <UncontrolledTooltip
                            target={`client-details-connected`}
                          >
                            {dateTimeFormatter(
                              device.lastStatusAt,
                              "long",
                              "medium"
                            ) +
                              " (" +
                              getTimeZone(false) +
                              ") "}
                          </UncontrolledTooltip> : null}
                      </div>
                    ) : null}
                    <div
                    title={device.aaaUsername ?? "-"}
                    >
                      <b>Identity:&nbsp;</b>
                      {device.aaaUsername ?? "-"}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center align-items-center position-relative">
                    {device?.clientType === "wired" ? (
                      device?.clientStatus === "connected" ? (
                        <PortIcon className="icon" />
                      ) : (
                        <PortIcon className="icon grey-icon" />
                      )
                    ) : device?.clientStatus === "connected" ? (
                      <WifiIconColored className="icon" />
                    ) : (
                      <WifiIconGrey className="icon" />
                    )}
                    {device?.clientType === "wired" ? (
                      <hr className="icon-line position-absolute" />
                    ) : null}
                  </div>
                  {device?.clientType != "wired" ? (
                    <LightBadge
                      className="d-flex justify-content-center my-50"
                      innerClassName="pill"
                      color={device.clientStatus === "connected" ?
                        "primary"
                        : "secondary"}
                    >
                      {device?.band ?? "-"}
                    </LightBadge>
                  ) : null}
                  <div
                    className={`client-details text-font-small-size`}
                    data-wired={device?.clientType === "wired" ? true : false}
                    data-topPadding={
                      device?.clientType != "wired" ? false : true
                    }
                  >
                    {device?.clientType === "wired" ? (
                      device?.infraCategory == AP ? (
                        <>
                          <div title={displayPort(device?.port) ?? "-"}>
                            <b>Port Name:</b>&nbsp;
                            {displayPort(device?.port) ?? "-"}
                          </div>
                          <div title={device?.vlan ?? "-"}>
                            <b>VLAN:</b>&nbsp;{device?.vlan ?? "-"}
                          </div>
                        </>
                      ) : device?.switches?.length === 1 ? (
                        <>
                          <div title={displayPort(device?.switches[0]?.port) ?? "-"}>
                            <b>Port Name:</b>&nbsp;
                            {displayPort(device?.switches[0]?.port) ?? "-"}
                          </div>
                          <div title={device?.switches[0]?.vlan ?? "-"}>
                            <b>VLAN:</b>&nbsp;
                            {device?.switches[0]?.vlan?.join(",") ?? "-"}
                          </div>
                        </>
                      ) : (
                        <div id='switch-connections'
                          onMouseEnter={() => { setActive1(true) }}
                          onMouseLeave={() => { setActive1(false) }}
                        >
                          {device?.switches?.map((switchItem, key) =>
                            key < 2 ? (
                              <div
                                title={device?.switches?.length > 2 ? null : (displayPort(switchItem?.port) ?? "-")}
                                key={"port" + key + 1}
                              >
                                <b>Port Name&nbsp;{key + 1}:</b>&nbsp;
                                {displayPort(switchItem?.port) ?? "-"}
                              </div>
                            ) : null
                          )}
                          {device?.switches?.map((switchItem, key) =>
                            key < 2 ? (
                              <div
                                title={device?.switches?.length > 2 ? null : (switchItem?.vlan?.join(",") ?? "-")}
                                key={"vlan" + key + 1}
                              >
                                <b>{`Infra ${key + 1} VLAN:`}</b>&nbsp;
                                {switchItem?.vlan?.join(",") ?? "-"}
                              </div>
                            ) : null
                          )}
                          {device?.switches?.length > 2 ?
                            <ToolTip
                              parent={`#switch-connections`}
                              active={active1}
                              position="bottom"
                            // arrow="center"
                            >
                              {device?.switches?.map((switchItem, key) =>
                                <div
                                  // title={displayPort(switchItem?.port) ?? "-"}
                                  key={"port" + key + 1}
                                >
                                  <b>Port Name&nbsp;{key + 1}:</b>&nbsp;
                                  {displayPort(switchItem?.port) ?? "-"}
                                </div>
                              )}
                              {device?.switches?.map((switchItem, key) =>
                                <div
                                  // title={switchItem?.vlan?.join(",") ?? "-"}
                                  key={"vlan" + key + 1}
                                >
                                  <b>{`Infra ${key + 1} VLAN:`}</b>&nbsp;
                                  {switchItem?.vlan?.join(",") ?? "-"}
                                </div>
                              )}
                            </ToolTip> : null}
                        </div>
                      )
                    ) : (
                      <>
                        <div title={device?.ssid ?? "-"}>
                          <b>Network:</b>{" "}
                          <LinkWrapper
                            to={
                              !!device?.networkId
                                ? `/organization/${activeOrgId}/networks/${device?.networkId}/`
                                : null
                            }
                          >
                            {device?.ssid ?? "-"}
                          </LinkWrapper>
                        </div>
                        <div title={(device?.channel ?? "-") + (device?.channelWidth ? ` (${device?.channelWidth} MHz)` : '')}>
                          <b>Channel:</b> {(device?.channel ?? "-") + (device?.channelWidth ? ` (${device?.channelWidth} MHz)` : '')}
                        </div>
                        <div title={device?.vlan ?? "-"}>
                          <b>VLAN:</b> {device?.vlan ?? "-"}
                        </div>
                        {device.clientStatus === "connected" ? (
                          <>
                            <div title={device?.rssi ? (device?.rssi + " dBm") : "-"}>
                              <b>RSSI:</b> {(device?.rssi ?? "-") + " dBm"}
                            </div>
                            <div title={unitConverter(device?.txphyRate) ?? "-"}>
                              <b>Phy Rate(TX):</b>{" "}
                              {unitConverter(device?.txphyRate) ?? "-"}
                            </div>
                            <div title={unitConverter(device?.rxphyRate) ?? "-"}>
                              <b>Phy Rate(RX):</b>{" "}
                              {unitConverter(device?.rxphyRate) ?? "-"}
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center align-items-center">
                    {device?.infraCategory == SWITCH ? (
                      <SwitchColored
                        className={`icon ${device?.infraStatus === "connected" ? "" : "grey-icon"
                          }`}
                      />
                    ) : (
                      <APColored
                        className={`icon ${device?.infraStatus === "connected" ? "" : "grey-icon"
                          }`}
                      />
                    )}
                  </div>
                  <LightBadge
                    className="d-flex justify-content-center my-50"
                    color={
                      device?.infraStatus === "connected"
                        ? "success"
                        : "secondary"
                    }
                  >
                    {device?.infraStatus === "connected"
                      ? "Online"
                      : device?.infraStatus === "disconnected"
                        ? "Offline"
                        : "-"}
                  </LightBadge>
                  <div
                    className="client-details text-font-small-size"
                    data-wired={device?.clientType === "wired" ? true : false}
                  >
                    {device?.infraCategory == AP ? (
                      <>
                        <div title={device?.infraName ?? "-"}>
                          <b>Infrastructure:</b>{" "}
                          <LinkWrapper
                            to={
                              !!device?.infraId
                                ? `/organization/${activeOrgId}/infra/${device?.infraId}/`
                                : null
                            }
                          >
                            {device?.infraName ?? "-"}
                          </LinkWrapper>
                        </div>
                        <div title={device?.venueName ?? "-"}>
                          <b>Venue:</b>{" "}
                          <LinkWrapper
                            to={
                              !!device?.venueId
                                ? `/organization/${activeOrgId}/venues/${device?.venueId}/`
                                : null
                            }
                          >
                            {device?.venueName ?? "-"}
                          </LinkWrapper>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div id="infra-switches"
                          onMouseEnter={() => { setActive2(true) }}
                          onMouseLeave={() => { setActive2(false) }}
                        >
                          {device?.switches?.map((switchItem, key) => {
                            return key < 2 ?
                              <div title={device?.switches?.length > 2 ? null : (switchItem?.name ?? "-")}>
                                <b>Infrastructure{device?.switches?.length > 1 ? ` ${key + 1}` : ''}:</b>{" "}
                                <LinkWrapper
                                  to={
                                    !!switchItem?.infra_id
                                      ? `/organization/${activeOrgId}/infra/${switchItem?.infra_id}/`
                                      : null
                                  }
                                >
                                  {switchItem?.name ?? "-"}
                                </LinkWrapper>
                              </div> : null
                          })}
                          {device?.switches?.length > 2 ?
                            <ToolTip
                              parent={`#infra-switches`}
                              active={active2}
                              position="bottom"
                            // arrow="center"
                            >
                              {device?.switches?.map((switchItem, key) => {
                                return <div
                                // title={switchItem?.name ?? "-"}
                                >
                                  <b>Infrastructure{device?.switches?.length > 1 ? ` ${key + 1}` : ''}:</b>{" "}
                                  <LinkWrapper
                                    to={
                                      !!switchItem?.infra_id
                                        ? `/organization/${activeOrgId}/infra/${switchItem?.infra_id}/`
                                        : null
                                    }
                                  >
                                    {switchItem?.name ?? "-"}
                                  </LinkWrapper>
                                </div>
                              })}
                            </ToolTip> : null}
                        </div>
                        <div title={device?.venueName ?? "-"}>
                          <b>Venue:</b>
                          <LinkWrapper
                            to={
                              !!device?.venueId
                                ? `/organization/${activeOrgId}/venues/${device?.venueId}/`
                                : null
                            }
                          >
                            {device?.venueName ?? "-"}
                          </LinkWrapper>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
        {device?.clientType === "wireless" ? (
          <>
            <div style={{ width: device?.clientType === "wired" ? "44%" : "20%" }}>
              {networkRef.isLoading ? (
                <div className="bg-white p-1 h-100">
                  <Skeleton
                    containerClassName="d-block h-100"
                    className="d-block h-100"
                  />
                </div>
              ) : networkRef.isError ? (
                <ChartError
                  title="Traffic"
                  value={networkRef.isError}
                  noCardBorder={true}
                  noStyle={true}
                />
              ) : null}
              <div
                className={`${!JSON.parse(networkRef.data) ||
                  networkRef.isLoading ||
                  networkRef.isError
                  ? "d-none"
                  : ""
                  }`}
                // style={device?.clientType==='wired'?{height: '100%'}:{}}
                style={{ height: '100%' }}
              >
                <NetworkThresholdChart
                  smallTitle={true}
                  noStyle={true}
                  height="100%"
                  width={device?.clientType === "wired" ? "100%" : null}
                  noCardBorder={true}
                  ticks={2}
                  up={formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).up}
                  down={formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).down}
                  total={formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).total}
                  categories={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).labels
                  }
                  series1={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series1
                  }
                  series2={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series2
                  }
                  series3={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series3
                  }
                  series4={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series4
                  }
                  series5={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series5
                  }
                  series6={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).series6
                  }
                  typeSeriesTrafficDownlink={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data))
                      .typeSeriesTrafficDownlink
                  }
                  typeSeriesTrafficUplink={
                    formatNetworkThreshholdCardData(JSON.parse(networkRef.data))
                      .typeSeriesTrafficUplink
                  }
                  isLoading={
                    networkRef.isLoading ||
                    (!networkRef.isError && !networkRef.data)
                  }


                />
              </div>
              {!JSON.parse(networkRef.data) &&
                !networkRef.isLoading &&
                !networkRef.isError ? (
                <div className="d-flex align-items-center h-100 bg-white p-5 justify-content-center rounded">
                  <h4>Network stats are not available.</h4>
                </div>
              ) : null}
            </div>
            <div style={{ width: "20%" }}>
              {phyRateRef.isLoading ? (
                <div className="bg-white p-1 h-100">
                  <Skeleton
                    containerClassName="d-block h-100"
                    className="d-block h-100"
                  />
                </div>
              ) : phyRateRef.isError ? (
                <ChartError
                  title="Phy Rate"
                  value={phyRateRef.isError}
                  noCardBorder={true}
                  noStyle={true}
                />
              ) : null}
              <div
                className={`${!JSON.parse(phyRateRef.data) ||
                  phyRateRef.isLoading ||
                  phyRateRef.isError
                  ? "d-none"
                  : ""
                  }`}
                style={{ height: '100%' }}
              >
                <PhyRateChart
                  noStyle={true}
                  noCardBorder={true}
                  ticks={2}
                  height={"100%"}
                  // width={"100%"}
                  up={phyChartData.up}
                  down={phyChartData.down}
                  total={phyChartData.total}
                  categories={phyChartData.labels}
                  colors={["#FFE3C7", "#FFC185", "#FF9F43", "#F17E8E", "#DE3FC7", "#CC00FF"]}
                  series1={phyChartData.series1}
                  series2={phyChartData.series2}
                  series3={phyChartData.series3}
                  series4={phyChartData.series4}
                  series5={phyChartData.series5}
                  series6={phyChartData.series6}
                  unit={phyChartData.unit}
                  isLoading={
                    phyRateRef.isLoading ||
                    (!phyRateRef.isError && !phyRateRef.data)
                  }
                />
              </div>
              {!JSON.parse(phyRateRef.data) &&
                !phyRateRef.isLoading &&
                !phyRateRef.isError ? (
                <div className="d-flex align-items-center h-100 bg-white p-5 justify-content-center rounded">
                  <h4>Phy Rate stats are not available.</h4>
                </div>
              ) : null}
            </div>
            <div style={{ width: "20%" }}>
              {rssiRef.isLoading ? (
                <div className="bg-white p-1 h-100">
                  <Skeleton
                    containerClassName="d-block h-100"
                    className="d-block h-100"
                  />
                </div>
              ) : rssiRef.isError ? (
                <ChartError
                  title="RSSI"
                  value={rssiRef.isError}
                  noCardBorder={true}
                  noStyle={true}
                />
              ) : null}
              <div
                className={`${!JSON.parse(rssiRef.data) ||
                  rssiRef.isLoading ||
                  rssiRef.isError
                  ? "d-none"
                  : ""
                  }`}
                style={{ height: '100%' }}
              >
                <RssiChart
                  noCardBorder={true}
                  ticks={2}
                  height={"100%"}
                  // width={"70%"}
                  categories={rssiChartData.labels}
                  colors={["#d4f7ff", "#07cbf7", "#79e4fc"]}
                  series={rssiChartData.series}
                  seriesMin={rssiChartData.seriesMin}
                  seriesMax={rssiChartData.seriesMax}
                  max={rssiChartData.max}
                  unit="dBm"
                  isLoading={rssiRef.isLoading}
                  isRssi
                // isLoading={venueAlarmRef.isLoading || (!venueAlarmRef.isError && !venueAlarmRef.data)}
                />
              </div>
              {!JSON.parse(rssiRef.data) &&
                !rssiRef.isLoading &&
                !rssiRef.isError ? (
                <div className="d-flex align-items-center h-100 bg-white p-5 justify-content-center rounded">
                  <h4>RSSI stats are not available.</h4>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      <Row
        className={`${device?.clientType === "wireless" ? "pb-50" : "my-50"}`}
      >
        <Col>
          {device?.clientType === "wireless" ? (
            <Header
              // heading={(device?.vendor??'-') + (device?.client_name?`(${device?.client_name})`:'')}
              heading={
                device?.clientName && device?.clientName != ""
                  ? device?.clientName
                  : device?.vendor ?? "-"
              }
              device={device}
              setDevice={setDevice}
              editing={editing}
              setEditing={setEditing}
              processing={processing}
              setProcessing={setProcessing}
              clientMac={colonMac}
              setBanClient={setBanClient}
              from={!!searchParams.get("blockClientId") ? "banned" : ""}
              blockId={searchParams.get("blockClientId")}
              disabled={!device || deviceLoading || !device.ssid || !device.networkId}
              networks={!!device?.networks ? [...device?.networks] : []}
            />
          ) : null}
        </Col>
      </Row>
      <div className="bg-white rounded p-1">
        <FilterSetter
          // disabled={!Object.keys(filterData).length}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          elements={() => {
            return [
              <PriorityFilter
                filter={filterSelection}
                setFilter={setFilterSelection}
              />,
              <EventFilter
                filter={filterSelection}
                setFilter={setFilterSelection}
              />,
            ];
          }}
          handleApplyClick={() => {
            setFilterData(filterSelection);
            setMainEventCategory("all");
            fetchEvents(filterSelection, "all");
            // getCount(filterSelection, 'all')
            setShowFilter(false);
            if (lodash.isEqual(filterSelection, filterInitial))
              setFilterActive(false);
            else setFilterActive(true);
          }}
          handleClearAll={() => {
            const filterDataToSet = filterInitial;
            setFilterSelection(filterDataToSet);
            setFilterData(filterDataToSet);
            setMainEventCategory("all");
            fetchEvents(filterDataToSet, "all");
            getCount(filterDataToSet);
            setShowFilter(false);
            setFilterActive(false);
          }}
        />
        <div className="heading-style-text headings mb-50">Events</div>
        <div className="d-flex">
          <div className="d-flex w-75">
            <InputGroup className="input-group-merge" style={{ width: "22rem" }}>
              <Input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  // dispatch(deviceActions.reset());
                  // dispatch(deviceActions.setLoading(true));
                  debounce(() => {
                    setSearch(e.target.value);
                  });
                  // setIsTouched(true);
                }}
              />
              <SearchIconAddon />
            </InputGroup>
            <GroupButton className="ml-50">
              <div
                className={
                  "grp-btn-custom " +
                  (mainEventCategory === "all" && !filterActive ? "active" : "")
                }
                onClick={() => {
                  handleMainEventCategoryClick("all");
                }}
              >
                All ({filterCount?.all ?? 0})
              </div>
              <div
                className={
                  "grp-btn-custom " +
                  (mainEventCategory === "failures" && !filterActive
                    ? "active"
                    : "")
                }
                onClick={() => {
                  handleMainEventCategoryClick("failures");
                }}
              >
                Failures ({filterCount?.failures ?? 0})
              </div>
              {device?.clientType === "wireless" ? (
                <div
                  className={
                    "grp-btn-custom " +
                    (mainEventCategory === "roaming" && !filterActive
                      ? "active"
                      : "")
                  }
                  onClick={() => {
                    handleMainEventCategoryClick("roaming");
                  }}
                >
                  Roaming ({filterCount?.roaming ?? 0})
                </div>
              ) : null}
            </GroupButton>
            <FilterButton
              className='ml-50'
              size={22}
              active={filterActive}
              onClick={() => setShowFilter(true)}
              style={{ padding: "0.36rem" }}
            />
          </div>
          <div className="text-right w-25">
            <CsvIcon height={24} width={24}
              className={`${downloading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={() => {
                if (!downloading)
                  handleDownload()
              }}
              title={`Export List\n(maximum limit: 5000)`}
            />
          </div>
        </div>
        <InfiniteScroll
          dataLength={eventData?.length ?? 0}
          next={() => {
            const limit = 10;
            const { run } = createRequest(services.devices.GET_DEVICE_EVENTS, [
              colonMac,
              activeOrgId,
              limit,
              eventData?.length ?? 0,
              search,
              //start date,
              isoDate(range),
              //end date,
              isoDate(),
              // timezone
              new Date().getTimezoneOffset(),
              // eventType
              mainEventCategory,
              filterData,
              sort
            ]);
            run()
              .then((response) => {
                if (response.data.length < limit) {
                  setHasMore(false);
                }
                setEventData((currList) => [...currList, ...response.data]);
              })
              .catch((err) => {
                setHasMore(false);
                // setError(new CatchedWebError(err).message);
                make_custom_toast(
                  "error",
                  "Client Events",
                  new CatchedWebError(err).message
                );
              });
          }}
          hasMore={hasMore}
          loader={<div>{SCROLL_MORE_TEXT}</div>}
          endMessage={<span>Showing {eventData?.length} result(s)</span>}
          scrollableTarget="client-event-list"
        >
          <div
            className="mt-1"
            id="client-event-list"
            ref={eventListRef}
            data-wireless={device?.clientType === "wireless" ? "yes" : "no"}
          >
            <Table className="table-view fixed-header">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>
                    <ColumnHeader
                      header="Time"
                      attribute="timestamp"
                      sort={sort}
                      setter={setSort}
                    />
                  </th>
                  <th style={{ width: "10%" }}>
                    <ColumnHeader
                      header="Severity"
                      attribute="severity"
                      sort={sort}
                      setter={setSort}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    <ColumnHeader
                      header="Event"
                      attribute="event"
                      sort={sort}
                      setter={setSort}
                    />
                  </th>
                  <th style={{ width: "50%" }}>
                    <ColumnHeader
                      header="Details"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {eventDataLoading ? (
                  <tr>
                    <td
                      className="text-center p-3 text-primary"
                      style={{ height: "30vh" }}
                      colSpan={20}
                    >
                      <Spinner />
                    </td>
                  </tr>
                ) : !!eventData && eventData.length > 0 ? (
                  <>
                    {eventData.map((event, key) => {
                      return (
                        <tr key={key}>
                          <td id={`client-uptime-socket${key}`}>
                            {!!event.lastReportedTime ?
                              timeDiff(event.lastReportedTime) + " ago"
                              : "-"}
                            {!!event.lastReportedTime ?
                              <UncontrolledTooltip
                                target={`client-uptime-socket${key}`}
                              >
                                {dateTimeFormatter(
                                  event.lastReportedTime,
                                  "long",
                                  "medium"
                                ) +
                                  " (" +
                                  getTimeZone(false) +
                                  ") "}
                              </UncontrolledTooltip> : null}
                          </td>
                          <td>
                            {event.severity ? (
                              <LightBadge
                                color={
                                  event?.severity === "Major" ||
                                    event?.severity === "Critical"
                                    ? "danger"
                                    : event?.severity === "Minor"
                                      ? "success"
                                      : "secondary"
                                }
                              >
                                {event.severity}
                              </LightBadge>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{event.event ?? "-"}</td>
                          {/* <td>Client connected to AP-13 on network Shasta on 6GHZ channel with Vlan ID of 200 with RSSI reading of -67dbm</td> */}
                          <td>
                            <EventDetails event={event} />
                            {event.radius == null ? null :
                              event.radius.length > 1  ?
                                <RadiusDetails 
                                  event={event}
                                />
                              : event.radius.map((attributeData, index) => {
                                return (
                                  <span key={index}>
                                    <AttributeFormatter attributeData={attributeData}/>
                                  </span>
                                )
                              })
                            }
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr key={1}>
                      <td>5 Min(s) Ago</td>
                      <td>
                        <LightBadge color="success">Minor</LightBadge>
                      </td>
                      <td>Connect</td>
                      <td>
                        Client connected to AP-13 on network Shasta on 6GHZ
                        channel with Vlan ID of 200 with RSSI reading of -67dbm
                      </td>
                    </tr>
                    <tr key={2}>
                      <td>44 Min(s) Ago</td>
                      <td>
                        <LightBadge color="danger">Critical</LightBadge>
                      </td>
                      <td>Authentication Failure</td>
                      <td>
                        Username: employe11-John failed to authenticate, AAA
                        (192.168.1.1) rejected due to "incorrect password"
                      </td>
                    </tr>
                    <tr key={3}>
                      <td>5 Min(s) Ago</td>
                      <td>
                        <LightBadge color="success">Minor</LightBadge>
                      </td>
                      <td>Roaming</td>
                      <td>
                        Client moved from AP-1,5G radio, Channel 11, RSSI--98dB
                        to AP-2,5G radio, Channel 32, RSSI--61dB
                      </td>
                    </tr> */}
                  </>
                ) : (
                  <tr style={{ minHeight: "calc(50vh - 150px)" }}>
                    <td
                      className="text-center pt-5"
                      style={{ height: "26vh", backgroundColor: "white" }}
                      colSpan={10}
                    >
                      <h4>No Events</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default Overview;
