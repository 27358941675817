import { EMAIL_REGEX } from "./constants"
import { useEffect } from "react"
import axios from "axios";
import store from "../redux/store";
import { dateTimeFormatter } from "./Localization";

export const formatConnectivityData = (item) => {
  let connectivityData = null
  let type = item.infraItemId != null ? 'infra' : item.venueId != null ? 'venue' : item.orgId != null ? 'org' : ''
  if(!!item.connectivity) {
    let dataArray = item?.connectivity?.data?.map((it) => {return {
      connectivity: (it.percentageConnected??0), 
      datetime: dateTimeFormatter(it.label, 'medium',undefined,true),
      type: type,
    }}) ?? []
    if(dataArray.length !== 7) {
      dataArray = []
      const dataObj = {connectivity: 0, datetime: '-', type: type}
      while(dataArray.length < 7) {
        dataArray.push(dataObj)
      }
    }
    connectivityData = {
      avg: item.connectivity.percentageOverallConnected,
      data: dataArray
    }
  }
  return connectivityData
}

export const formattedAlarmCount = (count) => {
  try {
    const formattedValue = Number(count) > 1 ? `(${count})` : ''
    return formattedValue
  } catch(err) {
    return count
  }
}

export const commaSeparated = (number) => {
  try {
    return number.toLocaleString()
  } catch(err) {
    return number
  }
}

export const notiLink = (notiData) => {
  const {
    orgId,
    orgs,
    venues,
    infra,
    historical
  } = notiData;
  let link = `/organization/${orgId}/notification`
  const filter = {}
  if (orgs?.length > 0) {
    filter.orgs = orgs.map(it => {
      return { orgId: it }
    })
  }
  if (venues?.length > 0) {
    filter.venues = venues.map(v => {
      return { venueId: v, venueName: '' }
    });
  }
  if(infra != null) {
    filter.infras = [infra]
  }

  const applyFilter = Object.keys(filter).length > 0

  link = link + `?${historical ? 'historical=true':''}${historical && applyFilter ? '&' : ''}${applyFilter ? `filter=${JSON.stringify(filter)}` : ''}`

  return link
}

export const replaceParam = (setParams, key, value, options = {}) => {
  //don't use prev state to set params. 
  //It does not guarantee updated state when doing simultaneous updates.
  // https://github.com/remix-run/react-router/issues/9757
  const params = new URLSearchParams(window.location.search);
  setParams({ ...Object.fromEntries(params), [key]: value }, options);
};

export const scrollToTop = (ref) => {
  if (ref?.current != null) {
    ref.current.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const meterToFeet = (meters) => {
  if (typeof value === 'number' || !isNaN(Number(meters))) {
    meters = Number(meters);
    const feetPerMeter = 3.28084;
    return Math.floor(meters * feetPerMeter);
  }
  return '-'
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getRandomNumber = (max = 100) => {
  let rand = Math.random() * max;
  rand = Math.ceil(rand);
  return rand;
}


export const dateGapFinder = (date) => {
  const dateToday = new Date()
  const oldDate = new Date(date)

  const difference = dateToday.getTime() - oldDate.getTime()

  const diffInMins = Math.floor(difference / (1000 * 60))
  const diffInHours = Math.floor(difference / (1000 * 3600))
  const diffInDays = Math.floor(difference / (1000 * 3600 * 24))
  const diffInWeek = Math.floor(difference / (1000 * 3600 * 24 * 7))
  const diffInMonth = Math.floor(difference / (1000 * 3600 * 24 * 30))


  if (diffInMonth > 0)
    return `${diffInMonth} Month(s) ago`;
  if (diffInWeek > 0)
    return `${diffInWeek} Week(s) ago`;
  if (diffInDays > 0)
    return `${diffInDays} Day(s) ago`;
  if (diffInHours > 0)
    return `${diffInHours} Hr(s) ago`;
  if (diffInMins > 0)
    return `${diffInMins} Min(s) ago`;
  return `1 Min ago`;


}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validateEmail = (email) => {
  const emailRegex = new RegExp(EMAIL_REGEX);
  return emailRegex.test(email);
};
export const validateIdentityEmail = (email) => {
  const emailRegex = new RegExp(EMAIL_REGEX);
  return emailRegex.test(email);
};
export const makeStringFromArray = (dataArray) => {
  let newString = ""
  dataArray.map((item, index) => {
    newString += item
    if (index < (dataArray.length - 1)) {
      newString += ','
    }
  })
  return newString
};

export const formatDecimalPoints = (valueData, decimal = 1) => {
  const value = Number(valueData)
  if (Number.isInteger(value))
    return value
  else if (value % 1 != 0)
    return value.toFixed(decimal)
  return value

}
export const roundOffValue = (value) => {

  return Math.round(value)

}

export const formatNumber = (value) => {

  let val = Number(value) ?? 0;

  if (val >= 10 ** 3 && val < 10 ** 6) {
    val = (val / 1000).toFixed(0) + ' K'
  } else if (val >= 10 ** 6) {
    val = (val / 1000000).toFixed(0) + ' M'
  } else {
    val = val;
  }

  return val;
}

export function usePreventZoom(scrollCheck = true, keyboardCheck = true) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (
        keyboardCheck &&
        e.ctrlKey &&
        (e.keyCode == "61" ||
          e.keyCode == "107" ||
          e.keyCode == "173" ||
          e.keyCode == "109" ||
          e.keyCode == "187" ||
          e.keyCode == "189")
      ) {
        e.preventDefault();
      }
    };

    const handleWheel = (e) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, [scrollCheck, keyboardCheck]);
}

export const capitalizeWord = (word) => {
  if (!word || typeof word != 'string' || word.length == 0) return word;
  else {
    try {
      return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    }
    catch (err) {
      return word;
    }
  }
}
export const capitalizeSentence = (sentence) => {
  if (!sentence || typeof sentence != 'string' || sentence.length == 0) return sentence;
  try {
    const words = sentence.split(" ");
    let newWords = [];
    for (let i = 0; i < words.length; i++) {
      newWords.push(capitalizeWord(words[i]))
    }
    const newSentence = newWords.join(" ")
    return newSentence;
  }
  catch (err) {
    return sentence;
  }
}

export const LowerCase = (stringToConvert) => {
  let returnValue = stringToConvert
  try {
    return returnValue.toLowerCase()
  }
  catch {
    return stringToConvert
  }
}


export const downloadSpreadsheet = (apiURL, fileName, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiURL, {
        responseType: 'arraybuffer', headers: {
          authorization: "Bearer " + authToken,
          'session-id': store?.getState()?.activeOrg?.data?.orgId
        }
      })
      .then(response => {
        var blob = new Blob(
          [response.data],
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        );
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
      })
      .catch(error => {
        reject(error);
      })
  })
}

export const dateLeftFinder = (date) => {
  const dateToday = new Date()
  const futureDate = new Date(date)

  const difference = futureDate.getTime() - dateToday.getTime()

  const diffInHours = Math.floor(difference / (1000 * 3600))
  const diffInDays = Math.floor(difference / (1000 * 3600 * 24))
  const diffInWeek = Math.floor(difference / (1000 * 3600 * 24 * 7))
  const diffInMonth = Math.floor(difference / (1000 * 3600 * 24 * 30))

  if (diffInMonth > 0)
    return `${diffInMonth} Month(s)`;
  if (diffInWeek > 0)
    return `${diffInWeek} Week(s)`;
  if (diffInDays > 0)
    return `${diffInDays} Day(s)`;
  if (diffInHours > 0)
    return `${diffInHours} Hour(s)`;
  return `Less than Hour`;
}