/**
 * Organization Service
 * @file organization.service.js
 * @description API Endpoints related to organization management
 * @author Utkarsh Gupta
 * @since 17 Jul 2022
 */

import Service from "./Service";
import { B } from "../helpers";

/** Services related to organization management */
const organization = {
  GET: new Service((id) => B(`organization/${id}`), "GET"),
  GET_SELF: new Service(() => B("organization"), "GET"),
  GET_SWITCH: new Service((offset = 0, limit = 20, search = "") => B(`organization/switch?offset=${offset}&limit=${limit}&search=${search}`), "GET"),
  GET_IDENTITIES: new Service((id, offset = 0, limit = 10, search = "", order, orderBy, role = "", isBlocked = null) => B(`organization/${id}/identity?offset=${offset}&limit=${limit}&search=${search}&order=${order}&orderBy=${orderBy}&role=${role}` + (isBlocked == null ? "" : `&isBlocked=${isBlocked}`)), "GET"),
  GET_ROLES: new Service((id, offset = 0, limit = 1000) => B(`organization/${id}/roles?offset=${offset}&limit=${limit}`), "GET"),
  GET_BUSINESS_ROLES: new Service((id, orgTypeId) => B(`organization/${id}/roles?orgTypeId=${orgTypeId}`), "GET"),
  GET_CHILDREN: new Service((id, depth = 1, offset = 0, limit = 100000, search = "", searchSwitchOrg = true, sort = { order: "ASC", orderBy: "orgId" }, filter = {}) =>
    B(`organization/${id}/child?depth=${depth}&offset=${offset}&limit=${limit}&search=${search}&searchSwitchOrg=${searchSwitchOrg}&order=${sort.order}&orderBy=${sort.orderBy}`
      + ((!!filter?.status) ? `&status=${filter.status}` : ``)
      + ((filter?.orgType?.length) ? `&orgType=${filter.orgType}` : ``)), "GET"),
  GET_TYPES: new Service(() => B("orgType"), "GET"),
  GET_PARENT: new Service((orgId, getParentMSP = false) => B(`organization/${orgId}/parent?getParentMSP=${getParentMSP}`), "GET"),
  CREATE: new Service(() => B("organization"), "POST"),
  ORG_PATCH: new Service((id) => B(`organization/${id}`), "PATCH"),
  EDIT: new Service((id) => B(`organization/${id}`), "PUT"),
  SET_STATE: new Service((id) => B(`organization/${id}/state`), "PUT"),
  // OLS_ENABLE: new Service((id) => B(`organization/${id}/ols`), "POST"),
  // MARKETPLACE_ENABLE: new Service((id) => B(`organization/${id}/marketplace`), "POST"),
  FEATURES: new Service((id) => B(`organization/${id}/features`), "PUT"),
  DELETE: new Service(id => B(`organization/${id}`), "DELETE"),
  LOGO: new Service((id, method) => B(`organization/${id}/logo?purpose=${method}`), "GET"),
  LOGO_RESET: new Service((id) => B(`organization/${id}/logo`), "DELETE"),
  GET_SUBORG_COUNT: new Service((id) => B(`organization/${id}/suborg`), "GET"),
  //  GET_FWUPGRADE_SCHEDULE: new Service(() => `https://dev-orgservice.shastacloud.com/api/v1/organization/schedule/time`, "POST"),
  GET_FWUPGRADE_SCHEDULE: new Service(() => B(`firmware/schedule/venue/time`), "POST"),
  GET_ORGANIZATION_ACTIONS: new Service((orgId, offset, limit, level, order, searchMacAddress) => B(`organization/${orgId}/action?offset=${offset}&limit=${limit}&level=${level}&order=${order}&orderBy=timestamp` + (searchMacAddress ? `&macAddress=${searchMacAddress}` : '')), "GET"),
  GET_VENUE_IDENTITIES: new Service((venueId, limit = 20, offset = 0, search = '', viewType, filter) => B(`venues/${venueId}/identity?offset=${offset}&limit=${limit}&search=${search}&viewType=${viewType}` + ((filter.roles && filter.roles.length > 0) ? `&roleIds=${filter.roles}` : '') + ((filter && filter?.status == 'enabled') ? '&blockedStatus=false' : (filter && filter?.status == 'disabled') ? '&blockedStatus=true' : '')), "GET"),
  GET_VENUE_IDENTITY_COUNT: new Service((venueId, search) => B(`venues/${venueId}/identity/count` + ((!!search && search.length > 0) ? `?search=${search}` : '')), "GET"),
  GET_IDENTITY_PERMISSIONS: new Service((venueId, identityId) => B(`venues/${venueId}/identity/${identityId}`), "GET"),
  POST_IDENTITY_PERMISSIONS: new Service((venueId, identityId) => B(`venues/${venueId}/identity/${identityId}`), "POST"),

  GET_SWAGGER_LIST: new Service((id) => B(`shasta-api`), "GET"),
  GET_SWAGGER_DETAIL: new Service((id, slug) => B(`shasta-api/${slug}`), "GET"),
  VENUE_STREETVIEW: new Service((id, venueId, purpose = "get") => B(`organization/${id}/upload?filename=venue-street-view-${venueId}&extenstion=jpeg&folder=venue&purpose=${purpose}&withoutTimestamp=true&thumbnail=false`), "GET"),

  /**
   * Upload a file to S3, given a specfic folder
   * @example
   * params = [
   *   id: number,
   *   filename: string,
   *   extension: string,
   *   folder: "ticket" | "infrastructure" | "layout",
   *   purpose: "get" | "put",
   *   withoutTimestamp: boolean
   * ]
   * data = {}
   */
  UPLOAD_FILE: new Service((id, filename, extension, folder, purpose, withoutTimestamp = false) => B(`/organization/${id}/upload?filename=${filename}&extenstion=${extension}&folder=${folder}&purpose=${purpose}&withoutTimestamp=${withoutTimestamp}`)),

  INITIATE_MULTIPART_UPLOAD: new Service(() => B(`multipart/initiate`), "POST"),
  GET_PRESIGNED_URLS: new Service((fileName, folderName, uploadId, totalChunks) => B(`multipart/presignedurl?fileName=${fileName}&folderName=${folderName}&uploadId=${uploadId}&totalChunks=${totalChunks}`), "GET"),
  COMPLETE_UPLOAD: new Service(() => B(`multipart/complete`), "POST"),
  ABORT_UPLOAD: new Service(() => B(`multipart/abort`), "POST"),


  CREATE_API_KEY: new Service(() => B(`apikey`), "POST"),
  GET_API_KEY_LIST: new Service((orgId, offset, limit, sort) => B(`apikey?orgId=${orgId}&offset=${offset}&limit=${limit}` + (sort?.order ? `&order=${sort?.order}` : '') + (sort?.orderBy?.length > 0 ? `&orderBy=${sort?.orderBy}` : '')), "GET"),
  GET_API_KEY: new Service((orgId, apiKeyId) => B(`apikey/${apiKeyId}?orgId=${orgId}`), "GET"),
  UPDATE_API_KEY: new Service((apiKeyId) => B(`apikey/${apiKeyId}`), "PUT"),
  DELETE_API_KEY: new Service((orgId, apiKeyId) => B(`apikey/${apiKeyId}?orgId=${orgId}`), "DELETE"),

};

export default organization;
