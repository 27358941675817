/***
 *
 * Controller class for user.
 * @file Network.js
 * @description Network component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import createRequest, { services } from "../../../services";
import { breadcrumbActions } from "../../../redux/slices";
import Header from "../_builder/Header";
import { Header as TableHeader, SortingHeader, ColumnHeader } from "../../../components";
import { Alert, Spinner, Table, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalFooter, Badge, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import NetworkSelector from "../_builder/NetworkSelector"
import REDUX_WORKER from "../../../redux/workers";
import "./Network.scss";
import useAutoclear from "../../../hooks/useAutoclear";
import { ENTERPRISE, MANAGE, MPSK, PASSPHRASE, PUBLIC, VIEW } from "../../../utility/constants";
import { CatchedWebError } from "../../../configs";
// import VenueIcon from "../../../assets/images/icons/VenueIcon.svg";
import OpenNetwork from '../../../assets/images/icons/OpenNetwork.svg';
import PassphraseNetwork from '../../../assets/images/icons/PassphraseNetwork.svg';
import MspkNetwork from '../../../assets/images/icons/MpskNetwork.svg';
import FilterButton from "../../../components/FilterButton";
import LightBadge from "../../../components/LightBadge";
import { getColumns, setColumns } from "../../../utility/colSaver";
import SidePanel from "../../../components/SidePanel";
import ColSelector from "../../../components/ColSelector";
import LinkWrapper from "../../../components/LinkWrapper";

const Network = (props) => {
  const SCREEN_ID = "venue-network";
  const initMount = useRef(true);
  const initColumns = {
    Type: true,
    Bands: true,
    Infrastructure: true,
    Venues: true
  }

  const view = useSelector(state => state.identity.meta.view);
  const activeVenue = useSelector(store => store.activeVenue.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const orgPermissions = useSelector(store => store.rbac.permissions);
  const dispatch = useDispatch();
  const [cols, setCols] = useState({...initColumns, ...getColumns(SCREEN_ID, view)})
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const [error, setError] = useState(null);
  const [networkList, setNetworkList] = useState([]);
  const [networkListLoading, setNetworkListLoading] = useState(true);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [deactivateSuccess, setDeactivateSuccess] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [activating, setActivating] = useState(false);
  const [activateSuccess, setActivateSuccess] = useState(false);
  const [networkModal, setNetworkModal] = useState(false);
  const [sort, setSort] = useState({
    order: "ASC",
    orderBy: "networkName"
  })
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const public_icon = <span className="material-symbols-outlined cursor-pointer">public</span>

  const Icons = {
    [PASSPHRASE]: PassphraseNetwork,
    [PUBLIC]: OpenNetwork,
    [MPSK]: MspkNetwork,
    [ENTERPRISE]: MspkNetwork
  }

  const Color = {
    [PASSPHRASE]: "warning",
    [PUBLIC]: "success",
    [MPSK]: "primary",
    [ENTERPRISE]: "secondary"
  }

  const Text = {
    [PASSPHRASE]: "Passphrase",
    [PUBLIC]: "Public",
    [MPSK]: "MPSK AAA",
    [ENTERPRISE]: "Enterprise AAA"
  }

  const activateNet = (network) => {
    const { run } = createRequest(services.networks.ACTIVATE, [network.networkId, activeVenue.venueId]);

    if (network) {
      setActivating(true)
      run()
        .then((response) => {
          setNetworkList([...networkList, { ...response.data, "venueCount": network.venueCount + 1 }]);
          setActivateSuccess(true);
        })
        .catch((error) => {
          setError((new CatchedWebError(error)).message);
        })
        .finally(() => {
          setNetworkModal(false);
          setActivating(false)
        })
    }
  }

  const deactivateNet = (networkId) => {
    const { run } = createRequest(services.networks.DEACTIVATE, [networkId, activeVenue.venueId]);

    if (networkId) {
      setDeactivating(true)
      run()
        .then((response) => {
          setDeactivateModal(false);
          setNetworkList(networkList.filter(it => it.networkId !== networkId))
          setDeactivateSuccess(true);
        })
        .catch((error) => {
          setError((new CatchedWebError(error)).message);
        })
        .finally(() => {
          setDeactivating(false)
        })
    }
  }

  const fetchNetworks = (filterData = {}) => {

    const { run } = createRequest(services.networks.GET_BY_VENUE, [activeVenue.venueId, sort.order, sort.orderBy, filterData]);

    run()
      .then((response) => {
        setNetworkList(response.data);
      })
      .catch(error => {
        setError((new CatchedWebError(error)).message);
      })
      .finally(() => {
        setNetworkListLoading(false);
        setShowFilter(false);
      })
  }


  useEffect(() => {
    fetchNetworks(filterData)
  }, [activeVenue.venueId, sort]);

  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/network/`,
      text: "Network",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);


  useEffect(() => {
    if(initMount.current)
      initMount.current = false;
    else 
      setCols({...initColumns, ...getColumns(SCREEN_ID, view)})
  },[view])

  useAutoclear(deactivateSuccess, () => setDeactivateSuccess(false));
  useAutoclear(activateSuccess, () => setActivateSuccess(false));

  return (
    <div className="Network" data-testid="Network">
      <Header heading={activeVenue.venueName} />
      {/* {showFilter && <FilterSetter 
        showFilter={showFilter}
        disabled={!Object.keys(filterData).length} 
        elements={() => [
          <NetworkTypeFilter filter={filterData} setFilter={setFilterData} />,
          <BandFilter filter={filterData} setFilter={setFilterData}/>
        ]}
        handleApplyClick={() => {
          fetchNetworks(filterData);
          setFilterActive(true);
        }}
        handleClearAll={() => {
          fetchNetworks({});
          setFilterData({});
          setFilterActive(false);
        }}
        setShowFilter={setShowFilter} />} */}
      <Alert color="success" isOpen={deactivateSuccess} toggle={() => setDeactivateSuccess(false)}>
        <div className="alert-body">Network has been unassigned from this venue</div>
      </Alert>
      <Alert color="success" isOpen={activateSuccess} toggle={() => setActivateSuccess(false)}>
        <div className="alert-body">Network has been assigned to this venue</div>
      </Alert>
      {/* Autoclose */}
      <Alert color="danger" isOpen={error} toggle={() => setError(null)}>
        <div className="alert-body">{error}</div>
      </Alert>
      <div className="d-flex justify-content-end pb-1">
        <div className="d-none mr-1">
          <FilterButton active={filterActive} onClick={() => setShowFilter(true)} />
        </div>
        {(props?.venuePermissions?.network == null ? orgPermissions?.manageNetwork?.create : props?.venuePermissions?.network == MANAGE) ?
          <Button.Ripple color="primary" onClick={() => setNetworkModal(true)}>Assign Network</Button.Ripple>
          : null}
      </div>
      <SidePanel
        isOpen = {isColumnSelectorOpen}
        setIsOpen = {setIsColumnSelectorOpen}>
        <ColSelector cols={cols} setCols={setCols} setIsOpen={setIsColumnSelectorOpen} onApply={(newCol) => {
          setColumns(SCREEN_ID, newCol, view);
        }}/>
      </SidePanel>
      <div id="venue-network-list">
        <Table className="table-view">
          <thead>
            <tr>
              <th style={{ width: "20%" }}><SortingHeader header={"NAME"} attribute={"networkName"} setter={setSort} sort={sort} /></th>
              {cols.Type && <th style={{ width: "20%" }}><SortingHeader header={"TYPE"} attribute={"networkTypeId"} setter={setSort} sort={sort} /></th>}
              {cols.Bands && <th style={{ width: "15%" }}><TableHeader header={"BANDS"} /></th>}
              {cols.Infrastructure && <th style={{ width: "15%" }}><ColumnHeader right header={"Infrastructure"} attribute={"infraCount"} setter={setSort} sort={sort} /></th>}
              {cols.Venues && <th style={{ width: "15%" }}><ColumnHeader right header={"VENUES"} attribute={"venueCount"} setter={setSort} sort={sort} /></th>}
              {(props?.venuePermissions?.network == null ? orgPermissions?.manageNetwork?.update : props?.venuePermissions?.network > VIEW) ?
                <th className="text-right" style={{ width: "10%" }}>
                  <span 
                    className="material-symbols-outlined cursor-pointer" 
                    onClick={() => 
                      setIsColumnSelectorOpen(prevState => !prevState)}>
                      settings
                  </span>
                </th>
                : null}
            </tr>
          </thead>
          {networkListLoading ? <tbody>
            <tr><td colSpan={10} className="text-center p-5"><Spinner /></td></tr>
          </tbody> : networkList.length > 0 ? <tbody>
            {networkList.map(network => {
              return (
                <tr className="hoverable" key={network.networkId}>
                  <td>
                    <LinkWrapper
                      to={`/organization/${activeOrgId}/networks/${network.networkId}/?backTo=${activeVenue.venueId}`}
                    >{network.networkName}
                    </LinkWrapper>
                  </td>
                  {cols.Type && <td>
                    <span className="d-flex align-items-center">
                      <img src={Icons[network.networkTypeId]} style={{ width: '22px', margin: '8px' }} />
                      <span>{Text[network.networkTypeId]}</span>
                    </span>
                  </td>}
                  {cols.Bands && <td className="">
                    <div className="d-flex align-items-center">
                      {network?.configuration?.wifiBands.map((band) => {
                        return <LightBadge color="primary">{band}</LightBadge>
                      })}
                    </div>
                  </td>}
                  {cols.Infrastructure && <td className="text-right">
                    <div style={{paddingRight:"24px"}}>
                      {network.infraCount ?? "0"}
                    </div>
                  </td>}
                  {cols.Venues && <td className="text-right">
                    <span style={{paddingRight:"24px"}}>
                      {/* <img src={VenueIcon} alt="Venues Activated" /> &nbsp;&nbsp; */}
                      <span>{network.venueCount ?? "0"}</span>
                    </span>
                  </td>}
                  {(props?.venuePermissions?.network == null ? orgPermissions?.manageNetwork?.update : props?.venuePermissions?.network > VIEW) ?
                    <td className="text-right">
                      <UncontrolledButtonDropdown direction="left">
                        <DropdownToggle color='white' className="w-0 p-0">
                          <span className="material-symbols-outlined text-primary cursor-pointer">more_vert</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="w-100" onClick={
                            () => setDeactivateModal(network.networkId)
                          }>  
                            Unassign
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </td> : null}
                </tr>
              );
            })}
          </tbody> : <tbody><tr><td colSpan={10} className="p-3 text-center"><h4>No Network Found</h4></td></tr></tbody>}
        </Table>
      </div>
      <span>Showing {networkList.length} result(s)</span>

      <Modal centered isOpen={deactivateModal} toggle={() => setDeactivateModal(false)}>
        <ModalHeader toggle={() => setDeactivateModal(false)}></ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            {/* Autoclose */}
            <Alert color="danger" className="w-100" isOpen={error !== null} toggle={() => { setError(null); }}>
              <div className="alert-body">
                {error}
              </div>
            </Alert>
            <span className="material-symbols-outlined display-1 text-danger">warning</span>
            <strong className="py-1">Are you sure you want to unassign this Network?</strong>
            <span>This process cannot be reverted.</span>
            <div className="d-flex justify-content-center pt-2">
              <Button color="primary" disabled={deactivating} onClick={() => { setDeactivateModal(false); }}>Cancel</Button>
              <Button className="ml-2" color="outline-danger" disabled={deactivating} onClick={() => deactivateNet(deactivateModal)}>{deactivating? <Spinner size='sm'/> : "Unassign"}</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={networkModal} toggle={() => { setNetworkModal(false); setSelectedNetwork(null) }} >
        <ModalHeader toggle={() => { setNetworkModal(false); setSelectedNetwork(null) }}>Assign Network to Venue</ModalHeader>
        <ModalBody>
          <NetworkSelector selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} networkList={networkList} />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button.Ripple disabled={!selectedNetwork} color="primary"
              onClick={() => activateNet(selectedNetwork)}>
              {activating ? <Spinner size="sm" /> : "OK"}
            </Button.Ripple>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

Network.propTypes = {};

Network.defaultProps = {};

export default Network;
