import { BUSINESS_VIEW, DEPLOYMENT_VIEW, NETWORK_VIEW } from "../../utility/constants"

export const LIST_TYPES = {
  INFRA_LIST: 1,
  MSP_LIST: 2,
  VENUE_LIST: 3,
  ORG_LIST: 4,
  WAREHOUSE_LIST: 5,
  BUSINESS_DASH_LIST: 6
}

export const filterObj = {
  "infra-list": {
    [NETWORK_VIEW]: {
      'All': {
        name: "All",
        countName: 'all',
      },
      'Online': {
        name: "Online",
        countName: 'online',
      },
      'Issues': {
        name: "Issues",
        countName: 'issues',
      },
      'Offline': {
        name: "Offline",
        countName: 'offline',
      },
    },
    [BUSINESS_VIEW]: {
      'All': {
        name: "All",
        apiValue: 'All',
        countName: 'all',
      },
      'Online': {
        name: "Online",
        countName: 'online',
      },
      'Issues': {
        name: "Issues",
        countName: 'issues',
      },
      'Offline': {
        name: "Offline",
        countName: 'offline',
      },
    },
    [DEPLOYMENT_VIEW]: {
      'All': {
        name: "All",
        countName: 'all',
      },
      'Not Initialized': {
        name: "Not Initialized",
        countName: 'notInitialized',
      },
      'No Photos': {
        name: "No Photos",
        countName: 'noPhotos',
      },
      'Not Installed': {
        name: "Not Installed",
        countName: 'notInstalled',
      },
      'Not Validated': {
        name: "Not Validated",
        countName: 'notValidated',
      },
    },
  },
  "warehouse-list": {
    [NETWORK_VIEW]: {
      'All': {
        name: "All",
        countName: 'all',
      },
      // 'Online': {
      // name: "Online",
      // countName: 'online',
      // },
      // 'Issues': {
      // name: "Issues",
      // countName: 'issues',
      // },
      // 'Offline': {
      // name: "Offline",
      // countName: 'offline',
      // },
    },
    [BUSINESS_VIEW]: {
      'All': {
        name: "All",
        apiValue: 'All',
        countName: 'all',
      },
      // 'Online': {
      // name: "Online",
      // countName: 'online',
      // },
      // 'Issues': {
      // name: "Issues",
      // countName: 'issues',
      // },
      // 'Offline': {
      // name: "Offline",
      // countName: 'offline',
      // },
    },
    [DEPLOYMENT_VIEW]: {
      'All': {
        name: "All",
        countName: 'all',
      },
      // 'Not Initialized' : {
      // name: "Not Initialized",
      // countName: 'notInitialized',
      // },
      // 'No Photos' : {
      // name: "No Photos",
      // countName: 'noPhotos',
      // },
      // 'Not Installed' : {
      // name: "Not Installed",
      // countName: 'notInstalled',
      // },
      // 'Not Validated' : {
      // name: "Not Validated",
      // countName: 'notValidated',
      // },
    },
  },
  "venue-dashboard": {
    [NETWORK_VIEW]: {
      "All": {
        name: "All",
        countName: "all",
      },
      "Issues": {
        name: "Issues",
        countName: "issues",
      },
      "Offline": {
        name: "Offline",
        countName: "offline",
      },
    },

    [DEPLOYMENT_VIEW]: {
      "All": {
        name: "All",
        countName: "all",
      },
      "Pending Tasks": {
        name: "Pending Tasks",
        countName: "pending",
      },
      "Completed": {
        name: "Completed",
        countName: "completed",
      },
    },

    [BUSINESS_VIEW]: {
      "All": {
        name: "All",
        countName: "all",
      },
      "Design": {
        name: "Design",
        countName: "design",
      },
      "Deployment": {
        name: "Deployment",
        countName: "deployment",
      },
      "Live": {
        name: "Live",
        countName: "live",
      },
      "Disabled": {
        name: "Disabled",
        countName: "disabled",
      },
    },
  },
  "dashboard": {
    [NETWORK_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Critical": {
        name: "Critical",
        countName: "Critical",
      },
      "Major": {
        name: "Major",
        countName: "Major",
      },
    },

    [DEPLOYMENT_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Critical": {
        name: "Critical",
        countName: "Critical",
      },
      "Major": {
        name: "Major",
        countName: "Major",
      },
    },

    [BUSINESS_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Critical": {
        name: "Critical",
        countName: "Critical",
      },
      "Major": {
        name: "Major",
        countName: "Major",
      },
    },
  },
  "dashboard-business": {
    [NETWORK_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Critical": {
        name: "Critical",
        countName: "Critical",
      },
      "Major": {
        name: "Major",
        countName: "Major",
      },
    },

    [DEPLOYMENT_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Critical": {
        name: "Critical",
        countName: "Critical",
      },
      "Major": {
        name: "Major",
        countName: "Major",
      },
    },

    [BUSINESS_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Opportunity": {
        name: "Opportunity",
        countName: "Opportunity",
      },
      "Onboarding": {
        name: "Onboarding",
        countName: "Onboarding",
      },
      "Live": {
        name: "Live",
        countName: "Live",
      },
      "Disabled": {
        name: "Disabled",
        countName: "Disabled",
      }
    },
  },
  "org-dashboard": {
    [NETWORK_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Issues": {
        name: "Issues",
        countName: "Issues",
      },
      "Offline": {
        name: "Offline",
        countName: "Offline",
      },
    },

    [DEPLOYMENT_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Issues": {
        name: "Issues",
        countName: "Issues",
      },
      "Offline": {
        name: "Offline",
        countName: "Offline",
      },
    },

    [BUSINESS_VIEW]: {
      "All": {
        name: "All",
        countName: "All",
      },
      "Opportunity": {
        name: "Opportunity",
        countName: "Opportunity",
      },
      "Onboarding": {
        name: "Onboarding",
        countName: "Onboarding",
      },
      "Live": {
        name: "Live",
        countName: "Live",
      },
      "Disabled": {
        name: "Disabled",
        countName: "Disabled",
      }
    },
  }
}

export const columnObj = {
  "dashboard": {
    [BUSINESS_VIEW]: {
      "Alarms": true,
      "State": true,
      "Infrastructure": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Avg Uptime": true,
    },
    [DEPLOYMENT_VIEW]: {
      "Alarms": true,
      "State": true,
      "Infrastructure": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Avg Uptime": false
    },
    [NETWORK_VIEW]: {
      "Alarms": true,
      "State": true,
      "Infrastructure": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Avg Uptime": false
    }
  },
  "dashboard-business": {
    [BUSINESS_VIEW]: {
      "State": true,
      "Infrastructure": true,
      "Design Sites": true,
      "Deployment Sites": true,
      "Live Sites": true,
      "Proposed Amt": true,
      "Country": true,
      // "Pending Tasks": false,
      "Traffic": false,
      "Clients": false,
      "Connectivity": false,
      "Avg Uptime": true,
      "Operations": false
    },
    [DEPLOYMENT_VIEW]: {
      "Alarms": true,
      "State": true,
      // "Pending Tasks": false,
      "Infrastructure": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Avg Uptime": false
    },
    [NETWORK_VIEW]: {
      "Alarms": true,
      "State": true,
      // "Pending Tasks": false,
      "Infrastructure": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Avg Uptime": false
    }
  },
  "infra-list": {
    // Operations, Infrastructure, Traffic, Clients, Firmware, Location, State, In-Service, Cost, 
    // Proposed, VenueAddress, Proposal Count, 
    // Tasks,MAC Address, Operations, UpTime, Statistics, Room, Speedtest, Private IP, Public IP, 
    // AP Profile, Switch Profile, RF Profile, Radio 2G, Radio 5G, Radio 6G
    [NETWORK_VIEW]: {
      "Status": true,
      "Organization-Venue": true,
      "Private IP": true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": false,
      "Release": true,
      "Operations": true,
      "MAC Address": true,
      "Serial Number": false,
      "Asset Tag": false
    },
    [BUSINESS_VIEW]: {
      "Status": true,
      "Organization-Venue": true,
      "Private IP": true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": true,
      "Release": true,
      "Operations": true,
      "MAC Address": true,
      "Serial Number": false,
      "Asset Tag": false
    },
    [DEPLOYMENT_VIEW]: {
      "Status": true,
      "Organization-Venue": true,
      "Private IP": false,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "Tasks": true,
      "Room": true,
      "Location": true,
      "Traffic": false,
      "Clients": false,
      "Connectivity": false,
      "Uptime": false,
      "Release": false,
      "Operations": false,
      "MAC Address": true,
      "Serial Number": false,
      "Asset Tag": false
    },
  },
  "warehouse-list": {
    [NETWORK_VIEW]: {
      "Status": true,
      "Name": false,
      'Type': true,
      "MAC Address": true,
      "Private IP": true,
      "Release": true,
      "Serial Number": true,
      "Asset Tag": true
    },
    [BUSINESS_VIEW]: {
      "Status": true,
      "Name": false,
      'Type': true,
      "MAC Address": true,
      "Private IP": true,
      "Release": true,
      "Serial Number": true,
      "Asset Tag": true
    },
    [DEPLOYMENT_VIEW]: {
      "Status": true,
      "Name": false,
      'Type': true,
      "MAC Address": true,
      "Private IP": true,
      "Release": true,
      "Serial Number": true,
      "Asset Tag": true
    }
  },
  "venue-dashboard": {
    [NETWORK_VIEW]: {
      Operations: true,
      Infrastructure: true,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      "Avg Uptime": false,
      Release: true,
      State: true,
      Country: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": false,
    },

    [DEPLOYMENT_VIEW]: {
      Operations: false,
      Infrastructure: true,
      Traffic: false,
      Clients: false,
      Connectivity: false,
      "Avg Uptime": false,
      Release: false,
      State: true,
      Country: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": true,
    },

    [BUSINESS_VIEW]: {
      Operations: false,
      Infrastructure: false,
      Traffic: false,
      Clients: false,
      Connectivity: false,
      "Avg Uptime": true,
      Release: false,
      State: true,
      Country: true,
      "In-Service": true,
      Cost: true,
      "Pending Tasks": false,
    },
  },
  "org-dashboard": {
    [NETWORK_VIEW]: {
      Infrastructure: true,
      Venues: true,
      Operations: true,
      Country: true,
      "Pending Tasks": false,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      "Avg Uptime": false,
      State: false,
      "Live Sites": false,
      "Design Sites": false,
      "Deployment Sites": false,
      "Proposed Amt": false,
      "Proposed Subs": false,
    },
    [DEPLOYMENT_VIEW]: {
      Infrastructure: true,
      Venues: true,
      Operations: false,
      Country: false,
      "Pending Tasks": true,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      "Avg Uptime": false,
      State: true,
      "Live Sites": true,
      "Design Sites": false,
      "Deployment Sites": false,
      "Proposed Amt": false,
      "Proposed Subs": false,
    },
    [BUSINESS_VIEW]: {
      Infrastructure: true,
      Venues: false,
      Operations: false,
      Country: false,
      "Pending Tasks": false,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      "Avg Uptime": true,
      State: true,
      "Live Sites": true,
      "Design Sites": true,
      "Deployment Sites": true,
      "Proposed Amt": true,
      "Proposed Subs": false,
    }
  }
}

export const apiColumns = {
  'In Service': 'In-Service',
  'Venue Address': 'VenueAddress',
  'Uptime': 'UpTime',
  'Avg Uptime': 'UpTime',
  "Pending Tasks": "Tasks",
  "Release": "Firmware",
  "Proposed Subs": "Proposed Subscription"
}

export const allowedColumnsObj = {
  [LIST_TYPES.MSP_LIST]: [
    "Alarms",
    "State",
    "Operations",
    "Country",
    "Venues",
    "Tasks",
    "Infrastructure",
    "Design Sites",
    "Deployment Sites",
    "Live Sites",
    "Proposed Amt",
    "Proposed Subscription",
    "UpTime"
  ],
  [LIST_TYPES.BUSINESS_DASH_LIST]: [
    "State",
    "Operations",
    "Venues",
    "Proposed Amt",
    "Design Sites",
    "Deployment Sites",
    "Live Sites",
    "Tasks",
    "Infrastructure",
    "Proposed Subscription",
    "Country",
    "Traffic",
    "Clients",
    "UpTime"
  ],
  [LIST_TYPES.VENUE_LIST]: [
    "State",
    "Operations",
    "Country",
    "Infrastructure",
    "Tasks",
    "In-Service",
    "Cost",
    "Traffic",
    "Clients",
    "UpTime",
    "Firmware",
    //there in api definition but there are no columns for these
    // "Organization", 
    // "Proposed", 
    // "VenueAddress", 
    // "Proposal Count", 
    // "Tasks", 
    // "Alarms"
  ],
  [LIST_TYPES.INFRA_LIST]: [
    "Status",
    "Organization-Venue",
    "Private IP",
    "Public IP",
    "AP Profile",
    "Switch Profile",
    "RF Profile",
    "Radio 2G",
    "Radio 5G",
    "Radio 6G",
    "Tasks",
    "Room",
    "Location",
    "Traffic",
    "Clients",
    "UpTime",
    "Firmware",
    "Operations",
    "MAC Address",
    "Serial Number",
    "Asset Tag",
    // "Speedtest", 
  ],
}

export const mapColumns = [
  "Name",
  "Traffic",
  "Clients",
  "Firmware",
  "State",
  "Infrastructure",
  "Alarms",
  "VenueAddress",
];

export const isProposalAllowed = (rbacLevel, permission, activeOrg)=>rbacLevel < 3 &&  permission?.manageQuote?.create && activeOrg?.marketplaceEnable

  