/***
 *
 * Controller class for user.
 * @file ApMockUp.js
 * @description ApMockUp component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./APMockUp.scss";
// import { PORT_DATA } from "../../pages/Infrastructure/_builder/const";
import { ReactComponent as LAN } from "../../assets/images/icons/EthPort.svg";
import { ReactComponent as Bulb } from "../../assets/images/icons/lightBulbs.svg"
import { ReactComponent as BulbGrey } from "../../assets/images/icons/bulbGrey.svg"
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import createRequest, { services } from "../../services";
import { Tooltip } from "react-tooltip";
import Toggle from "../Toggle";
import { CatchedWebError } from "../../configs";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { make_custom_toast } from "../../helpers/toasts";
import { reactselectTheme } from "../../utility/constants";
import Select from "react-select";
import { includeProperty, SingleVal } from "../../pages/Infrastructure/_switches/DummySwitch";
import { ReactComponent as PoeIcon } from "../../assets/images/icons/poe.svg";
import { X } from "react-feather";


const ApMockUp = (props) => {
  const infraType = useSelector(store => store.infraTypes.data.find(it => it.infraTypeId == props.infraData.infraTypeId))
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
  const permissions = useSelector(store => store?.rbac?.permissions);
  const [inheritOpns, setInheritOpns] = useState([{ label: "Custom", value: "custom" }])
  const [status, setStatus] = useState([{ label: "Enable", value: true }, { label: "Disable", value: false }])
  const [portSpeed, setPortSpeed] = useState([{ value: "auto", label: "Auto" }, { value: "10", label: "10 Mbps" }, { value: "100", label: "100 Mbps" }, { value: "1000", label: "1 Gbps" },
  { value: "2500", label: "2.5 Gbps" }, { value: "5000", label: "5 Gbps" }, { value: "10000", label: "10 Gbps" }]);
  const [portDuplex, setPortDuplex] = useState([{ value: "auto", label: "Auto" }, { value: "half", label: "Half" }, { value: "full", label: "Full" }]);

  const [selectedPort, setSelectedPort] = useState(null);
  const [vlanError, setVLANError] = useState(null);
  const [loading, setLoading] = useState(false);
  const fillColour = (connected, connection, status) => {
    if (!connected)
      return "fill-grey ";
    else if (!status || connection?.status == "Blocked")
      return "fill-black "
    else if (connection?.status === "Connected")
      return "fill-green ";
    else if (connection?.status === "Warning")
      return "fill-yellow ";
    else
      return "fill-grey "
  }

  useEffect(() => {
    if (selectedPort) {
      setInheritOpns(prevState => [{label: "Inherit", value: "inherit", inherit: selectedPort.vlanProfileValue}, {label:"Custom", value:"custom"}]);
      setStatus(prevState => [{ label: "Inherit", value: "inherit", inherit: prevState.find(it => it.value == selectedPort.statusProfileValue)?.label }, { label: "Enable", value: true }, { label: "Disable", value: false }])
      setPortSpeed(prevState => [{ label: "Inherit", value: "inherit", inherit: prevState.find(it => it.value == selectedPort.speedProfileValue)?.label }, { value: "auto", label: "Auto" }, { value: "10", label: "10 Mbps" }, { value: "100", label: "100 Mbps" }, { value: "1000", label: "1 Gbps" },
      { value: "2500", label: "2.5 Gbps" }, { value: "5000", label: "5 Gbps" }, { value: "10000", label: "10 Gbps" }])
      setPortDuplex(prevState => [{ label: "Inherit", value: "inherit", inherit: prevState.find(it => it.value == selectedPort.duplexProfileValue)?.label }, { value: "auto", label: "Auto" }, { value: "half", label: "Half" }, { value: "full", label: "Full" }])
    }
  }, [selectedPort])

  const Port = ({ port, index, data, connected, connection, isMock, lldp }) => {
    return (
      <div>
        <div className="d-flex flex-column align-items-center mx-1">
          <div className="font-weight-bolder">{port.name}</div>
          <div className="border-big mb-50" style={{ minHeight: "30px" }}></div>
          <LAN className={"cursor-pointer " + fillColour(connected, connection, data?.status)}
            width={'45px'}
            height={'45px'}

            id={`Port${index}`}
            onClick={() => {
              setSelectedPort(props.profileData.inheritConfiguration.Ports.filter(it => it.name == port.name)[0] ?? {
                name: port.name,
                vlan: 0,
                status: false,
                duplex: "auto",
                speed: "auto"
              })
            }} />
        </div>
        {!isMock && <Tooltip variant="light" opacity={1} delayShow={500} border="2px solid #EAEAEA" clickable place="bottom" style={{ zIndex: 1 }} anchorSelect={`#Port${index}`}>
          <div style={{ width: "320px", padding: "10px" }}>
            <h5 style={{ marginBottom: "15px", fontWeight: "600" }}>{port.name}</h5>
            <Row>
              <Col md={6} xs={12}>
                <p className="d-flex justify-content-between">
                  <span className="firstRowAP">Enabled</span>
                  <span className="secondRowAP">{data?.status || "-"}</span>
                </p>
                <p className="d-flex justify-content-between">
                  <span className="firstRowAP">VLAN</span>
                  <span className="secondRowAP">{data?.vlan ?? "Unspecified"}</span>
                </p>
              </Col>
              <Col md={6} xs={12}>
                <p className="d-flex justify-content-between">
                  <span className="firstRowAP">Speed</span>
                  <span className="secondRowAP">{portSpeed.find(it => it.value == connection?.speed)?.label ?? "Unknown"}</span>
                </p>
                <p className="d-flex justify-content-between">
                  <span className="firstRowAP">Duplex</span>
                  <span className="secondRowAP">{portDuplex.find(it => it.value == connection?.duplex)?.label ?? "Unknown"}</span>
                </p>
              </Col>
              <Col xs={12}>
                <div className="firstRowAP">LLDP</div>
                <span className="secondRowAP">{lldp}</span>
              </Col>
            </Row>
          </div>
        </Tooltip>}</div>
    )
  }

  const UplinkPort = (props) => {
    const [uplinkTooltip, setUplinkTooltip] = useState(false);
    return (
      <div>
        <div className="d-flex flex-column align-items-center mx-1">
          <div className="font-weight-bolder">Uplink(PoE)</div>
          <div className="border-big mb-50" style={{ minHeight: "30px" }}></div>
          <div style={{ position: 'relative' }}>
            <span id="uplink">
              <LAN height={'45px'} width={'45px'} onMouseOver={() => setUplinkTooltip(true)} onMouseLeave={() => setUplinkTooltip(false)} className={"cursor-pointer " + fillColour(props?.infraData?.connected, props?.connectionData?.uplinkPort?.WAN1, true)} />
            </span>
            {
              props?.infraData?.poe?.poe > 0 &&
              <div className="uplink-poe-icon"><PoeIcon /></div>
            }
          </div>
          <Tooltip variant="light" opacity={1} delayShow={500} border="2px solid #EAEAEA" place="bottom" style={{ zIndex: 1 }} anchorSelect={`#uplink`} clickable>
            <div style={{ width: "300px", padding: "10px" }}>
              <h5 style={{ marginBottom: "15px", fontWeight: "600" }}>Uplink(PoE)</h5>
              <Row>
                <Col xs={12}>
                  <p className="d-flex justify-content-between">
                    <span className="firstRowAP">Speed</span>
                    <strong className="secondRowAP">{portSpeed.find(it => it.value == props?.connectionData?.uplinkPort?.WAN1?.speed)?.label ?? "Auto"}</strong>
                  </p>
                  <p className="d-flex justify-content-between">
                    <span className="firstRowAP">Duplex</span>
                    <strong className="secondRowAP">{portDuplex.find(it => it.value == props?.connectionData?.uplinkPort?.WAN1?.duplex)?.label ?? "Auto"}</strong>
                  </p>
                </Col>
                {
                  props?.infraData?.poe?.poe > 0 &&
                  <Col xs={12}>
                    <p className="d-flex justify-content-between">
                      <span className="firstRowAP">Power Draw:</span>
                      <span className="secondRowAP">{((props?.infraData?.poe?.poe ?? 0) / 1000).toFixed(2)}W</span>
                    </p>
                  </Col>
                }
                {
                  props?.infraData?.poe?.poe > 0 &&
                  <Col xs={12}>
                    <p className="d-flex justify-content-between">
                      <span className="firstRowAP">Reported by:</span>
                      <span className="secondRowAP">{props?.infraData?.poe?.switchName ?? '-'}</span>
                    </p>
                  </Col>
                }
                <Col xs={12}>
                  <div className="firstRowAP">LLDP</div>
                  <span className="secondRowAP"><ul className="pl-1">{props?.lldp?.map(des => <li>{des}</li>)}</ul></span>
                </Col>
              </Row>
            </div>
          </Tooltip>
        </div>
      </div>
    )
  }

  const updateProfile = (leds = props.profileData.inheritConfiguration.leds) => {
    if (props.infraData) {
      const newPorts = props.profileData.inheritConfiguration.Ports.map(it => includeProperty(it, ["name", "status", "duplex", "speed", "vlan"]))
      const { run } = createRequest(services.networks.UPDATE_PROFILE_INFRA, [props.infraData.infraItemId, activeOrgId], {
        orgId: activeOrgId,
        configuration: {
          leds: leds,
          Ports: newPorts,
        },
      });

      run()
        .then(() => {
          make_custom_toast("success", "AP Information Updated", "Changes will take affect in a few minutes.");

        })
        .catch((error) => {
          const x = new CatchedWebError(error);
          make_custom_toast("error", "AP Information", x.message);
        })
        .finally(() => {
          setLoading(false);
          setSelectedPort(null);
        })
    }

  }

  const onSubmit = () => {
    setLoading(true);
    props.setProfileData((prevState) => {
      let newPorts = prevState.inheritConfiguration.Ports;
      let index = newPorts.map(it => it.name).indexOf(selectedPort.name)
      newPorts[index >= 0 ? index : newPorts.length] = selectedPort
      return {
        ...prevState,
        inheritConfiguration: {
          ...prevState.inheritConfiguration,
          Ports: newPorts
        }
      }
    })
    setTimeout(updateProfile, 800)
  }
  return (
    <div className="ApMockUp w-100" data-testid="ApMockUp">
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="d-flex justify-content-center">
          {props.profileData.inheritConfiguration.Ports.map((port, index) => {
            return <Port isMock={props.isMock} port={port} key={index} index={index} data={props.profileData.inheritConfiguration.Ports.filter(it => it.name == port.name)[0]} connected={props.infraData.connected} connection={(props.connectionData?.downlinkPort[port.name])} lldp={props?.connectionData?.lldpInformation?.describe ?? ""} />
          })}
          <UplinkPort connectionData={props?.connectionData} infraData={props.infraData} lldp={props?.connectionData?.lldpWanInfo?.map(it => it.description ?? "")} />
        </div>
        {/* <div className="d-flex align-items-center font-weight-bolder mr-3">
          {permissions?.manageInfra?.update &&
          <Toggle value={props.profileData.inheritConfiguration.leds} onClick={() => {
            props.setProfileData((prevState) => {
              return{
                ...prevState, leds:!prevState.leds
              }
            })
            updateProfile(!props.profileData.inheritConfiguration.leds);
          }} displayText={false} color="#289A71" disabled={!props.infraData.connected}/>}
          {(props.infraData.connected && props.profileData.inheritConfiguration.leds) ? <Bulb width={42} height={42} /> : <BulbGrey width={42} height={42}/>} 
          LED
        </div> */}
      </div>
      <div className="d-flex justify-content-center m-0 mt-1">
        <span className="switch-label">
          <div className="switch-colour-label" style={{ backgroundColor: "#1FC78F" }}></div>
          Connected
        </span>
        <span className="switch-label">
          <div className="switch-colour-label" style={{ backgroundColor: "#808080" }}></div>
          Disconnected
        </span>
        <span className="switch-label">
          <div className="switch-colour-label" style={{ backgroundColor: "#000" }}></div>
          Disabled
        </span>
        <span className="switch-label">
          <div className="switch-colour-label" style={{ backgroundColor: "#FFBE40" }}></div>
          Warning
        </span>
      </div>

      {selectedPort && <Modal centered isOpen={!!selectedPort} toggle={() => !loading && setSelectedPort(null)}>
        <ModalHeader className="bg-white" toggle={() => !loading && setSelectedPort(null)}></ModalHeader>
        <ModalBody>
          <h4>{selectedPort?.name}</h4>
          <Row className="mt-2">
            <Col sm={12} md={6}>
              <Label className="port-label">Status</Label>
              <Select
                styles={reactselectTheme}
                value={status?.find(opn => opn.value == selectedPort.status)}
                components={{ SingleValue: SingleVal }}
                options={status}
                onChange={(e) => {
                  setSelectedPort((prevState) => {
                    return {
                      ...prevState,
                      status: e.value
                    }
                  })
                }}
              />
            </Col>
            <Col sm={12} md={6}>
              <Label className="port-label">Speed</Label>
              <Select
                styles={reactselectTheme}
                value={portSpeed?.find(opn => opn.value == selectedPort.speed) ?? { value: "auto", label: "Auto" }}
                components={{ SingleValue: SingleVal }}
                options={portSpeed.filter(opn => opn.value === "inherit" || opn.value === "auto" || (opn.value <= (infraType?.maxSpeed ?? 1000)))}
                onChange={(e) => {
                  setSelectedPort((prevState) => {
                    return {
                      ...prevState,
                      speed: isNaN(Number(e.value)) ? e.value : Number(e.value)
                    }
                  })
                }}
              />
            </Col>
            <Col sm={12} md={6} className="mt-1">
              <Label className="port-label">VLAN</Label>
              {
                selectedPort?.vlan == "inherit"
                ? <Select
                    value={inheritOpns.find(it => it.value == (selectedPort?.vlan == "inherit" ? "inherit" : "custom"))}
                    options={inheritOpns}
                    styles={reactselectTheme}
                    components={{ SingleValue: SingleVal }}
                    onChange={(e) => {
                      if(e.value == "inherit"){
                        setSelectedPort((prevState) => {
                          return{
                            ...prevState,
                            vlan:e.value
                          }
                        })
                      }
                      else {
                        setSelectedPort((prevState) => {
                          return{
                            ...prevState,
                            vlan: Number(selectedPort.vlanProfileValue)
                          }
                        })
                      }
                    }}
                  />
                : <div className="d-flex align-items-center">
                    <Input type="number" invalid={!!vlanError} style={{marginTop:"0.25rem"}} defaultValue={selectedPort?.vlan} onChange={(e) => {
                      if(e.target.value && (e.target.value<0 || e.target.value>4050))
                        setVLANError("Enter Value [0-4050]")
                      else
                        setVLANError(null);
                      setSelectedPort((prevState) => {
                        return{
                          ...prevState,
                          vlan:Number(e.target.value)
                        }
                      })
                    }}/>
                    <X className="cursor-pointer ml-50" onClick={() => {
                      setSelectedPort((prevState) => {
                        return{
                          ...prevState,
                          vlan:"inherit"
                        }
                      })
                    }} />
                  </div>
                }
              {/*  */}
              <div className="text-danger">{vlanError}</div>
            </Col>
            <Col sm={12} md={6} className="mt-1">
              <Label className="port-label">Duplex</Label>
              <Select
                styles={reactselectTheme}
                components={{ SingleValue: SingleVal }}
                value={portDuplex?.find(opn => opn.value == selectedPort.duplex) ?? { value: "auto", label: "Auto" }}
                options={portDuplex}
                onChange={(e) => {
                  setSelectedPort((prevState) => {
                    return {
                      ...prevState,
                      duplex: e.value
                    }
                  })
                }}
              />
            </Col>
          </Row>
          <div className="text-right mt-2">
            <Button.Ripple color="primary" outline onClick={() => setSelectedPort(null)}>Discard</Button.Ripple>
            <Button.Ripple className="ml-1" color="primary" onClick={onSubmit} disabled={loading || !!vlanError}>{loading ? <Spinner size="sm" /> : "Submit"}</Button.Ripple>
          </div>
        </ModalBody>
      </Modal>}
    </div>
  );
};

ApMockUp.propTypes = {};

ApMockUp.defaultProps = {};

export default ApMockUp;
