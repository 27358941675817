/***
 *
 * Controller class for user.
 * @file MoveInfraModal.js
 * @description MoveInfraModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useCallback, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./MoveInfraModal.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import createRequest, { services } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { identityActions } from "../../redux/slices";
import { make_custom_toast } from "../../helpers/toasts";
import InfraInfo from "../InfraInfo";
import { CatchedWebError } from "../../configs";
import SiteSelector from "../SiteSelector";
import { SHASTA } from "../../utility/constants";
import { createErrorContext } from "../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../pages/Tickets/TicketConstants";
import { useNavigate } from "react-router-dom";

const MoveInfraModal = (props) => {
  const { infraIds, selectedVenue, setSelectedVenue, isOpen, setIsOpen, onSuccess, onCancel, orgId ,
    isWarehouse
  } = props;
  const activeOrg = useSelector(store => store.activeOrg.data)
  const [submitting, setSubmitting] = useState(false)
  const [mspLoading, setMspLoading] = useState(true)
  const [listOrgId, setListOrgId] = useState(null);
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onMoveClick = () => {
    if(submitting) return;
    const UUID = uuidv4();
    dispatch(identityActions.setMoveUUID(UUID));
    const {context , run} = createRequest(services.infra.MOVE, [selectedVenue?.venueId, orgId, UUID], Array.from(infraIds ?? {}))
    setSubmitting(true)
    run()
    .then(response => {
      dispatch(identityActions.setMoveUUID(UUID));
      make_custom_toast(
        'success',
        'Move Infrastructure',
        'Moving Infrastructure'
      )
      onSuccess()
      setIsOpen(false)
    })
    .catch(err => {
      let apiContext = createErrorContext(context, 'Unable to Move Infrastructure', TICKET_CATEGORY.NETWORK, err)
      make_custom_toast('error','Unable to Move Infrastructure', (new CatchedWebError(err)).message, true, 'Create Ticket', () => {
        navigate(
          `/organization/${activeOrg.orgId}/support/createticket`,
          {
            state: {
              ticketContext: apiContext,
            },
          }
        );
      })
    })
    .finally(() => {
      setSubmitting(false);
    })
  }

  const getMsp = useCallback(() => {
    const {run} = createRequest(services.organization.GET_PARENT, [orgId, true])
    setMspLoading(true)
    run()
    .then(response => {
      setListOrgId(response.data.orgId);
    })
    .catch(err => {
      make_custom_toast(
        'error', 
        'Venue List', 
        new CatchedWebError(err).message
      )
    })
    .finally(() => {
      setMspLoading(false);
    })
  }, [orgId])
  
  useEffect(() => {
    if(!isOpen || orgId == null)
      return;
    if(activeOrg.orgTypeId == SHASTA)
      getMsp()
    else
      setListOrgId(activeOrg.orgId)
  }, [orgId, activeOrg.orgTypeId, getMsp, activeOrg.orgId, isOpen])
  

  return (
    <Modal 
      isOpen={isOpen} 
      centered 
      className="MoveInfraModal" 
      data-testid="MoveInfraModal" 
      toggle={() => setIsOpen(prevState => submitting ? prevState : !prevState)}
    >
      <ModalHeader 
        className="bg-white move-infra-modal-header"
        toggle={() => setIsOpen(prevState => submitting ? prevState : !prevState)}
      >
        Move to Venue
        {infraIds?.size > 0 ? 
        ` (${infraIds?.size} Infrastructure Selected)`
        :''}
      </ModalHeader>
      <ModalBody className="overflow-auto">
        <div className="mb-50">Select Venue</div>
        {listOrgId == null ? <></> : 
        <SiteSelector
          propOrgId={listOrgId}
          selectedVenue={selectedVenue}
          setSelectedVenue={setSelectedVenue}
          noHeading
          columns={{
            Country: true, 
            Infrastructure: true
          }}
          noAddressText
          anyVenue
        />}
      </ModalBody>
      {isOpen ? 
      <ModalFooter className={`${selectedVenue == null? 'invisible' : ''}`}>
        {isWarehouse ?
        <div className="w-100 font-weight--500">
          {`This will move ${infraIds?.size} Infrastructure from the Virtual warehouse to the selected venue`}
        </div> :
        <InfraInfo 
          infraIds={infraIds}
          orgId={orgId}
        />
        }
        <div className="d-flex justify-content-end buttons-div mt-1">
          <Button.Ripple color='primary' outline
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </Button.Ripple>
          <Button.Ripple 
            color='primary'
            onClick={onMoveClick}
            disabled={submitting}
          >
            {
              submitting
              ? <Spinner />
              : "Move"
            }
          </Button.Ripple>
        </div>
      </ModalFooter>
      :null}
      {/* {selectedVenue == null ? null :
      } */}
    </Modal>
  );
};

MoveInfraModal.propTypes = {};

MoveInfraModal.defaultProps = {};

export default MoveInfraModal;
