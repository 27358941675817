import React, { useState } from 'react';
import { Modal, ModalBody, Button, Form, ModalHeader, Spinner } from 'reactstrap';
import { make_custom_toast } from '../../../../helpers/toasts';
import { CatchedWebError } from '../../../../configs';
import createRequest, { services } from '../../../../services';

interface DeleteModalProps {
    quoteId: number
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onSuccess: () => void;
}

const DeleteQuoteModal: React.FC<DeleteModalProps> = (props: DeleteModalProps) => {
    const { quoteId, visible, setVisible, onSuccess } = props;
    const [deleting, setDeleting] = useState(false)


    const deleteQuote = (quoteId: number) => {
        if (!quoteId)
            return
        setDeleting(true)
        const { run } = createRequest(services.marketplace.DELETE_QUOTE, [quoteId])
        run()
            .then(res => {
                onSuccess()
                setVisible(false)
            })
            .catch(err => {
                setVisible(false)
                make_custom_toast("error", "Marketplace", (new CatchedWebError(err)).message)
            })
    }

    return (
        <Modal centered isOpen={visible}>
            <ModalHeader
                className='bg-white p-0 m-0'
                toggle={() => {
                    if (!deleting)
                        setVisible(false);
                }} />
            <ModalBody>
                <div className="d-flex flex-column align-items-center">
                    <span className="material-symbols-outlined display-1 text-danger">warning</span>
                    <strong className="py-1">Are you sure you want to delete this Proposal?</strong>
                    <span>This process cannot be reverted.</span>
                    <div className='d-flex align-items-center mt-1'>
                        <Button disabled={deleting} color='primary' onClick={() => setVisible(false)} className='add-small-button mr-1'>Cancel</Button>
                        <Button disabled={deleting} outline color='danger' onClick={() => deleteQuote(quoteId)} className='add-small-button'>{deleting ? <Spinner size={'sm'} /> : "Delete"}</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DeleteQuoteModal;
