/***
 *
 * Controller class for user.
 * @file AlertVenueTable.js
 * @description AlertVenueTable component
 * @author Rajinder Singh
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./AlertVenueTable.scss";
import FilterButton from "../FilterButton";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, Popover, PopoverBody, PopoverHeader, Spinner, Table, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { ReactComponent as Expand } from "../../assets/images/icons/ExpandIcon.svg";
import ColumnHeader from "../ColumnHeader";
import Header from "../Header";
import { useSelector } from "react-redux";
import blazer from "../../services/blazer.service";
import { useEffect } from "react";
import createRequest, { services } from "../../services";
import { dateTimeFormatter, getTimeZone } from "../../utility/Localization";
import { ALERT_LOW, ALERT_MED, ALERT_HIG, SCROLL_MORE_TEXT } from "../../utility/constants";
import { make_toast } from "../../helpers";
import { CatchedWebError } from "../../configs";
import { timeDiff } from "../../pages/Infrastructure/SwitchOverview";
import APSVG from "../../pages/Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../pages/Infrastructure/InfraList/SwitchSVG";
import { applyFilter, clearFilter } from "../../pages/Filters/filterFunctions";
import RefreshButton from "../../pages/Dashboard/Graphs/RefreshButton";
import Skeleton from "../../pages/Dashboard/Graphs/Skeleton";
import FilterSetter from "../FilterSetter";
import { AggregateVenueFilter, CumulativeInfrasFilter, NotificationImpactFilter, NotificationTypeFilter } from "../../pages/Filters/filters";
import lodash from "lodash-es";
import { ExpandViewIcon, VenueBlueIcon } from "../../assets/images/icons/Icons";
import { ReactComponent as Venue } from "../../assets/images/icons/Venue.svg";
import { ReactComponent as TicketIcon } from "../../assets/images/icons/ticket-icon.svg";
// import {ReactComponent as NotificationIcon} from "../../assets/images/icons/notification.svg";
import notificationSvg from "../../assets/images/icons/notification.svg";
import { EmergencySirenIcon } from "../../assets/images/icons/Icons";
import { ReactComponent as WarningIcon } from "../../assets/images/icons/WarningWhiteExclamation.svg";
import { ReactComponent as OrangeWarningIcon } from "../../assets/images/icons/OrangeWarning.svg";
import { summaryPopoverStyle } from "../../pages/Organization/Notification";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupButton from "../GroupButton";
import SearchIconAddon from "../SearchIconAddon";
import { Tooltip } from "react-tooltip";
import AlarmDesc from "../AlarmDesc";
import SidePanel from "../SidePanel";
import ColSelector from "../ColSelector";
import { cmpCol, getColumns, setColumns } from "../../utility/colSaver";
import LinkWrapper from "../LinkWrapper";

export const alertColor = {
  [ALERT_LOW]: "success",
  [ALERT_MED]: "warning",
  [ALERT_HIG]: "danger"
}
export const alertText = {
  [ALERT_LOW]: "Minor",
  [ALERT_MED]: "Major",
  [ALERT_HIG]: "Critical"
}

const API_LIMIT = 20

const affectsLink = (noti) => {
  let link = null
  if (noti.org_id != null) {
    if (
      noti?.affected_entities != null &&
      noti?.affected_entities[0]?.venue_id != null
    )
      link = `/organization/${noti.org_id}/venues/${noti?.affected_entities[0]?.venue_id}`
    else if (
      noti?.affected_entities != null &&
      noti?.affected_entities[0]?.infra_id != null &&
      noti?.is_active
    )
      link = `/organization/${noti.org_id}/infra/${noti?.affected_entities[0]?.infra_id}`
  }
  return link;
}

const AlertVenueTable = (props) => {
  const SCREEN_ID = "notifi-venue-alert";

  const initColumns = {
    Type: true,
    Affects: true,
    Summary: true,
    Venues: true,
    Created: true,
    Severity: true,
  }

  const activeVenueId = props.venueId
  const filterInitial = {
    alertImpact: [],
    alertType: [],
    // venues: [{ venueId: activeVenueId }],
    infras: []
  }
  const view = useSelector(store => store.identity.meta.view);

  const [cols, setCols] = useState(cmpCol(initColumns, getColumns(SCREEN_ID, view) ?? {}));

  const permissions = useSelector(store => store?.rbac?.permissions);
  const rbac = useSelector(store => store?.rbac?.data);
  const [hasMore, setHasMore] = useState(true)
  const [filterActive, setFilterActive] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState(filterInitial);
  const [historical, setHistorical] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [popover, setPopover] = useState(null);
  const [ackModal, setAckModal] = useState(null);
  const [ackLoading, setAckLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [count, setCount] = useState({});
  const [severity, setSeverity] = useState('all')
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: "created_at"
  });
  const [summaryPopover, setSummaryPopover] = useState(null);

  let timer
  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }


  const getNotification = (filter = filterData) => {
    const { run } = createRequest(services.telemetry.GET_VENUE_ALERTS, [activeVenueId, 0, API_LIMIT, search, historical ? "acknowledge" : "open", severity, sort, filter, true, true])
    setLoading(true);
    setShowFilter(false);
    run().then((response) => {
      if (response?.data?.response?.length >= API_LIMIT) {
        setHasMore(true)
      }
      else {
        setHasMore(false)
      }

      setNotification(response.data.response);
      setCount({
        historical: response?.data?.historical,
        actionable: response?.data?.actionable,
        all: response?.data?.all,
        critical: response?.data?.critical,
        major: response?.data?.major,
        minor: response?.data?.minor,
      })
      setLoading(false)
    })
      .finally(() => setLoading(false));
  }

  const ackNotification = (id) => {
    const { run } = createRequest(blazer.ACK_ALERT, [], {
      id: [id]
    })
    setAckLoading(true);
    run()
      .then(() => {
        setNotification(prevState => prevState.filter(it => it.id != id));
        getNotification();
      })
      .catch((err) => {
        make_toast("error", (new CatchedWebError(err)).message);
      })
      .finally(() => {
        setAckLoading(false);
        setAckModal(null);
      })
  }

  useEffect(() => {
    getNotification()
  }, [search, historical, sort, severity])

  const clearFilterWrapper = (property) => {
    clearFilter({
      setFilterData: setFilterData,
      property: property,
      filterInitial: filterInitial,
      setFilterActive: setFilterActive,
      onApply: getNotification
    });
  }

  const applyFilterWrapper = (filter = filterData) => {
    applyFilter({
      onApply: getNotification,
      filterData: filter,
      filterInitial: filterInitial,
      setFilterActive: setFilterActive
    })
  }

  return (
    <div className="AlertVenueTable p-1 bg-white mt-1" >

      <SidePanel
        isOpen={isColumnSelectorOpen}
        setIsOpen={setIsColumnSelectorOpen}>
        <ColSelector cols={cols} setCols={setCols} setIsOpen={setIsColumnSelectorOpen} onApply={(newCol) => {
          setColumns(SCREEN_ID, newCol, view);
        }} />
      </SidePanel>
      <FilterSetter setShowFilter={setShowFilter}
        showFilter={showFilter}
        filter={filterData}
        setFilter={setFilterData}
        elements={() => {
          return [
            <NotificationTypeFilter filter={filterData} setFilter={setFilterData} />,
            // <NotificationImpactFilter filter={filterData} setFilter={setFilterData} />,
            // <AggregateVenueFilter filter={filterData} setFilter={setFilterData} />,
            <CumulativeInfrasFilter filter={filterData} setFilter={setFilterData} />
          ]
        }}
        disabled={!Object.keys(filterData)?.length}
        handleApplyClick={() => {
          setSeverity('all')
          if (lodash.isEqual(filterData, filterInitial))
            setFilterActive(false)
          else
            setFilterActive(true);
          getNotification();
        }}
        handleClearAll={() => {
          setFilterActive(false);
          setFilterData(filterInitial);
          getNotification(filterInitial);
        }}
      />


      <div className="d-flex justify-content-between heading">
        <div className="d-flex align-items-center" style={{ width: "90%" }} >
          <div className="search-box mr-1" style={{ width: "310px" }}>

            <InputGroup className="input-group-merge">
              <Input type="text" placeholder="Search" style={{ height: '2.4rem' }} onChange={
                (e) => {
                  debounce(() => setSearch(e.target.value));
                }
              } />
              <SearchIconAddon />
            </InputGroup>
          </div>

          <GroupButton className="mr-1">
            <div className={"grp-btn-custom " + (!historical ? "active" : "")} onClick={() => { if (!loading) setHistorical(false) }}>Actionable ({count?.actionable ?? 0})</div>
            <div className={"grp-btn-custom " + (historical ? "active" : "")} onClick={() => { if (!loading) setHistorical(true) }}>Historical ({count?.historical ?? 0})</div>
          </GroupButton>

          <GroupButton className="mr-1">
            <div className={"grp-btn-custom " + (severity == 'all' ? "active" : "")} onClick={() => { if (!loading) setSeverity('all') }}>All ({count?.all ?? 0})</div>
            <div className={"grp-btn-custom " + (severity == 'critical' ? "active" : "")} onClick={() => { if (!loading) setSeverity('critical') }}>Critical ({count?.critical ?? 0})</div>
            <div className={"grp-btn-custom " + (severity == 'major' ? "active" : "")} onClick={() => { if (!loading) setSeverity('major') }}>Major ({count?.major ?? 0})</div>
            <div className={"grp-btn-custom " + (severity == 'minor' ? "active" : "")} onClick={() => { if (!loading) setSeverity('minor') }}>Minor ({count?.minor ?? 0})</div>
          </GroupButton>

          <FilterButton size={18} active={filterActive} onClick={() => setShowFilter(true)} style={{ padding: '0.45%' }} />
        </div>
        <RefreshButton clickState={false} clickHandler={() => { getNotification() }} />
      </div>
      <div className="rounded" style={{ minHeight: "340px" }}>
        <Modal centered isOpen={ackModal} toggle={() => { if (!ackLoading) setAckModal(null) }}>
          <ModalBody className="text-center p-1">
            <div className="text-center">
              <span className="material-symbols-outlined text-warning display-2">error</span>
            </div>
            <h3 className="font-weight-bolder mt-1 mb-2">Are you sure?</h3>
            Acknowledging this notification, will it move it to historical view.
            <div className="d-flex justify-content-center mt-2">
              <Button color="danger" outline onClick={() => { if (!ackLoading) setAckModal(null) }}>Cancel</Button>
              <Button color="primary" disabled={ackLoading} className="ml-1" onClick={() => { ackNotification(ackModal) }}>{ackLoading ? <Spinner color="white" size="sm" /> : "Yes, Acknowledge"}</Button>
            </div>
          </ModalBody>
        </Modal>


        <InfiniteScroll
          className="mt-1"
          dataLength={notification?.length}
          next={() => {
            const { run } = createRequest(services.telemetry.GET_VENUE_ALERTS, [activeVenueId, notification?.length, API_LIMIT, search, historical ? "acknowledge" : "open", severity, sort, filterData, true, true])
            if (!loading)
              run().then((response) => {
                if (response?.data?.response?.length >= API_LIMIT) {
                  setHasMore(true)
                }
                else {
                  setHasMore(false)
                }

                setNotification([...notification, ...response.data.response]);
                setCount(
                  {
                    historical: response?.data?.historical,
                    actionable: response?.data?.actionable,
                    all: response?.data?.all,
                    critical: response?.data?.critical,
                    major: response?.data?.major,
                    minor: response?.data?.minor,
                  }
                )
              })
                .finally(() => setLoading(false));
            // const { run } = createRequest(services.marketplace.GET_QUOTES, [activeOrgId, quoteList.length, QUOTE_LISTING_LIMIT, sort, filterData, search])
            // run()
            //   .then(res => {
            //     setLoading(false)
            //     setQuoteList([...quoteList, ...res.data])
            //     if (res.data.length < QUOTE_LISTING_LIMIT) {
            //       setHasMore(false)
            //     }
            //   })
            //   .catch(err => {
            //     setLoading(false)
            //     setHasMore(false)
            //     // setError((new CatchedWebError(err)).message
            //     make_custom_toast('error', 'Marketplace', (new CatchedWebError(err)).message)
            //   })
          }
          }
          hasMore={hasMore}
          loader={<div >{SCROLL_MORE_TEXT}</div>}
          endMessage={<div className="mb-2">Showing {notification?.length} result(s)</div>}
          scrollableTarget="alert-table-wrapper"
          scrollThreshold={0.6}
        >

          <div id="alert-table-wrapper">
            <Table className=" mt-50 table-view fixed-header ">
              <thead>
                <tr>
                  {
                    cols.Type &&
                    < th style={{ width: "7%" }}>
                      <ColumnHeader attribute={"alert_type"} header="Type" setter={setSort} sort={sort}
                        filter={{
                          component: <NotificationTypeFilter filter={filterData} setFilter={setFilterData} />,
                          onFilterClear: () => clearFilterWrapper('alertType'),
                          onFilterApply: applyFilterWrapper,
                          isFilterActive: !!filterData?.alertType?.length && filterActive
                        }} />
                    </th>
                  }
                  <th style={{ width: "18%" }}><ColumnHeader header="Id" attribute={"alert_id"} setter={setSort} sort={sort} /></th>

                  {cols.Affects && <th
                    style={{ width: permissions?.manageNotificationstats?.view ? "16%" : "19%" }}>
                    <Header header="Affects" />
                  </th>}
                  {
                    cols.Summary &&
                    <th style={{ width: "25%" }}><ColumnHeader header="Summary" attribute={"display_name"} setter={setSort} sort={sort} /></th>
                  }
                  {
                    cols.Venues &&
                    <th style={{ width: "20%" }}><Header header="Venue" /></th>
                  }
                  {
                    cols.Created &&
                    <th style={{ width: "12%" }}><ColumnHeader header="Created" attribute={"created_at"} setter={setSort} sort={sort} /></th>
                  }
                  {
                    cols.Severity &&
                    <th style={{ width: "12%" }} className={permissions?.manageNotificationstats?.view ? "" : "rounded-right-top"}>
                      <ColumnHeader header="Severity" attribute={"alert_impact"} setter={setSort} sort={sort}
                        filter={{
                          component: <NotificationImpactFilter filter={filterData} setFilter={setFilterData} />,
                          onFilterClear: () => clearFilterWrapper('alertImpact'),
                          onFilterApply: () => applyFilterWrapper(),
                          isFilterActive: !!filterData?.alertImpact?.length && filterActive
                        }} /></th>
                  }
                  {/* {permissions?.manageNotificationstats?.view
                    ? <th style={{ width: "5%" }} className="rounded-right-top"></th>
                    : null
                  } */}
                  <th style={{ width: "2%" }} className="text-right">
                    <span
                      className="material-symbols-outlined cursor-pointer"
                      onClick={() =>
                        setIsColumnSelectorOpen(prevState => !prevState)}>
                      settings
                    </span>
                  </th>
                </tr>
              </thead>
              {loading ?
                <tbody className="bg-white">
                  <tr>
                    <td colSpan={10}>
                      <Skeleton height="20px" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton height="20px" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton height="20px" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton height="20px" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton height="20px" />
                    </td>
                  </tr>
                </tbody> : (notification?.length == 0) ?
                  <tr>
                    <td className="text-center p-5" colSpan={10}>
                      <h4>No {historical ? 'historical' : 'actionable'} notification at this time!</h4>
                    </td>
                  </tr> :
                  <tbody>
                    {notification?.map((noti, index) => {
                      return (
                        <tr key={index} className="bg-white">
                          {
                            cols.Type &&

                            <td>
                              {(noti?.alert_type === "alarm" || noti?.alert_type === "venue_alarm") ? (
                                noti?.alert_impact == ALERT_HIG ? (
                                  <EmergencySirenIcon
                                    style={{ width: "22px", height: "22px" }}
                                  />
                                ) : noti?.alert_impact == ALERT_MED ? (
                                  <OrangeWarningIcon
                                    style={{ width: "22px", height: "22px" }}
                                  />
                                ) : noti?.alert_impact == ALERT_LOW ? (
                                  <WarningIcon
                                    style={{ width: "22px", height: "22px" }}
                                  />
                                ) : null
                              ) : noti?.alert_type === "ticket" ? (
                                <TicketIcon
                                  style={{ width: "22px", height: "22px" }}
                                />
                              ) : noti?.alert_type === "notification" ? (
                                // <NotificationIcon
                                //   style={{ width: "22px", height: "22px" }}
                                // />
                                <img src={notificationSvg} style={{ width: '22px', height: '22px' }} alt="notification" />
                              ) : null}
                            </td>
                          }
                          <td>
                            <LinkWrapper
                              to={
                                (noti?.alert_type === "ticket" && rbac?.level<3 && permissions?.manageTicket?.view)
                                  ? `/organization/${noti.org_id}/support/${noti?.alert_id}`
                                  : null}
                            >
                              <span style={{ textTransform: "capitalize" }}>
                                {noti?.alert_type?.replace("_", " ")}{" "}
                              </span>
                              {noti?.alert_id != null ? noti?.alert_id : "-"}
                            </LinkWrapper>
                          </td>

                          {

                            cols.Affects &&
                            <td>
                              <div
                                className={
                                  "d-flex align-items-center "}
                              >
                                <LinkWrapper
                                  to={affectsLink(noti)}
                                >
                                  {noti?.affected_entities != null &&
                                    noti?.affected_entities[0]?.org_name == null && (
                                      <span>
                                        {noti?.affected_entities[0].venues != null ? (
                                          <Venue className="mr-50" />
                                        ) : noti?.affected_entities[0].infra_category ==
                                          "ap" ? (
                                          <APSVG className="mr-50" />
                                        ) : noti?.affected_entities[0].infra_category == 'switch' ? (
                                          <SwitchSVG className="mr-50" />
                                        ) : (noti?.affected_entities[0].infra_category == null &&
                                          !!noti?.affected_entities[0].venue_name) ? (
                                          <Venue className="mr-50" />
                                        ) : (
                                          null
                                        )}
                                      </span>
                                    )}
                                  {noti?.affected_entities != null ? (
                                    noti?.affected_entities[0].venues != null ? (
                                      <span
                                        className="cursor-pointer "
                                        id={`venue${index}`}
                                        onMouseEnter={() => setPopover(index)}
                                        onMouseLeave={() => setPopover(null)}
                                      >
                                        <span>
                                          {noti?.affected_entities[0].venues?.length > 1
                                            ? noti?.affected_entities[0].venues
                                              ?.length + " Venues"
                                            : noti?.affected_entities[0].venues[0]
                                              .venue_name}
                                        </span>
                                        <Popover
                                          placement="right"
                                          target={`venue${index}`}
                                          isOpen={popover === index}
                                        >
                                          <PopoverHeader>Venues</PopoverHeader>
                                          <PopoverBody className="scrollable-popover">
                                            {noti?.affected_entities[0].venues?.map(
                                              (venue, key) => (
                                                <LinkWrapper
                                                  to={`/organization/${noti.org_id}/venues/${venue?.venue_id}`}
                                                  key={"orgRowPopver" + key}
                                                >
                                                  {venue.venue_name}
                                                </LinkWrapper>
                                              )
                                            )}
                                          </PopoverBody>
                                        </Popover>
                                      </span>
                                    ) : (
                                      noti?.affected_entities[0].org_name ??
                                      noti?.affected_entities[0].venue_name ??
                                      noti?.affected_entities[0].infra_name
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </LinkWrapper>
                              </div>
                            </td>
                          }
                          {cols.Summary &&
                            <td>
                              <div
                                id={`noti-description${index}`}
                                onMouseEnter={() => setSummaryPopover(`noti-description${index}`)}
                                onMouseLeave={() => setSummaryPopover(null)}
                                className={`${'cursor-pointer'}`}
                              >
                                {noti?.display_name != null ? <span>{noti?.display_name}<span className="material-symbols-outlined info-icon">info</span></span> : "-"}
                              </div>
                              {document.getElementById(`noti-description${index}`) ?
                                <>
                                  <Tooltip
                                    anchorSelect={`#noti-description${index}`}
                                    variant="light"
                                    clickable
                                    place="bottom"
                                    border="solid 2px #EAEAEA"
                                    opacity={1}
                                    style={{ boxShadow: "2px 2px 15px #EAEAEA", zIndex: 40 }}
                                    delayShow={200}
                                  >
                                    <div className="ellipsed-noti" style={{ width: "270px", fontSize: "1rem" }}>
                                      <AlarmDesc alarmType={noti?.alert_code} data={noti?.metadata_payload ?? {}} desc={noti?.description} orgId={noti?.org_id} />
                                    </div>
                                  </Tooltip>
                                </>
                                : null}
                            </td>
                          }
                          {cols.Venues &&
                            <td
                              style={{ maxWidth: '210px' }}>


                              <div className="all-venue-div">


                                {
                                  noti?.venue_hierarchy != null && noti?.venue_hierarchy.length > 0 &&
                                  noti?.venue_hierarchy.map((prevVenue, index) =>
                                    <span>
                                      <LinkWrapper
                                        to={(!!prevVenue?.venue_name && noti?.org_id != null && prevVenue?.venue_id != null)
                                          ? `/organization/${noti.org_id}/venues/${prevVenue?.venue_id}`
                                          : null}
                                        key={index + 'venueall'}
                                      >
                                        {prevVenue.venue_name}
                                      </LinkWrapper>
                                      /&nbsp;
                                    </span>
                                  )
                                }

                                {
                                  noti.alert_type != "notification" ?

                                    <LinkWrapper
                                      to={(!!noti?.venue_name && noti?.org_id != null && noti?.venue_id != null)
                                        ? `/organization/${noti.org_id}/venues/${noti?.venue_id}`
                                        : null}
                                    >
                                      {(noti.venue_name ?? "-")}
                                    </LinkWrapper>
                                    : <LinkWrapper
                                      to={!!noti?.affected_entities[0]?.venues[0]?.venue_name && noti?.affected_entities[0]?.org_id != null && noti?.affected_entities[0]?.venues[0]?.venue_id != null
                                        ? `/organization/${noti?.affected_entities[0]?.org_id}/venues/${noti?.affected_entities[0]?.venues[0]?.venue_id}`
                                        : null
                                      }
                                    >
                                      {(noti?.affected_entities[0]?.venues[0]?.venue_name ?? "-")}
                                    </LinkWrapper>
                                }
                              </div>


                            </td>
                          }
                          {

                            cols.Created &&
                            <td className="cursor-pointer" id={`noti${index}`}>
                              {timeDiff(noti?.created_at, true) + " ago"}
                              <UncontrolledTooltip target={`noti${index}`}>
                                {dateTimeFormatter(
                                  noti.created_at,
                                  "long",
                                  "medium"
                                ) +
                                  " (" +
                                  getTimeZone(false) +
                                  ") "}
                              </UncontrolledTooltip>
                            </td>
                          }
                          {

                            cols.Severity &&
                            <td>
                              <span
                                className={`font-weight-bolder text-${alertColor[noti?.alert_impact]
                                  }`}
                                style={{ textTransform: "capitalize" }}
                              >
                                {alertText[noti?.alert_impact]}
                              </span>
                            </td>
                          }
                          {permissions?.manageNotificationstats?.view ? (
                            <td>
                              {!historical && (
                                <UncontrolledDropdown direction="down">
                                  <DropdownToggle color="white" className="w-0 p-0">
                                    <span
                                      className={
                                        "material-symbols-outlined cursor-pointer " +
                                        (historical
                                          ? "text-secondary"
                                          : "text-primary")
                                      }
                                      title="Actions"
                                    >
                                      more_vert
                                    </span>
                                  </DropdownToggle>
                                  {!historical && (
                                    <DropdownMenu>
                                      <DropdownItem
                                        className="w-100"
                                        onClick={() => {
                                          setAckModal(noti?.id);
                                        }}
                                      >
                                        Acknowledge
                                      </DropdownItem>
                                    </DropdownMenu>
                                  )}
                                </UncontrolledDropdown>
                              )}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                    }
                  </tbody>}
            </Table>
          </div>
        </InfiniteScroll>
      </div >
    </div >
  );
};

AlertVenueTable.propTypes = {};

AlertVenueTable.defaultProps = {};

export default AlertVenueTable;
