/***
 *
 * Controller class for user.
 * @file Settings.js
 * @description Settings component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../_builder/Header";
import "./Settings.scss";
import * as Yup from "yup";
import { Row, Col, Spinner, Label, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Input, FormGroup, CustomInput } from "reactstrap";
import TextInput from "../../../components/TextInput";
import DeleteModal from "../../../components/DeleteModal";
import Geocode from "react-geocode";
import Select from "react-select"
import { GoogleMap, Marker } from "@react-google-maps/api";
import createRequest, { services } from "../../../services";
import { activeVenueActions, breadcrumbActions, infraListActions } from "../../../redux/slices";
import { useNavigate } from "react-router-dom";
import useAutoClear from "../../../hooks/useAutoclear";
import AutoComplete from "react-google-autocomplete";
import store from "../../../redux/store";
import REDUX_WORKER from "../../../redux/workers";
import { CatchedWebError } from "../../../configs";
import ReportAlert from "../../../components/ReportAlert";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { make_custom_toast } from "../../../helpers/toasts";
import { UpdateVenueState, venueState } from "../PAdmin";
import { SHASTA, UPDATE, VENUE_STATE, VENUE_STATE_LABEL, VENUE_STATE_MAP, VIEW, isAdmin } from "../../../utility/constants";
import { makeStringFromArray, validateEmail } from "../../../utility/Utils";
import VenueStateDropdown from "../../../components/VenueStateDropdown";
import { GOOGLE_MAP } from "../../../components/VenueBasicDetails/helper";
import { ReactComponent as MapViewIcon } from "../../../assets/images/icons/map_view/map_view_icon.svg";
import { ReactComponent as StreetViewIcon } from "../../../assets/images/icons/map_view/street_view_icon.svg";
import { getVenueStreetView } from "../../../components/MapModal";
import { streetviewHandler } from "../AddVenue";
import TextInputWithIcon from "../../../components/TextInputWithIcon";
import { VenueIcon } from "../VenueTree";
import { zoneMap } from "../../../components/MapWidget";

const RECIPIENT_TYPE = [
  {
    value: 1,
    label: 'Disabled'
  },
  {
    value: 2,
    label: 'MSP Admin'
  },
  {
    value: 3,
    label: 'Custom'
  }
]

const Settings = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrg = useSelector(store => store.activeOrg.data);
  const activeVenue = useSelector(store => store.activeVenue.data);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const rbacData = useSelector(store => store?.rbac?.data);
  const orgPermissions = useSelector(store => store?.rbac?.permissions);
  const identity = useSelector(store => store.identity.data);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);
  const [deletionError, setDeletionError] = useState(null);
  const [map, setMap] = useState(null);
  const [streetView, setStreetView] = useState(null);
  const [basicVenueDetails, setBasicVenueDetails] = useState({});

  const [pos, setPos] = useState(null);
  const [alarmNotifications, setAlarmNotification] = useState(activeVenue?.alarmNotifications ?? false)
  const [isCustomRecipient, setIsCustomRecipient] = useState(activeVenue?.recipients?.length ? true : false)
  const [recipients, setRecipients] = useState(activeVenue?.recipients ? activeVenue?.recipients : '')
  const [venues, setVenues] = useState([]);
  const [venueAddressAlert, setVenueAddressAlert] = useState(false);
  const [shippingAddressAlert, setShippingAddressAlert] = useState(false);
  const [address, setAddress] = useState({ venue: activeVenue?.venueAddress?.addressLine || "", shipping: "" });
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [currentValue, setCurrentValue] = useState('')
  const [noCustomEmailAdded, setNoCustomEmailAdded] = useState(false)
  const [initiatedFirst, setInitiatedFirst] = useState(true)

  const [googleMapView, setGoogleMapView] = useState(GOOGLE_MAP.MAP_VIEW);


  const inputRef = useRef(null)

  useEffect(() => {


    setDisableUpdate(props?.venuePermissions?.venue == null ? !orgPermissions?.manageVenue?.update : !(props?.venuePermissions?.venue > VIEW));
  }, [orgPermissions, props?.venuePermissions])

  useEffect(() => {
    if (!initiatedFirst && inputRef.current) {
      inputRef?.current?.focus()
    }
    else {
      setInitiatedFirst(false)
    }
  }, [currentValue])


  const formikRef = useRef(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shippingAdd = useRef();
  const venueAdd = useRef();
  Geocode.setApiKey(process.env.REACT_APP_MAP);


  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: 0, lng: 0 });
    map.fitBounds(bounds);
    setMap(map);
  }, [map])

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, [])

  useAutoClear(submitSuccess, () => setSubmitSuccess(false));



  useEffect(() => {
    // getVenueDetails()
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    getVenueBasicDetails();

    store.dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/settings`,
      text: "Settings",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);

  useEffect(() => {
    if (streetView == null)
      getVenueStreetView(activeVenue.orgId, activeVenue.venueId, (res) => setStreetView(res))
    setTimeout(() =>
      setVenues([
        { lat: activeVenue?.venueAddress.latitude, lng: activeVenue?.venueAddress.longitude}
      ]), 500);
  }, [activeVenue]);


  const saveInput = () => {
    if (formikRef.current) {

      formikRef.current.handleSubmit();
      setPos(null);
    }
  };

  const deleteVenue = useCallback(() => {
    const { context, run } = createRequest(services.venue.DELETE, [activeVenue.venueId]);

    setDeletionLoading(true);
    run()
      .then(response => {
        setDeletionError(null);
        navigate(`/organization/${activeOrgId}/venues?deleted=true`)
      })
      .catch(err => {
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(context, 'DELETE VENUE', TICKET_CATEGORY.VENUE, err)
        // setDeletionError(x.message);
        make_custom_toast('error', 'Venue', x.message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        })
        setDeleteModal(false)
      })
      .finally(() => {
        setDeletionLoading(false);
      });
  }, [activeVenue.venueId, navigate, activeOrgId]);

  const getVenueBasicDetails = () => {
    const { run } = createRequest(services.telemetry.GET_VENUE_DETAILS, [activeVenue?.venueId])
    run()
      .then(res => {
        setBasicVenueDetails(res.data)
      })
  }

  const ChipInputBox = () => {
    const [chipList, setChipList] = useState(recipients.length > 0 ? recipients.split(',') : [])
    return <>
      <div>Email Recipient(s)<span className="text-danger">*</span></div>
      <div className={`chip-input-box ${noCustomEmailAdded ? "red-border" : ""} `}
        onClick={() => {
          setNoCustomEmailAdded(false)
          if (inputRef.current) {
            inputRef?.current?.focus()
          }
        }}>
        {
          chipList.map((chip, index) => {
            return <span
              className="chip-design d-flex align-items-center">
              <span>{chip}</span>
              <span class={"material-symbols-outlined ml-50 cursor-pointer"}
                onClick={() => {
                  if (orgPermissions?.manageOrganization?.update) {
                    let tempList = [...chipList]
                    tempList.splice(index, 1)
                    setChipList(tempList)
                    setRecipients(makeStringFromArray(tempList))
                  }
                }}>
                close
              </span>
            </span>
          })
        }
        {
          orgPermissions?.manageOrganization?.update &&
          <input
            type="text"
            ref={inputRef}
            id="chip-input-id"
            className={`chip-input ${chipList.length > 0 ? 'margin-bottom-input' : ''}`}
            value={currentValue}
            onKeyDown={e => {
              if (e.code == 'Space' || e.code == 'Enter' || e.code == 'Comma') {
                e.preventDefault()

                let tempList = [...chipList]
                if (validateEmail(currentValue)) {
                  tempList.push(currentValue)
                  setChipList(tempList)
                  setRecipients(makeStringFromArray(tempList))
                  setCurrentValue('')
                }

              }
            }}

            onChange={e => {
              setNoCustomEmailAdded(false)
              setCurrentValue(e.target.value)

            }}
          />
        }
      </div>
      {
        noCustomEmailAdded && <span className="text-danger">{currentValue.length > 0 ? "Invalid Email" : "Required"}</span>
      }
    </>
  }

  return (
    <div className="Settings mb-2" data-testid="Settings">

      <div>

        <Row className="p-0 m-0">
          <Col className="p-0 m-0" xs={11}>
            <Header heading={activeVenue.venueName} />
          </Col>
          <Col className="p-0 m-0" xs={1}>
            <Button className="small-add-button mt-50" color="primary" onClick={saveInput} >Update</Button>
          </Col>
        </Row>


      </div>

      <div className="  ">

        {/* removed as per figma */}
        {/* <Row>
          <Col xs="12">
            {<GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '280px'
              }}
              zoom={14}
              onLoad={onLoad}
              onUnmount={onUnmount}
              >
              {
                <div>
                {venues && venues.map((item, index) => {
                  if (item.lat === undefined)
                      return <React.Fragment key={index}></React.Fragment>
                    map?.panTo({ lat: item.lat, lng: item.lng })
                    map?.setZoom(10);
                    return <Marker key={index} position={{ lat: item.lat, lng: item.lng }}
                    icon={item?.icon} />
                  }
                  )}
                  {(!!pos) && <Marker position={pos} />}{!!(pos) && map?.panTo(pos)}
                </div>
              }
            </GoogleMap>}
          </Col>
        </Row> */}


        <div className="rounded" style={{ position: 'relative' }}>
          {/* <span className="fw-600">Venue Settings</span> */}

          {activeVenue.venueId &&
            <Formik
              innerRef={formikRef}
              initialValues={{
                name: activeVenue.venueName ?? "",
                venueAddressLine: activeVenue.venueAddress.addressLine ?? "",
                venueAddressLat: activeVenue.venueAddress?.latitude,
                venueAddressLng: activeVenue.venueAddress?.longitude,
                shippingAddressLine: activeVenue.shippingAddress?.addressLine ?? "",
                shippingAddressLat: activeVenue.shippingAddress?.latitude,
                shippingAddressLng: activeVenue.shippingAddress?.longitude,
                radiusLocation: activeVenue.radiusLocation ?? "",
                state: activeVenue.state,
                notes: activeVenue?.reason ?? "",

              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(3, "Minimum 3 Characters are Required")
                  .max(45, "Maximum 45 Characters are Allowed")
                  .matches(/^[a-zA-Z0-9_#'\- ]*$/, "Only alphanumeric characters and ( _, ', -, # ) are allowed")
                  .required("Required"),
                venueAddressLine: Yup.string().required("Required"),
                notes: Yup.string().when("state", ([state], schema) => {
                  if ([3].includes(state))
                    return schema.required("Required");
                  return schema
                })
              })}
              onSubmit={async (values, { setSubmitting }) => {
                let venueAddress = {
                  addressLine: address.venue
                };

                if (!!values.venueAddressLat) {
                  venueAddress.latitude = values.venueAddressLat
                  venueAddress.longitude = values.venueAddressLng
                }
                else {
                  try {
                    let geocode = await Geocode.fromAddress(venueAddress.addressLine);
                    if (geocode?.results?.length > 0) {
                      venueAddress.latitude = geocode?.results[0]?.geometry?.location?.lat;
                      venueAddress.longitude = geocode?.results[0]?.geometry?.location?.lng;
                      setPos({ lat: geocode?.results[0]?.geometry?.location?.lat, lng: geocode?.results[0]?.geometry?.location?.lng })
                    }
                  }
                  catch {
                    setPos({ lat: 0, lng: 0 })
                  }
                }
                let shippingAddress = {
                  addressLine: address.shipping,
                };
                if (!!values.shippingAddressLat) {
                  shippingAddress.latitude = values.shippingAddressLat
                  shippingAddress.longitude = values.shippingAddressLng
                }

                let tempRecipients = recipients

                if (currentValue?.length > 0 || currentValue != '' || currentValue != null) {
                  if (validateEmail(currentValue)) {
                    if (recipients.length > 0) {
                      setRecipients(tempRecipients + ',' + currentValue)
                      setCurrentValue('')
                      tempRecipients += ',' + currentValue
                    }
                    else {
                      setRecipients(currentValue)
                      setCurrentValue('')
                      tempRecipients = currentValue
                    }
                  }
                  else {
                    if (currentValue.length > 0) {
                      setNoCustomEmailAdded(true)
                      return
                    }
                  }

                }



                let updatedData = {
                  venueName: values.name,
                  venueAddress: venueAddress,
                  alarmNotifications: alarmNotifications,
                  recipients: ""
                };

                if (alarmNotifications) {
                  if (isCustomRecipient) {
                    if (tempRecipients.length > 0)
                      updatedData.recipients = tempRecipients
                    else {
                      setNoCustomEmailAdded(true)
                      return
                    }
                  }
                  else {
                    updatedData.recipients = ""

                  }
                }
                setNoCustomEmailAdded(false)

                if (orgPermissions?.manageOrganization?.create) // activeOrg?.orgTypeId > rbacData?.orgTypeId
                {
                  updatedData.state = Number(values.state);
                }

                updatedData.reason = (values.state == 3) ? values.notes : "";

                if (shippingAddress?.addressLine?.length > 0) {
                  updatedData.shippingAddress = shippingAddress
                }
                if (values.radiusLocation.length > 0) {
                  updatedData.radiusLocation = values.radiusLocation
                }


                const { context, run } = createRequest(services.venue.EDIT, [activeVenue.venueId], updatedData);
                run()
                  .then(response => {
                    streetviewHandler(response.data.orgId, response.data.venueId, response?.data?.venueAddress?.latitude ?? 0, response?.data?.venueAddress?.longitude ?? 0)
                    dispatch(activeVenueActions.setActiveVenue(response.data));
                    setRecipients(response?.data?.recipients)
                    setSubmitError(null);
                    dispatch(infraListActions.reset());
                    // setSubmitSuccess(true);
                    // setAddress({ venue: "", shipping: "" });
                    make_custom_toast('success', 'Venue Updated', 'Venue Updated successfully')
                  })
                  .catch(err => {
                    let x = new CatchedWebError(err);
                    const apiContext = createErrorContext(context, 'UPDATE VENUE', TICKET_CATEGORY.VENUE, err)
                    // setSubmitError(x.message);
                    make_custom_toast('error', 'Venue', x.message, true, 'Create Ticket', () => {
                      navigate(
                        `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
                        {
                          state: {
                            ticketContext: apiContext,
                          },
                        }
                      );
                    })
                  })
                  .finally(() => {
                    setSubmitting(false);
                    if (!isCustomRecipient) {
                      setRecipients('')
                    }
                  });
              }
              }
            >
              {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    // console.log("event-->", event)
                    address.venue === "" ? setFieldValue('venueAddressLine', event.target[1].value) : setFieldValue('venueAddressLine', address.venue);
                    address.shipping === "" ? setFieldValue('shippingAddressLine', event.target[2].value) : setFieldValue('shippingAddressLine', address.shipping);
                    {
                      event.target[1].value.length === 0 ? setVenueAddressAlert("Required") :
                        event.target[1].value.length < 3 ? setVenueAddressAlert("Minimum 3 Characters are Required") :
                          event.target[1].value.length > 250 ? setVenueAddressAlert("Maximum 250 Characters are Allowed") :
                            event.target[2].value.length > 250 ? setShippingAddressAlert("Maximum 250 Characters are Allowed") :
                              handleSubmit()
                    }
                  }}>
                  {
                    !activeVenue.defaultVenue && (props?.venuePermissions?.venue == null ? orgPermissions?.manageVenue?.delete : props?.venuePermissions?.venue > UPDATE) &&
                    <div className="delete-dropdown" >
                      <UncontrolledButtonDropdown direction="left">
                        <DropdownToggle color='white' className="pl-50" >
                          <span className="material-symbols-outlined text-primary">more_vert</span>
                        </DropdownToggle>
                        <DropdownMenu >
                          <DropdownItem
                            className="w-100"
                            onClick={() => {
                              setDeleteModal(true);
                            }}>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  }

                  <div className="bg-white rounded mb-1 p-2">

                    <Row>
                      <Col xs={12} md={4}>

                        <TextInputWithIcon type="text" name="name" label="Name" placeholder="Name" defaultTag={activeVenue.defaultVenue} disabled={disableUpdate} isrequired icon={VenueIcon[activeVenue.venueType]} />
                        {orgPermissions?.manageOrganization?.create &&
                          // activeOrg?.orgTypeId > rbacData?.orgTypeId) &&
                          <Col className="p-0 m-0">

                            <div className="mb-50">State<span className="text-danger">*</span></div>

                            <VenueStateDropdown
                              selectedVenueState={VENUE_STATE_MAP.find(it => it.value == values.state).value}
                              onChange={(e) => {
                                setFieldValue("state", e)
                                setFieldValue("notes", "")
                              }}
                            />
                            {/*
                              <Select
                                options={VENUE_STATE_MAP}
                                defaultValue={VENUE_STATE_MAP.find(it => it.value == values.state)}
                                onChange={(e) => {
                                  setFieldValue("state", e.value)
                                  setFieldValue("notes", "")
                                }}
                              /> */}
                          </Col>}
                        {/* <Col md={6} sm={12}>
                        <div className="mb-50">Venue Type</div>
                        <Input value={venueType[activeVenue.venueType]} disabled />
                      </Col> */}
                      </Col>
                      <Col xs={12} md={4}>
                        <Label className="autocomplete-label">
                          Venue Address
                        </Label>
                        <span className="text-danger">*</span>
                        <AutoComplete className={"form-control autocomplete-input " + (venueAddressAlert && "invalid")}
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          disabled={disableUpdate}
                          onChange={() => {
                            setFieldValue('venueAddressLat', null);
                            setFieldValue('venueAddressLng', null);
                            setVenueAddressAlert(null);
                            // shippingHandle();
                          }}
                          options={{
                            types: []
                          }}
                          placeholder="Venue Address"
                          ref={venueAdd}
                          defaultValue={activeVenue?.venueAddress?.addressLine}
                          onPlaceSelected={
                            (place) => {
                              const selectLat = place?.geometry?.location?.lat() ?? 0;
                              const selectLng = place?.geometry?.location?.lng() ?? 0;
                              setStreetView(`https://maps.googleapis.com/maps/api/streetview?size=1024x320&location=${selectLat},${selectLng}&return_error_code=true&fov=120&pitch=10&key=${process.env.REACT_APP_STREET_VIEW}`)
                              setAddress(currState => { return { ...currState, venue: place.formatted_address ?? place.name } });
                              setFieldValue('venueAddressLat', selectLat);
                              setFieldValue('venueAddressLng', selectLng);

                              setPos({ lat: selectLat, lng: selectLng });
                            }
                          }
                        />
                        {venueAddressAlert && <span className="text-danger error-field">{venueAddressAlert}</span>}
                        {(orgPermissions?.manageOrganization?.create && (values.state == VENUE_STATE.DISABLED)) &&
                          <div className="mt-2">
                            <TextInput name="notes" label={`Reason for ${VENUE_STATE_LABEL[values.state]} state`} placeholder="Add Reason" disabled={disableUpdate} isrequired />
                          </div>}
                      </Col>
                      <Col md={4} className="m-0 p-0">
                        <div className="google-map-container">
                          {googleMapView == GOOGLE_MAP.MAP_VIEW ? <GoogleMap
                            mapContainerStyle={{
                              width: '100%',
                              height: '11em'
                            }}
                            options={{
                              keyboardShortcuts: false,
                              disableDefaultUI: true,
                              minZoom: 5
                            }}
                            zoom={12}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                          >
                            {
                              <div>
                                {venues && venues.map((item, index) => {
                                  if (item.lat === undefined)
                                    return <React.Fragment key={index}></React.Fragment>
                                  map?.panTo({ lat: item.lat, lng: item.lng })
                                  setTimeout(() => map?.setZoom(12), 10)
                                  return <Marker key={index} position={{ lat: item.lat, lng: item.lng }}
                                    icon={{url: zoneMap[basicVenueDetails?.zone]}} />
                                }
                                )}
                                {(!!pos) && <Marker position={pos} />}{!!(pos) && map?.panTo(pos)}
                              </div>
                            }
                          </GoogleMap> :
                            <div className="text-center" style={{ height: "11em" }}>
                              {streetView != null ?
                                <img className="ap-img" alt={"No streetview"} src={streetView} onError={() => setStreetView(null)} />
                                : <div className="pt-2">
                                  No Streetview found
                                </div>}
                            </div>
                          }
                          <div className="map-switch-container d-flex ">
                            <div className={`cursor-pointer map-switch-button d-flex leftsideborder ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.STREET_VIEW)}>
                              <StreetViewIcon
                                className={`street-icon ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`}
                                width={12}
                                height={12} />
                            </div>
                            <div className={`cursor-pointer map-switch-button d-flex rightsideborder ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.MAP_VIEW)}>
                              <MapViewIcon
                                className={`map-icon ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`}
                                width={12}
                                height={12} />
                            </div>
                          </div>
                        </div>

                      </Col>
                    </Row>

                  </div>


                  <div className="bg-white rounded p-2">



                    <Row className="p-0 m-0 pt-1">
                      <Col className="p-0 m-0" xs={12} md={6}>
                        <Label className="autocomplete-label">
                          Customer Shipping Address
                        </Label>
                        <AutoComplete className={"form-control autocomplete-input " + (shippingAddressAlert && "invalid ")}
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          onChange={() => {
                            setFieldValue('shippingAddressLat', null);
                            setFieldValue('shippingAddressLng', null);
                            setShippingAddressAlert(null);
                          }}
                          options={{
                            types: []
                          }}
                          disabled={disableUpdate}
                          ref={shippingAdd}
                          placeholder="Shipping Address"
                          defaultValue={activeVenue?.shippingAddress?.addressLine}
                          onPlaceSelected={
                            (place) => {
                              const selectLat = place?.geometry?.location?.lat() ?? 0;
                              const selectLng = place?.geometry?.location?.lng() ?? 0;
                              setAddress(currState => { return { ...currState, shipping: place.formatted_address ?? place.name } });
                              setFieldValue('shippingAddressLat', selectLat);
                              setFieldValue('shippingAddressLng', selectLng);
                              // setPos({ lat: selectLat, lng: selectLng }); //marker position
                            }}
                        />
                        {shippingAddressAlert && <span className="text-danger error-field">{shippingAddressAlert}</span>}
                      </Col>
                      <Col xs={12} md={6}>
                        <Label className="autocomplete-label">
                          Custom RADIUS Location
                        </Label>
                        <AutoComplete className="form-control autocomplete-input"
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          onChange={(e) => {
                            console.log("onchange", e.target.value)
                            setFieldValue("radiusLocation", e.target.value);
                          }}
                          options={{
                            types: []
                          }}
                          disabled={disableUpdate}
                          placeholder="RADIUS Location"
                          defaultValue={activeVenue?.radiusLocation ?? ""}
                          onPlaceSelected={
                            (place) => {
                              setFieldValue("radiusLocation", place.formatted_address ?? place.name)
                            }}
                        />
                        {shippingAddressAlert && <span className="text-danger error-field">{shippingAddressAlert}</span>}
                      </Col>
                    </Row>


                    {/* removed as per figma */}
                    {/* <Row className="mt-2">
                    {(orgPermissions?.manageOrganization?.create &&
                      activeOrg?.orgTypeId > rbacData?.orgTypeId) &&
                      <Col md={6} sm={12}>
                        <div className="mb-50">State<span className="text-danger">*</span></div>
                        <Select
                          options={venueState}
                          defaultValue={venueState.find(it => it.value == values.state)}
                          onChange={(e) => {
                            setFieldValue("state", e.value)
                            setFieldValue("notes", "")
                          }}
                        />
                      </Col>}
                    <Col md={6} sm={12}>
                      <div className="mb-50">Venue Type</div>
                      <Input value={venueType[activeVenue.venueType]} disabled />
                    </Col>
                  </Row> */}

                    {/* Alarm Notifications Enable/Disable */}
                    {

                      ((orgPermissions?.manageOrganization?.create &&
                        activeOrg?.orgTypeId > rbacData?.orgTypeId) || activeOrg?.orgTypeId == SHASTA) &&
                      <Row className="p-0 m-0 mt-2 ">
                        <Col className="p-0 " md={6} sm={12} >
                          <div className="mb-50">Infrastructure Alarm Emails</div>
                          <Select
                            options={RECIPIENT_TYPE}
                            isDisabled={!orgPermissions?.manageOrganization?.update}
                            defaultValue={alarmNotifications ?
                              isCustomRecipient ? RECIPIENT_TYPE[2] : RECIPIENT_TYPE[1]
                              : RECIPIENT_TYPE[0]}
                            onChange={(e) => {
                              if (e.value == 1) {
                                setAlarmNotification(false)
                                setIsCustomRecipient(false)
                              }
                              else if (e.value == 2) {
                                setAlarmNotification(true)
                                setIsCustomRecipient(false)
                              }
                              else {
                                setAlarmNotification(true)
                                setIsCustomRecipient(true)
                              }
                            }}
                          />

                        </Col>

                        <Col md={6} sm={12}>
                          {
                            alarmNotifications && isCustomRecipient ?
                              <ChipInputBox /> : null
                          }
                        </Col>
                      </Row>
                    }
                  </div>

                  {/* <Row>
                   
                  </Row> */}

                  {/* {orgPermissions?.manageVenue?.update &&
                    <Row className="d-flex justify-content-end pt-2 px-1">
                      <Button.Ripple className="mr-1" color="secondary" disabled={isSubmitting} outline onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`/organization/${activeOrgId}/venues/${activeVenue.venueId}/`);
                      }}>Discard</Button.Ripple>
                      <Button type="submit" color="primary small-add-button" disabled={isSubmitting}>{isSubmitting ? <Spinner size="sm" /> : "Update"}</Button>
                    </Row>
                  } */}
                </Form>
              )}
            </Formik>
          }
        </div>
        <DeleteModal isOpen={deleteModal} toggle={setDeleteModal}
          heading="Are You Sure?"
          info="Are you sure you want to delete this venue?"
          icon={(deletionLoading) ? <Spinner className='me-25 m-1' size="lg" /> : "error"}>
          <Button disabled={deletionLoading} color="primary" className="mr-2 small-add-button" onClick={() => { setDeleteModal(false); }}>Cancel</Button>
          <Button disabled={deletionLoading} color="danger" className="small-add-button" onClick={deleteVenue}>Delete</Button>
        </DeleteModal>
      </div>
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
