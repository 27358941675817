/***
 *
 * Controller class for user.
 * @file AddVenue.js
 * @description AddVenue component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import "./AddVenue.scss";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TextInput from "../../../components/TextInput";
import VenueStateDropdown from "../../../components/VenueStateDropdown";
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import Geocode from "react-geocode";
import createRequest, { services } from "../../../services";
import { GoogleMap, Marker } from "@react-google-maps/api";
import AutoComplete from "react-google-autocomplete";
import store from "../../../redux/store";
import { activeVenueActions, breadcrumbActions } from "../../../redux/slices";
import { CatchedWebError } from "../../../configs";
import Select from "react-select";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { make_custom_toast } from "../../../helpers/toasts";
import { ReactComponent as StreetViewIcon } from "../../../assets/images/icons/map_view/street_view_icon.svg";
import { ReactComponent as MapViewIcon } from "../../../assets/images/icons/map_view/map_view_icon.svg";
import { ReactComponent as Site } from "../../../assets/images/icons/Site.svg";
import { ReactComponent as Warning } from "../../../assets/images/icons/Warning.svg";
import { SITE, SUB_VENUE, VENUE_STATE, VENUE_STATE_LABEL, VENUE_TYPE_LABEL, VENUE_TYPE_MAPPING, reactselectTheme } from "../../../utility/constants";
import HeightAnimator from "../../../components/HeightAnimator";
import { VenueIcon } from "../VenueTree";
import axios from "axios";
import { saveAs } from "file-saver";
import { GOOGLE_MAP } from "../../../components/VenueBasicDetails/helper";
import VenueStateGroup from "../../../components/VenueStateGroup";
import VenueStateIcon from "../../../components/VenueStateIcon";


const addVenueForm = {
  initialValues: (props, activeOrgAddress, activeVenueAddress) => {
    return {
      name: "",
      state: VENUE_STATE.LIVE,
      venuesListLength: 0,
      reason: '',
      addressLine: "",
      type: props.rootVenue ? SITE : SUB_VENUE,
      lat: (props.rootVenue ? activeOrgAddress.latitude : activeVenueAddress.latitude ? activeVenueAddress.latitude : 0),
      lng: (props.rootVenue ? activeOrgAddress.longitude : activeVenueAddress.longitude ? activeVenueAddress.longitude : 0)
    };
  },
  validationSchema: () => Yup.object({


    // name: Yup.string().when(["venuesListLength"], ([venuesListLength], schema) => {
    //   if (venuesListLength == 0) {
    //     return schema.required("Required")
    //       .min(3, "Minimum 3 Characters are Required")
    //       .max(45, "Maximum 45 Characters are Allowed")
    //       .matches(/^[a-zA-Z0-9_#'\- &]*$/, "Only alphanumeric characters and ( _, ', -, #, & ) are allowed")
    //   }
    //   else return schema.notRequired()
    // }),
    name: Yup.string().min(3, "Minimum 3 Characters are Required")
      .max(45, "Maximum 45 Characters are Allowed")
      .matches(/^[a-zA-Z0-9_#'\- &]*$/, "Only alphanumeric characters and ( _, ', -, #, & ) are allowed"),
    addressLine: Yup.string(),
    reason: Yup.string().when(['state', 'venuesListLength'], ([state, venuesListLength], schema) => {

      if (state == VENUE_STATE.DISABLED && venuesListLength < 1) {
        return schema.required("Required")
      }
      else return schema.notRequired()
    })
  }),
  extractSubmitData: (values, props, activeOrgId, venueId) => {
    const submitData = {
      orgId: activeOrgId,
      parentVenueId: (props.rootVenue ? 0 : Number(venueId)),
      venueType: values.type,
      venueName: values.name,
      state: values.state,
      venueAddress: {
        addressLine: values.addressLine,
      },
      shippingAddress: {
        addressLine: values.addressLine,
      }
    };
    if (values.state == VENUE_STATE.DISABLED)
      submitData.reason = values.reason
    if (values.lat && values.lng) {
      submitData.venueAddress.latitude = values.lat;
      submitData.venueAddress.longitude = values.lng;
      submitData.shippingAddress.latitude = values.lat;
      submitData.shippingAddress.longitude = values.lng;
    }
    return submitData;
  }
};


export const streetviewHandler = (orgId, venueId, lat, lng) => {
  axios({
    method: 'get',
    url: `https://maps.googleapis.com/maps/api/streetview?size=1024x320&location=${lat},${lng}&return_error_code=true&fov=120&pitch=10&key=${process.env.REACT_APP_STREET_VIEW}`,
    responseType: 'arraybuffer',
  }).then(response => {
    // console.log("Response Image--->", response);
    const { run } = createRequest(services.organization.VENUE_STREETVIEW, [orgId, venueId, "put"]);
    const blob = new Blob([response.data]);
    const file = new File([blob], "streetview.jpeg");
    // saveAs(file);
    run()
      .then(url => {
        // console.log("URL--->", url.data)
        axios.put(url.data, file)
      })

  })
    .catch(err => {
      // console.log(err);
    });

}

/// setup state variables and init functions for 
const useAddVenue = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrgAddress = useSelector(store => store.activeOrg.data.orgAddress);
  const activeVenueAddress = useSelector(store => store.activeVenue.data.venueAddress);
  const permissions = useSelector(store => store.permissions.data);
  const { venueId } = useParams();
  const navigate = useNavigate();
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitErrorContext, setSubmitErrorContext] = useState(null);
  const [map, setMap] = useState(null);
  const [pos, setPos] = useState({
    lat: (props.rootVenue ? activeOrgAddress.latitude : activeVenueAddress.latitude ? activeVenueAddress.latitude : 0),
    lng: (props.rootVenue ? activeOrgAddress.longitude : activeVenueAddress.longitude ? activeVenueAddress.longitude : 0)
  });
  const [streetView, setStreetView] = useState(null);
  const [address, setAddress] = useState("");
  const [addressAlert, setAddressAlert] = useState(null);
  const [reasonRequired, setReasonRequired] = useState(false)
  const [addedVenuesList, setAddedVenuesList] = useState([])
  const [addedVenuesListError, setAddedVenuesListError] = useState(null)

  useEffect(() => {
    if (!!pos) {
      setStreetView(`https://maps.googleapis.com/maps/api/streetview?size=1024x320&location=${pos.lat},${pos.lng}&return_error_code=true
      &fov=120&pitch=10&key=${process.env.REACT_APP_STREET_VIEW}`)
    }
  }, [pos])

  Geocode.setApiKey(process.env.REACT_APP_MAP);
  /// Set up breadcrumb
  useEffect(() => {
    store.dispatch(breadcrumbActions.setData([{
      path: `/organization/${activeOrgId}/venues/`,
      text: "Venues",
      active: false
    },
    {
      path: `/organization/${activeOrgId}/venues/add`,
      text: "New Venue",
      active: true
    }]))
  }, [activeOrgId]);

  /// Load and set lat, long bounds on map
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(pos);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  /// Set map state to null on unmount
  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  /// Call API to create new venue
  function onAddVenueFormSubmit(values, { setSubmitting }) {
    const submitData = addVenueForm.extractSubmitData(values, props, activeOrgId, venueId);
    let payload = []
    if (submitData?.venueName?.length >= 3 && submitData?.venueAddress?.addressLine?.length >= 3)
      payload = [{
        venueName: submitData.venueName,
        state: submitData.state,
        venueType: submitData.venueType,
        reason: submitData.reason || "",
        venueAddress: {
          addressLine: submitData.venueAddress.addressLine,
          latitude: submitData.venueAddress.latitude,
          longitude: submitData.venueAddress.longitude
        }
      }]

    for (let i in addedVenuesList) {
      let addedVenue = addedVenuesList[i]
      payload.push(
        {
          venueName: addedVenue.name,
          state: addedVenue.state,
          venueType: addedVenue.type,
          reason: addedVenue.reason,
          venueAddress: {
            addressLine: addedVenue.addressLine,
            latitude: addedVenue.lat,
            longitude: addedVenue.lng
          }
        }
      )
    }


    let API;
    if (props.rootVenue)
      API = createRequest(services.venue.IMPORT, [activeOrgId, false, , true], payload);
    else
      API = createRequest(services.venue.POST, [], submitData);
    const { context, run } = API

    async function execute() {
      setSubmitting(true)
      run()
        .then((response) => {
          let errorVenues = []
          for (let i in response.data) {
            let venue = response.data[i]
            if (venue.error) {
              errorVenues.push(venue)
            }
          }
          if (errorVenues.length > 0) {
            setAddedVenuesListError(errorVenues)
          }
          else {
            if (props?.rootVenue)
              navigate(`/organization/${activeOrgId}/venues`)
            else {
              navigate(`/organization/${activeOrgId}/venues/${response.data.venueId}`)
              store.dispatch(activeVenueActions.resetInfra());
              streetviewHandler(activeOrgId, response.data.venueId,
                response.data.venueAddress.latitude, response.data.venueAddress.longitude)
            }
          }
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          const apiContext = createErrorContext(context, 'CREATE VENUE', TICKET_CATEGORY.VENUE, err)
          make_custom_toast('error', 'Venue', x.message, true, 'Create Ticket', () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          })
        })
        .finally(() => {
          setSubmitting(false);
        })
    }

    execute()
    // if (!submitData.venueAddress.latitude)
    //   Geocode.fromAddress(submitData.venueAddress.addressLine).then((response) => {
    //     submitData.venueAddress.latitude = response?.results[0]?.geometry?.location?.lat;
    //     submitData.venueAddress.longitude = response?.results[0]?.geometry?.location?.lng;
    //     setPos({ lat: response?.results[0]?.geometry?.location?.lat, lng: response?.results[0]?.geometry?.location?.lng })
    //     execute();
    //   }).catch(() => { execute() })
    // else
    //   execute();
  }

  /// Pre-Validate address field before submitting to Formik's onSubmit
  function onAddressPreValidation(event, handleSubmit, setFieldValue, addedVenuesList = []) {
    // if (event)
    event?.preventDefault();
    let addressValue = document.getElementById("venue-address").value
    if (address === "") {
      setFieldValue("addressLine", addressValue);
    } else {
      setFieldValue("addressLine", address);
    }
    let addressLength = addressValue;
    if (addedVenuesList.length < 1)
      if (addressLength === 0) {
        setAddressAlert("Required");
      } else if (addressLength < 3) {
        setAddressAlert("Minimum 3 Characters are Required");
      } else if (addressLength > 250) {
        setAddressAlert("Maximum 250 Characters are Allowed");
      } else {
        handleSubmit();
      }
    else {
      handleSubmit();
    }
  }



  return { activeOrgId, onAddVenueFormSubmit, onAddressPreValidation, onLoad, onUnmount, activeOrgAddress, activeVenueAddress, permissions, venueId, navigate, submitSuccess, submitError, submitErrorContext, setSubmitSuccess, setSubmitError, map, setMap, streetView, setStreetView, pos, setPos, address, setAddress, addressAlert, setAddressAlert, addedVenuesList, setAddedVenuesList, addedVenuesListError, setAddedVenuesListError, reasonRequired, setReasonRequired };
};

const ReasonInput = ({ reasonRequired }) => {
  return (
    <TextInput
      name="reason"
      label="Reason for disabled state"
      ignoreTouch={reasonRequired}
      isrequired
      placeholder="Reason"
      // overallClasses={`mt-2 min-height--reason-input`}
      noBottomMargin
    />
  )
}

const isButtonDisabled = (addedVenuesList, values) => {
  if (addedVenuesList?.length < 1 && (values?.name?.length < 3 || values?.addressLine?.length < 3))
    return true
  return false
}


const AddVenue = (props) => {
  const { activeOrgId, onAddVenueFormSubmit, onAddressPreValidation, onLoad, onUnmount, activeOrgAddress, activeVenueAddress, streetView, setStreetView, navigate, submitSuccess, submitError, submitErrorContext, setSubmitError, map, pos, setPos, address, setAddress, addressAlert, setAddressAlert, addedVenuesList, setAddedVenuesList, addedVenuesListError, setAddedVenuesListError, reasonRequired, setReasonRequired } = useAddVenue(props);

  const [googleMapView, setGoogleMapView] = useState(GOOGLE_MAP.MAP_VIEW);



  return (
    <div className="AddVenue" data-testid="AddVenue">
      {/* <Heading text="Add Venue" /> */}
      {(activeOrgAddress || activeOrgAddress) &&
        <div className="rounded bg-white shadow-sm border py-2 px-50 mt-1 addVenueBlock">
          <Formik
            initialValues={addVenueForm.initialValues(props, activeOrgAddress, activeVenueAddress)}
            validationSchema={addVenueForm.validationSchema()}
            enableReinitialize
            onSubmit={onAddVenueFormSubmit}>
            {({ values, errors, touched, resetForm, isSubmitting, handleSubmit, validateForm, isValid, setFieldValue, setSubmitting }) => (
              <Form onSubmit={event => { onAddressPreValidation(event, handleSubmit, setFieldValue, addedVenuesList) }}
                className=" d-flex h-100 flex-column justify-content-between">
                {/* <ReportAlert
                  error={submitError}
                  setError={setSubmitError}
                  context={submitErrorContext}
                /> */}
                {/* <AlertBox color="danger" isOpen={submitError} toggle={() => { setSubmitError(null) }}>{submitError}</AlertBox> */}

                {/* New Venue created success modal */}
                <Modal centered isOpen={!!submitSuccess} className="p-5">
                  <ModalBody className="d-flex flex-column align-items-center p-4">
                    <div className="font-weight-bolder">A new venue is created successfully.</div>
                    <div className="pt-3">
                      <Button className="mx-1" color="outline-warning rx-1" onClick={() => { navigate(`/organization/${activeOrgId}/venues/`) }}>
                        All Venues
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Button className="mx-1" color="outline-primary" onClick={() => { navigate(`/organization/${activeOrgId}/venues/${submitSuccess.venueId}/`); }}>
                        Go to new Venue
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal isOpen={!!addedVenuesListError} size="lg" centered  >
                  <ModalHeader className="p-0 m-0 bg-white" toggle={() => {
                    navigate(`/organization/${activeOrgId}/venues`)
                  }} ></ModalHeader>
                  <ModalBody>
                    <div>
                      <h5 className="font-weight-bolder  pb-1  font-dark">Failed {addedVenuesListError?.length > 1 ? "Venues" : "Venue"}</h5>
                      <div id="failed-venues-table">
                        <Table className="table-view fixed-header">
                          <thead>
                            <tr>
                              <th>VENUE NAME</th>
                              <th>VENUE ADDRESS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              !!addedVenuesListError && addedVenuesListError?.map((venue, index) => {
                                return <tr key={`dewnd${index}sdd`}>
                                  <td><div className="d-flex align-items-center">
                                    <Site className="mr-50" />
                                    <span>{venue?.venueName}</span>
                                  </div></td>
                                  <td>{venue?.venueAddress?.addressLine}</td>
                                </tr>
                              })
                            }
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-2 text-center d-flex align-items-center justify-content-center">

                        <span style={{ width: "75%" }}><Warning /> System failed to create following venues, please try and create them again under the Business Organization </span>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button color="primary" className="small-add-button" onClick={() => {
                          navigate(`/organization/${activeOrgId}/venues`)
                        }} >Close</Button>
                      </div>
                    </div>
                  </ModalBody>

                </Modal>

                {/* Add Venue Form */}
                <div>

                  <div className="px-1">
                    <Row>
                      <Col xs={12} md={5}>
                        <div className="min-height--input">
                          <TextInput
                            type="text"
                            name="name"
                            label="Name"
                            isrequired={"true"}
                            placeholder="Name"
                            noBottomMargin
                          />
                        </div>

                        <div className='min-height--input'>
                          <label className="labelfont">State</label>
                          <VenueStateDropdown
                            selectedVenueState={values.state}
                            onChange={option => {
                              setFieldValue("state", Number(option))
                            }}
                          />
                        </div>
                      </Col>

                      <Col xd={12} md={4}>
                        <div className="min-height--address-input">
                          <Label className="autocomplete-label">
                            Address
                          </Label>
                          <span className="text-danger">*</span>
                          {/* Address Autocomplete */}
                          <AutoComplete className={"form-control autocomplete-input " + (addressAlert && "invalid")}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            // onBlur={(e) => {
                            //   Geocode.fromAddress(e.target.value).then((response) => {
                            //     setFieldValue('lat',response?.results[0]?.geometry?.location?.lat);
                            //     setFieldValue('lng',response?.results[0]?.geometry?.location?.lng);
                            //     setPos({lat:response?.results[0]?.geometry?.location?.lat,lng:response?.results[0]?.geometry?.location?.lng});
                            //   })
                            // }}
                            onChange={(e) => {
                              setFieldValue('lat', null);
                              setFieldValue('lng', null);
                              setAddressAlert(null);
                              setAddress(e.target.value);
                            }}
                            id="venue-address"
                            options={{ types: [] }}
                            // defaultValue={(props.rootVenue ? activeOrgAddress.addressLine : activeVenueAddress.addressLine) || ""}
                            value={address}
                            placeholder="Address"
                            onPlaceSelected={
                              place => {
                                const selectLat = place?.geometry?.location?.lat() || 0;
                                const selectLng = place?.geometry?.location?.lng() || 0;
                                // setAddress(place?.formatted_address || place.name);
                                setFieldValue('lat', selectLat);
                                setFieldValue('lng', selectLng);
                                setPos({ lat: selectLat, lng: selectLng });
                                let value = document.getElementById("venue-address").value
                                setAddress(value || place?.formatted_address || place.name);
                                setFieldValue("addressLine", value || place?.formatted_address || place.name)

                              }
                            }
                          />
                          {addressAlert && <span className="text-danger error-field">{addressAlert}</span>}
                        </div>
                        {
                          values.state == VENUE_STATE.DISABLED &&
                          <ReasonInput reasonRequired={reasonRequired} />
                        }
                        {/* <HeightAnimator heightAuto={values.state == VENUE_STATE.DISABLED} transitionTime='200ms'>
                      </HeightAnimator> */}
                        {/* <Select
                      defaultValue={VENUE_TYPE_MAPPING[props.rootVenue?0:1]}
                      styles={reactselectTheme}
                      isDisabled
                      options={VENUE_TYPE_MAPPING}
                      onChange={(e) => setFieldValue("type", Number(e.value))}
                    /> */}
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="google-map-container">
                          {googleMapView == GOOGLE_MAP.MAP_VIEW ? <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '12em' }}
                            defaultCenter={pos}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            zoom={14}
                            options={{
                              disableDefaultUI: true,
                              keyboardShortcuts: false,
                              minZoom: 5
                            }}
                          >
                            {map?.panTo(pos)}
                            <Marker position={pos} />
                            {setTimeout(() => map?.setZoom(12), 10)}
                          </GoogleMap> :
                            <div className="text-center" style={{ height: "12em" }}>
                              {streetView != null ?
                                <img className="ap-img" alt={"No streetview"} src={streetView} onError={() => setStreetView(null)} />
                                : <div className="pt-2">
                                  No Streetview found
                                </div>}
                            </div>
                          }
                          <div className="map-switch-container d-flex ">
                            <div className={`cursor-pointer map-switch-button d-flex leftsideborder ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.STREET_VIEW)}>
                              <StreetViewIcon
                                className={`street-icon ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`}
                                width={12}
                                height={12} />
                            </div>
                            <div className={`cursor-pointer map-switch-button d-flex rightsideborder ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.MAP_VIEW)}>
                              <MapViewIcon
                                className={`map-icon ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`}
                                width={12}
                                height={12} />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* If Site venue */}
                  {
                    props?.rootVenue &&
                    <div className="px-1">
                      <span className={` ${ isButtonDisabled(addedVenuesList,values)?"text-disabled-primary cursor-not-allowed":"text-primary cursor-pointer"} user-select-none`}
                       onClick={() => {
                        if(isButtonDisabled(addedVenuesList,values))
                          return

                        onAddressPreValidation(null, () => { }, setFieldValue)
                        if (Object.keys(errors).length > 0) {
                          if (Object.keys(errors).includes("reason")) {
                            setReasonRequired(true)
                          }
                          return
                        }
                        if (values?.name?.length >= 3 && values?.addressLine?.length >= 3 && addedVenuesList?.length < 10) {
                          let tempList = [...addedVenuesList]
                          tempList.push(values)
                          setAddedVenuesList(tempList)
                          setFieldValue("venuesListLength", tempList.length)
                          setFieldValue("addressLine", "")
                          setFieldValue("lat", 0)
                          setFieldValue("name", "")
                          setFieldValue("reason", "")
                          setFieldValue("lng", 0)
                          setAddress("")
                        }
                        else if (addedVenuesList?.length == 10) {
                          make_custom_toast("error", "Venue", "Maximum 10 Venues can be added.")
                        }

                      }}>
                        Add Another Venue
                      </span>



                      {
                        addedVenuesList?.length > 0 &&
                        <div id="another-venues-table" className="mt-2">
                          <Table className="table-view fixed-header">
                            <thead>
                              <tr>

                                <th style={{ width: "25%" }}>

                                  <span>VENUE NAME</span>
                                </th>
                                <th style={{ width: "40%" }}>
                                  <span>VENUE ADDRESS</span>
                                </th>
                                <th>
                                  <span>STATE</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                addedVenuesList?.map((addedVenue, index) =>
                                  <tr key={`ubwei${index}ds`} className="another-venue-row">
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <Site className="mr-1" />
                                        <span>
                                          {addedVenue?.name}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>{addedVenue?.addressLine}</div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span className="d-flex align-items-center">
                                          <VenueStateIcon selectedSize={20} size={20} active={addedVenue?.state} />

                                          <span className="ml-50">{addedVenue?.state == VENUE_STATE.DISABLED ? addedVenue?.reason : VENUE_STATE_LABEL[addedVenue?.state]}</span>
                                        </span>
                                        <span
                                          class="material-symbols-outlined text-danger cursor-pointer user-select-none close-hover-show"
                                          onClick={() => {
                                            let tempList = [...addedVenuesList]
                                            tempList.splice(index, 1)
                                            setAddedVenuesList(tempList)
                                            setFieldValue("venuesListLength", tempList.length)
                                          }}>
                                          close
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                      }

                    </div>
                  }

                </div>
                {/* Form Submit/Discard Buttons */}
                <div className="mt-2 px-1 d-flex justify-content-end align-items-center">
                  <Button.Ripple color="primary" className="mr-2 small-add-button" disabled={isSubmitting} outline onClick={() => { navigate(`/organization/${activeOrgId}/venues`) }}>Discard</Button.Ripple>
                  <Button.Ripple type="submit" color="primary" className="small-add-button" disabled={isButtonDisabled(addedVenuesList, values) || isSubmitting}>{isSubmitting ? <Spinner size="sm" /> : "Add"}</Button.Ripple>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    </div >
  );
};

AddVenue.propTypes = {
  rootVenue: PropTypes.bool
};

AddVenue.defaultProps = {
  rootVenue: false
};

export default AddVenue;
