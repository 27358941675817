/***
 *
 * Controller class for user.
 * @file OrgList.js
 * @description OrgList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledTooltip, Modal, ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap";
import { ReactComponent as Ticket } from "../../../assets/images/icons/ticket-icon.svg";
import { ReactComponent as Firmware } from "../../../assets/images/icons/Firmware.svg";
import { ReactComponent as MSPIcon } from "../../../assets/images/icons/MSP.svg"
import { ReactComponent as BusIcon } from "../../../assets/images/icons/BUS.svg"
import { EmergencySirenIcon } from "../../../assets/images/icons/Icons";
import { ALARM_OPERATION, FIRMWARE_OPERATION, TICKET_OPERATION } from "../../../utility/tooltip-config-global";
import { LIST_TYPES } from "../../../components/OrgWidget/utils";
import OrgWidget from "../../../components/OrgWidget";
import "./OrgList.scss"
import { MaterialSymbol, VeryFancyLoader } from "../../../components";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { useDispatch, useSelector } from "react-redux";
import createRequest, { services } from "../../../services";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { CatchedWebError } from "../../../configs";
import { useNavigate } from "react-router-dom";
import { breadcrumbActions } from "../../../redux/slices";
import { BUSINESS_VIEW } from "../../../utility/constants";

/// Icons for organization types
export const orgtypesIcons = {
  1: <MSPIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
  2: <MSPIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
  3: <BusIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
};

const Operations = (ticket, alarm, firmware) => {

  return <Row className="m-auto">
    {alarm ? <Col sm={3}>
      <EmergencySirenIcon id="ALARM_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="ALARM_OPERATION">{ALARM_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
    {ticket ? <Col sm={3}>
      <Ticket className="stroke-black" id="TICKET_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="TICKET_OPERATION">{TICKET_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
    {firmware ? <Col sm={3}>
      <Firmware className="fill-black" id="FIRMWARE_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="FIRMWARE_OPERATION">{FIRMWARE_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
  </Row>
}

export const OrgDeleteModal = (props) => {

  const { deleteItem, setDeleteItem, onSubmit } = props;

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [deleting, setDeleting] = useState(false);
  const [deleteText, setDeleteText] = useState("");

  const navigate = useNavigate();

  const deleteIt = (item) => {
    const { context, run } = createRequest(services.organization.DELETE, [item.orgId]);

    setDeleting(true);
    run().then(() => {
      if (onSubmit)
        onSubmit();
      setDeleteItem(null);
      setDeleteText("");
    })
      .catch((err) => {
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(context, item.inviteId ? 'DELETE INVITE' : 'DELETE ORGANIZATION', TICKET_CATEGORY.NETWORK, err)
        make_custom_toast('error', 'Organization', x.message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        })
      }).finally(() => {
        setDeleting(false);
      })

  }

  return (<Modal isOpen={!!deleteItem} centered toggle={() => { if (!deleting) { setDeleteText(""); setDeleteItem(null); } }} >
    <ModalHeader toggle={() => { if (!deleting) { setDeleteText(""); setDeleteItem(null); } }}>Organization Deletion</ModalHeader>
    {deleteItem &&
      <React.Fragment>
        <ModalBody className="px-4">
          <div className="d-flex flex-column align-items-center">
            {deleting && <div className="text-center"><VeryFancyLoader /></div>}
            <div className="text-center">
              <MaterialSymbol className="text-warning display-1" value="info" />
            </div>
            <div className="text-center">You won't be able to revert this. <br /> If you are sure, please enter the organization name <strong>{deleteItem.orgName}</strong> to continue.</div>
            <div className="w-100 pt-1">
              <Input className="w-100" valid={deleteText === deleteItem.name || deleteText === deleteItem.orgName} color="danger" value={deleteText} onChange={(e) => { setDeleteText(e.target.value); }} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0 d-flex justify-content-center pb-3">
          <Button className="mr-2 small-add-button" color="primary" disabled={deleting} onClick={() => { setDeleteText(""); setDeleteItem(null); }}>Cancel</Button>
          <Button color="danger" className="small-add-button" onClick={() => { deleteIt(deleteItem); }} disabled={!(deleteText === deleteItem.orgName) || deleting}>Delete</Button>
        </ModalFooter>
      </React.Fragment>
    }
  </Modal>)

}

const OrgList = () => {
  const SCREEN_ID = "org-dashboard";

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const dispatch = useDispatch();
  const view = useSelector((store) => store.identity.meta.view);


  useEffect(() => {
    dispatch(breadcrumbActions.setData([
      {
        path: `/organization/${activeOrgId}/sub`,
        text: "Organizations",
        active: true
      }]))
  }, []);

  return (
    <div className="OrgList" data-testid="OrgList">
      <div className="mt-1">
        <OrgWidget
          SCREEN_ID={SCREEN_ID}
          listType={view == BUSINESS_VIEW ? LIST_TYPES.BUSINESS_DASH_LIST : LIST_TYPES.ORG_LIST}
          filterReq={{
            OrgStateFilter: true,
            LocationFilter: view != BUSINESS_VIEW
          }}
          showOrgOptions
        />
      </div>
    </div>
  );
};

OrgList.propTypes = {};

OrgList.defaultProps = {};

export default OrgList;
