/***
 *
 * Controller class for user.
 * @file InventoryWidget.js
 * @description InventoryWidget component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./InventoryWidget.scss";
import { Button, Container, Modal, ModalBody, ModalHeader, Progress } from "reactstrap";
import Select from 'react-select';
import { INFRASTRUCTURE_TYPE_COLORS, reactselectThemeWithMargin } from "../../utility/constants";
import APSVG from "../../pages/Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../pages/Infrastructure/InfraList/SwitchSVG";
import { capitalizeSentence, commaSeparated } from "../../utility/Utils";
import Skeleton from "../../pages/Dashboard/Graphs/Skeleton";

// function getContinuousRgbShades(rgbColor, steps) {
//   const [r, g, b] = rgbColor; 

//   const shades = [];
//   const stepR = (255 - r) / steps; // Calculate step size for red
//   const stepG = (255 - g) / steps; // Calculate step size for green
//   const stepB = (255 - b) / steps; // Calculate step size for blue

//   for (let i = 0; i <= steps; i++) {
//     const newR = Math.round(r + (stepR * i));
//     const newG = Math.round(g + (stepG * i));
//     const newB = Math.round(b + (stepB * i));
//     shades.push([newR, newG, newB]); 
//   }

//   return shades;
// }

// // Example usage:
// const originalRgbColor = [255, 165, 0]; // Example RGB value
// const numShades = 4;
// const rgbShades = getContinuousRgbShades(originalRgbColor, numShades);
// const rgbShadesFormatted = rgbShades.map(rgb => `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`)

const selectOptions = [
  {
    label: 'By Model',
    value: 'model'
  },
  {
    label: 'By Type',
    value: 'type'
  }
]

const inventoryDropdownTheme = {
  ...reactselectThemeWithMargin,
  container: (provided, state) => ({
    ...provided,
    marginTop: "0",
    minWidth: '7rem'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    cursor: 'pointer'
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    "&:hover" : {
      border: 'none',
      boxShadow: 'none'
    },
    fontSize: '10px',
    minHeight: '0'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
    cursor: 'pointer'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "0.72rem",
    color: "#444",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? provided?.color : "#111",
    cursor: 'pointer'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#5279CE" : state.isFocused ? "#5279CE1F" : "white",
    cursor: 'pointer'
  }),
}

const displayModels = (models, isModal) => {
  return isModal ? models : models.slice(0,5)
}

const InventoryWidget = (props) => {
  const {
    progressMode,
    setProgressMode,
    totalCount,
    models,
    loading,
    fixedSelection
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false)


  const Content = ({isModal}) => {
    return (
      loading ?
        <div className="InventoryWidgetContent d-flex align-items-top h-100" data-ismodal={isModal} data-isloader={true}>
          <Container className="text-center">
            <Skeleton width="100%" height="30px" />
            <div>
              <Skeleton width="30%" height="10px" />
              <Skeleton width="30%" height="10px" />
            </div>
          </Container>
        </div> :
      <div className="InventoryWidgetContent" data-ismodal={isModal}>
      <div className="d-flex justify-content-between align-items-center">
        <div 
          className="heading"
        >
          {
          `Infrastructure Inventory${fixedSelection ? ` By ${capitalizeSentence(progressMode)}` : ''} (${commaSeparated(totalCount ?? 0)})`
          }
        </div>
        {fixedSelection ? null :
        <Select
          styles={inventoryDropdownTheme}
          classNamePrefix='inv-widget-select'
          isSearchable={false}
          options={selectOptions}
          value={selectOptions.find(item => item.value === progressMode)}
          onChange={(option) => {
            setProgressMode(option.value)
          }}
        />}
      </div>
      <Progress multi>
        {models.map((it, index) => {
          return (
            <Progress
              style={{
                backgroundColor: it.color ?? INFRASTRUCTURE_TYPE_COLORS[it.type],
                height: "12px"
              }}
              key={index} 
              bar 
              value={(it.count / totalCount) * 100}
              >
            </Progress>
          )
        }
        )}
      </Progress>
      <div 
        className="legends mt-1 mb-50 text-center" 
        data-unorganized={isModal}
      >
        {displayModels(models, isModal).map((it, index) => {
          return (
            <div 
              key={index}
              className="d-flex align-items-center"
            >
              <div
                className="legend-color" 
                style={{ backgroundColor: it.color ?? INFRASTRUCTURE_TYPE_COLORS[it.type] }}
              >
                  &nbsp;
              </div>
              <div 
                className={`legend-text${it.infraCategory == 2 ? ' d-flex align-items-center' : ''}`}
              >
                {
                  it.infraCategory == 1
                  ? <APSVG height='18' width='18' className='mr-25'/>
                  : it.infraCategory == 2
                    ? <SwitchSVG height='18' width='18' className='mr-25'/>
                    : null
                }
                {`${(progressMode === 'model' ? it.infraType : it.type) ?? ''} (${commaSeparated(it.count??0)})`}
              </div>
            </div>)
        })}
        {
          !isModal && models?.length > 5 ?
          <div 
            className='legend-text text-end text-primary cursor-pointer'
            onClick={() => setIsModalOpen(true)}
          >
            See more({models.length - 5})
          </div> 
          : null
        }
      </div>
      </div>
    )
  }

  return (
    <div className="InventoryWidget border bg-white p-50 rounded" data-testid="InventoryWidget">
      <Modal 
        isOpen={isModalOpen} 
        centered
        toggle={() => setIsModalOpen(false)}
      >
        <ModalHeader 
          size='lg'
          className='p-0 bg-white'
          toggle={() => setIsModalOpen(false)}
        >

        </ModalHeader>
        <ModalBody>
          <Content isModal/>
          <div className="d-flex justify-content-end">
            <Button.Ripple 
              color='primary' 
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button.Ripple>
          </div>
        </ModalBody>
      </Modal>
      <Content />
    </div>
  );
};

InventoryWidget.propTypes = {};

InventoryWidget.defaultProps = {};

export default InventoryWidget;
