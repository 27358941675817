import PropTypes from "prop-types";
import StaticLayoutRenderer from "../../../StaticLayoutRenderer";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import { motion } from 'framer-motion';
import { DISPLAY_LAYOUT } from "../AvailableLayouts";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import createRequest, { services } from "../../../../../../services";
import { wre } from "../../../../../../services/wre.service";
import { LAYOUT_TYPES } from "../../../constants";
/**
 * Available Layouts List Item
 * @param {{
 *  onAdd: Function,
 *  layout: {
 *     "venueId": number,
 *     "name": string,
 *     "layoutType": 1 | 2 | 3,
 *     "layoutFile": string,
 *     "infraPositions": Array<{
 *       "infra_type_id": number,
 *       "x": number,
 *       "y": number
 *     }>?,
 *     "layoutJson": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     }
 *   }
 * }} props 
 */
const AvailableLayoutsItem = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [img, setImg] = useState(null)
  const [imgLoading, setImgLoading] = useState(true)
  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const [layoutName, setLayoutName] = useState(props.layout.name)
  const [editName, setEditName] = useState(false)
  const textInputRef = useRef(null)
  const blockDrag = ((currentInsertedLayout == LAYOUT_TYPES.IMAGE) || (currentInsertedLayout == LAYOUT_TYPES.SINGLE_LIDAR_SCAN && props.type == DISPLAY_LAYOUT.IMAGE))
  useEffect(() => {
    if ((activeOrgId && props.type === DISPLAY_LAYOUT.IMAGE)) {
      const { run, controller } = createRequest(wre.GET_IMAGE, [activeOrgId, props.layout.id]);
      run()
        .then(response => {
          setImg(response.data);

        })
        .catch(err => {
          setImg("");
        })
        .finally(() => { setImgLoading(false) })
      return () => controller && controller.abort();
    }
  }, []);

  const editLayoutName = () => {
    setEditName(true)
    if (textInputRef && textInputRef.current) {
      textInputRef.current.focus()
    }
    setLayoutName(props.layout.name)
  }

  const updateLayout = () => {
    setEditName(false)
    const payload = {
      name: layoutName.trim()
    }
    const { run, controller } = createRequest(services.wre.EDIT_LAYOUT, [props.layout.id], payload)
    run()
      .then(res => {

        props.onSuccess()
      })
      .catch(err => {

      })
  }

  return (


    <div className="AvailableLayoutsItem p-50  rounded bg-white" style={{ width: "100%" }}>
      <div className="d-flex justify-content-between">
        {
          editName ?
            <div>
              <Input ref={textInputRef} value={layoutName}
                onKeyDown={(e) => {
                  if (e.key == 'Escape') {
                    setEditName(false)
                    setLayoutName(props.layout.name)
                  }
                  if (e.key == 'Enter') {
                    if (layoutName.length > 0)
                      updateLayout()
                  }
                }}
                onChange={(e) => {
                  setLayoutName(e.target.value)
                }} />
            </div>
            : <div className="text-primary" title={props.layout.name}>{layoutName.length > 15 ? layoutName.slice(0, 14) + "..." : layoutName}</div>
        }
        <UncontrolledDropdown>
          <DropdownToggle
            color="white"
            className="material-symbols-outlined text-secondary cursor-pointer layout-options-icon"
            tag={'span'}>more_vert</DropdownToggle>
          <DropdownMenu right className="p-0 " >
            <DropdownItem className="text-primary w-100" onClick={editLayoutName}>Edit</DropdownItem>
            <DropdownItem className="text-danger w-100" onClick={props.onDelete}>Delete</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div
        draggable={!blockDrag}
        className={(blockDrag ? "pointer-not-allowed" : "cursor-grabbing")}
        onDragStart={e => {
          props.draggedItem.current = props.layout
        }}
      >
        {
          props.type == DISPLAY_LAYOUT.SCAN ?
            < div >
              <StaticLayoutRenderer layout={props.layout.layoutJson} width="100%" height="100px" hideBackGrid />
            </div>
            : <div className="d-flex justify-content-center">
              <img draggable={false} height="171px" style={{ paddingTop: "5px", borderRadius: "5px", maxWidth: "100%", objectFit: "contain" }} src={img} alt={props.layout.name} />
            </div>
        }
      </div >
      {/* <div className="d-flex justify-content-end">
        <Button color="flat-primary" size="sm" className="p-0 m-0" onClick={props.onAdd}>
          <span className="material-symbols-outlined">
            add
          </span>
        </Button>
      </div> */}
    </div >

  );
};

AvailableLayoutsItem.propTypes = {
  onAdd: PropTypes.func,
  layout: PropTypes.object
};
AvailableLayoutsItem.defaultProps = {};

export default AvailableLayoutsItem;