import store from "../redux/store";
import REDUX_WORKER from "../redux/workers";
import axios from "axios";
import organization from "./organization.service";
import identity from "./identity.service";
import invite from "./invite.service";
import venue from "./venues.service";
import infra from "./infra.service";
import networks from "./networks.service";
import telemetry from "./telemetry.service";
import devices from "./devices.service";
import fwupgrade from "./fwupgrade.service";
import ticket from "./ticket.service";
import marketplace from "./marketplace.service";
import { wre } from "./wre.service";
import { getToken, refreshToken } from "../authCustom";
import { identityActions } from "../redux/slices";
import { checkSession, getTokenSilently, getTokenWithPopup, logout } from "../auth0";
import { initSocket } from "../redux/workers/critical3.worker";

const createRequest = (service, params = [], data = {}, sendSessionId = true, customId = null, configOptions = {}) => {
  const controller = new AbortController();
  const context = {
    method: service.method,
    url: service.urlBuilder(...params),
    payload: data
  }
  // const dispatch = useDispatch();
  const run = async () => {
    // const token = getToken();
    const token = store.getState().identity.meta.token;

    const execute = async (freshToken) => {
      try {
        const headers = {
          authorization: `Bearer ${freshToken}`
        };

        // for RBAC
        if (sendSessionId) {
          if (customId) {
            headers["session-id"] = customId;
          } else {
            headers["session-id"] = store.getState().activeOrg.data.orgId;
          }
        }
        
        const response = await axios({
          method: service.method,
          url: service.urlBuilder(...params),
          data: data,
          headers: headers,
          ...configOptions
        });
        return response;

      } catch (err) {
        // eslint-disable-next-line eqeqeq
        if (err?.response?.data?.error?.statusCode == 401) {
          try{
            let newToken = await getTokenSilently();
            store.dispatch(identityActions.setToken(newToken));
            initSocket(newToken);
            return execute(newToken);
          }
          catch(err) {
            logout({ logoutParams: { returnTo: `${window.location.origin}/exit.html` } });
          }
        } else {
          throw err;
        }
      }
    };

    if (token === null) {
      const { afterPromise } = REDUX_WORKER.getIdentity(execute);
      return afterPromise;
    } else {
      return execute(token);
    }
  };
  return { context, controller, run };
};

export const services = {
  organization,
  identity,
  invite,
  venue,
  infra,
  networks,
  devices,
  telemetry,
  fwupgrade,
  ticket,
  marketplace,
  wre
};

export default createRequest;