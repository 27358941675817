import { BUSINESS_VIEW, DEPLOYMENT_VIEW, NETWORK_VIEW } from "../../utility/constants";

export const filterObj = {
  "venue-dashboard": {
    [NETWORK_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Issues",
        count: "issues",
      },
      {
        filter: "Offline",
        count: "offline",
      },
    ],

    [DEPLOYMENT_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Pending Tasks",
        count: "pending",
      },
      {
        filter: "Completed",
        count: "completed",
      },
    ],

    [BUSINESS_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Design",
        count: "design",
      },
      {
        filter: "Deployment",
        count: "deployment",
      },
      {
        filter: "Live",
        count: "live",
      },
      {
        filter: "Disabled",
        count: "disabled",
      },
    ],
  },

  "venue-infra": {
    [NETWORK_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Online",
        count: "online",
      },
      {
        filter: "Issues",
        count: "issues",
      },
      {
        filter: "Offline",
        count: "offline",
      },
    ],

    [BUSINESS_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Online",
        count: "online",
      },
      {
        filter: "Issues",
        count: "issues",
      },
      {
        filter: "Offline",
        count: "offline",
      },
    ],

    [DEPLOYMENT_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Not Initialized",
        count: "notInitialized",
      },
      {
        filter: "No Photos",
        count: "noPhotos",
      },
      {
        filter: "Not Installed",
        count: "notInstalled",
      },
      {
        filter: "Not Validated",
        count: "notValidated",
      },
    ],
  },

  "association": {
    [NETWORK_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Online",
        count: "online",
      },
      {
        filter: "Issues",
        count: "issues",
      },
      {
        filter: "Offline",
        count: "offline",
      },
    ],

    [BUSINESS_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Online",
        count: "online",
      },
      {
        filter: "Issues",
        count: "issues",
      },
      {
        filter: "Offline",
        count: "offline",
      },
    ],

    [DEPLOYMENT_VIEW]: [
      {
        filter: "All",
        count: "all",
      },
      {
        filter: "Not Initialized",
        count: "notInitialized",
      },
      {
        filter: "No Photos",
        count: "noPhotos",
      },
      {
        filter: "Not Installed",
        count: "notInstalled",
      },
      {
        filter: "Not Validated",
        count: "notValidated",
      },
    ],
  },
};

export const columnObj = {
  "association": {
    [NETWORK_VIEW]: {
      "Operations": true,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Status": true,
      "Private IP" :true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": false,
      "Release": true,
      "Serial Number": false,
      "Asset Tag": false
    },

    [DEPLOYMENT_VIEW]: {
      "Operations": false,
      "Tasks": true,
      "Room": true,
      "Location": true,
      "Status": true,
      "Private IP" : false,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": false,
      "Clients": false,
      "Connectivity": false,
      "Uptime": false,
      "Release": false,
      "Serial Number": false,
      "Asset Tag": false
    },

    [BUSINESS_VIEW]: {
      "Operations": true,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Status": true,
      "Private IP" :true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": true,
      "Release": true,
      "Serial Number": false,
      "Asset Tag": false
    },
  },
  "venue-dashboard": {
    [NETWORK_VIEW]: {
      Organization: true,
      Operations: true,
      Infrastructure: true,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      Release: true,
      State: true,
      Location: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": false,
      "Serial Number": false,
      "Asset Tag": false
    },

    [DEPLOYMENT_VIEW]: {
      Organization: true,
      Operations: false,
      Infrastructure: false,
      Traffic: true,
      Clients: true,
      Connectivity: true,
      Release: true,
      State: true,
      Location: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": true,
    },

    [BUSINESS_VIEW]: {
      Organization: true,
      Operations: false,
      Infrastructure: false,
      Traffic: false,
      Clients: false,
      Connectivity: false,
      Release: false,
      State: true,
      Location: true,
      "In-Service": true,
      Cost: true,
      "Pending Tasks": false,
    },
  },

  "venue-infra": {
    [NETWORK_VIEW]: {
      "Venue": true,
      "Operations": true,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Status": true,
      "Private IP" :true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": false,
      "Release": true,
      "Serial Number": false,
      "Asset Tag": false
    },

    [BUSINESS_VIEW]: {
      "Venue": true,
      "Operations": true,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Status": true,
      "Private IP" :true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": true,
      "Clients": true,
      "Connectivity": true,
      "Uptime": true,
      "Release": true,
      "Serial Number": false,
      "Asset Tag": false
    },

    [DEPLOYMENT_VIEW]: {
      "Venue": true,
      "Operations": false,
      "Tasks": true,
      "Room": true,
      "Location": true,
      "Status": true,
      "Private IP" : false,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "Radio 2G": false,
      "Radio 5G": false,
      "Radio 6G": false,
      "MAC Address": true,
      "Traffic": false,
      "Clients": false,
      "Connectivity": false,
      "Uptime": false,
      "Release": false,
      "Serial Number": false,
      "Asset Tag": false
    },
  }
}