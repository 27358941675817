import { toast, TypeOptions } from "react-toastify";
import CustomToast from "../components/CustomToasts/CustomToast";


const TOAST_AUTOCLOSE_TIME = 1.5 * 1000 // in milliseconds

const toastConfig = {
  position: "top-right",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  containerId: "A",
  theme: "coloured",

  className: "custom-toast-container ",
  // bodyClassName: "grow-font-size",
  progressClassName: "custom-toast-progress",
}


export default function make_toast(type, message, shouldDisappearAutomatically = false, onClose) {
  let config = { ...toastConfig };
  if (!!onClose) config = { ...toastConfig, onClose: onClose };
  if (!shouldDisappearAutomatically) {
    toast(message, { ...config, type: type });
  } else {
    toast(message, { ...config, type: type, autoClose: 5000 });
  }
}





// --- Look in Portal.scss ----
const customToastStyle = {
  error: "custom-toast-container-error",  // red
  info: "custom-toast-container-info",  //
  success: "custom-toast-container-success"  // white 
}


/** Create an custom toast
 * @param {TypeOptions} type - One of `'info',success','error' 
 * @param {string} title - Title of the toast.
 * @param {string} message - Message to be displayed.
 * @param {boolean} hasAction - Clickable Action associated with the Toast by default its false. 
 * @param {string} actionText - Text to show for any action.
 * @param {function} action - Action to be performed on click of that button.
 */
export function make_custom_toast(type, title, message, hasAction = false, actionText = '', action = () => { }) {

  let config = { ...toastConfig, className: customToastStyle[type], autoClose: (hasAction ? (TOAST_AUTOCLOSE_TIME * 3.5) : TOAST_AUTOCLOSE_TIME) };
  toast(
    <CustomToast
      type={type}
      title={title}
      message={message}
      hasAction={hasAction}
      actionText={actionText}
      action={action}
    />,
    { ...config });

}