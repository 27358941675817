/***
 *
 * Controller class for user.
 * @file ApexChartsTrendLine.js
 * @description ApexChartsTrendLine component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { memo } from "react";
import { Area, AreaChart, Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
// const Chart = lazy(() => import('react-apexcharts'));

const ConnectivityText = ({datetime, percentage, type}) => {
  switch(type) {
    case 'org':
      return (
        <p>
          {datetime ?? ''}, Organization's infrastructure were
          <br/>
          connected {percentage ?? ''}% of the time.
        </p>
      )
    case 'venue':
      return (
        <p>
          {datetime ?? ''}, Venue's infrastructure were
          <br/>
          connected {percentage ?? ''}% of the time.
        </p>
      )
    case 'infra':
      return (
        <p>
          {datetime ?? ''}, Infrastructure was
          <br/>
          connected {percentage ?? ''}% of the time.
        </p>
      )
    default:
      return ''
  }
} 

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bar-graph-tooltip rounded p-50">
        <ConnectivityText 
          datetime={payload[0].payload.datetime}
          percentage={payload[0].payload.connectivity}
          type={payload[0].payload.type}
        />
      </div>
    );
  }

  return null;
};

const ApexChartsTrendLine = memo((props) => {
  const {
    size,
    yAxisProps,
    xAxisProps
  } = props;
//   const options = {
//   legend: {
//       show: false
//   },
//   yaxis: {
//     min: 0
//   },
//   dataLabels: {
//       enabled: false
//   },
//   stroke:{
//     width: 3
//   },
//   colors:props.color,
//   fill: {
//     type:"solid",
//     opacity:0.2
//   },
//   chart: {
//       sparkline:{
//         enabled:true
//       },
//       parentHeightOffset: 0,
//       animations: {
//           enabled: false
//       },
//       zoom:{
//           enabled:false
//       },
//       toolbar: {
//         show: false,
//       },
//   },
//   tooltip: {
//       enabled:false,
//   }

// }

return (
    <>
        {/* {console.log("ApexCharts")} */}
        {/* <Chart options={options} series={props.series} type="area" width={"80px"} height={"40px"} /> */}
        {/* <ResponsiveContainer width="80px" height="40px"> */}
        {props.type==='bar' ? 
        <BarChart
          width={size=== 'lg' ? 750 : 80}
          height={size === 'lg' ? 360 : 40}
          data={props.series}
          margin={{
            top: 1,
            right: 0,
            left: size === 'lg' ? 5 : 0,
            bottom: size === 'lg' ? 30 : 0,
          }}
          barSize={size === 'lg' ? 50 : 20}
          cursor={size === 'lg' ? undefined : 'pointer'}
        >
          <YAxis
            hide
            domain={[0, 100]}
            {...yAxisProps}
          />
          <XAxis 
            hide
            {...xAxisProps}
          />
          {size==='lg' ? 
            <Tooltip 
              content={<CustomTooltip />} 
              cursor={false}
            /> 
          : null}
          {props.seriesTitles?.map((title,index) => {
            return <Bar key={index} dataKey={title} fill={props.color} background={{ fill: `#FBDBDB` }} isAnimationActive={false}/>
          })}
        </BarChart> : 
        <AreaChart
          width={80}
          height={40}
          data={props.series}
          margin={{
            top: 1,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {props.seriesTitles?.map((title,index) => {
            return <Area type="monotone" dataKey={title} stackId="1" stroke={props.color[index]} fill={props.color[index]} strokeWidth="3" isAnimationActive={false}/>
          })}
        </AreaChart>
        }
      {/* </ResponsiveContainer> */}
    </>
);
});

ApexChartsTrendLine.propTypes = {};

ApexChartsTrendLine.defaultProps = {};

export default ApexChartsTrendLine;
