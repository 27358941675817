/***
 *
 * Controller class for user.
 * @file SiteSelector.js
 * @description SiteSelector component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { createContext, Suspense, useContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./SiteSelector.scss";
import createRequest, { services } from "../../services";
import { useSelector } from "react-redux";
import { Input, InputGroup, Spinner, Table } from "reactstrap";
import SearchIconAddon from "../SearchIconAddon";
import { ExpCell } from "../ExpTable";
import { CollapseIcon, ExpandIcon } from "../../assets/images/icons/Icons";
import { ReactComponent as OrgIcon } from "../../assets/images/icons/Organization-Black.svg";
import { ReactComponent as MSPIcon } from "../../assets/images/icons/MSP.svg";
import { ReactComponent as Infrastructure } from "../../assets/images/icons/Infrastructure-SM.svg";
import { BUSINESS, BUSINESS_VIEW, SITE, SUB_VENUE } from "../../utility/constants";
import { Highlight } from "../VenueInfraWidget";
import { VenueIcon } from "../../pages/Venue/VenueTree";
import ColumnHeader from "../ColumnHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupButton from "../GroupButton";

let timer;
const debounce = (func) => {
  clearTimeout(timer);
  timer = setTimeout(func, 800);
}

const VenueSelectContext = createContext(null);

const filterInitial = {
  infraCategory: "ALL",
}

const ExpRow = (props) => {
  const {
    sort,
    search,
    selectedVenue,
    setSelectedVenue,
    setShowModal,
    columns,
    action,
    setSelectedInfrastructure,
    hasAction,
    hideSubVenue
  } = useContext(VenueSelectContext)

  const [expand, setExpand] = useState(props?.venue?.children?.length > 0);
  const [subList, setSubList] = useState(props?.venue?.children ?? []);
  const [hasMore, setHasMore] = useState(true);
  let level = props.level ?? 1;
  let subCount = 0;

  const getList = (offset = 0) => {

    // const { controller, run } = createRequest(
    //   services.telemetry.GET_VENUE_CHILDREN,
    //   [
    //     props.venueId,
    //     offset,
    //     20,
    //     "",
    //     view,
    //     ["MAC Address", "Status"],
    //     "All",
    //     sort,
    //     {},
    //     true,
    //     ,
    //     false
    //   ]
    // );
    const { controller, run } = props?.venue?.venueId == null ?
      createRequest(
        services.telemetry.GET_ORG_VENUE_LIST,
        [
          props?.venue?.orgId,
          offset,
          20,
          props?.search ?? '',
          'All',
          sort,
          undefined,
          {},
          Object.keys(columns),
          BUSINESS_VIEW
        ]
      )
      : createRequest(
        services.telemetry.GET_VENUE_CHILD,
        [
          props?.venue?.venueId,
          offset,
          20,
          props?.search ?? '',
          BUSINESS_VIEW,
          Object.keys(columns),
          "All",
          sort,
          {},
          false,
          false,
          false,
        ]
      );
    run()
      .then((response) => {
        subCount += response.data.length
        if (offset == 0) {
          setSubList([...response.data]);
        } else {
          setSubList((prevState) => [...prevState, ...response.data]);
        }

        if (response.data.length == 20) getList(subCount);
        else setHasMore(false);

      })
      .catch((err) => {
        // let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  };

  useEffect(() => {
    if (props?.venue?.children == null || props?.venue?.children?.length == 0) {
      if (expand) {
        let controller = getList(0);

        return () => {
          controller?.abort();
        };
      } else {
        setSubList([]);
        setHasMore(true);
        subCount = 0;
      }
    }
    else setHasMore(false);
  }, [expand, props.orgId, props.venueId]);

  useEffect(() => {
    setSubList(props?.venue?.children ?? []);
  }, [props?.venue?.children]);



  const firstCell = props.children[0];
  const newChildren = props?.children?.map((element) =>
    element ? React.cloneElement(element, { level: level }) : element
  );
  return (
    <>
      <div className={"ExpRow " + props.className}>
        <ExpCell
          {...firstCell.props}
          expand={expand}
          level={level}
          hasExpandIcon={!props?.venue?.leafNode}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start position-relative">
              {props.expand && expand && (
                <div
                  className={`${firstCell.props.last || firstCell.props.level == undefined
                    ? "fixed-line"
                    : "fixed-line-inner"
                    }`}
                />
              )}

              {/* {  !props?.venue?.leafNode && (hideSubVenue && props?.venue?.venueType!= SITE) ? ( */}
              {  (hideSubVenue ? (props?.venue?.venueType!= SITE && !props?.venue?.leafNode) :!props?.venue?.leafNode )? (
                expand ? (
                  <CollapseIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                ) : (
                  <ExpandIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                )
              ) :
                <div className="empty-space mr-1">
                  &nbsp;
                </div>
              }
              {(((props.venue.highlighted == null && props.venue.venueId != null) ? (search.length == 0) : props.venue.highlighted)) && (props?.venue?.venueType == SUB_VENUE ? !hideSubVenue : true) && (
                <input
                  className={`mr-50 ${props.venue.restricted ? "cursor-not-allowed" : "cursor-pointer"}`}
                  type="radio"
                  checked={selectedVenue?.venueId == props.venue.venueId}
                  disabled={props.venue.restricted}
                  onClick={() => {
                    if (selectedVenue?.venueId == props.venue?.venueId) {
                      setSelectedVenue(null)
                      if (hasAction) {
                        action({ venueId: 0, venueName: "", infraId: 0, infraName: "" })
                        setSelectedInfrastructure({ infraName: "", infraItemId: 0 })
                      }
                    }
                    else {
                      setSelectedVenue(props.venue)
                      if (hasAction) {
                        action({ venueId: props?.venue?.venueId, venueName: props?.venue?.venueName, orgId: props?.venue?.orgId })
                      }
                    }

                    if (!!setShowModal)
                      setTimeout(() => setShowModal(false), 800)
                  }}
                />
              )}
              {firstCell.props.children}
            </div>
          </div>
        </ExpCell>
        {newChildren.slice(1)}
      </div>
      {expand && (
        <>
          <SubList
            {...props}
            subList={subList}
            setSubList={setSubList}
            level={level + 1}
          />
        </>
      )}
    </>
  );
};

const SubList = (props) => {
  const { subList, level } = props;

  const {
    search,
    columns,
    noAddressText,
    hideSubVenue
  } = useContext(VenueSelectContext);


  const activeOrg = useSelector((store) => store.activeOrg.data);
  const permissions = useSelector((store) => store.rbac.permissions);

  return (
    <>
      {subList.map((venue, index) => {
        return (
          <ExpRow
            key={venue.venueId ?? venue.orgId}
            orgId={venue.orgId}
            venueId={venue.venueId}
            level={level}
            venue={venue}
            parentLast={
              props.parentLast
                ? [...props.parentLast, index == subList.length - 1]
                : [index == subList.length - 1]
            }
            className={
              (level === 1 ? 'bg-color--light-blue ' : '') +
              // (venue.venueType == SITE ? "site-bg " : "") +
              ((venue.highlighted != null ? !venue.highlighted : (search.length > 0))
                ? "opacity-50"
                : "")
            }
          >
            <ExpCell
              width="400px"
              showLines={level > 1}
              last={index == subList.length - 1}
              parentLast={
                props.parentLast
                  ? [...props.parentLast, index == subList.length - 1]
                  : []
              }
            >
              {!!venue.venueId ? (
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex py-50"
                    onClick={() => {
                      // if (venue.orgId == activeOrg.orgId && !venue.restricted) {
                      //   if (permissions?.manageVenue?.view)
                      //     navigate(
                      //       `/organization/${venue.orgId}/venues/${venue.venueId}`
                      //     );
                      // } else if (!venue.restricted && permissions?.manageHierarchy?.view)
                      //   navigate(
                      //     `/organization/${venue.orgId}/venues/${venue.venueId}`
                      //   );
                    }}
                  >
                    {!venue.permissionHide && (
                      <div className="d-flex align-items-center ">
                        {/* {view != BUSINESS_VIEW && (
                        statusIcons(venue.zone)
                      )} */}
                        <span>{VenueIcon[venue.venueType]}</span>
                      </div>
                    )}
                    <div
                      className={
                        "d-flex align-items-center "
                        // + (view != BUSINESS_VIEW ? "ml-50" : "")
                      }
                    >
                      <div>
                        <div className="d-flex">
                          <div
                            className={`${(
                              (venue.orgId == activeOrg.orgId
                                ? permissions?.manageVenue?.view
                                : permissions?.manageHierarchy?.view) &&
                              !venue.restricted
                            )
                              ? ""
                              : ""
                              } d-flex align-items-center`}
                          >
                            <Highlight search={search}>
                              {venue.venueName}
                            </Highlight>
                          </div>
                        </div>
                        {!noAddressText && !venue.restricted && (
                          <div
                            className="font-weight-bold"
                            style={{
                              marginTop: "0.2rem",
                              minHeight: "0.5rem",
                              fontSize: "0.80rem",
                            }}
                          >
                            {venue?.venueAddress?.addressLine}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex align-items-center">
                    <div>
                      {venue.orgTypeId == BUSINESS ? <OrgIcon width={22} height={22} />
                        : <MSPIcon width={22} height={22} />}
                    </div>
                    <div className="ml-50">
                      <div>{venue.orgName}</div>
                      {noAddressText ? null :
                        <div
                          style={{
                            marginTop: "0.2rem",
                            minHeight: "0.5rem",
                            fontSize: "0.80rem",
                          }}>
                          {venue?.orgAddress?.addressLine}
                        </div>}
                    </div>
                  </div>
                </div>
              )}
            </ExpCell>
            {!columns.Country ? null :
              <ExpCell width="100px">
                {venue.venueId == null ? null :
                  <div className="d-flex align-items-center">
                    <div
                      className="flag-icon mr-50"
                      style={{
                        backgroundImage: `url( https://flagcdn.com/${venue?.country?.toLowerCase()}.svg )`,
                      }}
                    >
                      &nbsp;
                    </div>
                    <div className="ellipsed-text long-text-div" title={venue?.country ?? ""}><Highlight search={search}>{venue?.country ?? ""}</Highlight></div>
                  </div>
                }
              </ExpCell>
            }
            {!columns.Infrastructure ? null :
              <ExpCell width='150px'
                className="justify-content-end"
              >
                <div className='d-flex align-items-end padding-right--24px'>
                  {venue.venueId == null ? null :
                    <>
                      <span
                        className={`${(venue?.onlineInfras ?? 0) > 0 &&
                          (venue?.totalInfras ?? 0) > 0
                          ? "text-success font-weight--600"
                          : ""
                          } font-size--larger`}
                      >
                        {venue?.onlineInfras != null ? venue?.onlineInfras : "0"}
                      </span>
                      &nbsp;/&nbsp;
                      {venue?.totalInfras != null ? venue?.totalInfras : "0"}
                      <Infrastructure className="make-black-icon ml-50" style={{ width: '14px' }} />
                    </>}
                </div>
              </ExpCell>
            }
          </ExpRow>
        );
      })}
    </>
  );
};

const SiteSelector = (props) => {
  const { selectedVenue, setSelectedVenue, setShowModal,
    noHeading,
    columns = { VenueAddress: true, Country: true },
    noAddressText,
    anyVenue,
    propOrgId,
    hasAction,
    action,
    setSelectedInfrastructure,
    showFilters,
    showVenueOnlyText,
    modalTitle,
    hideSubVenue
  } = props;
  const activeOrg = useSelector(store => store.activeOrg.data);
  const view = useSelector(store => store.identity.meta.view);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [sort, setSort] = useState({
    orderBy: "Name",
    order: "ASC"
  });

  const [filter, setFilter] = useState("All")
  const [siteCount, setSiteCount] = useState({
    all: 0,
    issues: 0,
    offline: 0
  })
  const [loading, setLoading] = useState(true);

  const filterObj = [];

  const fetchVenue = (offset = 0, orgId) => {
    setLoading(true);
    const { run } = createRequest(services.telemetry.GET_ORG_VENUE_LIST, [
      orgId ?? activeOrg.orgId,
      offset,
      20,
      encodeURIComponent(search),
      filter,
      sort,
      "Tree",
      {},
      Object.keys(columns),
      view,
      false,
      undefined,
      anyVenue ? false : true
    ]);

    run().then(response => {
      setHasMore(response.data.length >= 20)
      if (offset == 0)
        setList([...response.data]);
      else
        setList(prevState => [...prevState, ...response.data]);
    })
      .finally(() => {
        setLoading(false);
      })
  }
  const fetchVenueCount = (orgId) => {
    setLoading(true);
    const { run } = createRequest(services.telemetry.GET_ORG_VENUE_LIST_COUNT, [
      orgId ?? activeOrg.orgId,
      encodeURIComponent(search),
      view

    ]);

    run().then(response => {
      setSiteCount(
        {
          "issues": response.data.issues,
          "offline": response.data.offline,
          "all": response.data.all
        }
      )
    })
      .finally(() => {
      })
  }


  useEffect(() => {
    fetchVenue(0, propOrgId);
    fetchVenueCount(propOrgId)
    setList([]);
  }, [propOrgId, activeOrg, search, sort, filter])


  return (
    <div className="SiteSelector SingleInfraSelector" data-testid="SingleInfraSelector">
      {noHeading ? <></> : <>
        <h5 className="font-weight-bolder mt-50">{modalTitle ? modalTitle : "Select Site Venue"}</h5>
        <hr className="mb-2" />
      </>}

      <div className={`d-flex justify-content-between`}>
        <div className="d-flex">
          <div>
            <InputGroup
              className="input-group-merge"
              style={{ width: showFilters ? "20rem" : "510px" }}
            >
              <Input
                type="text"
                placeholder="Search for Venue"
                style={{ height: "2.4rem" }}
                defaultValue={search}
                onChange={(e) => {
                  if (
                    e.target.value.trim().length > 1 ||
                    e.target.value.trim().length === 0
                  ) {
                    debounce(() => {
                      setSearch(e.target.value);
                      setHasMore(true);
                    });
                  }
                  else {
                    debounce(() => {
                      setSearch("");
                      setHasMore(true);
                    });
                  }
                }}
              />
              <SearchIconAddon />
            </InputGroup>
          </div>
          {
            showFilters &&
            <GroupButton className="mx-1">
              <div className={"grp-btn-custom " + (filter == "All" ? "active" : "")} onClick={() => { setFilter("All") }} >All ({siteCount?.all})</div>
              <div className={"grp-btn-custom " + (filter == "Issues" ? "active" : "")} onClick={() => { setFilter("Issues") }} >Issues ({siteCount?.issues})</div>
              <div className={"grp-btn-custom " + (filter == "Offline" ? "active" : "")} onClick={() => { setFilter("Offline") }} >Offline ({siteCount?.offline})</div>
            </GroupButton>
          }
          {/* <div className="ml-50">
            <GroupButton>
              {filterObj.map((filter, index) => {
                return (
                  <div
                    className={
                      "grp-btn-custom " +
                      ((mainFilter == filter.filter && !filterActive)
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      setFilterActive(false);
                      setMainFilter(filter.filter);
                      fetchVenue(0, filter.filter, filterInitial);
                      // getCount();
                      setFilterData(filterInitial);
                      setFilterSelection(filterInitial);
                      setList([]);
                    }}
                    key={`${index}-venue-infra`}
                  >
                    {filter.filter} ({filterCount[filter?.count] ?? 0})
                  </div>
                );
              })}
            </GroupButton>
          </div>  
          <div>
            <FilterButton
              className="ml-50"
              size={22}
              active={filterActive}
              onClick={() => setShowFilter(true)}
              style={{ padding: "0.36rem" }}
            />
          </div> */}
        </div>
      </div>

      {/* <FilterSetter
        showFilter={showFilter}
        elements={() => [
          <CategoryFilter
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
          <ConnectedFilter
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
          <InfraTypeFilter 
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
        ]}
        handleApplyClick={() => {
          setMainFilter("All");
          setHasMore(true);
          fetchVenue(0, "All", filterSelection);
          setList([]);
          // getCount();
          setFilterData(filterSelection);
          if (lodash.isEqual(filterSelection, filterInitial))
            setFilterActive(false);
          else setFilterActive(true);
          setShowFilter(false);
        }}
        handleClearAll={() => {
          setHasMore(true);
          setFilterActive(false);
          setFilterSelection(filterInitial);
          setFilterData(filterInitial);
          fetchVenue(0, mainFilter, filterInitial);
          setList([]);
          // getCount();
          setShowFilter(false);
        }}
        setShowFilter={setShowFilter}
      /> */}
      <VenueSelectContext.Provider
        value={{
          sort: sort,
          search: search,
          selectedVenue: selectedVenue,
          setSelectedVenue: setSelectedVenue,
          setShowModal: setShowModal,
          columns: columns,
          noAddressText: noAddressText,
          hasAction: hasAction,
          action: action,
          setSelectedInfrastructure: setSelectedInfrastructure,
          hideSubVenue:hideSubVenue
        }}
      >
        <InfiniteScroll
          dataLength={list.length}
          next={() => {
            fetchVenue(list.length, propOrgId)
          }}
          hasMore={hasMore}
          loader={<></>}
          scrollableTarget="single-infra-selector"
          endMessage={<></>}
        >
          <div className="mt-1" id="single-infra-selector">
            <Table className="table-view fixed-header">
              <thead>
                <tr>
                  <th style={{ minWidth: "460px" }}><ColumnHeader header="Name" attribute="Name" sort={sort} setter={setSort} /></th>
                  {!columns.Country ? null :
                    <th style={{ minWidth: "100px" }}><ColumnHeader header="Country" attribute="Country" sort={sort} setter={setSort} /></th>
                  }
                  {!columns.Infrastructure ? null :
                    <th style={{ minWidth: "100px" }}>
                      <ColumnHeader
                        right
                        header="Infrastructure"
                        attribute="Infrastructure"
                        sort={sort}
                        setter={setSort}
                      />
                    </th>
                  }
                </tr>
              </thead>

              <tbody>
                {list && list?.length > 0 ? (
                  <SubList
                    level={1}
                    subList={list}
                    setSubList={setList}
                  />
                ) : loading ? (
                  <tr>
                    <td
                      className="text-center p-3"
                      style={{ height: "45vh", backgroundColor: "white" }}
                      colSpan={20}
                    >
                      <Spinner color="primary" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className="text-center p-3"
                      style={{ height: "40vh", backgroundColor: "white" }}
                      colSpan={4}
                    >
                      {(showFilters || showVenueOnlyText) ? "No Venue Found" : "No Venue/Infra Found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </VenueSelectContext.Provider>
    </div>
  );
};

SiteSelector.propTypes = {};

SiteSelector.defaultProps = {};

export default SiteSelector;
