/***
 *
 * Controller class for user.
 * @file Notification.js
 * @description Notification component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useRef } from "react";
// import PropTypes from 'prop-types';
import "./Notification.scss";
import { Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, Spinner, Table, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, Popover } from "reactstrap";
import { SearchIconAddon, FilterSetter, ColumnHeader } from "../../../components";
import FilterButton from "../../../components/FilterButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { breadcrumbActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import blazer from "../../../services/blazer.service";
import { ALERT_LOW, ALERT_MED, ALERT_HIG, SCROLL_MORE_TEXT, BUSINESS, MSP } from "../../../utility/constants";
import { AggregateVenueFilter, CumulativeInfrasFilter, NotificationImpactFilter, NotificationTypeFilter, OrgFilter } from "../../Filters/filters";
import { dateTimeFormatter, getTimeZone } from "../../../utility/Localization";
import InfiniteScroll from "react-infinite-scroll-component";
import { CatchedWebError } from "../../../configs";
import { timeDiff } from "../../Infrastructure/SwitchOverview";
import lodash from "lodash-es"
import APSVG from "../../Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../Infrastructure/InfraList/SwitchSVG";
import { ReactComponent as Venue } from "../../../assets/images/icons/Venue.svg";
import { ReactComponent as MSPIcon } from "../../../assets/images/icons/MSP.svg";
import { ReactComponent as OrgIcon } from "../../../assets/images/icons/Organization-Black.svg";
import GroupButton from "../../../components/GroupButton";
import { cmpCol, getColumns, setColumns } from "../../../utility/colSaver";
import SidePanel from "../../../components/SidePanel";
import ColSelector from "../../../components/ColSelector";
import { ReactComponent as TicketIcon } from "../../../assets/images/icons/ticket-icon.svg";
// import {ReactComponent as NotificationIcon} from "../../assets/images/icons/notification.svg";
import notificationSvg from "../../../assets/images/icons/notification.svg";
import { EmergencySirenIcon } from "../../../assets/images/icons/Icons";
import { ReactComponent as WarningIcon } from "../../../assets/images/icons/WarningWhiteExclamation.svg";
import { ReactComponent as OrangeWarningIcon } from "../../../assets/images/icons/OrangeWarning.svg";
import AlarmDesc from "../../../components/AlarmDesc";
import { Tooltip } from "react-tooltip";
import LinkWrapper from "../../../components/LinkWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { ReactComponent as CsvIconGrey } from "../../../assets/images/icons/xls_icon_grey.svg";
import { ReactComponent as CsvIcon } from "../../../assets/images/icons/xls_icon.svg";
import { downloadSpreadsheet, replaceParam } from "../../../utility/Utils";
import NotiAcknowledgeModal from "../../../components/NotiAcknowledgeModal";
import DynamicComponent from "../../../components/DynamicComponent";

const alertColor = {
  [ALERT_LOW]: "success",
  [ALERT_MED]: "warning",
  [ALERT_HIG]: "danger"
}

const alertText = {
  [ALERT_LOW]: "Minor",
  [ALERT_MED]: "Major",
  [ALERT_HIG]: "Critical"
}

export const summaryPopoverStyle = `
.noti-summary-popover > .popover.bs-popover-auto[x-placement^=bottom] .arrow:after {
  border-bottom-color: white
}
.noti-summary-popover > .popover {
  border-radius: 0.358rem
}
.noti-summary-popover > .popover .popover-body {
  border-top-color: #ebe9f1;
  border-radius: 0.358rem
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 0;
  border-width: 0.1rem 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
`
const filterInitial = {
  alertImpact: [],
  alertType: [],
  infras: [],
  venues: [],
  orgs: []
  // venueChildren: false
}

export const ticketLink = (noti, rbac) => {
  let link = null;
  if (noti?.alert_type === "ticket" && rbac?.data?.level<3 && rbac?.permissions?.manageTicket?.view)
    link = `/organization/${noti.org_id}/support/${noti?.alert_id}`
  return link
}

export const affectsLink = (noti) => {
  let link = null;
  if (noti?.org_id != null) {
    if (noti?.affected_entities != null && noti?.affected_entities[0]?.venue_id != null)
      link = `/organization/${noti.org_id}/venues/${noti?.affected_entities[0]?.venue_id}`
    else if (noti?.is_active && noti?.affected_entities != null && noti?.affected_entities[0]?.infra_id != null)
      link = `/organization/${noti.org_id}/infra/${noti?.affected_entities[0]?.infra_id}/`
  }
  return link;
}

const notiOrgLink = (noti) => {
  let link = null
  // if (noti.alert_type === 'notification' &&
  //   (!!noti?.affected_entities &&
  //     noti.affected_entities.length === 1 &&
  //     noti?.affected_entities[0]?.org_id != null &&
  //     noti?.affected_entities[0]?.venues.length === 1 &&
  //     noti?.affected_entities[0]?.venues[0].venue_id != null)) {
  //   link = `/organization/${noti?.affected_entities[0]?.org_id}`
  // }
  // else if (!!noti?.venue_name && noti?.org_id != null && noti?.venue_id != null) {
  //   link = `/organization/${noti.org_id}`
  // }

  if(noti.org_id != null)
    link = `/organization/${noti.org_id}`

  return link
}

export const notiVenueLink = (noti) => {
  let link = null
  if (noti.alert_type === 'notification' &&
    (!!noti?.affected_entities &&
      noti.affected_entities.length === 1 &&
      noti?.affected_entities[0]?.org_id != null &&
      noti?.affected_entities[0]?.venues.length === 1 &&
      noti?.affected_entities[0]?.venues[0].venue_id != null)) {
    link = `/organization/${noti?.affected_entities[0]?.org_id}/venues/${noti?.affected_entities[0]?.venues[0].venue_id}`
  }
  else if (!!noti?.venue_name && noti?.org_id != null && noti?.venue_id != null) {
    link = `/organization/${noti.org_id}/venues/${noti?.venue_id}`
  }
  return link
}

const Notification = () => {
  const SCREEN_ID = "notifi-dashboard";
  const initColumns = {
    Type: true,
    Affects: true,
    Summary: true,
    Organization: true,
    Venue: true,
    Created: true,
    Severity: true,
  }
  const initMount = useRef(true);
  const allCheckRef = useRef(null);

  const view = useSelector(store => store.identity.meta.view);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const rbac = useSelector(store => store?.rbac);
  const identity = useSelector(store => store.identity);

  const [params, setParams] = useSearchParams();
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState(() => {
    let filterParam = params.get('filter');
    if (filterParam != null) {
      let parsedFilter = JSON.parse(filterParam)
      return { ...filterInitial, ...parsedFilter }
    } else {
      return filterInitial
    }
  });
  const [filterActive, setFilterActive] = useState(() => {
    return !lodash.isEqual(filterInitial, filterData)
  });
  const [filterSelection, setFilterSelection] = useState(
    filterData
  );
  const [historical, setHistorical] = useState(() => {
    const histParam = params.get('historical')
    const histVal = histParam == 'true' ? true : false
    replaceParam(setParams, 'historical', histVal, { replace: true })
    return histVal
  });
  const [search, setSearch] = useState("");
  const [popover, setPopover] = useState(null);
  const [ackModal, setAckModal] = useState(null);
  const [ackLoading, setAckLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({})
  const [notification, setNotification] = useState([]);
  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: "created_at"
  });
  const [cols, setCols] = useState(cmpCol(initColumns, getColumns(SCREEN_ID, view) ?? {}));
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const [notiController, setNotiController] = useState(null)
  const [isSelector, setIsSelector] = useState(false)
  const [selected, setSelected] = useState(new Set())
  const [excluded, setExcluded] = useState(new Set())
  const [areAllSelected, setAreAllSelected] = useState(false)
  const [downloadingList, setDownloadingList] = useState(false)
  const [exportItemHovered, setExportItemHovered] = useState(false)

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrg = useSelector(store => store.activeOrg.data);
  const navVenue = useSelector(store => store.activeOrg.meta.navVenue);

  const navigate = useNavigate()
  const dispatch = useDispatch();
  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  const refreshList = () => {
    setNotification([]);
    setLoading(true);
    getNotification(filterData, 0)
  }

  const resetAcknowledgement = () => {
    setAckLoading(false)
    setAckModal(false)
    setIsSelector(false)
    setAreAllSelected(false)
    setSelected(new Set())
    setExcluded(new Set())
  }

  const getNotification = (filter = filterData, from = notification.length, orgChildren = true) => {
    let apiCaller;
    if (navVenue > 0)
      apiCaller = createRequest(services.telemetry.GET_VENUE_ALERTS, [navVenue, from, 20, search, historical ? "acknowledge" : "open", , sort, {...filter}, true]);
    else {
      let filterOrgs = [...new Set(filter?.orgs?.map(it => it.orgId) ?? [])]
      apiCaller = createRequest(blazer.ORG_ALERT, [activeOrgId, from, 20, search, historical ? "acknowledge" : "open", sort, { ...filter, orgs: filterOrgs }, true, orgChildren])
    }
    setShowFilter(false);
    notiController?.abort()
    setNotiController(apiCaller?.controller)
    apiCaller?.run().then((response) => {
      if (from == 0)
        setNotification(response.data.response)
      else
        setNotification(prevState => [...prevState, ...response.data.response]);
      let newHasMoreValue = response.data.response.length < 20 ? false : true
      if (response.data.response.length < 20)
        setHasMore(newHasMoreValue);
      else
        setHasMore(newHasMoreValue)
      setCount({ historical: response?.data?.historicalCount ?? response?.data?.historical ?? 0, actionable: (response?.data?.totalCount ?? response?.data?.actionable ?? 0) })
      
      if(isSelector && (search != '' || !lodash.isEqual(filter, filterInitial))) {
        if(from === 0) {
          if(areAllSelected) {
            setSelected(prevSelectionState => {
              const newSelectionState = new Set()
              response?.data?.response?.forEach(noti => {
                const notiId = noti?.id
                if(!excluded.has(notiId)) {
                  newSelectionState.add(notiId)
                }
              })
              return newSelectionState
            })
            // if(excluded.size > 0 || newHasMoreValue === true)
            setAreAllSelected(false)
            setExcluded(new Set())
          }
        } else {
          // if(areAllSelected) {
          //   setSelected(prevSelectionState => {
          //     const newSelectionState = new Set()
          //     response?.data?.response?.forEach(noti => {
          //       newSelectionState.add(noti?.id)
          //     })
          //   })
          // }
        }
      }
    })
      .finally(() => {
        setLoading(false)
        setNotiController(null)
      });
  }

  const ackNotification = (ids, selectAll, excludedIds, children = true) => {
    const idsArray = [...ids]
    const excludedIdsArray = [...excludedIds]
    const { context, run } = createRequest(
      blazer.ACK_ALERT,
      [
        children
      ],
      {
        id: idsArray,
        acknowledgeAll: selectAll,
        excludedId: excludedIdsArray
      }
    )
    setAckLoading(true);
    run()
      .then(() => {
        refreshList();
        resetAcknowledgement();
        //no need for success toast message currently
        // make_custom_toast(
        //   'success',
        //   idsArray.length > 1 ? 'Bulk Acknowledge' : 'Alarm Acknowledgement',
        //   idsArray.length > 1 ? 'Bulk acknowledgement successful' : 'Acknowledgement successful'
        // )
      })
      .catch((err) => {
        const apiContext = createErrorContext(
          context,
          "Deleting Infrastructure",
          TICKET_CATEGORY.NETWORK,
          err
        );
        make_custom_toast(
          'error',
          idsArray.length > 1 ? 'Bulk Acknowledge' : 'Alarm Acknowledgement',
          (new CatchedWebError(err)).message,
          true,
          'Create Ticket',
          () => {
            navigate(
              `/organization/${activeOrg.orgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        )
      })
      .finally(() => {
        setAckLoading(false);
      })
  }

  const handleDownload = () => {
    // orgId, search, status, cols, sort, filter,  children
    const apiURL = blazer.DOWNLOAD_ORG_ALERT.urlBuilder(...[
      activeOrgId,
      search,
      historical ? "acknowledge" : "open",
      Object.keys(cols),
      sort,
      { ...filterData, orgs: filterData?.orgs?.map(it => it.orgId) },
      true
    ])
    const fileName = `Notifications-(${historical ? "Historical" : "Actionable"})-(${activeOrgId}).xlsx`;
    setDownloadingList(true);
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
    .catch(err => {
      make_custom_toast('error', 'Export Notifications', (new CatchedWebError(err)).message)
    })
      .finally(() => {
        setDownloadingList(false)
      })
    }
    
    const handleAllCheck = () => {
    setAreAllSelected(false)
    if(search != '' || filterActive) {
      let currentDataState = 'none'
      let counter=0
      for(const noti of notification) {
        if(selected.has(noti?.id)) {
          counter++
          currentDataState = 'partial'
        }
      }
      if(counter === notification.length && !hasMore)
        currentDataState = 'full'
      if(currentDataState === 'none') {
        setSelected(() => {
          const newSelectionState = new Set()
          notification?.forEach(noti => {
            const notiId = noti?.id
            newSelectionState.add(notiId)
          })
          setExcluded(new Set())
          return newSelectionState
        })
      } else {
        setSelected(prevSelectionState => {
          const newSelectionState = new Set(prevSelectionState)
          notification?.forEach(noti => {
            const notiId = noti?.id
            newSelectionState.delete(notiId)
          })
          return newSelectionState
        })
        setExcluded(new Set())
      }
    } else {
      if (areAllSelected || selected.size > 0) {
        setAreAllSelected(false)
        setSelected(new Set())
        setExcluded(new Set())
      } else {
        setAreAllSelected(true)
        setSelected(new Set())
        setExcluded(new Set())
      }
    }
  }

  useEffect(() => {
    let currParamVal = params?.get("historical")
      if(['true','false'].includes(currParamVal)) {
        setHistorical(currParamVal == 'true' ? true : false)
      } else {
        replaceParam(setParams, 'historical', false, { replace: true });
      }
    }, [params, setParams]);

  useEffect(() => {
    if (!!allCheckRef.current) {
      if(search != '' || filterActive) {
        const checkShowPartial = () => {
          let showPartial = false
          for(const noti of notification) {
            if(selected.has(noti?.id)) {
              showPartial = true
              break
            }
          }
          return showPartial
        }
        if(selected.size > 0 && selected.size === notification.length && !hasMore) {
          let completelySelected = true
          for(const noti of notification) {
            if(!selected.has(noti?.id)) {
              completelySelected = false
              break
            }
          }
          if(completelySelected) {
            allCheckRef.current.checked = true
            allCheckRef.current.indeterminate = false
          } else {
            const showPartial = checkShowPartial()
            if(showPartial) {
              allCheckRef.current.checked = false
              allCheckRef.current.indeterminate = true
            } else {
              allCheckRef.current.checked = false
              allCheckRef.current.indeterminate = false
            }
          }
        } else if(selected.size > 0) {
          const showPartial = checkShowPartial()
          if(showPartial) {
            allCheckRef.current.checked = false
            allCheckRef.current.indeterminate = true
          } else {
            allCheckRef.current.checked = false
            allCheckRef.current.indeterminate = false
          }
        } else {
          allCheckRef.current.checked = false
          allCheckRef.current.indeterminate = false
        }
      } else {
        if (
          (areAllSelected && excluded.size === 0) ||
          (!hasMore && selected.size === (count?.actionable ?? 0))
        ) {
          allCheckRef.current.checked = true
          allCheckRef.current.indeterminate = false
        } else {
          if (areAllSelected || selected.size > 0) {
            allCheckRef.current.checked = false
            allCheckRef.current.indeterminate = true
          } else {
            allCheckRef.current.checked = false
            allCheckRef.current.indeterminate = false
          }
        }
      }
    }
  }, [
    areAllSelected,
    excluded,
    hasMore,
    selected,
    count?.actionable,
    search,
    filterActive,
    notification
  ])


  useEffect(() => {
    let newParams = new URLSearchParams(window.location.search);
    newParams = Object.fromEntries(newParams);
    const filterObj = { ...filterData };
    filterObj.orgs = filterObj.orgs.map(org => org.orgId)
    const infraItemIds = [], infraNames = [];
    for (const infra in filterData.infras) {
      infraItemIds.push(infra.infraItemId)
      infraNames.push(infra.infraName)
    }
    newParams.filter = JSON.stringify(filterData)
    setParams(newParams, { replace: true })
  }, [filterData])

  useEffect(() => {
    setNotification([]);
    setHasMore(true);
    setLoading(true);
    getNotification(filterData, 0);
  }, [search, historical, sort])

  useEffect(() => {
    if (initMount.current)
      initMount.current = false
    else
      setCols({ ...initColumns, ...getColumns(SCREEN_ID, view) ?? {} })
  }, [view])

  useEffect(() => {
    dispatch(breadcrumbActions.setData([{
      text: "Notifications",
      active: true
    }]))

  }, [])

  return (
    <div className="Notification mt-1" data-testid="Notification">
      <div className="rounded border shadow bg-white p-1">
        <SidePanel
          isOpen={isColumnSelectorOpen}
          setIsOpen={setIsColumnSelectorOpen}>
          <ColSelector cols={cols} setCols={setCols} setIsOpen={setIsColumnSelectorOpen} onApply={(newCol) => {
            setColumns(SCREEN_ID, newCol, view);
          }} />
        </SidePanel>

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="search-box mr-1" style={{ width: "310px" }}>
              <InputGroup className="input-group-merge">
                <Input autoFocus type="text" placeholder="Search" style={{ height: '2.4rem' }} onChange={
                  (e) => {
                    debounce(() => setSearch(e.target.value));
                  }
                } />
                <SearchIconAddon />
              </InputGroup>
            </div>
            <GroupButton>
              <div className={"grp-btn-custom " + (!historical ? "active" : "")} onClick={() => { if (!loading) replaceParam(setParams, 'historical', false, { replace: true }) }}>Actionable ({count?.actionable ?? 0})</div>
              <div className={"grp-btn-custom " + (historical ? "active" : "")}
                onClick={() => {
                  if (!loading && !ackLoading) {
                    replaceParam(setParams, 'historical', true, { replace: true })
                    resetAcknowledgement()
                  }
                }}>Historical ({count?.historical ?? 0})</div>
            </GroupButton>
            <FilterButton className="ml-50" active={filterActive} size={22} onClick={() => setShowFilter(true)} style={{ padding: '0.36rem' }} />
            <FilterSetter setShowFilter={setShowFilter}
              showFilter={showFilter}
              elements={() => {
                return [
                  <NotificationTypeFilter filter={filterSelection} setFilter={setFilterSelection} />,
                  <NotificationImpactFilter filter={filterSelection} setFilter={setFilterSelection} />,
                  <OrgFilter hide={navVenue > 0} filter={filterSelection} setFilter={setFilterSelection} />,
                  <AggregateVenueFilter hide={navVenue > 0} filter={filterSelection} setFilter={setFilterSelection} />,
                  <CumulativeInfrasFilter hide={navVenue > 0} filter={filterSelection} setFilter={setFilterSelection} />
                ]
              }}
              disabled={!Object.keys(filterSelection).length}
              handleApplyClick={() => {
                setHasMore(true);
                if (lodash.isEqual(filterSelection, filterInitial))
                  setFilterActive(false)
                else
                  setFilterActive(true);
                setFilterData(filterSelection);
                getNotification(filterSelection, 0);
              }}
              handleClearAll={() => {
                setHasMore(true);
                setFilterActive(false);
                setFilterData(filterInitial);
                setFilterSelection(filterInitial);
                getNotification(filterInitial, 0);
              }}
            />
          </div>

          <div className="d-flex">
            <button className="p-0 refresh-button btn bg-white" onClick={refreshList}>
              <div className="d-flex align-items-center justify-content-center">
                <div className="material-symbols-outlined padded-icon">Cached</div>
              </div>
            </button>
            {isSelector ? (
              <div
                className="d-flex align-items-center"
              >
                <div
                  className="bg-primary rounded py-50 px-1 mx-1 text-white d-flex align-items-center justify-content-between"
                >
                  <div
                    className="selection-count-div"
                  >
                    {areAllSelected ? ((count?.actionable ?? 0) - excluded.size) : selected.size} Selected
                  </div>
                  <div
                    className={`d-flex align-items-center ml-1 ${ackLoading || (!areAllSelected && selected.size == 0) ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                    onClick={() => {
                      if (!areAllSelected && selected.size == 0) return
                      setAckModal(true)
                    }}
                  >
                    <span
                      className="material-symbols-outlined acknowledgement-icon"
                    >
                      task_alt
                    </span>
                    &nbsp;
                    Ack
                  </div>
                </div>
                <div
                  className="d-flex align-items-center border rounded px-1 cursor-pointer bg-primary text-white h-100"
                  onClick={resetAcknowledgement}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <UncontrolledDropdown
                direction="down"
              >
                <DropdownToggle
                  color="white"
                  className="h-100 p-0 d-flex align-items-center"
                  size='sm'
                >
                  <span className="material-symbols-outlined text-primary">more_vert</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="w-100 d-flex align-items-center"
                    disabled={downloadingList}
                    onClick={handleDownload}
                    onMouseEnter={() => setExportItemHovered(true)}
                    onMouseLeave={() => setExportItemHovered(false)}
                    title={`Export List\n(maximum limit: 5000)`}
                  >
                    <DynamicComponent
                      components={[CsvIcon, CsvIconGrey]}
                      condition={exportItemHovered}
                      height={18}
                      width={24}
                      className={`${downloadingList
                        ? "list-download-icon--disabled"
                        : "cursor-pointer"
                        }`}
                      disabled={downloadingList}
                    />
                    Export
                  </DropdownItem>
                  {!historical ?
                    <DropdownItem
                      className="w-100 d-flex align-items-center"
                      onClick={() => setIsSelector(true)}
                    >
                      <div
                        className="d-flex align-items-center"
                      >
                        <span
                          className="material-symbols-outlined"
                        >
                          stacks
                        </span>
                      </div>
                      Bulk Ack
                    </DropdownItem> : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
        </div>

        {/* <Modal centered isOpen={!!ackModal} toggle={() => { if (!ackLoading) setAckModal(null) }}>
          <ModalBody className="text-center p-1">
            <div className="text-center">
              <span className="material-symbols-outlined text-warning display-2">error</span>
            </div>
            <h3 className="font-weight-bolder mt-1 mb-2">Are you sure?</h3>
            Acknowledging this notification, will move it to historical view.
            <div className="d-flex justify-content-center mt-2">
              <Button color="danger" outline onClick={() => { if (!ackLoading) setAckModal(null) }}>Cancel</Button>
              <Button color="primary" className="ml-1" onClick={() => { ackNotification(ackModal) }}>Yes, Acknowledge</Button>
            </div>
          </ModalBody>
        </Modal> */}
        <NotiAcknowledgeModal
          isOpen={!!ackModal}
          onToggle={() => { if (!ackLoading) setAckModal(false) }}
          loading={ackLoading}
          selectionCount={selected.size}
          onAckClick={() => { ackNotification(selected, areAllSelected, excluded) }}
        />
        <div>
          <InfiniteScroll
            dataLength={notification.length}
            next={() => { getNotification(); }}
            hasMore={hasMore}
            loader={<div className="mt-50">{SCROLL_MORE_TEXT}</div>}
            endMessage={<div className="mt-50">Showing {notification.length} result(s)</div>}
            scrollableTarget="table-bod"
          >
            <div className="mt-2" id="table-bod">
              <Table className="bg-white table-view fixed-header">
                <thead>
                  <tr>
                    {isSelector && <th style={{ width: "2%" }}>
                      <input
                        type='checkbox'
                        ref={allCheckRef}
                        onChange={() => { }}
                        onClick={handleAllCheck}
                        className="cursor-pointer mt-50 tristate"
                      />
                    </th>}
                    {cols.Type && <th style={{ width: "7%" }}>
                      <ColumnHeader attribute={"alert_type"} header="Type" setter={setSort} sort={sort} />
                    </th>}
                    <th style={{ width: "18%" }}><ColumnHeader header="Id" attribute={"alert_id"} setter={setSort} sort={sort} /></th>
                    {cols.Affects && <th style={{ width: "19%" }}><ColumnHeader header="Affects" /></th>}
                    {cols.Summary && <th style={{ width: "20%" }}><ColumnHeader header="Summary" attribute={"display_name"} setter={setSort} sort={sort} /></th>}
                    {cols.Organization && <th style={{ width: "18%" }}><ColumnHeader header="Organization" attribute="organization" setter={setSort} sort={sort} /></th>}
                    {cols.Venue && <th style={{ width: "18%" }}><ColumnHeader header="Venue" /></th>}
                    {cols.Created && <th style={{ width: "12%" }}><ColumnHeader header="Created" attribute={"created_at"} setter={setSort} sort={sort} /></th>}
                    {cols.Severity && <th style={{ width: "12%" }}>
                      <ColumnHeader header="Severity" attribute={"alert_impact"} setter={setSort} sort={sort} />
                    </th>}
                    <th style={{ width: "2%" }} className="text-right">
                      <span
                        className="material-symbols-outlined cursor-pointer"
                        onClick={() =>
                          setIsColumnSelectorOpen(prevState => !prevState)}>
                        settings
                      </span>
                    </th>
                  </tr>
                </thead>
                {notification.length > 0 ?
                  <tbody>
                    {notification?.map((noti, index) => {
                      return <tr key={index}>
                        {isSelector ? (
                          <td>
                            <input
                              type="checkbox"
                              className='mr-1 cursor-pointer tristate'
                              checked={(search != '' || filterActive) ? selected.has(noti?.id) : ((areAllSelected && !excluded.has(noti?.id)) || selected.has(noti?.id))}
                              onChange={() => { }}
                              onClick={() => {
                                if(search != '' || filterActive) {
                                  setSelected(prevSelectionState => {
                                    const newSelectionState = new Set(prevSelectionState)
                                    if(prevSelectionState.has(noti?.id)) {
                                      newSelectionState.delete(noti?.id)
                                    } else {
                                      newSelectionState.add(noti?.id)
                                    }
                                    return newSelectionState
                                  })
                                } else {
                                  if (areAllSelected) {
                                    setExcluded(prevState => {
                                      const newState = new Set(prevState)
                                      if (!prevState.has(noti?.id)) {
                                        newState.add(noti?.id)
                                        setSelected(prevSelectionState => {
                                          const newSelectionState = new Set(prevSelectionState)
                                          newSelectionState.delete(noti?.id)
                                          return newSelectionState
                                        })
                                      } else {
                                        newState.delete(noti?.id)
                                      }
                                      return newState
                                    })
                                  } else {
                                    setSelected(prevState => {
                                      const newState = new Set(prevState)
                                      if (newState.has(noti?.id)) {
                                        newState.delete(noti?.id)
                                      } else {
                                        newState.add(noti?.id)
                                        setExcluded(prevExcludedState => {
                                          const newExcludedState = new Set(prevExcludedState)
                                          newExcludedState.delete(noti?.id)
                                          return newExcludedState
                                        })
                                      }
                                      return newState
                                    })
                                  }
                                }
                              }}
                            />
                          </td>
                        ) : null
                        }
                        {cols.Type && <td style={{ width: "7%" }}>
                          <div className="d-flex align-items-center">
                            {(noti?.alert_type === "alarm" || noti?.alert_type === "venue_alarm") ? (
                              noti?.alert_impact == ALERT_HIG ? (
                                <EmergencySirenIcon
                                  style={{ width: "22px", height: "22px" }}
                                />
                              ) : noti?.alert_impact == ALERT_MED ? (
                                <OrangeWarningIcon
                                  style={{ width: "22px", height: "22px" }}
                                />
                              ) : noti?.alert_impact == ALERT_LOW ? (
                                <WarningIcon
                                  style={{ width: "22px", height: "22px" }}
                                />
                              ) : null
                            ) : noti?.alert_type === "ticket" ? (
                              <TicketIcon
                                style={{ width: "22px", height: "22px" }}
                              />
                            ) : noti?.alert_type === "notification" ? (
                              // <NotificationIcon
                              //   style={{ width: "22px", height: "22px" }}
                              // />
                              <img src={notificationSvg} style={{ width: '22px', height: '22px' }} alt="notification" />
                            ) : null}
                          </div>
                        </td>}
                        <td style={{ width: "18%" }}
                        >
                          <LinkWrapper
                            to={ticketLink(noti, rbac)}
                          >
                            <span style={{ textTransform: "capitalize" }}>{noti?.alert_type.replace("_", " ")} </span>{noti?.alert_id != null ? noti?.alert_id : "-"}
                          </LinkWrapper>
                        </td>
                        {cols.Affects && <td style={{ width: "14%" }}>
                          <LinkWrapper
                            to={affectsLink(noti)}
                          >
                            {noti?.affected_entities != null &&
                              <span>
                                {noti?.affected_entities[0].venues != null ?
                                  <Venue className="mr-50" /> :
                                  noti?.affected_entities[0].infra_category == "ap" ? (
                                    <APSVG className="mr-50" />
                                  ) : noti?.affected_entities[0].infra_category == 'switch' ? (
                                    <SwitchSVG className="mr-50" />
                                  ) : (noti?.affected_entities[0].infra_category == null &&
                                    !!noti?.affected_entities[0].venue_name) ? (
                                    <Venue className="mr-50" />
                                  ) : (noti?.affected_entities[0]?.org_type_id == BUSINESS) ? (
                                    <OrgIcon className="mr-50" />
                                  ) : (noti?.affected_entities[0]?.org_type_id == MSP) ? (
                                    <MSPIcon className="mr-50" />
                                  ) : null
                                }
                              </span>}
                            {noti?.affected_entities != null ?
                              noti?.affected_entities[0].venues != null ?
                                <span className="cursor-pointer mt-50" id={`venue${index}`} onMouseEnter={() => setPopover(index)} onMouseLeave={() => setPopover(null)}>
                                  <span>{noti?.affected_entities[0]?.venues.length > 1 ? (noti?.affected_entities[0]?.venues.length || '-') + ' Venues'
                                    : noti?.affected_entities[0]?.venues[0]?.venue_name}</span>
                                  <Popover
                                    placement='right'
                                    target={`venue${index}`}
                                    isOpen={popover === index}
                                  >
                                    <PopoverHeader>Venues</PopoverHeader>
                                    <PopoverBody className="scrollable-popover">
                                      {noti?.affected_entities[0].venues?.map((venue, key) => (
                                        <LinkWrapper
                                          key={'orgRowPopver' + key}
                                          to={`/organization/${noti.org_id}/venues/${venue?.venue_id}`}
                                        >
                                          {venue.venue_name}
                                        </LinkWrapper>
                                      ))}
                                    </PopoverBody>
                                  </Popover>
                                </span> :
                                (noti?.affected_entities[0].org_name ?? noti?.affected_entities[0].venue_name ?? noti?.affected_entities[0].infra_name) : "-"}
                          </LinkWrapper>
                        </td>}
                        {cols.Summary &&
                          <td>
                            <div
                              id={`noti-description${index}`}
                              // onMouseEnter={() => setSummaryPopover(`noti-description${index}`)} 
                              // onMouseLeave={() => setSummaryPopover(null)}
                              className={`${'cursor-pointer'}`}
                            >
                              {noti?.display_name != null ? <span>{noti?.display_name}<span className="material-symbols-outlined info-icon">info</span></span> : "-"}
                            </div>
                            <Tooltip
                              anchorSelect={`#noti-description${index}`}
                              variant="light"
                              clickable
                              place="bottom"
                              border="solid 2px #EAEAEA"
                              opacity={1}
                              style={{ boxShadow: "2px 2px 15px #EAEAEA", zIndex: 40 }}
                              delayShow={200}
                            >
                              <div className="ellipsed-noti" style={{ width: "270px", fontSize: "1rem" }}>
                                <AlarmDesc alarmType={noti?.alert_code} data={noti?.metadata_payload ?? {}} desc={noti?.description} orgId={noti?.org_id} />
                              </div>
                            </Tooltip>
                          </td>
                        }
                        {cols.Organization &&
                          <td
                            style={{ width: "18%" }}
                          >
                            <LinkWrapper
                              to={notiOrgLink(noti)}
                            >
                              {/* {noti.alert_type === 'notification' ?
                                (!!noti?.affected_entities &&
                                  noti.affected_entities.length === 1 &&
                                  noti?.affected_entities[0]?.org_id != null &&
                                  noti?.affected_entities[0]?.venues.length === 1 &&
                                  noti?.affected_entities[0]?.venues[0].venue_id != null) ?
                                  noti?.affected_entities[0]?.venues[0]?.org_name ?? "-"
                                  : "-"
                                : (noti.org_name ?? "-")} */}
                                {noti.orgName ?? "-"}
                            </LinkWrapper>
                          </td>
                        }
                        {cols.Venue &&
                          <td
                            style={{ width: "18%" }}
                          >
                            <LinkWrapper
                              to={notiVenueLink(noti)}
                            >
                              {noti.alert_type === 'notification' ?
                                (!!noti?.affected_entities &&
                                  noti.affected_entities.length === 1 &&
                                  noti?.affected_entities[0]?.org_id != null &&
                                  noti?.affected_entities[0]?.venues.length === 1 &&
                                  noti?.affected_entities[0]?.venues[0].venue_id != null) ?
                                  noti?.affected_entities[0]?.venues[0]?.venue_name ?? "-"
                                  : "-"
                                : (noti.venue_name ?? "-")}
                            </LinkWrapper>
                          </td>
                        }
                        {cols.Created && <td style={{ width: "12%" }} className="cursor-pointer" id={`noti${index}`}>{timeDiff(noti.created_at) + " ago"}
                          <UncontrolledTooltip target={`noti${index}`}>
                            {dateTimeFormatter(noti.created_at, "long", "medium") + " (" + getTimeZone(false) + ") "}
                          </UncontrolledTooltip>
                        </td>}
                        {cols.Severity && <td style={{ width: "12%" }}><span className={`font-weight-bolder text-${alertColor[noti?.alert_impact]}`} style={{ textTransform: "capitalize" }}>{alertText[noti.alert_impact]}</span></td>}
                        <td className="text-right" style={{ width: "2%" }}>
                          <UncontrolledDropdown className={historical ? "d-none" : ""} direction="down" style={{ position: "inherit" }}>
                            <DropdownToggle color='white' className="w-0 p-0" disabled={historical || !permissions?.manageOrganization?.create}>
                              <span className={"material-symbols-outlined cursor-pointer " + ((historical || !permissions?.manageOrganization?.create) ? "text-secondary" : "text-primary")} title="Actions">more_vert</span>
                            </DropdownToggle>
                            {!historical && <DropdownMenu>
                              <DropdownItem className="w-100" onClick={() => {
                                let newSelection = new Set([noti?.id])
                                setSelected(newSelection)
                                setAreAllSelected(false)
                                setExcluded(new Set())
                                setAckModal(true)
                              }}>
                                Acknowledge
                              </DropdownItem>
                            </DropdownMenu>}
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    })
                    }
                  </tbody> : loading ?
                    <tbody>
                      <tr>
                        <td colSpan={10} className="p-5 text-center"><Spinner color="primary" /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      <tr>
                        <td colSpan={10} className="p-5 text-center"><h4>No Notification Present.</h4></td>
                      </tr>
                    </tbody>
                }
              </Table>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
