import Service from "./Service";
import { U } from "../helpers";

/** Services related to fwupgrade */
const fwupgrade = {
    GET_REPORT: new Service((id) => U(`firmware/upgrade/report/${id}`), "GET"),
    GET_VERSION: new Service((id) => U(`firmware/version/${id}`), "GET"),
    GET_FW_HIERARCHY: new Service((id) => U(`firmware/upgrade/${id}/hierarchy`), "GET"),
    GET_FWUPGRADE_VERSION: new Service((search='',limit,offset,venueMatrix=false, orderFirmware=[]) => U(`firmware/upgrade/version?search=${search}&orderFirmware=${orderFirmware}` + (limit!=null?`&limit=${limit}`:'')  + (offset!=null?`&offset=${offset}`:'') + (venueMatrix?'&venueMatrix=true':'')), "GET"),
    GET_ALL_SCHEDULE: new Service((offset = 0, limit = 100, searchText = "") => U(`firmware/upgrade/schedules?offset=${offset}&limit=${limit}&search=${searchText}`), "GET"),
    GET_SCHEDULE: new Service((id) => U(`firmware/upgrade/${id}`), "GET"),
    GET_FWUPGRADE_SCHEDULE_REPORT: new Service((id, search = "") => U(`firmware/upgrade/report/${id}?search=${search}`), "GET"),
    GET_FWUPGRADE_REPORT_ORG: new Service((orgId, id) => U(`firmware/upgrade/organization/${orgId}/report/${id}`), "GET"),
    UPDATE_FWUPGRADE: new Service((id) => U(`firmware/upgrade/${id}`), "PUT"),

    RESCHEDULE_FWUPGRADE: new Service((id) => U(`firmware/upgrade/${id}/reschedule`), "PUT"),
    RESCHEDULE_VENUE: new Service((id) => U(`firmware/upgrade/venue/${id}`), "PUT"),
    RESCHEDULE_INFRA: new Service((id) => U(`firmware/upgrade/infrastructure/${id}`), "PUT"),

    DELETE_FWUPGRADE: new Service((id) => U(`firmware/upgrade/${id}`), "DELETE"),
    NEW_FWUPGRADE: new Service(() => U(`firmware/upgrade`), "POST"),
    GET_FWUPGRADE_BY_ORG: new Service((id) => U(`firmware/upgrade/organization/${id}`), "GET"),
    // GET_FWUPGRADE_SUBSCHEDULE: new Service((id) => U(`firmware/upgrade/subschedule/${id}`),"GET"),
    GET_ONE_FWUPGRADE_BY_ORG: new Service((orgId, scheduleId) => U(`firmware/upgrade/organization/${orgId}/schedule/${scheduleId}`), "GET"),
    RETRY_FWUPGRADE: new Service(() => U(`firmware/upgrade/report/rerun`), "POST"),
    GET_RELEASE_NOTES: new Service(() => U(`firmware/releasenotes`), "GET"),
    GET_FWUPGRADE_COUNT_BY_ORG: new Service((orgId, children=false) => U(`firmware/upgrade/organization/${orgId}/count?children=${children}`), "GET"),
    GET_FAILED_VENUE_COUNT_BY_ORG: new Service((orgId) => U(`firmware/upgrade/organization/${orgId}/failed`), "GET"),
    GET_FW_VERSIONS_OF_SUB_ORGS: new Service((orgId, children) => U(`firmware/version/organization/${orgId}?children=${children}`), "GET"),
    GET_VENUE_SCHEDULE: new Service((venueId) => U(`firmware/upgrade/venue/${venueId}/schedule`), "GET"),
    GET_INFRA_SCHEDULE: new Service((venueId) => U(`firmware/upgrade/infrastructure/${venueId}/schedule`), "GET"),
    GET_LATEST_VERSION: new Service((venueId) => U(`firmware/upgrade/version/latest`), "GET"),
    UPDATE_VENUE_FW: new Service((venueId, enable) => U(`firmware/upgrade/venue/${venueId}/enable?enable=${enable}`),"PUT"),
    //firmware matrix
    GET_IMAGE_OPTIONS: new Service((limit=10,search='',infraTypeId) => U(`firmware/version/mapping?limit=${limit}&search=${search}` + (infraTypeId?`&infraTypeId=${infraTypeId}`:``)),"GET"),
    POST_MAPPING: new Service(() => U(`firmware/version/mapping`),"POST"),
    DELETE_MAPPING: new Service((version) => U(`firmware/${version}`),"DELETE"),
    MAKE_DEFAULT_MAPPING: new Service(() => U(`firmware/version/venue`),"PUT"),
    GET_RELEASE: new Service((release) => U(`firmware/${release}`),"GET")
};

export default fwupgrade;