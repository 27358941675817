/***
 *
 * Controller class for user.
 * @file Utilities.js
 * @description Utilities component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, Container, FormFeedback, Input, Progress, Row } from "reactstrap";
import Header from "../_builder/Header";
import { ReactComponent as ClipboardIcon } from "../../../assets/images/icons/Clipboard Icon.svg"
import "./Utilities.scss";
import { VeryFancyLoader } from "../../../components";
import createRequest, { services } from "../../../services";
import { CatchedWebError } from "../../../configs";
import useAutoclear from "../../../hooks/useAutoclear";
import { breadcrumbActions } from "../../../redux/slices";
import SwitchHeader from "../_builder/SwitchHeader";
import traceRouteAction from "../../../redux/slices/traceRouteData.slice";
import store from "../../../redux/store";
import { getRandomNumber } from "../../../utility/Utils";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { reactselectTheme } from "../../../utility/constants";

export const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const dnsRegex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;
export const dnsWithWildRegex = /^(([\w-]+\.)|(\*\.))+[\w-]+$/;


const Utilities = () => {
  const [params] = useSearchParams();
  const infraData = useSelector(store => store.oneInfra.data);
  const features = useSelector(store => store.oneInfra.features);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
  const traceRouteData = useSelector(store => store.traceRoute.data)
  const [infraName, setInfraName] = useState(null);
  const [operation, setOperation] = useState('')
  const [waitingMessage, setWaitingMessage] = useState('')
  const [commands, setCommands] = useState([
    { value: 'ping', label: "Ping" },
    { value: 'traceroute', label: "Trace Route" },
    { value: 'nslookup', label: "DNS Lookup" },
  ])
  const [command, setCommand] = useState(commands[0].value);
  const [commandInput, setCommandInput] = useState('');
  const [currentUUID, setCurrentUUID] = useState('')
  const [traceRouteProgress, setTraceRouteProgress] = useState(0)
  const [showTraceRouteProgress, setShowTraceRouteProgress] = useState(false)
  const [resolve, setResolve] = useState(true);
  const [inputIsValid, setInputIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [traceRouteTimeoutId, setTraceRouteTimeoutId] = useState(null);
  const defaultOutput = <div className=' h-100 w-100 d-flex justify-content-center align-items-center defaultOutput'>Send request to view output.</div>
  const waitOutput = <div className=' h-100 w-100 d-flex justify-content-center align-items-center defaultOutput'>Please Wait...</div>
  const [output, setOutput] = useState(defaultOutput);
  const [disableCopy, setDisableCopy] = useState(true);
  const infraTypes = useSelector(store => store.infraTypes.data);
  const infraTypeId = useSelector(store => store.oneInfra.data.infraTypeId);
  const deviceTypes = useSelector(store => store.infraTypes.data);
  const outputRef = useRef(null);
  // const ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
  const NO_RESULT_COMMAND = "Requested command failed to reach the target server. Please try again after sometime."
  const navigate = useNavigate()

  const dispatch = useDispatch();
  let traceRouteInterval = null

  useEffect(() => {
    setDisableCopy(((output?.props?.children === defaultOutput?.props?.children
      || output?.props?.children === waitOutput?.props?.children)
      || output === NO_RESULT_COMMAND
      || loading));
  }, [output, loading])


  useEffect(() => {
    return () => {
      if (!!traceRouteTimeoutId)
        clearTimeout(traceRouteTimeoutId);
    }
  }, [traceRouteTimeoutId])

  useEffect(() => {
    if (!!features?.utilitySupport) {
      let commandOptions = []
      if (features?.utilitySupport?.pingSupport) {
        commandOptions.push({ value: 'ping', label: "Ping" })
      }
      if (features?.utilitySupport?.tracerouteSupport) {
        commandOptions.push({ value: 'traceroute', label: "Trace Route" })
      }
      if (features?.utilitySupport?.dnsSupport) {
        commandOptions.push({ value: 'nslookup', label: "DNS Lookup" })
      }
      setCommand(commandOptions.length > 0 ? commandOptions[0].value : "")
      setCommands(commandOptions)
    }
  }, [features])



  useEffect(() => {
    store.dispatch(traceRouteAction.setTraceRouteData({}))
  }, [])

  useEffect(() => {
    if (infraData) {
      setInfraName(infraData.infraDisplayName);
    }
  }, [infraData]);

  useEffect(() => {
    if (Object.keys(traceRouteData).length > 0) {
      if (traceRouteData.uuid === currentUUID) {
        setTraceRouteProgress(100)
        setTimeout(() => {
          let out = traceRouteData?.result?.status?.result ||
            traceRouteData?.result?.status?.text ||
            traceRouteData?.result?.message ||
            NO_RESULT_COMMAND
          setOutput(out);
          setTraceRouteProgress(100)
          setTraceRouteTimeoutId(null)
          setTimeout(() => { setShowTraceRouteProgress(false); setTraceRouteProgress(100); }, 1000)
          clearInterval(traceRouteInterval)
          if (traceRouteTimeoutId) {
            clearTimeout(traceRouteTimeoutId)
            traceRouteInterval = null
            setTraceRouteTimeoutId(null)
            setTraceRouteProgress(0)
            setShowTraceRouteProgress(false)
          }
        }, 1000)
      }
    }
    else {
      clearInterval(traceRouteInterval)
    }
  }, [traceRouteData])

  useEffect(() => {
    isTouched &&
      setInputIsValid(
        (!!commandInput &&
          (ipv4Regex.test(commandInput)
            || dnsRegex.test(commandInput)
          )
        )
      )
  }, [commandInput]);

  useEffect(() => {
    dispatch(breadcrumbActions.setData(
      [{
        path: (params.has("backTo")? `/organization/${activeOrgId}/venues/${params.get("backTo")}/infrastructure/` : `/organization/${activeOrgId}/infra`),
        text: "Infrastructure",
        active: false
      },
      {
        text: infraData.infraDisplayName,
        active: false
      }, {
        path: `/organization/${activeOrgId}/infra/${infraData.infraItemId}/utilities/`,
        text: "Utilities",
        active: true
      }]
    ))
  }, [infraData.infraItemId, params])




  const handleCopy = () => {
    // navigator.clipboard.writeText(output);
    navigator.clipboard.writeText(outputRef.current.innerText);
    // make_toast("info", "Copied to clipboard.", true);
    // toast('Copied to clipboard!', { ...toastConfig, autoClose: 5000 });
  }

  const handleClick = () => {

    if (command === 'traceroute')
      setOperation('Trace Route')
    else if (command === 'nslookup')
      setOperation('DNS Lookup')
    else if (command === 'ping')
      setOperation('Ping')


    const { context, run } = createRequest(services.infra.UTILITY, [infraData.infraItemId], {
      "trigger": command,
      "resolveName": !resolve,
      "ipAddress": commandInput
    });
    if (infraData.infraItemId) {
      setLoading(true);
      setError(null);
      run()
        .then((response) => {
          setLoading(false);
          setTraceRouteProgress(0)
          handleTraceRouteProgess()
          setShowTraceRouteProgress(true)
          setOutput(waitOutput)
          store.dispatch(traceRouteAction.setTraceRouteData({}))
          setCurrentUUID(response.data.uuid)
          make_custom_toast('success', 'Infrastructure', "Command execution started")
        })
        .catch(err => {
          setLoading(false);
          const x = (new CatchedWebError(err))
          const apiContext = createErrorContext(context, 'Running Utilities on Infra', TICKET_CATEGORY.NETWORK, err)
          make_custom_toast('error', 'Infrastructure', x.message, true, 'Create Ticket', () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          })
          setOutput(defaultOutput);
        })
        .finally(() => { setLoading(false); })
    }
  }

  const handleTraceRouteProgess = () => {
    setWaitingMessage('')
    clearInterval(traceRouteInterval)
    traceRouteInterval = null
    traceRouteInterval = setInterval(() => {
      setTraceRouteProgress(prev => {
        if (prev < 92)
          return prev + getRandomNumber(3)
        else {
          setWaitingMessage('(this operation might take upto 5 minutes.)')
          return prev
        }
      })
    }, 3.1 * 1000);
    clearTimeout(traceRouteTimeoutId)
    setTraceRouteTimeoutId(null)

    setTraceRouteTimeoutId(setTimeout(() => {
      clearInterval(traceRouteInterval)
      traceRouteInterval = null
      setOutput(NO_RESULT_COMMAND)
      setShowTraceRouteProgress(false)
      setTraceRouteProgress(0)
    }, 5 * 60 * 1000))
  }

  useAutoclear(success, () => { setSuccess(null); });

  return (
    <div className="Utilities" data-testid="Utilities">
      {deviceTypes.find(itype => itype.infraTypeId === infraData.infraTypeId)?.infraCategory === 1 ?
        <Header heading={infraData.infraDisplayName} />
        :
        <div>
          <SwitchHeader activeOrgId={activeOrgId} infraId={infraData.infraItemId} />
        </div>
      }
      {/* <Alert color="danger" className="w-100" isOpen={!!error} toggle={() => { setError(null); }}>
        <div className="alert-body">
          {error}
        </div>
      </Alert> */}
      {/* <Alert color="success" className="w-100" isOpen={!!success} toggle={() => { setSuccess(null); }}>
        <div className="alert-body">
          {success}
        </div>
      </Alert> */}
      <div className='bg-white rounded shadow-sm border p-1 py-2'>
        Command
        <Container>
          <Row className='d-flex justify-content-between align-items-top'>
            <Col lg={4} className='pl-0'>
              <Select
                className='d-lg-inline-block w-100'
                styles={reactselectTheme}
                isClearable={false}
                isSearchable={false}
                value={commands?.find(option => option.value == command)}
                options={commands}
                onChange={
                  (event) => {
                    // dispatch(activeOrgActions.setDateRange(event.value)); 
                    setCommand(event.value);
                  }}
              />
            </Col>
            <Col lg={4} className="pl-0" style={{ paddingTop: "0.25rem" }}>
              {/* <Label for='validState'>IP Address/DNS</Label> */}
              <Input
                type='text'
                id='validState'
                name='validState'
                value={commandInput}
                onChange={(event) => { setIsTouched(true); setCommandInput(event.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !(!inputIsValid || loading || !infraData.connected || showTraceRouteProgress))
                    handleClick()
                }}
                invalid={!inputIsValid && isTouched}
              />
              <FormFeedback>Enter a valid IP Address or DNS.</FormFeedback>
            </Col>
            <Col lg={4} className="pl-0" style={{ paddingTop: "0.25rem" }}>
              <Button.Ripple
                className={`d-lg-inline-block ${showTraceRouteProgress ? 'cursor-not-allowed' : ''}`}
                disabled={!inputIsValid || loading || !infraData.connected || showTraceRouteProgress}
                onClick={handleClick}
                color='primary'>
                Send Request
              </Button.Ripple>
            </Col>
          </Row>
          <Row style={{ minHeight: "35px" }}>
            {command === "traceroute" &&
              <div className="d-flex mt-1">
                <input type="checkbox" checked={resolve} onChange={() => {
                  setResolve(prevState => !prevState)
                }} />
                <span className="ml-1">Perform Reverse DNS Look up</span>
              </div>}
          </Row>

          {/* ---- progress Bar --- */}
          {
            showTraceRouteProgress &&
            <div className="pt-2">
              <span>Performing {operation}...{waitingMessage}</span>
              <Progress
                value={traceRouteProgress}
              >
                {traceRouteProgress}%
              </Progress>
            </div>
          }

        </Container>
        <div className='LowerDiv p-1 mt-1'>
          <div className='d-flex justify-content-between align-items-center'>
            <span className='OutputHeading'>
              {(infraTypes.find(itype => itype.infraTypeId === infraTypeId)?.infraCategory) === 1 ? 'AP' : 'Switch'}
              &nbsp;Output
            </span>
            <span
              className={'ClipBoardSpan rounded p-50 d-flex align-items-center ' +
                (disableCopy ? ' cursor-not-allowed ' : ' cursor-pointer ')}
              onClick={() => { !disableCopy && handleCopy() }}
            >
              <ClipboardIcon width="14" height="14" />&nbsp;&nbsp;Copy to clipboard
            </span>
          </div>
          <div className='mt-1 pr-1 OutputDiv rounded' ref={outputRef}>
            {loading ?
              <div className='d-flex justify-content-center w-100'><VeryFancyLoader /></div>
              : <pre>
                {output}
              </pre>}
          </div>
        </div>
      </div>
    </div>
  );
};

Utilities.propTypes = {};

Utilities.defaultProps = {};

export default Utilities;
