/***
 *
 * Controller class for user.
 * @file SingleInfraSelector.js
 * @description SingleInfraSelector component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { createContext, Suspense, useContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./SingleInfraSelector.scss";
import createRequest, { services } from "../../services";
import { useSelector } from "react-redux";
import { Button, Col, Form, Input, InputGroup, Label, Row, Spinner, Table } from "reactstrap";
import SearchIconAddon from "../SearchIconAddon";
import GroupButton from "../GroupButton";
import { ExpCell } from "../ExpTable";
import { CollapseIcon, ExpandIcon } from "../../assets/images/icons/Icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { statusColor, statusText } from "../OrgWidget";
import { useNavigate } from "react-router-dom";
import { MANUAL, reactselectThemeWithMargin, SITE } from "../../utility/constants";
import APSVG from "../../pages/Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../pages/Infrastructure/InfraList/SwitchSVG";
import { Highlight, RowSecondLine } from "../VenueInfraWidget";
import { statusIcons, VenueIcon } from "../../pages/Venue/VenueTree";
import LightBadge from "../LightBadge";
import { MAC_ADDRESS } from "../../pages/Venue/Infrastructure";
import ColumnHeader from "../ColumnHeader";
import FilterSetter from "../FilterSetter";
import lodash from "lodash-es"
import { CategoryFilter, ConnectedFilter, InfraTypeFilter, InstalledFilter } from "../../pages/Filters/filters";
import FilterButton from "../FilterButton";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import TextInput from "../TextInput";
import { createErrorContext } from "../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../pages/Tickets/TicketConstants";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";

let timer;
const debounce = (func) => {
  clearTimeout(timer);
  timer = setTimeout(func, 800);
}

const InfraSelectContext = createContext(null);

const filterInitial = {
  infraCategory: "ALL",
}

const ExpRow = (props) => {
  const view = useSelector((store) => store.identity.meta.view);
  const {
    sort,
    search,
    mainFilter,
    filterActive,
    showAP,
    showSWITCH,
    selectedInfra,
    setSelectedInfra
  } = useContext(InfraSelectContext)

  const [expand, setExpand] = useState(props?.venue?.children?.length > 0);
  const [subList, setSubList] = useState(props?.venue?.children ?? []);
  const [hasMore, setHasMore] = useState(true);
  let level = props.level ?? 1;
  let subCount = 0;

  const getList = (offset = 0) => {

    const { controller, run } = createRequest(
      services.telemetry.GET_VENUE_CHILDREN,
      [
        props.venueId,
        offset,
        20,
        "",
        view,
        ["MAC Address", "Status", "Tasks"],
        "All",
        sort,
        {},
        true,
        ,
        false
      ]
    );
    // setSubListLoading(true);
    run()
      .then((response) => {
        // setSubListError(false);
        subCount += response.data.length
        if (offset == 0) {
          setSubList([...response.data]);
        } else {
          setSubList((prevState) => [...prevState, ...response.data]);
        }

        if (response.data.length == 20) getList(subCount);
        else setHasMore(false);

      })
      .catch((err) => {
        // let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  };

  useEffect(() => {
    if (props?.venue?.children == null || props?.venue?.children?.length == 0) {
      if (expand) {
        let controller = getList(0);

        return () => {
          controller?.abort();
        };
      } else {
        setSubList([]);
        setHasMore(true);
        subCount = 0;
      }
    }
    else setHasMore(false);
  }, [expand, props.orgId, props.venueId]);

  useEffect(() => {
    setSubList(props?.venue?.children ?? []);
  }, [props?.venue?.children]);



  const firstCell = props.children[0];
  const newChildren = props?.children?.map((element) =>
    element ? React.cloneElement(element, { level: level }) : element
  );
  return (
    <>
      <div className={"ExpRow " + props.className}>
        <ExpCell
          {...firstCell.props}
          expand={expand}
          level={level}
          hasExpandIcon={props.leafNode}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start position-relative">
              {props.expand && expand && (
                <div
                  className={`${firstCell.props.last || firstCell.props.level == undefined
                    ? "fixed-line"
                    : "fixed-line-inner"
                    }`}
                />
              )}
              {props.leafNode && !props.venue.infraItemId ? (
                expand ? (
                  <CollapseIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                ) : (
                  <ExpandIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                )
              ) : props.venue.infraItemId != null ? null : (
                <div className="mr-1" style={{ minWidth: "1.5rem" }}>
                  &nbsp;
                </div>
              )}
              {(((props.venue.highlighted == null && props.venue.infraItemId != null) ? (search.length == 0 && mainFilter == "All" && !filterActive) : props.venue.highlighted)) && (
                <input
                  className={`mr-1 ${props.venue.restricted ? "cursor-not-allowed" : "cursor-pointer"}`}
                  type="radio"
                  checked={selectedInfra?.infraItemId == props.venue.infraItemId}
                  disabled={props.venue.restricted}
                  onClick={() => {
                    if (selectedInfra?.infraItemId == props.venue?.infraItemId)
                      setSelectedInfra(null)
                    else
                      setSelectedInfra(props.venue)
                  }}
                />
              )}
              {firstCell.props.children}
            </div>
          </div>
        </ExpCell>
        {newChildren.slice(1)}
      </div>
      {expand && (
        <>
          <SubList
            {...props}
            subList={subList}
            setSubList={setSubList}
            level={level + 1}
          />
        </>
      )}
    </>
  );
};

const SubList = (props) => {
  const { subList, level } = props;

  const {
    search,
    mainFilter,
    filterActive,
  } = useContext(InfraSelectContext);


  const infraType = useSelector((store) => store.infraTypes.data);
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const view = useSelector((store) => store.identity.meta.view);
  const permissions = useSelector((store) => store.rbac.permissions);

  const navigate = useNavigate();

  return (
    <>
      {subList.map((venue, index) => {
        return (
          <ExpRow
            key={venue.infraItemId ?? venue.venueId}
            leafNode={!venue.leafNode}
            orgId={venue.orgId}
            venueId={venue.venueId}
            level={level}
            venue={venue}
            parentLast={
              props.parentLast
                ? [...props.parentLast, index == subList.length - 1]
                : [index == subList.length - 1]
            }
            className={
              (venue.venueType == SITE ? "site-bg " : "") +
              ((venue.highlighted != null ? !venue.highlighted : (search.length > 0 || mainFilter != "All" || filterActive))
                ? "opacity-50"
                : "")
            }
          >
            <ExpCell
              width="400px"
              showLines={level > 1}
              last={index == subList.length - 1}
              parentLast={
                props.parentLast
                  ? [...props.parentLast, index == subList.length - 1]
                  : []
              }
            >
              {!!venue.infraItemId ? (
                <span
                  onClick={() => {
                    navigate(
                      `/organization/${activeOrg.orgId}/infra/${venue.infraItemId}/`
                    );
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      {venue?.infraCategory === 1 ? (
                        <>
                          <Suspense fallback={<></>}>
                            <APSVG width="24" height="24" />
                          </Suspense>
                        </>
                      ) : (
                        <>
                          <Suspense fallback={<></>}>
                            <SwitchSVG width="24" height="24" />
                          </Suspense>
                        </>
                      )}
                      <div className="ml-50 table-link">
                        <Highlight search={search}>
                          {venue.infraName}
                        </Highlight>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "0.75rem" }}
                    >
                      <div className="d-flex align-items-center">
                        <Highlight search={search}>{venue.type}</Highlight>
                      </div>
                      <div className="d-flex align-items-center">
                        &nbsp;
                        <div
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            height: "5px",
                            width: "5px",
                            content: " ",
                            borderRadius: "50%",
                            backgroundColor: "black",
                          }}
                        ></div>
                        {
                          infraType.find(
                            (it) => it.infraTypeId == venue.infraTypeId
                          )?.infraType
                        }
                      </div>
                    </div>
                  </div>
                </span>
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex py-50"
                    onClick={() => {
                      if (venue.orgId == activeOrg.orgId && !venue.restricted) {
                        if (permissions?.manageVenue?.view)
                          navigate(
                            `/organization/${venue.orgId}/venues/${venue.venueId}`
                          );
                      } else if (!venue.restricted && permissions?.manageHierarchy?.view)
                        navigate(
                          `/organization/${venue.orgId}/venues/${venue.venueId}`
                        );
                    }}
                  >
                    {!venue.permissionHide && (
                      <div className="d-flex align-items-center ">
                        {/* {view != BUSINESS_VIEW && (
                          statusIcons(venue.zone)
                        )} */}
                        <span>{VenueIcon[venue.venueType]}</span>
                      </div>
                    )}
                    <div
                      className={
                        "d-flex align-items-center "
                        // + (view != BUSINESS_VIEW ? "ml-50" : "")
                      }
                    >
                      <div>
                        <div className="d-flex">
                          <div
                            className={`${(
                              (venue.orgId == activeOrg.orgId
                                ? permissions?.manageVenue?.view
                                : permissions?.manageHierarchy?.view) &&
                              !venue.restricted
                            )
                              ? "table-link"
                              : ""
                              } d-flex align-items-center`}
                          >
                            <Highlight search={search}>
                              {venue.venueName}
                            </Highlight>
                          </div>
                        </div>
                        {!venue.restricted && (
                          <div
                            className="font-weight-bold"
                            style={{
                              marginTop: "0.2rem",
                              minHeight: "0.5rem",
                              fontSize: "0.80rem",
                            }}
                          >
                            {RowSecondLine(venue)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ExpCell>
            <ExpCell width="100px">
              {venue.infraItemId ? (
                <LightBadge color={statusColor[venue.status]}>
                  <div className="text-dark">{statusText[venue.status]}</div>
                </LightBadge>
              ) : (
                <></>
              )}
            </ExpCell>
            <ExpCell width="170px">
              {venue.macAddress
                ? MAC_ADDRESS(venue.macAddress, true)
                : ""}
            </ExpCell>
            <ExpCell>
              {venue.infraItemId ? (venue.installed ? "Installed" : "Not Installed") : null}
            </ExpCell>


          </ExpRow>
        );
      })}
    </>
  );
};

const SingleInfraSelector = (props) => {
  const { venueId, venueName, showAP, showSWITCH, selectedInfra, setSelectedInfra, defaultDeviceType, defaultDeviceCategory, onPlace, setShowModal } = props;
  const deviceTypes = useSelector(store => store.infraTypes.data);
  const activeOrg = useSelector(store => store.activeOrg.data);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [mainFilter, setMainFilter] = useState("All");
  const [filterCount, setFilterCount] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [addInfra, setAddInfra] = useState(false)
  const [filterSelection, setFilterSelection] = useState({ ...filterInitial, infraCategory: (showAP ? "AP" : (showSWITCH ? "SWITCH" : "ALL")), taskFilter: ["not installed"] });
  const [filterData, setFilterData] = useState({ ...filterInitial, infraCategory: (showAP ? "AP" : (showSWITCH ? "SWITCH" : "ALL")), taskFilter: ["not installed"] })
  const [filterActive, setFilterActive] = useState(!lodash.isEqual(filterData, filterInitial));
  const [realInfra, setRealInfra] = useState(true);
  const [modelTypeOptions, setModelTypeOptions] = useState([]);
  const [placeLoading, setPlaceLoading] = useState(false)
  const [sort, setSort] = useState({
    orderBy: "Name",
    order: "ASC"
  });
  const [loading, setLoading] = useState(true);
  const view = useSelector(store => store.identity.meta.view);
  const navigate = useNavigate();

  const filterObj = [{
    filter: "All",
    count: "all"
  }];



  useEffect(() => {
    let normalModels = [], customModels = [];
    const filteredDeviceType = [...deviceTypes].filter(device => device.infraCategory == (showAP ? 1 : 2))
    filteredDeviceType.map(model => {
      if (model.customModel)
        customModels.push(model)
      else
        normalModels.push(model)
    })
    if (activeOrg.thirdPartyEnable) {
      setModelTypeOptions([
        {
          label: '',
          options: normalModels.map(model => {
            return {
              label: model.infraType?.replace(/_/g, " "),
              value: model.infraTypeId.toString()
            }
          })
        },
        {
          label: '3rd Party',
          options: customModels.map(model => {
            return {
              label: model.infraType?.replace(/_/g, " "),
              value: model.infraTypeId.toString()
            }
          })
        }
      ])
    }
    else {
      setModelTypeOptions([
        {
          label: '',
          options: normalModels.map(model => {
            return {
              label: model.infraType?.replace(/_/g, " "),
              value: model.infraTypeId.toString()
            }
          })
        }
      ])
    }
  }, [deviceTypes])

  const fetchVenue = (offset = 0, _mainFilter = mainFilter, _filterData = filterData) => {
    setLoading(true);
    const { run } = createRequest(services.telemetry.GET_VENUE_CHILDREN, [venueId, offset, 20, search, view, ["Status", "MAC Address", "Tasks"], _mainFilter, sort, _filterData, true, true, false, "Tree"])

    run().then(response => {
      if (offset == 0){
        setList([...response.data]);
        if(response?.data[0]?.children?.length<20){
          setHasMore(false)
        }
        else
          setHasMore(true)
      }
      else{
        if(response?.data[0]?.children?.length<20){
          setHasMore(false)
        }
       

        setList(prevState => [{...prevState[0], children:[...prevState[0]?.children, ...response?.data[0]?.children]}]);
      }
    })
      .finally(() => {
        setLoading(false);
      })
  }

  const getCount = () => {
    const { run } = createRequest(
      services.telemetry.GET_VENUE_CHILDREN_COUNT,
      [
        venueId,
        ["Mac Address", "Status", "Tasks"],
        encodeURIComponent(search),
        view,
        {},
      ]
    );

    run().then((response) => {
      setFilterCount(response.data);
    });
  }



  useEffect(() => {
    fetchVenue(0);
    getCount();
    setList([]);
  }, [venueId, search, sort])


  return (
    <div className="SingleInfraSelector" data-testid="SingleInfraSelector">
      <h4 className="font-weight-bolder">{addInfra ? "Add Infrastructure to Actual Position" : `Place ${showAP ? "Access Point" : "Switch"} Infrastructure`}</h4>

      <div className={`${addInfra ? `d-none` : `d-flex`} justify-content-between`}>
        <div className="d-flex">
          <div>
            <InputGroup
              className="input-group-merge"
              style={{ width: "260px" }}
            >
              <Input
                type="text"
                placeholder="Search"
                style={{ height: "2.4rem" }}
                defaultValue={search}
                onChange={(e) => {
                  if (
                    e.target.value.trim().length > 1 ||
                    e.target.value.trim().length === 0
                  ) {
                    debounce(() => {
                      setSearch(e.target.value);
                      setHasMore(true);
                    });
                  }
                  else {
                    debounce(() => {
                      setSearch("");
                      setHasMore(true);
                    });
                  }
                }}
              />
              <SearchIconAddon />
            </InputGroup>
          </div>
          <div className="ml-50">
            <GroupButton>
              {filterObj.map((filter, index) => {
                return (
                  <div
                    className={
                      "grp-btn-custom " +
                      ((mainFilter == filter.filter && !filterActive)
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      setFilterActive(false);
                      setMainFilter(filter.filter);
                      fetchVenue(0, filter.filter, filterInitial);
                      // getCount();
                      setFilterData(filterInitial);
                      setFilterSelection(filterInitial);
                      setList([]);
                    }}
                    key={`${index}-venue-infra`}
                  >
                    {filter.filter} ({filterCount[filter?.count] ?? 0})
                  </div>
                );
              })}
            </GroupButton>
          </div>
          <div>
            <FilterButton
              className="ml-50"
              size={22}
              active={filterActive}
              onClick={() => setShowFilter(true)}
              style={{ padding: "0.36rem" }}
            />
          </div>
        </div>
        {
          props?.showAddButton &&
          <div className="d-flex justify-content-end">
            <Button.Ripple disabled={!!selectedInfra} color="primary" onClick={() => setAddInfra(prevState => !prevState)}>
              Add Infrastructure
            </Button.Ripple>
          </div>
        }
      </div>

      <FilterSetter
        showFilter={showFilter}
        elements={() => [
          <CategoryFilter
            showAP={true}
            showSWITCH={true}
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
          <InstalledFilter
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
          <ConnectedFilter
            filter={filterSelection}
            setFilter={setFilterSelection}
          />,
          // <InfraTypeFilter
          //   filter={filterSelection}
          //   setFilter={setFilterSelection}
          // />,
        ]}
        handleApplyClick={() => {
          setMainFilter("All");
          setHasMore(true);
          fetchVenue(0, "All", filterSelection);
          setList([]);
          // getCount();
          setFilterData(filterSelection);
          if (lodash.isEqual(filterSelection, filterInitial))
            setFilterActive(false);
          else setFilterActive(true);
          setShowFilter(false);
        }}
        handleClearAll={() => {
          setHasMore(true);
          setFilterActive(false);
          setFilterSelection(filterInitial);
          setFilterData(filterInitial);
          fetchVenue(0, mainFilter, filterInitial);
          setList([]);
          // getCount();
          setShowFilter(false);
        }}
        setShowFilter={setShowFilter}
      />
      <InfraSelectContext.Provider
        value={{
          sort: sort,
          search: search,
          mainFilter: mainFilter,
          filterActive: filterActive,
          showAP: showAP,
          showSWITCH: showSWITCH,
          selectedInfra: selectedInfra,
          setSelectedInfra: setSelectedInfra
        }}
      >
        {addInfra ?
          <div className="mt-1">
            <Formik
              initialValues={{
                macAddress: "",
                name: "",
                //change this before deployment 
                deviceType: modelTypeOptions[0]?.options.find(it => it.value == defaultDeviceType)?.value ?? modelTypeOptions[1]?.options.find(it => it.value == defaultDeviceType)?.value ?? modelTypeOptions[0]?.options[0]?.value,
                serialNumber: "",
                assetTag: ""
              }}
              validationSchema={Yup.object({
                macAddress: Yup.string()
                  .matches((/^([0-9A-Fa-f]{2}[\s:-]){5}([0-9A-Fa-f]{2})$|^([a-fA-F0-9]{12})$|^([0-9A-Fa-f]{4}[\s:-]){2}([0-9A-Fa-f]{4})$/), "MAC address should be 12 characters long with values [a-f, A-F, 0-9]")
                  .required("Required"),
                name: Yup.string()
                  .min(3, "Minimum 3 characters are required")
                  .max(30, "Maximum 30 characters are allowed")
                  .matches(/^[a-zA-Z0-9',. _-]*$/, "Name can contain alphanumeric and allowed special characters(',. -_) only")
                  .required("Required"),
                deviceType: Yup.mixed().oneOf(deviceTypes.map(type => type.infraTypeId.toString())).required("Required"),
                serialNumber: Yup.string().max(48, "Maximum 48 characters are allowed"),
                assetTag: Yup.string().max(48, "Maximum 48 characters are allowed"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                let submitData = {
                  infraTypeId: Number(values.deviceType),
                  macAddress: (values.macAddress.replace(/[\s\.:\-]/g, "")),
                  infraDisplayName: values.name,
                  orgId: activeOrg.orgId,
                  sourceId: MANUAL,
                  venueId: venueId,
                  realInfra: realInfra,
                  serialNumber: values.serialNumber,
                  assetTag: values.assetTag
                };
                setPlaceLoading(true);
                const { context, run } = createRequest(services.infra.CREATE, [], submitData);

                run()
                  .then(async (response) => {
                    if (!!onPlace) {
                      await onPlace(response.data);
                    }
                    setShowModal(false);
                  })
                  .catch(err => {
                    const apiContext = createErrorContext(context, 'CREATE INFRASTRUCTURE', TICKET_CATEGORY.NETWORK, err)

                    let error = new CatchedWebError(err);
                    if (error.errorCode == "0x3FE")
                      setRealInfra(false);
                    else {
                      make_custom_toast('error', 'Infrastructure', error.message, true, 'Create Ticket', () => {
                        navigate(
                          `/organization/${activeOrg.orgId}/support/createticket`,
                          {
                            state: {
                              ticketContext: apiContext
                            }
                          }

                        );
                      })
                    }
                  })
                  .finally(() => {
                    setPlaceLoading(false);
                  });
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}>
                  <Row>
                    <Col xs={12} md={6}>
                      <TextInput autocomplete="off" isrequired type="text" name="macAddress" label="MAC Address" placeholder="E.g. 14:90:23:85:05:21" />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextInput autocomplete="off" isrequired type="text" name="name" label="Name" placeholder="E.g. New Infrastructure" noBottomMargin />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Label className="labelfont">Device Type<span className="text-danger">*</span></Label>
                      <Select
                        styles={reactselectThemeWithMargin}
                        options={modelTypeOptions}
                        removeTopMargin={true}
                        value={modelTypeOptions[0]?.options.find(it => it.value == values.deviceType) ?? modelTypeOptions[1]?.options.find(it => it.value == values.deviceType)}
                        onChange={(option) => {
                          setFieldValue('deviceType', option.value)
                        }} />
                    </Col>
                    <Col xs={12} md={6}>
                      <Label className="labelfont">Venue<span className="text-danger">*</span></Label>
                      <Input value={venueName} disabled={true} />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={12} md={6} className="input--min-height">
                      <TextInput
                        autocomplete="off"
                        type="text"
                        name="serialNumber"
                        label="Serial Number"
                        placeholder=""
                        noBottomMargin
                      />
                    </Col>
                    <Col xs={12} md={6} className="input--min-height">
                      <TextInput
                        autocomplete="off"
                        type="text"
                        name="assetTag"
                        label="Asset Tag"
                        placeholder=""
                        noBottomMargin
                      />
                    </Col>
                  </Row>
                  {!realInfra ?
                    <div className="mt-2 d-flex justify-content-end">
                      <div>
                        <span className="font-weight-bolder">No Certificate found for this device!</span>
                        <br />
                        Please recheck the Mac address or go ahead to add it as a non functional dummy device.
                      </div>
                    </div> : <></>}
                  <Row className="mt-2">
                    <Col xs={12}>
                      <div className="d-flex justify-content-end">
                        <span className="btn btn-outline-primary" onClick={() => {
                          if (!placeLoading) {
                            setRealInfra(true);
                            setAddInfra(false);
                          }
                        }}>
                          Cancel
                        </span>
                        <Button className="ml-2" color="primary" type="submit" disabled={placeLoading}>Save</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div> :
          <div>
            <InfiniteScroll 
            dataLength={list.length>0 ? list[0]?.children?.length : 0}
            hasMore={hasMore}
            next={()=>{
              fetchVenue(list.length>0 ? list[0]?.children?.length : 0)
            }}
            scrollableTarget="single-infra-selector"
            >
              <div className="mt-1" id="single-infra-selector">
                <Table className="table-view fixed-header">
                  <thead>
                    <tr>
                      <th style={{ minWidth: "400px" }}><ColumnHeader header="Name" attribute="Name" sort={sort} setter={setSort} /></th>
                      <th style={{ minWidth: "100px" }}><ColumnHeader header="Status" attribute="Status" sort={sort} setter={setSort} /></th>
                      <th style={{ minWidth: "170px" }}><ColumnHeader header="MAC Address" /></th>
                      <th style={{ minWidth: "170px" }}><ColumnHeader header="State" /></th>
                    </tr>
                  </thead>

                  <tbody>
                    {list && list?.length > 0 ? (
                      <SubList
                        level={1}
                        subList={list}
                        setSubList={setList}
                      />
                    ) : loading ? (
                      <tr>
                        <td
                          className="text-center p-3"
                          style={{ height: "45vh", backgroundColor: "white" }}
                          colSpan={20}
                        >
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          style={{ height: "40vh", backgroundColor: "white" }}
                          colSpan={4}
                        >
                          No Venue/Infra Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </InfiniteScroll>

            {
              selectedInfra != null && selectedInfra?.infraTypeId != defaultDeviceType &&
              < span >
                <span className="text-danger">
                  Warning:
                </span>
                The planned Infrastructure model and selected Infrastructure are different
              </span>
            }
            <div className="d-flex justify-content-end">
              <span className="btn btn-outline-primary" onClick={() => {
                if (!placeLoading) {
                  setShowModal(false);
                }
              }}>
                Cancel
              </span>
              <Button className="ml-2" color="primary" type="submit" disabled={placeLoading || selectedInfra == null || selectedInfra?.infraCategory != defaultDeviceCategory} onClick={async () => {
                setPlaceLoading(true);
                if (!!onPlace)
                  await onPlace();
                setShowModal(false);
              }}>Place</Button>
            </div>
          </div>
        }
      </InfraSelectContext.Provider >
    </div >
  );
};

SingleInfraSelector.propTypes = {};

SingleInfraSelector.defaultProps = {};

export default SingleInfraSelector;
