/**
 * Venue Service
 * @file venues.service.js
 * @description API Endpoints related to venue management
 * @author Utkarsh Gupta
 * @since 22 Jul 2022
 */

import Service from "./Service";
import { T, V } from "../helpers";
import { VIEW_API } from "../utility/constants";
const VX = V;

/** Services related to venue management */
const venue = {
  GET_ALL_FLOORS: new Service(venueId => VX(`venues/${venueId}/floor`), "GET"),
  CREATE_FLOOR: new Service(venueId => VX(`venues/${venueId}/floor`), "POST"),
  DELETE_FLOOR: new Service((venueId, floorId) => VX(`venues/${venueId}/floor/${floorId}`), "DELETE"),
  FLOORPLAN: new Service((venueId, floorId, purpose = "get", format = "dxf") => VX(`/venues/${venueId}/floor/${floorId}/floorplan?purpose=${purpose}${purpose === "get" ? "" : `&format=${format}`}`), "GET"),
  DELETE_FLOORPLAN: new Service((venueId, floorId) => VX(`/venues/${venueId}/floor/${floorId}/floorplan`), "DELETE"),
  SAVE_AP_DATA: new Service((venueId, floorId) => VX(`/venues/${venueId}/floor/${floorId}`), "PUT"),

  /** Get the list of venues or search through the same list
   * @example
   * params = [orgId: number, offset?: number = 0, limit?: number = 10, search?: string = ""]
   * data = {}
   */

  LIST_DASHBOARD: new Service((orgId, offset = 0, limit = 10, search = "", view, venueId, cols, sort = {}, filter = "All", filterData = {}) => {
    return T(`/organization/${orgId}/dashboard/venues?search=${search}&offset=${offset}&limit=${limit}&viewType=${VIEW_API[view]}&timezoneOffset=${(new Date()).getTimezoneOffset()}&filter=${filter}`
      + (venueId != null ? `&venueId=${venueId}` : "") + (cols != null ? `&columns=${cols}` : ``) + (sort.order != null ? `&order=${sort.order}` : ``) + (sort.orderBy != null ? `&orderkey=${sort.orderBy}` : ``)
      + (filterData?.orgId?.length > 0 ? `&orgId=${filterData.orgId}` : "")
      + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
      + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
      + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
      + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    )
  }, "GET"),

  SET_STATE: new Service((id) => V(`venues/${id}/state`), "PUT"),
  /** Create a new Venue
   * @example
   * params = []
   * data = {
   *   orgId: number,
   *   parentVenueId: number | null,
   *   venueName: string,
   *   venueAddress: {
   *     addressLine: string,
   *     state: string,
   *     city: string,
   *     zipCode: string
   *   },
   *   shippingAddress: {
   *     addressLine: string,
   *     state: string,
   *     city: string,
   *     zipCode: string
   *   }
   * }
   */
  POST: new Service(() => V("venues"), "POST"),

  IMPORT: new Service((orgId, preview, UUID, immediate = false) => V(`organization/${orgId}/venues-import?preview=${preview}&immediate=${immediate}` + (UUID != null ? `&uuid=${UUID}` : '')), "POST"),
  /** Delete venue by its Id
   * @example
   * params = [venueId: number]
   * data = {}
   */
  DELETE: new Service((venueId) => V(`venues/${venueId}`), "DELETE"),

  /** Get details of one venue 
   * @example
   * params = [venueId: number, orgId: number]
   * data = {}
  */
  GET: new Service((venueId, orgId) => V(`venues/${venueId}?orgId=${orgId}`), "GET"),

  /** Edit details of one venue
   * @example
   * params = [venueId: number]
   * data = {
   *   venueName: string,
   *   venueAddress: {
   *     addressLine: string,
   *     state: string,
   *     city: string,
   *     zipCode: string
   *   },
   *   shippingAddress: {
   *     addressLine: string,
   *     state: string,
   *     city: string,
   *     zipCode: string
   *   }
   * }
  */
  EDIT: new Service((venueId) => V(`venues/${venueId}`), "PUT"),

  /** Delete a floor plan 
   * @example
   * params = [venueId: number]
   * data = {}
  */
  DELETE_FLOORPLAN: new Service((venueId) => V(`venues/${venueId}/floorPlan`), "DELETE"),

  /** Get the pre-signed URL for floorPlan's image 
   * @example
   * params = [venueId: number, purpose: Union("get","put"), format? Union("jpeg", "png", "jpg") = "png"]
   * data = {}
  */
  FLOORPLAN_URL: new Service((venueId, purpose, format = "png") => V(`venues/${venueId}/floorPlan?purpose=${purpose}&format=${format}`), "GET"),

  /** Get the pre-signed URL for floorPlan's OBJ or DXF file 
   * @example
   * params = [venueId: number, purpose: Union("get", "put"), format: Union("obj", "dxf")]
   * data = {}
  */
  FLOORPLAN_3D_URL: new Service((venueId, purpose, format) => V(`venues/${venueId}/floorPlan?purpose=${purpose}&format=${format}`), "GET"),

  /** Get list of child venues of a venue filtered by provided orgId
   * @example
   * params = [orgId: number, venueId: number, depth? Union(1, 2, 3) = 1, all? boolean = false]
   * data = {}
  */
  GET_CHILDREN: new Service((orgId, venueId, depth = 1, all = false, sort = { order: "ASC", orderBy: "venueId" }) => V(`venues/${venueId}/child?orgId=${orgId}&depth=${depth}&all=${all}&order=${sort.order}&orderBy=${sort.orderBy}`), "GET"),
  GET_PARENT: new Service((venueId) => V(`venues/${venueId}/parent`), "GET")
};

export default venue;
