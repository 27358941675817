/***
 *
 * Controller class for user.
 * @file CreateTicket.js
 * @description CreateTicket component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import { TextInput } from "../../../components";
import { breadcrumbActions, ticketAction } from "../../../redux/slices";
import store from "../../../redux/store";
import createRequest, { services } from "../../../services";
import VenueSelector from "../../../components/VenueSelector";
import * as Yup from "yup";
import "./CreateTicket.scss";
import useAutoclear from "../../../hooks/useAutoclear";
import {
    CACHED_TICKET_KEY,
    CATEGORY_LIST,
    FILE_UPLOAD_ERROR_TEXT,
    PRIORITY_LIST,
    SUB_CATEGORY,
    SUB_CATEGORY_LIST,
    TICKET_LIMIT,
    TICKET_TYPE,
    editorConfig,
} from "../TicketConstants";
// import { extractAttachmentName, extractFileExtension, extractFileName } from "../utility/StringFunction";
import { uploadFile, useResetWarning } from "../utility/ticketUtility";
import {
    extractFileExtension,
    extractFileName,
} from "../utility/StringFunction";
import { CatchedWebError } from "../../../configs";
import TextEditor from "../../../components/TextEditor";
import { make_custom_toast } from "../../../helpers/toasts";
import SiteSelector from "../../../components/SiteSelector";
import OrgIcon from "../../../assets/images/icons/OrgGlobe.svg";
import VenueIcon from "../../../assets/images/icons/Site.svg";
import InfraIcon from "../../../assets/images/icons/AP_Black.svg"
import PencilIcon from "../../../assets/images/icons/Pencil.svg"
import SingleInfraSelector from "../../../components/SingleInfraSelector";
import InfraSelector from "../../../components/InfraSelector";

export const BlobToBase64New = (blobUrl) => {
    let img = new Image();
    img.src = blobUrl;
    URL.revokeObjectURL(blobUrl);
    let [w, h] = [img.width, img.height];
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL();
};

export const isBlobPresent = (text) => {
    const SEARCH_FOR = "src=\"blob:"
    return text.search(SEARCH_FOR) > 0;
}

export const extractBlobFromDescription = (data) => {
    let blobLinkArray = []
    let tempData = data;
    let firstIndex = 0
    let lastIndex = 0
    let value = ''
    while (tempData.length > 0) {
        firstIndex = tempData.indexOf("blob:")
        if (firstIndex < 0) break;
        lastIndex = tempData.indexOf('\"')
        value = tempData.substring(firstIndex, lastIndex)
        if (value.includes('blob:'))
            blobLinkArray.push(value)
        tempData = tempData.substring(lastIndex + 1)
    }
    return blobLinkArray
}

const EditorConfig = { ...editorConfig, height: "40vh" }

const CreateTicket = () => {
    const identityData = useSelector((state) => state.identity.data);
    const identityId = identityData.identityId;
    const requesterEmail = identityData.email;
    const requesterName = identityData.userName;
    const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
    const localTicketDetails = localStorage.getItem(CACHED_TICKET_KEY)
    const cachedTicketDetails = localTicketDetails ? JSON.parse(localTicketDetails) : {}
    const activeOrgName = useSelector(store => store.activeOrg.data.orgDisplayName);
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();
    const formikRef = useRef(null)
    const [showVenueModal, setShowVenueModal] = useState(false)
    const [showInfraModal, setShowInfraModal] = useState(false)
    const [ticketReference, setTicketReference] = useState("")
    // const param = URLSearchParams()
    const ticketContext = location?.state?.ticketContext;

    const ticketSubjectPrev = location.state?.ticketSubject
        ? location.state.ticketSubject
        : cachedTicketDetails.subject ? cachedTicketDetails.subject
            : "";

    const ticketPriorityPrev = location.state?.ticketPriority
        ? location.state.ticketPriority
        : cachedTicketDetails.priority ? cachedTicketDetails.priority
            : 1;

    const ticketDescriptionPrev = location.state?.ticketDescription
        ? location.state.ticketDescription
        : cachedTicketDetails.description ? cachedTicketDetails.description
            : "";

    const ticketMacAddressPrev = location.state?.macAddress
        ? location.state.macAddress
        : cachedTicketDetails?.infra?.macAddress ? cachedTicketDetails?.infra?.macAddress
            : "";

    const ticketVenueIdPrev = location.state?.venueId
        ? location.state?.venueId
        : cachedTicketDetails?.venue?.venueId ? cachedTicketDetails?.venue?.venueId
            : 0;

    const ticketInfraIdPrev = location.state?.infraId
        ? location.state.infraId
        : cachedTicketDetails?.infra?.infraItemId ? cachedTicketDetails?.infra?.infraItemId
            : 0;

    const ticketNetworkIdPrev = location.state?.networkId
        ? location.state.networkId
        : 0;

    const ticketVenueNamePrev = location.state?.venueName
        ? location.state.venueName
        : cachedTicketDetails?.venue?.venueName ? cachedTicketDetails?.venue?.venueName
            : "";

    const ticketInfraNamePrev = location.state?.infraName
        ? location.state.infraName
        : cachedTicketDetails?.infra?.infraName ? cachedTicketDetails?.infra?.infraName
            : "";
    const ticketNetworkNamePrev = location.state?.networkName
        ? location.state.networkName
        : "";
    const showVenuePrev = location.state?.openVenue
        ? location.state.openVenue
        : false;
    const ticketCategoryPrev = location?.state?.selectedCategory
        ? location?.state?.selectedCategory
        : ticketContext?.category
            ? ticketContext?.category
            : cachedTicketDetails?.category ? cachedTicketDetails?.category
                : CATEGORY_LIST[0].label;
    const ticketSubCategoryPrev = location.state?.selectedSubCategory
        ? location.state.selectedSubCategory
        : ticketContext?.subCategory
            ? ticketContext?.subCategory
            : cachedTicketDetails?.subCategory ? cachedTicketDetails?.subCategory
                : SUB_CATEGORY[CATEGORY_LIST[0].label][0].label;

    // const categoryList = location.state?.categoryList
    //     ? location.state.categoryList.length > 0
    //         ? location.state.categoryList
    //         : CATEGORY_LIST
    //     : CATEGORY_LIST;

    const categoryList = CATEGORY_LIST;
    const subCategoryList = SUB_CATEGORY_LIST;

    const [error, setError] = useState(null);
    const [showSelectVenue, setShowSelectVenue] = useState(
        showVenuePrev || true
    );
    const [selectedInfrastructure, setSelectedInfrastructure] = useState({
        infraItemId: ticketInfraIdPrev,
        infraName: ticketInfraNamePrev,
        macAddress: ticketMacAddressPrev,
    });
    const [selectedVenue, setSelectedVenue] = useState({
        venueId: ticketVenueIdPrev,
        venueName: ticketVenueNamePrev,
    });
    const [venueSelectorModal, setVenueSelectorModal] = useState(false);
    const [infrastructureList, setInfrastructureList] = useState([]);
    const [subject, setSubject] = useState(
        location.state?.ticketSubject
            ? location.state.ticketSubject
            : cachedTicketDetails.subject ? cachedTicketDetails.subject
                : ""
    );
    const [description, setDescription] = useState(
        cachedTicketDetails?.description ? cachedTicketDetails?.description
            : ticketContext?.errorMessage || ""
    );
    const [uploadedFiles, setUploadedFiles] = useState(cachedTicketDetails?.uploadedFiles ? cachedTicketDetails?.uploadedFiles : []);
    const [ticketPriority, setTicketPriority] = useState(ticketPriorityPrev);
    const [ticketApiLoading, setTicketApiLoading] = useState(false);
    const [ticketCategory, setTicketCategory] = useState(CATEGORY_LIST[0]);
    const [ticketSubCategory, setTicketSubCategory] = useState(SUB_CATEGORY[CATEGORY_LIST[0].label][0]);
    const [uploadUrl, setUploadUrl] = useState(cachedTicketDetails?.fileUrl ? cachedTicketDetails?.fileUrl : []);
    // const [openTicketCreatedModal, setOpenTicketCreatedModal] = useState(false) replaced by toast
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [emptyTitle, setEmptyTitle] = useState(false);
    const [emptyDescription, setEmptyDescription] = useState(false);
    const [ticketTitle, setTicketTitle] = useState(ticketSubjectPrev
        ? ticketSubjectPrev
        : ticketContext?.action || "",)

    // useAutoclear(ticketNotCreatedAlert, () => { setTicketNotCreatedAlert(false); }); // error message should not clear
    const editor = useRef(null);
    const cacheData = useRef(null)
    const [ticketReferenceError, setTicketReferenceError] = useState(false)
    const customStyles = {
        menu: (base) => ({
            ...base,
            zIndex: 100,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            svg: {
                height: "20px",
                width: "20px",
            },
        }),
    };
    const [params] = useSearchParams();
    const searchParam = new URLSearchParams(document.location.search)

    const breadCrumbs = [
        {
            path: `/organization/${activeOrgId}/support/tickets`,
            text: "Support",
            active: false,
        },
        {
            path: `/organization/${activeOrgId}/support/createticket`,
            text: searchParam.get("type") == TICKET_TYPE.FEATURE ? "Create Feature Request" : "Create Ticket",
            active: true,
        },
    ];



    /* Making cache data for Ticket, when any of these values are changed 
    1. Subject
    2. Description
    3. Priority
    4. Category
    5. VenueDetails
    6. Infra details
    */
    useEffect(() => {
        let categoryValue = CATEGORY_LIST?.filter(
            (category) => category?.label == ticketCategory
        )[0]?.value

        cacheData.current = {
            subject: subject,
            description: description,
            priority: ticketPriority,
            category: categoryValue,
            venue: selectedVenue,
            infra: selectedInfrastructure,
            uploadedFiles: uploadedFiles,
            fileUrl: uploadUrl,
            cacheTime: new Date()

        }
    }, [subject, description, ticketPriority, ticketCategory, selectedInfrastructure, selectedVenue, uploadUrl, uploadedFiles])

    //saving the cache data of Ticket when component is unmounted and storing that in Redux 
    useEffect(() => {
        return () => {
            const cachedConvertedData = JSON.stringify(cacheData.current)
            try {
                localStorage.setItem(CACHED_TICKET_KEY, cachedConvertedData)
            }
            catch (e) {

            }
        }
    }, [])

    useResetWarning(ticketTitle, description, setEmptyTitle, setEmptyDescription)



    useEffect(() => {
        if (selectedVenue == null) {
            setInfrastructureList([]);
            setSelectedInfrastructure({
                infraItemId: ticketInfraIdPrev,
                infraName: ticketInfraNamePrev,
                macAddress: ticketMacAddressPrev,
            });
        }
        else {
            setSelectedInfrastructure({
                infraItemId: 0,
                infraName: '',
                macAddress: ''
            })
        }
    }, [selectedVenue]);

    useEffect(() => {
        if (ticketCategoryPrev) {
            setTicketCategory(
                CATEGORY_LIST?.filter(
                    (category) => category.label == ticketCategoryPrev
                )[0]
            );
            if (ticketSubCategoryPrev) {
                setTicketSubCategory(
                    SUB_CATEGORY[ticketCategoryPrev]?.filter(
                        (category) =>
                            category.label ==
                            ticketSubCategoryPrev
                    )[0]
                )
            }
        }


    }, []);

    // ---Params Use Effect
    useEffect(() => {
        store.dispatch(breadcrumbActions.setData(breadCrumbs));
    }, [params]);

    useEffect(() => {
        if (selectedVenue == null) {
            setSelectedInfrastructure({
                infraItemId: 0,
                infraName: "",
                macAddress: ""
            })
        }
    }, [selectedVenue])

    // // --- SelectedVenue Use Effect
    // useEffect(() => {
    //     if (selectedVenue && selectedVenue.venueId != 0) {
    //         const { run } = createRequest(services.infra.GET_BY_VENUE, [
    //             selectedVenue.venueId,
    //             0,
    //             1000,
    //             "",
    //         ]);
    //         run()
    //             .then((res) => {
    //                 let tempInfraList = [];
    //                 res.data.map((item) => {
    //                     tempInfraList.push({
    //                         value: item.infraItemId ? item.infraItemId : 0,
    //                         label: item.infraDisplayName
    //                             ? item.infraDisplayName
    //                             : "-",
    //                         macAddress: item.macAddress
    //                             ? item.macAddress
    //                             : "",
    //                     });
    //                 });
    //                 setInfrastructureList(tempInfraList);
    //                 if (
    //                     tempInfraList.length > 0 &&
    //                     selectedInfrastructure.infraItemId == 0
    //                 ) {
    //                     setSelectedInfrastructure({
    //                         infraItemId: tempInfraList[0].value,
    //                         infraName: tempInfraList[0]?.label,
    //                         macAddress: tempInfraList[0].macAddress,
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 setError(new CatchedWebError(err).message);
    //             });
    //     }
    // }, [selectedVenue]);


    useEffect(() => {
        if (ticketTitle.length > TICKET_LIMIT.TITLE) {
            setEmptyTitle(`Maximum ${TICKET_LIMIT.TITLE} characters are allowed.`)
        }
        else {
            setEmptyTitle(null)
        }



    }, [ticketTitle])





    const createTicket = () => {
        if (description.length < 10) {
            setEmptyDescription(true);
            if (ticketTitle.length < 3) {
                setEmptyTitle("Minimum 3 characters required.");
                return;
            }
            else if (ticketTitle.length > TICKET_LIMIT.TITLE) {
                setEmptyTitle(`Maximum ${TICKET_LIMIT.TITLE} characters are allowed.`)
                return;
            }
            return;

        }
        if (ticketTitle.length < 3) {
            setEmptyTitle("Minimum 3 characters required.");
            return;
        }
        else if (ticketTitle.length > TICKET_LIMIT.TITLE) {
            setEmptyTitle(`Maximum ${TICKET_LIMIT.TITLE} characters are allowed.`);
            return;
        }
        if (ticketReference.length > TICKET_LIMIT.REFERENCE) {
            setTicketReferenceError(`Maximum ${TICKET_LIMIT.REFERENCE} characters are allowed.`)
            return
        }

        const createTicketPayload = {
            subject: ticketTitle,
            description: description,
            status: 2, // 'open' status by default
            type: searchParam.get("type") == TICKET_TYPE.FEATURE ? TICKET_TYPE.FEATURE : TICKET_TYPE.BUG,
            severity: ticketPriority,
            category: searchParam.get("type") == TICKET_TYPE.FEATURE ? CATEGORY_LIST[3].label : ticketCategory?.label,
            subCategory: searchParam.get("type") == TICKET_TYPE.FEATURE ? SUB_CATEGORY[CATEGORY_LIST[0].label][0].label : ticketSubCategory?.label,
            source: 2,
            attachments: uploadUrl,
            venueId: selectedVenue ? selectedVenue?.venueId : 0,
            venueName: selectedVenue ? selectedVenue?.venueName : "",
            networkId: ticketNetworkIdPrev,
            networkName: ticketNetworkNamePrev,
            infraId: selectedInfrastructure
                ? selectedInfrastructure?.infraItemId
                : 0,
            infraName: selectedInfrastructure
                ? selectedInfrastructure.infraName
                : "",
            identityId: identityId,
            requesterEmail: requesterEmail,
            requesterName: requesterName,
            orgId: (selectedVenue && selectedVenue?.orgId) ? selectedVenue?.orgId : activeOrgId,
            orgName: activeOrgName,
            ticketReference: ticketReference,
            macAddress: selectedInfrastructure?.macAddress
                ? selectedInfrastructure?.macAddress
                : "",
        };


        const blobArray = extractBlobFromDescription(description);

        let newDescription = description

        if (isBlobPresent(description) && blobArray.length > 0) {
            for (let i = 0; i < blobArray.length; i++) {
                let tempBlobLink = blobArray[i]
                let convertedLink = BlobToBase64New(tempBlobLink)
                newDescription = newDescription.replace(tempBlobLink, convertedLink)

            }
            setDescription(newDescription)

        }
        createTicketPayload.description = newDescription



        const { run } = createRequest(services.ticket.CREATE_TICKET, [], createTicketPayload);
        setTicketApiLoading(true);
        run()
            .then((res) => {
                window.scrollTo(0, 0);
                const newTicket = res.data;
                // make_custom_toast(
                //     "success",
                //     "Ticket Successfully created",
                //     `Your Ticket #${newTicket.freshworksTicketId} is in review`,
                //     true,
                //     "View Ticket",
                //     () => {
                //         navigate(
                //             `/organization/${activeOrgId}/support/ticket/details/${newTicket.freshworksTicketId}`
                //         );
                //     }
                // );
                cacheData.current = {} //resetting the cacheData for ticket
                // store.dispatch(ticketAction.setOpenDraft(false))
                if (searchParam.get("type") == TICKET_TYPE.FEATURE) {
                    navigate(
                        `/organization/${activeOrgId}/support/features`,
                        { replace: true }
                    );
                }
                else {
                    navigate(
                        `/organization/${activeOrgId}/support/tickets/`,
                        { replace: true }
                    );
                }
            })
            .catch((err) => {
                window.scrollTo(0, 0);
                setTicketApiLoading(false);
                const x = new CatchedWebError(err)
                make_custom_toast('error', 'Ticket', x.message)
            });



    };

    const uploadFiles = (files) => {
        if (files == null || files == undefined || files.length < 1) {
            return;
        }
        let tempUploadedFiles = [...uploadedFiles];
        let tempUploadUrl = [...uploadUrl];
        setFileUploadLoading(true);
        const promiseList = files.map((file) => uploadFile(file, activeOrgId));
        Promise.all(promiseList)
            .then((resArray) => {
                setFileUploadLoading(false);
                resArray.map((res) => {
                    tempUploadUrl.push({
                        filename: res.fileName,
                    });
                    tempUploadedFiles.push({
                        filename: res.displayName,
                    });
                });
                setUploadUrl(tempUploadUrl);
                setUploadedFiles(tempUploadedFiles);
            })
            .catch((errArray) => {
                setFileUploadLoading(false);
                if (errArray?.errorCode === 900) {
                    make_custom_toast("error", "Ticket", errArray?.message ?? "File size exceeded 20MB")
                }
                else {
                    make_custom_toast("error", "Ticket", FILE_UPLOAD_ERROR_TEXT)
                }
            });
    };

    const deleteFile = (index) => {
        const fileName = encodeURIComponent(
            extractFileName(uploadUrl[index].filename)
        );
        const extension = extractFileExtension(uploadedFiles[index].filename);

        const { run } = createRequest(services.ticket.REMOVE_ATTACHMENT, [
            activeOrgId,
            fileName,
            extension,
        ]);
        run()
            .then((res) => {
                let tempUploadedFiles = [...uploadedFiles];
                let tempUploadUrl = [...uploadUrl];
                tempUploadedFiles.splice(index, 1);
                tempUploadUrl.splice(index, 1);
                setUploadedFiles(tempUploadedFiles);
                setUploadUrl(tempUploadUrl);
            })
            .catch((err) => {
                setError(new CatchedWebError(err).message);
            });
    };

    return (
        <>

            <div className="CreateTicket   pb-5 mt-2">

                <Row>
                    <Col md="5">
                        <div className="bg-white rounded shadow-sm  p-1 h-100">
                            <h5 className="fw-500 dark-black" >Title<span className="text-danger" >*</span></h5>
                            <textarea
                                className="w-100 no-border-textarea"
                                rows="5"
                                placeholder="Add Title"
                                value={ticketTitle}
                                onChange={(e) => {
                                    setTicketTitle(e.target.value)
                                    setSubject(e.target.value)
                                    // if(e.target.value.length>TICKET_LIMIT.LE)
                                }}

                            />
                            {!!emptyTitle && (
                                <span className="empty-description">{emptyTitle}</span>
                            )}
                        </div>
                    </Col>
                    <Col md="3" className=" ">
                        <div className="d-flex flex-column justify-content-between bg-white rounded shadow-sm h-100 p-1">
                            <div className="d-flex align-items-center">
                                <img src={OrgIcon} className="mr-1" />
                                <span>{activeOrgName}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between selector-container">
                                <div className="d-felx align-items-center">
                                    <img src={VenueIcon} className="mr-1" />
                                    <span className="tilted-text">{selectedVenue?.venueId ? selectedVenue?.venueName ? selectedVenue?.venueName : '-' : "No Venue Selected"}</span>
                                </div>
                                <img src={PencilIcon} style={{ height: '16px' }} className="hover-show cursor-pointer" onClick={() => {
                                    setShowVenueModal(true)
                                }} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between selector-container ">
                                <div className="d-flex align-items-center">
                                    <img src={InfraIcon} className="mr-1" />
                                    <span className="tilted-text">{selectedInfrastructure?.infraItemId ? selectedInfrastructure?.infraName ? selectedInfrastructure?.infraName : '-' : "No Infrastructure Selected"}</span>
                                </div>
                                <img src={PencilIcon} style={{ height: '16px' }} className={`hover-show ${selectedVenue?.venueId ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={() => {
                                    if (selectedVenue?.venueId)
                                        setShowInfraModal(true)
                                }} />
                            </div>
                            <div>
                                <div className="d-flex">
                                    <div className="fw-500 dark-black">Ticket reference:&nbsp;</div>
                                    <input
                                        className="ticket-reference-input"
                                        placeholder="Add Reference"
                                        value={ticketReference}
                                        onChange={(e) => {
                                            if (e.target.value.length <= TICKET_LIMIT.REFERENCE)
                                                setTicketReferenceError(false);
                                            setTicketReference(e.target.value)
                                        }}
                                    />

                                </div>
                                {
                                    !!ticketReferenceError &&
                                    <span className="text-danger">{ticketReferenceError}</span>
                                }
                            </div>
                        </div>

                    </Col>
                    <Col md="4">
                        <div className="bg-white rounded shadow-sm h-100 w-100 p-1">
                            <div className="d-flex align-items-center mb-50">
                                <span className="w-50 fw-500 dark-black">Status<span className="text-danger">*</span></span>
                                <div className="border rounded  w-50 pr-1 py-50 pl-0 numb-text padding-left-custom" style={{
                                    backgroundColor: "#f6f6f6",
                                    borderColor: "rgba(212, 212, 212, 1)",

                                }}>
                                    Open
                                </div>
                            </div>


                            <div className="d-flex align-items-center mb-50">
                                <span className="w-50 fw-500 dark-black">Severity<span className="text-danger">*</span></span>
                                <Select
                                    className="w-50"
                                    name={"ticketpriority"}
                                    classNamePrefix="select"

                                    defaultValue={PRIORITY_LIST.find(
                                        (it) =>
                                            it.value ===
                                            ticketPriorityPrev
                                    )}
                                    options={PRIORITY_LIST}
                                    isClearable={false}
                                    isSearchable={false}
                                    styles={customStyles}
                                    onChange={(option) => {
                                        setTicketPriority(
                                            option.value
                                        );
                                    }}
                                />
                            </div>

                            {
                                searchParam.get("type") != TICKET_TYPE.FEATURE &&
                                <div className="d-flex align-items-center mb-50">
                                    <span className="w-50 fw-500 dark-black">Category<span className="text-danger">*</span></span>
                                    <Select
                                        className="w-50"
                                        name={"ticketpriority"}
                                        classNamePrefix="select"

                                        defaultValue={CATEGORY_LIST.find(
                                            (it) =>
                                                it.label ===
                                                ticketCategoryPrev
                                        )}
                                        options={[...CATEGORY_LIST].slice(0, 3)}
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={customStyles}
                                        onChange={(option) => {
                                            setTicketCategory(
                                                option
                                            );
                                            setTicketSubCategory(SUB_CATEGORY[option.label][0])
                                        }}
                                    />
                                </div>
                            }
                            {
                                searchParam.get("type") != TICKET_TYPE.FEATURE &&
                                <div className="d-flex align-items-center">
                                    <span className="w-50 fw-500 dark-black">Sub-Category<span className="text-danger">*</span></span>
                                    <Select
                                        className="w-50"
                                        classNamePrefix="select"
                                        // defaultValue={}
                                        value={ticketSubCategory}
                                        options={SUB_CATEGORY[ticketCategory?.label]}
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={customStyles}
                                        onChange={(option) => {
                                            setTicketSubCategory(
                                                option
                                            );
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>


                {/* {showSelectVenue && (
                    <Row className="fadable">
                        <Col xs="8" md="6">
                            <Label className="labelfont">
                                Select Venue (optional)
                            </Label>
                            <InputGroup>
                                <Input
                                    type="text"
                                    disabled
                                    value={
                                        selectedVenue &&
                                            selectedVenue.venueId !==
                                            0
                                            ? selectedVenue.venueName
                                            : "No Venue Selected"
                                    }
                                    isrequired
                                />
                                <Button
                                    color="outline-primary rounded-custom"
                                    onClick={() => {
                                        setVenueSelectorModal(
                                            true
                                        );
                                    }}
                                >
                                    Select Venue
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs="8" md="6" className="d-flex ">
                            <div className="d-flex flex-column w-100">
                                <Label className={"labelfont"}>
                                    Select Infrastructure
                                    (optional)
                                </Label>
                                <Select
                                    key={`SELECT_INFRA_KEY_${infrastructureList[0]?.label}`}
                                    isDisabled={
                                        selectedVenue &&
                                            selectedVenue.venueId ==
                                            0
                                            ? true
                                            : false
                                    }
                                    className={"selectBox"}
                                    classNamePrefix="select"
                                    // value={selectedInfrastructure}
                                    defaultValue={
                                        selectedInfrastructure.infraName
                                            ? {
                                                label: selectedInfrastructure.infraName,
                                                value: selectedInfrastructure.infraItemId,
                                            }
                                            : infrastructureList[0] ||
                                            "Select"
                                    }
                                    options={infrastructureList}
                                    isClearable={false}
                                    isSearchable
                                    styles={customStyles}
                                    onChange={(option) => {
                                        setSelectedInfrastructure(
                                            {
                                                infraItemId:
                                                    option.value,
                                                infraName:
                                                    option?.label,
                                                macAddress:
                                                    option.macAddress,
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                )} */}


                {/* --- Decription and Attachment View --- */}

                <div className="my-2">
                    <Row>
                        <Col md="8">
                            {/* <TextInput label="Description (Not more than 1000 characters)" placeholder='Add description' rows='5' type="textarea" name="ticketdescription" isrequired /> */}
                            <div className="bg-white p-1 rounded shadow-sm h-100 editor-container">

                                <Label className="fw-500 dark-black" style={{ fontSize: "1em" }}>
                                    Description
                                    <span className="text-danger">
                                        *
                                    </span>
                                </Label>
                                <TextEditor
                                    ref={editor}
                                    value={description ?? ""}
                                    config={EditorConfig}
                                    tabIndex={1} // tabIndex of textarea
                                    onChange={(newDescription) => {
                                        if (newDescription.length >= TICKET_LIMIT.MIN.DESCRIPTION) {
                                            setEmptyDescription(null)
                                        }
                                    }}
                                    onBlur={(newDescription) =>
                                        setDescription(newDescription)
                                    } // preferred to use only this option to update the content for performance reasons
                                // onChange={(newDescription) => { setDescription(newDescription) }}
                                />
                                {emptyDescription && (
                                    <span className="empty-description">{`Minimum 10 characters required.`}</span>
                                )}
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="bg-white rounded shadow-sm p-1 h-100">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="cursor-default">
                                        Upload Attachment
                                    </h5>
                                    {fileUploadLoading &&
                                        <Spinner size={'sm'} color={`primary`} />
                                    }
                                </div>

                                <div className="fileupload">
                                    <Dropzone onDrop={uploadFiles}>
                                        {({
                                            getRootProps,
                                            getInputProps,
                                        }) => (
                                            <section className="d-flex w-100">
                                                <div
                                                    {...getRootProps()}
                                                    className="d-flex justify-content-center align-items-center  w-100 text-center"
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <div className="text-center">
                                                        <p className="cursor-default">
                                                            <div className="d-flex align-items-center mt-1">
                                                                <span className="material-symbols-outlined large text-primary cursor-default">
                                                                    file_upload
                                                                </span>
                                                                <span className="cursor-pointer text-primary">
                                                                    Upload a file
                                                                </span>&nbsp;
                                                                or drag and drop
                                                            </div>
                                                            <div className="text-muted text-center" style={{ fontSize: '12px' }}>
                                                                (Maximum Upload file size: 20MB)
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="pt-1">
                                    {uploadedFiles.length > 0 && uploadedFiles.map(
                                        (file, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="ticket-file d-flex align-items-center justify-content-between rounded py-50 px-1 mb-1 "
                                                >
                                                    <span>
                                                        <span></span>
                                                        <span className="text-primary">{file.filename}</span>
                                                    </span>
                                                    <span
                                                        className="bin-icon ml-1 cursor-pointer d-inline material-symbols-outlined"
                                                        onClick={() => { deleteFile(index); }}
                                                    >
                                                        delete
                                                    </span>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>

                {/* <div className="d-flex justify-content-between">
                    {uploadedFiles.length > 0 ? (
                        <Dropzone onDrop={uploadFiles}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input
                                            {...getInputProps()}
                                        />
                                        <div>
                                            <Button
                                                outline
                                                className="mr-1"
                                                disabled={
                                                    ticketApiLoading
                                                }
                                                color="primary"
                                                onClick={() => { }}
                                            >
                                                Add Files
                                            </Button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    ) : (
                        <div />
                    )}
                </div> */}


                {/* --- Create Ticket Button --- */}
                <div className="d-flex justify-content-end">
                    <Button
                        outline
                        className="mr-1 small-add-button"
                        disabled={ticketApiLoading}
                        color="primary"
                        onClick={() => {
                            cacheData.current = {} //resetting the cacheData for ticket
                            navigate(-1);
                        }}>

                        Discard
                    </Button>
                    <Button
                        disabled={ticketApiLoading}
                        className="small-add-button"
                        // type={"submit"}
                        onClick={() => {
                            createTicket()
                        }}
                        color="primary"
                    >
                        {ticketApiLoading ? (
                            <Spinner size={"sm"} />
                        ) : (
                            `Create`
                        )}
                    </Button>
                </div>



                {/* <Modal
                    centered
                    isOpen={venueSelectorModal}
                    toggle={() => {
                        setVenueSelectorModal(false);
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            setVenueSelectorModal(false);
                        }}
                    >
                        Assign to Venue
                    </ModalHeader>
                    <ModalBody>
                        <VenueSelector
                            showCount
                            selectedVenue={selectedVenue}
                            setSelectedVenue={setSelectedVenue}
                            setVenueSelectorModal={
                                setVenueSelectorModal
                            }
                            showChild
                        />
                    </ModalBody>
                </Modal> */}

                {
                    showVenueModal &&
                    <Modal size="lg" centered isOpen={showVenueModal} toggle={() => { setShowVenueModal(false); }}>
                        <ModalHeader className="p-0 bg-white" toggle={() => { setShowVenueModal(false); }}></ModalHeader>
                        <ModalBody>
                            <SiteSelector
                                setShowModal={setShowVenueModal}
                                selectedVenue={selectedVenue}
                                setSelectedVenue={setSelectedVenue}
                                columns={{
                                    Country: true,
                                    Infrastructure: true
                                }}
                                showFilters={true}
                                modalTitle="Select Venue"
                            />
                        </ModalBody>
                    </Modal>
                }

                {
                    showInfraModal &&
                    <Modal style={{ maxWidth: '1020px', width: '100%' }} isOpen={showInfraModal} centered toggle={() => setShowInfraModal(false)}>
                        <ModalHeader className="p-0 bg-white" toggle={() => setShowInfraModal(false)}></ModalHeader>
                        <ModalBody>

                            <InfraSelector
                                headingText="Select Infrastructure"
                                venueId={selectedVenue?.venueId}
                                venueName={selectedVenue?.venueName}
                                // defaultDeviceType={props.data?.infraTypeId}
                                // defaultDeviceCategory={props.data?.infraCategory}
                                selectedInfra={selectedInfrastructure}
                                setSelectedInfra={setSelectedInfrastructure}
                                setShowModal={setShowInfraModal}
                                hideState
                            />
                        </ModalBody>
                    </Modal>
                }
            </div >

        </>
    );
};

CreateTicket.propTypes = {};

CreateTicket.defaultProps = {};

export default CreateTicket;