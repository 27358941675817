/***
 *
 * Controller class for user.
 * @file RadiusDetails.js
 * @description RadiusDetails component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./RadiusDetails.scss";
import { Modal, ModalBody, Table } from "reactstrap";
import { ReactComponent as Key } from "../../assets/images/icons/Key Blue.svg";
import { LowerCase } from "../../utility/Utils";
import { CLIENT_EVENTS } from "../../utility/constants";

export const AttributeFormatter = (props) => {
  const {
    attributeData
  } = props;
  
  return <>
  {attributeData.attributeName??''}{attributeData.attribute != null ? `(${attributeData.attribute})` : ''}:&nbsp;{attributeData.attributeValue??''}
  </>
}
// const dummyData = [
//   {
//     attribute: 2,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting error connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connectingerror connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
//   {
//     attribute: 4,
//     attributeName: 'username',
//     attributeValue: 'error connecting'
//   },
// ]

const RadiusDetails = (props) => {
  const {
    event={}
  } = props;

  // const details = dummyData
  const details = event.radius ?? []

  const [isModalOpen, setIsModalOpen] = useState()
  return (
    <span className="RadiusDetails" data-testid="RadiusDetails">
      <AttributeFormatter attributeData={details[0]}/>
      <Key 
        onClick={() => setIsModalOpen(true)}
        className="cursor-pointer ml-1"
      />
      <Modal 
        isOpen={!!isModalOpen}
        toggle={() => setIsModalOpen(false)}
        centered
        className="radius-details-modal"
        size='lg'
      >
        {/* <ModalHeader
          className="p-0 bg-white"
          toggle={() => setIsModalOpen(false)}
        >
        </ModalHeader> */}
        <ModalBody>
          <div className='radius-attributes-list-heading heading-style-text mb-50'>AAA Response Attributes</div>
          <div className="mb-1">
          {LowerCase(event.event) === LowerCase(CLIENT_EVENTS.AAA_REJECTED_AUTH)
          ? (
            `Received Access-Reject from AAA server${event.aaaServer != null ? ` ${event.aaaServer}`:''}${event.aaaPort != null ? `:${event.aaaPort}`:''}${event.aaaRTT != null ? ` with an RTT of  ${event.aaaRTT} ms`:''}.`
          ) : event.aaaServer != null ? (
            `Received Access-Accept from AAA server${event.aaaServer != null ? ` ${event.aaaServer}`:''}${event.aaaPort != null ? `:${event.aaaPort}`:''}${event.aaaRTT != null ? ` with an RTT of  ${event.aaaRTT} ms`:''}.`
          ) : null
          }
          </div>
          <div className="attributes-list w-100">
          <Table className="table-view">
            <thead>
              <tr>
                <th>
                  ID
                </th>
                <th>
                  Name
                </th>
                <th>
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {details?.map((attributeData,key) => 
                <tr className="hoverable" key={key}>
                  <td>
                    {attributeData?.attribute??''}
                  </td>
                  <td className='text-nowrap'>
                    {attributeData?.attributeName??''}
                  </td>
                  <td className='text-nowrap'>
                    {attributeData?.attributeValue??''}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* {details?.map((attributeData, index) => {
            return (
              <div key={index} className="mb-1">
                <AttributeFormatter attributeData={attributeData}/>
              </div>
            )
          })} */}
          </div>
        </ModalBody>
      </Modal>
    </span>
  );
};

RadiusDetails.propTypes = {};

RadiusDetails.defaultProps = {};

export default RadiusDetails;
