import PropTypes from "prop-types";
import "./AvailableLayouts.scss";
import { useCallback, useEffect, useState } from "react";
import createRequest, { services } from "../../../../../../services";
import { FAKE_WALL_DATA, FETCH_LIMIT_LAYOUTS, LAYOUT_TYPES, SUPPORTED_IMAGE_TYPES } from "../../../constants";
import { CatchedWebError } from "../../../../../../configs";
import { Col, Container, Input, InputGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import AvailableLayoutsItem from "../AvailableLayoutsItem";
import AvailableLayoutsItemSkeleton from "../AvailableLayoutsItemSkeleton";
import { SearchIconAddon, Tabs } from "../../../../../../components";
import ImageUploadModal from "../../../ImageUploadModal";
import LayoutDeletionConfirmation from "../../../LayoutDeletionConfirmation";
import { wre } from "../../../../../../services/wre.service";
import { make_toast } from "../../../../../../helpers";
import { make_custom_toast } from "../../../../../../helpers/toasts";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import axios from "axios";

/**
 * Available Layouts List (Infinitely Loaded)
 * @param {{
 *  layoutManager: object
 *  venueId: number
 * }} props 
 * @returns 
 */

export const DISPLAY_LAYOUT = {
  SCAN: 2,
  IMAGE: 1
}
let timer;
const debounce = (func) => {
  clearTimeout(timer);
  timer = setTimeout(func, 800);
}
const AvailableLayouts = (props) => {
  const { venueId } = props;
  const [search, setSearch] = useState("");
  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const orgId = useSelector(store => store.activeOrg.data.orgId);
  const { insertLayout } = props.layoutManager;

  const [layouts, setLayouts] = useState([]);
  const [layoutsLoading, setLayoutsLoading] = useState(false);
  const [layoutsError, setLayoutsError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [active, setActive] = useState(currentInsertedLayout == LAYOUT_TYPES.IMAGE ? 1 : 2);
  const [uploadImageDialog, setUploadImageDialog] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState({ id: 0, name: '' })
  const [fileSelected, setFileSelected] = useState(null)
  const [layoutsCount, setLayoutsCount] = useState(0)
  const [imageCount, setImageCount] = useState(0)


  const TABS = [

    {
      text: "Scan",
      id: DISPLAY_LAYOUT.SCAN,
    },
    {
      text: "Image",
      id: DISPLAY_LAYOUT.IMAGE,
    }

  ]



  // Fetch Layouts in Chunks
  const fetchLayouts = useCallback(async (from, to, silent = false) => {
    if (venueId) {
      if (!silent) {
        setLayoutsLoading(true);
      }
      setLayoutsError(false);
      const { run } = createRequest(services.wre.GET_LAYOUTS_BY_VENUE, [venueId, from, to, null, search]);
      run()
        .then(response => {
          // setImageCount((response.data.filter(layout => layout.layoutType === LAYOUT_TYPES.IMAGE)).length)
          // setLayoutsCount((response.data.filter(layout => layout.layoutType != LAYOUT_TYPES.IMAGE)).length)
          if (response.data.length !== FETCH_LIMIT_LAYOUTS * 2) {
            setHasMore(false);
          }
          if (silent) {
            let newData = response.data
            for (let i = 0; i < newData.length; i++) {
              if (newData[i].layoutType === LAYOUT_TYPES.IMAGE) {
                newData[i].layoutJson = FAKE_WALL_DATA

              }
            }
            setLayouts(newData);
          } else {
            let newData = response.data
            for (let i = 0; i < newData.length; i++) {
              if (newData[i].layoutType === LAYOUT_TYPES.IMAGE) {
                newData[i].layoutJson = FAKE_WALL_DATA

              }
            }
            setLayouts(lts => lts.concat(newData));
          }
        })
        .catch(err => {
          // HANDLE ERROR
          const x = new CatchedWebError(err);
          setLayoutsError(x.message);
          setHasMore(false)
        })
        .finally(() => {
          setLayoutsLoading(false);
        })
    }
  }, [venueId, search]);

  useEffect(() => {
    setLayouts([]);
    setHasMore(true);
    fetchLayouts(0, FETCH_LIMIT_LAYOUTS * 2)
    // getLayoutCount()
  }, [fetchLayouts])

  useEffect(() => {
    setImageCount((layouts.filter(layout => layout.layoutType === LAYOUT_TYPES.IMAGE)).length)
    setLayoutsCount((layouts.filter(layout => layout.layoutType != LAYOUT_TYPES.IMAGE)).length)
  }, [layouts])

  const deleteLayout = (id, resolve = () => { }, reject = () => { }) => {
    setHasMore(true);
    const { run } = createRequest(wre.DELETE_LAYOUT, [id]);
    run()
      .then(_ => {
        document.getElementById("mainBod").scrollTop = 0;
        fetchLayouts(0, FETCH_LIMIT_LAYOUTS * 2, true);
        // getLayoutCount()
        resolve();
      })
      .catch(err => {
        // HANDLE ERROR
        const x = new CatchedWebError(err);
        make_custom_toast('error', x.message)
        reject();
      })
  };

  // const getLayoutCount = () => {
  //   const { run, controller } = createRequest(services.wre.GET_LAYOUT_COUNT, [venueId])
  //   run()
  //     .then(res => {
  //       setLayoutsCount(res.data.count)
  //     })
  //     .catch(err => {
  //       make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
  //     })
  // }



  const removeFileExtension = (fileName) => {
    const removedName = fileName.split('.')
    return removedName[0]
  }

  const uploadNewImage = () => {
    setUploadImageDialog(true)
  }
  const createImageLayout = async (file) => {
    const { run } = createRequest(wre.CREATE_LAYOUT, [1], {
      venueId: venueId,
      name: removeFileExtension(file.name),
      layoutFile: removeFileExtension(file.name),
      isActive: true,
      infraPositions: []
    })
    const response = await run();
    const id = response.data.id;

    const newLayoutFileName = 'layout-' + id
    const { run: editLayout } = createRequest(wre.EDIT_LAYOUT, [id], {
      layoutFile: newLayoutFileName.trim()
    })
    await editLayout()

    const { run: run2 } = createRequest(wre.SET_IMAGE, [orgId, id]);
    const response2 = await run2();
    const urlToUploadTo = response2.data;
    await axios.put(urlToUploadTo, file);
    // getLayoutCount();
    fetchLayouts(0, FETCH_LIMIT_LAYOUTS * 2, true)
  }

  const getScannedLayoutCount = (layoutCount, imageCount) => {
    if ((layoutCount - imageCount) >= 0)
      return layoutCount - imageCount
    return 0
  }

  return (
    <div className="AvailableLayouts">
      <h4 className="p-1 m-0 border-bottom">
        Available {active == DISPLAY_LAYOUT.SCAN ? `Layouts (${getScannedLayoutCount(layoutsCount, imageCount)})`
          : `Images (${imageCount})`}
      </h4>
      <Container className="px-1 m-0">

        <Nav tabs className="infra-types-tabs">
          {TABS.map(tab => {
            return (
              <NavItem key={tab}>
                <NavLink
                  className={active === tab.id ? "ActiveTab" : (currentInsertedLayout != LAYOUT_TYPES.NONE && tab.id != currentInsertedLayout) ? "BlockedTab" : ""}
                  onClick={() => {
                    if ((currentInsertedLayout == LAYOUT_TYPES.NONE) || (currentInsertedLayout == tab.id)) {
                      setActive(tab.id)
                    }
                  }}>
                  {tab.text}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        {/* {TABS.map((tab) =>
            <span
              key={tab.id}
              className={"cursor-pointer Tab " + (active === tab.id ? "ActiveTab" : "")}
              onClick={() => setActive(tab.id)}
            >{tab.text}
            </span>
          )} */}


        <Row className="p-0 m-0 mt-2">

          <InputGroup className="input-group-merge">
            <Input type="text" placeholder="Search by layout name" onChange={(e) => {
              debounce(() => setSearch(e.target.value))
            }} />
            <SearchIconAddon />
          </InputGroup>
        </Row>
        <Row>
          {layoutsLoading && <Col xs={6} className="mt-1"><AvailableLayoutsItemSkeleton /></Col>}
          {!layoutsLoading && layoutsError && (
            <Col xs={12} className="text-center text-danger">
              {layoutsError}
            </Col>
          )}
        </Row>
        <Container className="p-0 mr-0">
          {!layoutsError && (layouts.length > 0 ?
            <InfiniteScroll
              dataLength={layouts.length}
              next={() => { fetchLayouts(layouts.length, FETCH_LIMIT_LAYOUTS * 2) }}
              hasMore={hasMore}
              // loader={<Col xs={6}><AvailableLayoutsItemSkeleton /></Col>}
              scrollThreshold={0.8}
              scrollableTarget="available-layouts-list"
              className="w-100 mt-1 p-0"
            >
              <Row className="p-0 m-0 layout-list-container" >
                {layouts.map(layout => {
                  if (active == DISPLAY_LAYOUT.SCAN ? layout.layoutType === LAYOUT_TYPES.SINGLE_LIDAR_SCAN : layout.layoutType === LAYOUT_TYPES.IMAGE)
                    return (
                      <Col xs={6} key={layout.id} className="p-0 pr-50 mb-1">
                        <AvailableLayoutsItem
                          draggedItem={props.draggedItem}
                          onAdd={() => { insertLayout(layout); }}
                          layout={layout}
                          type={active}
                          onDelete={() => { setDeleteConfirmationOpen({ id: layout.id, name: layout.name }) }}
                          onSuccess={() => fetchLayouts(0, FETCH_LIMIT_LAYOUTS * 2, true)}
                        />
                      </Col>)
                })}
                {
                  active == DISPLAY_LAYOUT.IMAGE &&
                  <Col xs={6} className="p-0 pr-50 ">

                    <Dropzone
                      onDrop={file => {
                        if (!SUPPORTED_IMAGE_TYPES.includes(file[0].type)) {
                          make_custom_toast("error", "Layouts", "Please upload a supported file.");
                        } else {
                          // setFileSelected(file[0]);
                          createImageLayout(file[0])
                        }
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="d-flex flex-column justify-content-center align-items-center p-1 text-primary cursor-pointer upload-new-image-container"
                          onClick={uploadNewImage}
                        >
                          <input {...getInputProps()} />
                          <span class="material-symbols-outlined mb-1" style={{ fontSize: '3em' }}>
                            upload
                          </span>
                          <span>
                            Add Image
                          </span>
                        </div>
                      )}
                    </Dropzone>

                  </Col>

                }
              </Row>
            </InfiniteScroll> :
            (!layoutsLoading ?
              <div className="text-center pt-3 text-secondary">
                {
                  active == DISPLAY_LAYOUT.IMAGE &&
                  <Col xs={6} className="p-0 pr-50 ">

                    <Dropzone onDrop={file => {
                      if (!SUPPORTED_IMAGE_TYPES.includes(file[0].type)) {
                        make_custom_toast("error", "Layouts", "Please upload a supported file.");
                      } else {
                        // setFileSelected(file[0]);
                        createImageLayout(file[0])
                      }
                    }}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="d-flex flex-column justify-content-center align-items-center p-1 text-primary cursor-pointer upload-new-image-container"
                          onClick={uploadNewImage}
                        >
                          <span class="material-symbols-outlined mb-1" style={{ fontSize: '3em' }}>
                            upload
                          </span>
                          <span>
                            Add Image
                          </span>
                        </div>
                      )}
                    </Dropzone>

                  </Col>

                } </div> : <> Loading... </>))
          }
        </Container>
      </Container>

      <LayoutDeletionConfirmation
        isOpen={deleteConfirmationOpen}
        toggle={() => { setDeleteConfirmationOpen({ id: 0, name: "" }) }}
        deleteLayout={deleteLayout}
      />

      <ImageUploadModal
        uploadImageDialog={uploadImageDialog}
        setUploadImageDialog={setUploadImageDialog}
        afterSaveSuccess={() => {
          // getLayoutCount(); 
          fetchLayouts(0, FETCH_LIMIT_LAYOUTS * 2, true)
        }}


      />
    </div >
  );
};

AvailableLayouts.propTypes = {
  layoutManager: PropTypes.object,
  venueId: PropTypes.number
};
AvailableLayouts.defaultProps = {};

export default AvailableLayouts;