//import { useState } from 'react';
//import SupportModal from '../../SupportModal';
import './CustomToast.scss';
import { useSelector } from 'react-redux';
const BELL_ICON = require('../../../assets/images/icons/custom_toast/bell_icon.webp');
const SUCCESS_ICON = require('../../../assets/images/icons/custom_toast/success_icon.webp');
const INFO_ICON = require('../../../assets/images/icons/custom_toast/info_toast_icon.webp');

/**
 * JSX Component for displaying selected images in a fullscreen Modal
 * @param {object} props
 * @param {string} type type of
 * @param {string} props.title Title of
 * @param {string} props.message Message displayed in Toast
 * @returns
 */
const CustomToast = (props) => {
  const { type, title, message, hasAction, actionText, action } = props;
  const permissions = useSelector((store) => store?.rbac?.permissions);
  const rbac = useSelector((store) => store?.rbac?.data);

  const makeTicket = () => {};

  const getToastIcon = () => {
    if (type === 'error') return BELL_ICON;
    if (type === 'success') return SUCCESS_ICON;
    if (type === 'info') return INFO_ICON;
  };

  return (
    <div className='CustomToast cursor-default'>
      <div>
        {/* ---- Title---- */}
        <span>
          <img
            className='mr-1'
            src={getToastIcon()}
            style={{ width: '30px' }}
          />
          <span className={`custom-toast-title ${type}`}>{title}</span>
        </span>

        {/* --- Message --- */}
        <div className='custom-toast-message'>{message}</div>
        <div className='mt-1 d-flex justify-content-end'>
          {(hasAction && (rbac?.level < 3 && permissions?.manageTicket?.create)) && (
            <span
              className='cursor-pointer create-ticket-button'
              onClick={action === 'maketicket' ? makeTicket : action}
            >
              {actionText}
            </span>
          )}
          {/* {ticketContext && (
            <span
              className='cursor-pointer create-ticket-button'
              onClick={() => {
                navigate(
                  `/organization/${activeOrgId}/support/ticket/createticket`,
                  {
                    state: {
                      ticketContext: ticketContext,
                    },
                  }
                );
              }}
            >
              Create Ticket
            </span>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default CustomToast;
