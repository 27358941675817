/***
 *
 * Controller class for user.
 * @file MapWidget.js
 * @description MapWidget component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./MapWidget.scss";
import { Input, InputGroup, Spinner } from "reactstrap";
import { filterObj } from "../OrgWidget/utils";
import GroupButton from "../GroupButton";
import SearchIconAddon from "../SearchIconAddon";
import FilterButton from "../FilterButton";
import { useSelector } from "react-redux";
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import RedMarker from "../../assets/images/icons/RedMarker.svg";
import OrangeMarker from "../../assets/images/icons/OrangerMarker.svg";
import GreenMarker from "../../assets/images/icons/GreenMarker.svg";
import RedMarkerWithIcon from "../../assets/images/icons/RedWithSite.svg";
import OrangeMarkerWithIcon from "../../assets/images/icons/OrangeWithSite.svg";
import GreenMarkerWithIcon from "../../assets/images/icons/GreenWithSite.svg";
import MapModal from "../MapModal";

/// Input debounce for search box
let timer;
const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 1000);
}

export const zoneMap = {
  RED_ZONE: RedMarkerWithIcon,
  ORANGE_ZONE: OrangeMarkerWithIcon,
  YELLOW_ZONE: OrangeMarkerWithIcon,
  GREEN_ZONE: GreenMarkerWithIcon,
} 


const MapWidget = (props) => {
  const {venues, loading, height} = props;
  const [map, setMap] = useState(null);
  const [selectedLoc, setSelectedLoc] = useState(null);

  useEffect(() => {
    setSelectedLoc(null)
  },[venues])

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({lat: 41, lng: -87}, {lat:42, lng:-86});
    map?.fitBounds(bounds);
    setMap(map);
    venues?.map((item, index) => {
        // console.log(item.venueId, { lat: item.lat??0, lng: item.lng??0 })
        // console.log(item.venueId,"--->", item.lat, item.lng);
        map?.panTo({ lat: Number(item.lat??0), lng: Number(item.lng??0) });
        map?.setZoom(15);

        if (venues.length > 1) {
          bounds?.extend({
            lat: Number(item.lat??0),
            lng: Number(item.lng??0),
          });
          map?.fitBounds(bounds);
        }
      });
  }, [venues]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div className="MapWidget" data-testid="MapWidget">
      {/* Map View */}
      <div className="position-relative">
      <MapModal
        site={selectedLoc}
        openModal={selectedLoc != null}
        setOpenModal={setSelectedLoc}
      />
      {(!loading)? <div id="map-widget">
        <GoogleMap
          mapContainerStyle={{
            height: height??"360px",
            width:"100%"
          }}
          options={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            clickableIcons: false,
            minZoom: 2
          }}
          onClick={() => {setSelectedLoc(null)}}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerClusterer
            onClick={(clusterer) => {
              // console.log(clusterer.getCenter(),clusterer.getMarkers())
              const lat = clusterer.getCenter().lat();
              const lng = clusterer.getCenter().lng();
              const check = clusterer.getMarkers().every(it => it.position.lat() == lat && it.position.lng() == lng)
              if(check){
                setSelectedLoc(venues.filter(item => item.lng == lng && item.lat == lat))
              }
            }}
            // Default --- styles assigned according to cluster size like index-1 for small, 2-for medium and 3-for large
            // Customized --- based on index in calculator (index value here starts with 1)
            styles={[
              {
                url: RedMarker,
                height: 38,
                width: 38,
                textSize: 15,
                textColor: "white",
                anchorText: [-2, 0], //[y,x]
              },
              {
                url: OrangeMarker,
                height: 38,
                width: 38,
                textSize: 15,
                textColor: "white",
                anchorText: [-2, 0],
              },
              {
                url: GreenMarker,
                height: 38,
                width: 38,
                textSize: 15,
                textColor:"white",
                anchorText: [-2, 0],
              },
            ]}
            // Calculator to decide which cluster icon to showCase for each cluster...Its runs for every cluster
            calculator={(markers, numStyles) => {
              let index = 0, count = markers.length;

              let venueFiltered = venues
                .filter((venue) => {
                  return markers.some((marker) => {
                    return (
                      Number(venue.lat??0) === marker.position.lat() &&
                      Number(venue.lng??0) === marker.position.lng()
                    );
                  });
                });
              
              if(venueFiltered.map(v => v.zone).includes("RED_ZONE"))
                index = 1;
              else if(venueFiltered.map(v => v.zone).includes("ORANGE_ZONE") || venueFiltered.map(v => v.zone).includes("YELLOW_ZONE"))
                index = 2;
              else
                index = 3; 

              return {
                text: count,
                index: index,
              };
            }}
          >
            {(clusterer) => (
              <div>
                {venues &&
                  venues.map((item, index) => {
                    if (item.lat === undefined)
                      return <React.Fragment key={index}></React.Fragment>;

                    return (
                      <>
                        <Marker
                          key={index}
                          clusterer={clusterer}
                          onClick={(e) => {
                            setSelectedLoc([item]);
                            map?.panTo({
                              lat: Number(item.lat??0),
                              lng: Number(item.lng??0),
                            })
                          }}
                          position={{
                            lat: Number(item.lat??0),
                            lng: Number(item.lng??0),
                          }}
                          icon={{
                            url: zoneMap[item.zone],
                          }}
                        >
                        </Marker>
                      </>
                    );
                  })}
              </div>
            )}
          </MarkerClusterer>
        </GoogleMap> 
        </div> : <div className="text-center p-5" style={{height: "360px"}}>
            <Spinner color="primary"/>
          </div>}
      </div>
    </div>
  );
};

MapWidget.propTypes = {};

MapWidget.defaultProps = {};

export default MapWidget;
